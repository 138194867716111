import Config from '../config/Config';
import { post } from './default';

export function reportError(
    restauId,
    email,
    { msg, url, lineNo, columnNo, error }
) {
    try {
        const apiUrl = Config.report_error;
        const errorString = error
            ? JSON.stringify(
                  {
                      name: error.name,
                      message: error.message,
                      stack: error.stack,
                  },
                  null,
                  2
              )
            : null;
        return post(apiUrl, {
            restauId,
            email,
            error: { msg, url, lineNo, columnNo, error: errorString },
        });
    } catch (e) {
        console.log('Failed to report');
    }
}

export function reportWarning(
    restauId,
    email,
    { msg, url, lineNo, columnNo, error }
) {
    try {
        const apiUrl = Config.report_warning;
        const errorString = error
            ? JSON.stringify(
                  {
                      name: error.name,
                      message: error.message,
                      stack: error.stack,
                  },
                  null,
                  2
              )
            : null;
        return post(apiUrl, {
            restauId,
            email,
            error: { msg, url, lineNo, columnNo, error: errorString },
        });
    } catch (e) {
        console.log('Failed to report');
    }
}
