import React, { useContext } from 'react';
import styled from 'styled-components';
import { formatPrice } from '../Data/FoodData';
import { getPrice } from '../FoodDialog/FoodDialog';
import Collapsible from '../Menu/Collapsible';
import { RibbonButton } from '../Navbar/Ribbon';
import {
    DetailItem,
    OrderContainerNonClickable,
    Title20px,
    ClearButton,
} from './Feed';
import ItemMetaData from '../components/modifiers/ItemMetaData';

import {
    markTakeoutOrderDoneImpl,
    markTakeoutOrderAccepted,
    TakeoutItem,
} from './QsrAndTakeoutOrders';
import { getLocalDateTime, getQsrTableIdLabel } from '../utils/Util';
import { useNotes } from '../Hooks/useNotes';
import { useState } from 'react';
import { corianderGreen, pomegranateRed } from '../Styles/colors';
import {
    OrderContainer,
    getTakeOutSubTotal,
    ButtonContainerCenter,
} from './Order';
import OrderPrintButton from '../components/common/OrderPrintButton';
import PayButton from '../components/PayButton';
import { PaymentContext } from '../contexts/PaymentContext';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const SearchOrderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0px 10px 0px;

    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
`;

export const SearchOrderText = styled.div`
    height: 20px;
    padding: 5px;
    text-align: center;
`;

export function SearchTakeoutOrder({
    tab,
    restauId,
    enqueueSnackbar,
    setRefundOrder,
    minCreditCardFees,
    minCreditCardAmt,
    salesTax,
    restauName,
    setAcceptOrderDialog,
    showPrintPrice,
    restauAddress,
    restauPhoneNumber,
}) {
    const { terminalReaderConnected, processCashPayment } = useContext(
        PaymentContext
    );

    const orderNumberSearch = useNotes();
    const [searchResult, setSearchResult] = useState(null);

    const database = window.customerApp.database();

    async function searchOrderNumber() {
        if (!orderNumberSearch.value) {
            return;
        }
        var number = parseInt(orderNumberSearch.value.trim());
        var tableSearchOrder = [
            '/takeout_orders_completed/',
            '/takeout_orders/',
        ];
        if (tab === 'Tasks') {
            tableSearchOrder = [
                '/takeout_orders/',
                '/takeout_orders_completed/',
            ];
        }
        var result = (await database
            .ref(tableSearchOrder[0] + restauId)
            .orderByChild('number')
            .equalTo(number)
            .once('value')).val();
        if (!result) {
            result = (await database
                .ref(tableSearchOrder[1] + restauId)
                .orderByChild('number')
                .equalTo(number)
                .once('value')).val();
        }
        if (result) {
            setSearchResult(result);
        } else {
            enqueueSnackbar('No data for order # ' + orderNumberSearch.value, {
                variant: 'error',
            });
        }
    }

    function clearOrderNumber() {
        orderNumberSearch.setValue('');
        setSearchResult(null);
    }

    function searchBoxEnterPressed(e) {
        if (e.key === 'Enter') {
            searchOrderNumber();
        }
    }

    function handleChange(e) {
        orderNumberSearch.onChange(e);
        if (!e.target.value) {
            setSearchResult(null);
        }
    }
    function getScheduledOrderTitle(o){
        if(o['scheduledOrder']){
            return "/ Scheduled Order[ "+o['scheduledDate'] +" " +o['scheduledTime']+" ]";
        }else{
            return "";
        }
    }
    return (
        <>
            <SearchOrderContainer>
                <TextField
                    id="order_number"
                    name="order_number"
                    label="Enter Order Number"
                    placeholder="order #"
                    type="search"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    value={orderNumberSearch.value}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        type: 'number',
                        pattern: '[0-9]*',
                    }}
                    onChange={handleChange}
                    onKeyPress={searchBoxEnterPressed}
                />
                <div>
                    <Button
                        variant="contained"
                        onClick={() => searchOrderNumber()}
                    >
                        Search
                    </Button>
                    {searchResult && Object.keys(searchResult).length > 0 && (
                        <Button
                            variant="contained"
                            style={{ marginLeft: '10px' }}
                            onClick={() => clearOrderNumber()}
                        >
                            Clear
                        </Button>
                    )}
                </div>
            </SearchOrderContainer>
            {searchResult &&
                Object.keys(searchResult)
                    .reverse()
                    .map(tkey => (
                        <Collapsible
                            key={'search' + tkey}
                            trigger={
                                'Order # ' +
                                searchResult[tkey]['number'] +
                                ' / ' +
                                getQsrTableIdLabel(
                                    searchResult[tkey],
                                    'Table-',
                                    ' / '
                                ) +
                                (searchResult[tkey]['orderUnderName']
                                    ? searchResult[tkey]['orderUnderName'] +
                                      ' / '
                                    : '') +
                                (searchResult[tkey]['payment_success']
                                    ? 'Paid ' +
                                      (searchResult[tkey]['paid_in_store']
                                          ? '(Store) '
                                          : '') +
                                      '/ '
                                    : 'Unpaid / ') +
                                searchResult[tkey]['orderType'] +getScheduledOrderTitle(searchResult[tkey])+
                                ' / ' +
                                getLocalDateTime(
                                    searchResult[tkey]['created_timestamp']
                                )
                            }
                            triggerStyle={{
                                backgroundColor: searchResult[tkey][
                                    'payment_success'
                                ]
                                    ? '#c3f6c3'
                                    : '#f6c3c3',
                            }}
                            open={true}
                        >
                            {searchResult[tkey]['orderInstruction'] && (
                                <div>
                                    {'Order Instruction :' +
                                        searchResult[tkey]['orderInstruction']}
                                </div>
                            )}
                            <div>
                                Phone Number :{' '}
                                {searchResult[tkey]['phoneNumber']
                                    ? searchResult[tkey]['phoneNumber']
                                    : 'Not Given'}
                                &nbsp;/ {searchResult[tkey]['customerType']}
                            </div>
                            <div>Order Id : {tkey}</div>
                            {searchResult[tkey]['paid_in_store'] && (
                                <div>Paid In Store</div>
                            )}
                            {searchResult[tkey]['paid_in_cash'] && (
                                <div>Cash payment</div>
                            )}
                            {searchResult[tkey]['scheduledOrder'] && (
                                <div>
                                    Scheduled Order:{' '}
                                    {searchResult[tkey]['scheduledDate'] +
                                        ' ' +
                                        searchResult[tkey]['scheduledTime']}
                                </div>
                            )}

                            {searchResult[tkey]['deliveryAddress'] && (
                                <div>
                                     Delivery Address:{' '}
                                    {searchResult[tkey]['deliveryAddress'] }
                                </div>
                            )}
                            {searchResult[tkey]['deliveryScheduledDate'] && (
                                <div>
                                   Delivery Scheduled Date:{' '} 
                                    {searchResult[tkey]['deliveryScheduledDate'] }
                                </div>
                            )}

                            {searchResult[tkey]['deliveryScheduledTime'] && (
                                <div>
                                   Delivery Scheduled Time :{' '} 
                                    {searchResult[tkey]['deliveryScheduledTime'] }
                                </div>
                            )}
                            {searchResult[tkey]['created_timestamp'] && (
                                <div>
                                    Customer Checkout Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey]['created_timestamp']
                                    )}
                                </div>
                            )}
                            {searchResult[tkey][
                                'payment_success_timestamp'
                            ] && (
                                <div>
                                    Payment Success Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey][
                                            'payment_success_timestamp'
                                        ]
                                    )}
                                </div>
                            )}
                            {searchResult[tkey]['accepted_timestamp'] && (
                                <div>
                                    Accepted Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey]['accepted_timestamp']
                                    )}
                                </div>
                            )}
                            {typeof searchResult[tkey][
                                'estimated_time_in_minutes'
                            ] != 'undefined' && (
                                <div>
                                    ETA sent to customer :{' '}
                                    {
                                        searchResult[tkey][
                                            'estimated_time_in_minutes'
                                        ]
                                    }{' '}
                                    mins
                                </div>
                            )}
                            {searchResult[tkey]['ready_timestamp'] && (
                                <div>
                                    Marked as Ready Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey]['ready_timestamp']
                                    )}
                                </div>
                            )}
                            {searchResult[tkey]['paid_in_store_timestamp'] && (
                                <div>
                                    Paid In Store Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey][
                                            'paid_in_store_timestamp'
                                        ]
                                    )}
                                </div>
                            )}
                            {searchResult[tkey]['completed_timestamp'] && (
                                <div>
                                    Completed Time :{' '}
                                    {getLocalDateTime(
                                        searchResult[tkey][
                                            'completed_timestamp'
                                        ]
                                    )}
                                </div>
                            )}
                            {searchResult[tkey]['status'] == 'VOID' && (
                                <div>Order Cancelled</div>
                            )}

                            {searchResult[tkey]['status'] != 'VOID' && (
                                <>
                                    {Object.keys(
                                        searchResult[tkey]['items']
                                    ).map((key, index) => (
                                        <OrderContainerNonClickable key={key}>
                                            <TakeoutItem>
                                                <Title20px>
                                                    {
                                                        searchResult[tkey][
                                                            'items'
                                                        ][key].name
                                                    }
                                                </Title20px>
                                                <Title20px>
                                                    Qty-
                                                    {
                                                        searchResult[tkey][
                                                            'items'
                                                        ][key].quantity
                                                    }
                                                </Title20px>
                                                <div>
                                                    {formatPrice(
                                                        getPrice(
                                                            searchResult[tkey][
                                                                'items'
                                                            ][key]
                                                        ) / 100
                                                    )}
                                                </div>
                                            </TakeoutItem>
                                            <ItemMetaData
                                                item={
                                                    searchResult[tkey]['items'][
                                                        key
                                                    ]
                                                }
                                            />
                                        </OrderContainerNonClickable>
                                    ))}
                                    <OrderContainer>
                                        <TakeoutItem>
                                            <div>Sub-Total</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    getTakeOutSubTotal(
                                                        searchResult[tkey][
                                                            'items'
                                                        ]
                                                    ) / 100
                                                )}
                                            </div>
                                        </TakeoutItem>
                                        {searchResult[tkey][
                                            'minCreditCardFees'
                                        ] ? (
                                            <TakeoutItem>
                                                <div>
                                                    Credit Card fees <br />{' '}
                                                    (order &lt;{' '}
                                                    {formatPrice(
                                                        minCreditCardAmt / 100
                                                    )}
                                                    )
                                                </div>
                                                <div />
                                                <div>
                                                    {formatPrice(
                                                        minCreditCardFees / 100
                                                    )}
                                                </div>
                                            </TakeoutItem>
                                        ) : (
                                            ''
                                        )}

                                        {searchResult[tkey][
                                            'deliveryFees'
                                        ] ? (
                                            <TakeoutItem>
                                                <div>
                                                    Delivery fees  
                                                </div>
                                                <div />
                                                <div>
                                                    {formatPrice(
                                                        searchResult[tkey][
                                                            'deliveryFees'
                                                        ] / 100
                                                    )}
                                                </div>
                                            </TakeoutItem>
                                        ) : (
                                            ''
                                        )}
                                        {searchResult[tkey][
                                            'convenientFee'
                                        ] ? (
                                            <TakeoutItem>
                                                <div>
                                                    Convenient fees  
                                                </div>
                                                <div />
                                                <div>
                                                    {formatPrice(
                                                        searchResult[tkey][
                                                            'convenientFee'
                                                        ] / 100
                                                    )}
                                                </div>
                                            </TakeoutItem>
                                        ) : (
                                            ''
                                        )}
                                        <TakeoutItem>
                                            <div>Tax</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    Math.round(
                                                        (getTakeOutSubTotal(
                                                            searchResult[tkey][
                                                                'items'
                                                            ]
                                                        ) *
                                                            salesTax) /
                                                            100
                                                    ) / 100
                                                )}
                                            </div>
                                        </TakeoutItem>
                                        <TakeoutItem>
                                            <div>Tip</div>
                                            <div />
                                            <div>
                                                {searchResult[tkey].tip
                                                    ? formatPrice(
                                                          searchResult[tkey]
                                                              .tip / 100
                                                      )
                                                    : ''}
                                            </div>
                                        </TakeoutItem>
                                        <TakeoutItem>
                                            <div>Total</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    searchResult[tkey][
                                                        'payment_total'
                                                    ] / 100
                                                )}
                                            </div>
                                        </TakeoutItem>
                                        {!searchResult[tkey][
                                            'payment_success'
                                        ] &&
                                            searchResult[tkey]
                                                .total_amount_paid && (
                                                <>
                                                    <TakeoutItem>
                                                        <div>Amount Paid</div>
                                                        <div />
                                                        <div>
                                                            {formatPrice(
                                                                searchResult[
                                                                    tkey
                                                                ]
                                                                    .total_amount_paid /
                                                                    100
                                                            )}
                                                        </div>
                                                    </TakeoutItem>
                                                    <TakeoutItem>
                                                        <div>Unpaid Amount</div>
                                                        <div />
                                                        <div>
                                                            {formatPrice(
                                                                (searchResult[
                                                                    tkey
                                                                ]
                                                                    .payment_total -
                                                                    searchResult[
                                                                        tkey
                                                                    ]
                                                                        .total_amount_paid) /
                                                                    100
                                                            )}
                                                        </div>
                                                    </TakeoutItem>
                                                </>
                                            )}
                                    </OrderContainer>
                                    <ButtonContainerCenter>
                                        <RibbonButton
                                            color={
                                                searchResult[tkey][
                                                    'payment_success'
                                                ]
                                                    ? `${corianderGreen};`
                                                    : `${pomegranateRed};`
                                            }
                                            disabled={
                                                searchResult[tkey]['status']
                                            }
                                            onClick={e => {
                                                markTakeoutOrderAccepted(
                                                    e,
                                                    searchResult[tkey],
                                                    setAcceptOrderDialog,
                                                    restauId,
                                                    restauName
                                                );
                                                clearOrderNumber();
                                            }}
                                        >
                                            Accept
                                        </RibbonButton>
                                        <OrderPrintButton
                                            order={searchResult[tkey]}
                                            reprint={true}
                                        >
                                            Print Order
                                        </OrderPrintButton>
                                        <OrderPrintButton
                                            order={searchResult[tkey]}
                                            receipt={true}
                                        >
                                            Print Receipt
                                        </OrderPrintButton>
                                        {/* Am clearing search result after refund, as its not possible to update the search results after refund automatically. What I fear is waiters refunding the same item twice again.  */}
                                        <RibbonButton
                                            color={
                                                searchResult[tkey][
                                                    'payment_success'
                                                ]
                                                    ? `${corianderGreen};`
                                                    : `${pomegranateRed};`
                                            }
                                            onClick={() => {
                                                setRefundOrder(
                                                    searchResult[tkey]
                                                );
                                                clearOrderNumber();
                                            }}
                                        >
                                            Refund
                                        </RibbonButton>
                                        <RibbonButton
                                            color={
                                                searchResult[tkey][
                                                    'payment_success'
                                                ]
                                                    ? `${corianderGreen};`
                                                    : `${pomegranateRed};`
                                            }
                                            disabled={
                                                searchResult[tkey]['status'] ===
                                                    'READY' ||
                                                searchResult[tkey]['status'] ===
                                                    'DONE' ||
                                                searchResult[tkey]['status'] ===
                                                    'ARCHIVE'
                                            }
                                            onClick={() => {
                                                markTakeoutOrderDoneImpl(
                                                    searchResult[tkey],
                                                    restauId,
                                                    restauName,
                                                    enqueueSnackbar
                                                );
                                                clearOrderNumber();
                                            }}
                                        >
                                            Ready
                                        </RibbonButton>
                                        {!searchResult[tkey][
                                            'payment_success'
                                        ] &&
                                            (searchResult[tkey]['status'] ===
                                                'DONE' ||
                                            searchResult[tkey]['status'] ===
                                                'ARCHIVE' ||
                                            !terminalReaderConnected ? (
                                                // <RibbonButton color={pomegranateRed} disabled={(searchResult[tkey]['status'] === 'DONE' || searchResult[tkey]['status'] === 'ARCHIVE')} onClick={() => {markTakeoutOrderPaidInStoreImpl(searchResult[tkey], restauId, enqueueSnackbar); clearOrderNumber();}}>Paid</RibbonButton>
                                                <AsyncProgressRibbonButton
                                                    color={pomegranateRed}
                                                    isDisabled={
                                                        searchResult[tkey][
                                                            'status'
                                                        ] === 'DONE' ||
                                                        searchResult[tkey][
                                                            'status'
                                                        ] === 'ARCHIVE'
                                                    }
                                                    onClick={async () => {
                                                        try {
                                                            const resultMsg = await processCashPayment(
                                                                searchResult[
                                                                    tkey
                                                                ]
                                                            );
                                                            if (
                                                                resultMsg &&
                                                                resultMsg.length >
                                                                    0
                                                            ) {
                                                                enqueueSnackbar(
                                                                    resultMsg,
                                                                    {
                                                                        variant:
                                                                            'success',
                                                                    }
                                                                );
                                                                clearOrderNumber();
                                                            }
                                                        } catch (error) {
                                                            enqueueSnackbar(
                                                                'Accepting cash Payment failed.' +
                                                                    error,
                                                                {
                                                                    variant:
                                                                        'error',
                                                                }
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <b>Paid</b>
                                                </AsyncProgressRibbonButton>
                                            ) : (
                                                <PayButton
                                                    order={searchResult[tkey]}
                                                />
                                            ))}
                                    </ButtonContainerCenter>
                                </>
                            )}
                        </Collapsible>
                    ))}
        </>
    );
}
