import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function SectionList(props) {
    const { filteredMenu, onSectionClick } = props;
    return (
        <div
            style={{
                position: 'sticky',
                top: 10,
                zIndex: 5,
                backgroundColor: 'rgba(250,250,250,0.95)',
                marginTop: 10,
            }}
        >
            <div style={{ overflow: 'hidden' }}>
                <div
                    style={{
                        overflow: 'auto',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: 5,
                        padding: 10,
                    }}
                >
                    {filteredMenu.map((section, index) => {
                        return (
                            <div>
                                <Button
                                    onClick={() => {
                                        console.log(index, section.id);
                                        onSectionClick(section.id);
                                    }}
                                    color="primary"
                                    variant="outlined"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {section.name}
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

SectionList.propTypes = {};

export default SectionList;
