import React, { useContext, useEffect, useState } from 'react';
import { CreateOrderContext, ORDER_TYPE } from '../contexts/CreateOrderContext';
import { OrderContext } from '../contexts/OrderContext';
import { RestaurantContext } from '../contexts/RestaurantContext';
import { isDineInMode, isTakeoutMode } from '../Hooks/useOrders';
import { Menu } from './Menu';
import addOrder from './utils/addOrder';

function MenuContainer(props) {
    const {
        tableId,
        setOpenFood,
        menu,
        activeSection,
        showCount,
        searchTerm,
    } = props;

    const { inProgressOrderType, addItem } = useContext(CreateOrderContext);
    const [scrollToView, setScrollToView] = useState(false);
    const [currentSection, setCurrentSection] = useState(menu[0].id);
    const { restauId } = useContext(RestaurantContext);
    const { operationMode } = useContext(OrderContext);

    useEffect(() => {
        if (!activeSection || !scrollToView) {
            return;
        }
        const timeoutId = setTimeout(() => {
            const element = document.getElementById(`section-${activeSection}`);
            if (element) {
                window.scrollTo({
                    behavior: element ? 'smooth' : 'auto',
                    top: element.offsetTop - 100,
                });
            }
        }, 300);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [activeSection, scrollToView]);

    useEffect(() => {
        setCurrentSection(currentSection);
        setScrollToView(true);
    }, [currentSection]);

    useEffect(() => {
        if (searchTerm) {
            setCurrentSection('');
            setScrollToView(false);
        } else {
            setCurrentSection(activeSection);
            setScrollToView(true);
        }
    }, [activeSection, searchTerm]);

    useEffect(() => {
        setCurrentSection(menu[0].id);
    }, [menu, inProgressOrderType]);

    useEffect(() => {
        setScrollToView(false);
    }, [inProgressOrderType]);

    const handleAddToOrder = order => {
        const status = 'DRAFT';
        if (
            isDineInMode(operationMode) &&
            inProgressOrderType === ORDER_TYPE.DINEIN
        ) {
            addOrder(restauId, tableId, order, status);
        } else if (
            isTakeoutMode(operationMode) &&
            inProgressOrderType === ORDER_TYPE.TAKEOUT
        ) {
            addItem(
                order.id,
                order.name,
                order.quantity,
                order.price,
                order.desc,
                '',
                order.toppings,
                order.choice,
                order.choiceId,
                order.choices,
                order.notes,
                'DRAFT',
                order.station,
                order.modifierGroup,
                order.isCustomItem
            );
        }
    };
    return (
        <Menu
            setOpenFood={setOpenFood}
            menu={menu}
            margin={'0px'}
            padding={'0px'}
            activeSection={currentSection}
            showCount={showCount}
            scrollToView={scrollToView}
            onSectionClick={setCurrentSection}
            onAddToOrder={handleAddToOrder}
            tableId={tableId}
        />
    );
}

MenuContainer.propTypes = {};

export default MenuContainer;
