import React, { useState } from 'react';
import { SmallCircleButton } from './CircleButton';

const ESTIMATED_WAIT_MINUTES = [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
export default function EstimatedWaitTimeInput({
    estimatedTimeInMins,
    setEstimatedTimeInMins,
}) {
    const defaultOrEstimated =
        estimatedTimeInMins && estimatedTimeInMins >= 0
            ? estimatedTimeInMins
            : 0;
    return (
        <>
            {ESTIMATED_WAIT_MINUTES.map(min => (
                <SmallCircleButton
                    key={min}
                    selected={min === defaultOrEstimated}
                    onClick={() => setEstimatedTimeInMins(min)}
                >
                    {min == 0 ? '-' : min}
                </SmallCircleButton>
            ))}
        </>
    );
}
