import React from 'react';
import styled from 'styled-components';
import {
    Header,
    HtmlContainer,
    OrderHeader,
    OrderItems,
    OrderTotal,
    StationFooter,
} from './components';
import { pStyleH1, pStyleH2, pStyleH4 } from './components/TemplateStyles';

export function PrintReceipt({
    takeoutOrder,
    showPrices,
    restauName,
    address,
    phonenumber = '',
}) {
    //let showPrices = props.showPrices || !takeoutOrder.payment_success || receipt;
    const isCancelled =
        takeoutOrder.isUpdate &&
        (!takeoutOrder.items || Object.keys(takeoutOrder.items).length == 0);
    const isUpdated = takeoutOrder.isUpdate && !isCancelled;

    const tableId = takeoutOrder['tableId'];
    const isTogo = takeoutOrder.orderType == 'To-go';

    const timeStamp = takeoutOrder.updated_timestamp
        ? takeoutOrder.updated_timestamp
        : takeoutOrder.created_timestamp;

    return (
        <HtmlContainer>
            <Header {...{ restauName, address, phonenumber }} />
            <OrderHeader
                {...{
                    isCancelled,
                    isUpdated,
                    number: takeoutOrder.number,
                    orderUnderName: takeoutOrder.orderUnderName,
                    isTogo,
                    tableId,
                    timeStamp,
                    paymentStatus: takeoutOrder.payment_success == true,
                    scheduledOrder: takeoutOrder.scheduledOrder,
                    scheduledDate: takeoutOrder.scheduledDate,
                    scheduledTime: takeoutOrder.scheduledTime,
                    deliveryScheduledForLater: takeoutOrder.deliveryScheduledForLater,
                    deliveryScheduledDate:takeoutOrder.deliveryScheduledDate,
                    deliveryScheduledTime: takeoutOrder.deliveryScheduledTime,
                    orderType: takeoutOrder.orderType
                }}
            />
            {!isCancelled && (
                <OrderItems
                    {...{ takeoutOrder, showPrices, highLightItem: false }}
                />
            )}
            <OrderTotal {...{ takeoutOrder }} />
            <div
                style={{ textAlign: 'center', marginTop: '15px', ...pStyleH4 }}
            >
                Thank you!
            </div>
        </HtmlContainer>
    );
}
