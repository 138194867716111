import { useState, useEffect } from 'react';

export function useSoftwareVersion(restauId) {
    const [softwareVersion, setSoftwareVersion] = useState(null);
    const [updateAvailable, setUpdateAvailable] = useState(false);

    function detachListener() {
        const database = window.customerApp.database();
        var swVersionRef = database.ref('/software_version/waiter');
        swVersionRef.off();
        console.log('Stopping waiter_software_version Listener', restauId);
    }

    async function updateSoftware() {
        const database = window.customerApp.database();
        var update = {};
        update[
            'menus/' +
                restauId +
                '/details/software_version/waiter/globalVersionInvocator'
        ] = softwareVersion;
        await database.ref().update(update);
        setUpdateAvailable(false);
        window.location.reload();
        return false;
    }

    useEffect(() => {
        async function setupSoftwareUpdater() {
            if (restauId === 'None') {
                return;
            }
            console.log('CREATING waiter_software_version LISTENER', restauId);
            const database = window.customerApp.database();
            database
                .ref('/software_version/waiter')
                .on('value', async snapshot => {
                    const versionElement = document.getElementById(
                        'softwareVersion'
                    );
                    if (!versionElement) {
                        return;
                    }
                    const versionHtmlStr = versionElement.innerHTML;
                    console.log(
                        '/software_version/waiter  DB, HTML :',
                        snapshot.val(),
                        versionHtmlStr
                    );
                    if (versionHtmlStr !== snapshot.val()) {
                        setUpdateAvailable(true);
                    } else {
                        setUpdateAvailable(false);
                        var update = {};
                        update[
                            'menus/' +
                                restauId +
                                '/details/software_version/waiter/versionInHtml'
                        ] = versionHtmlStr;
                        await database.ref().update(update);
                    }
                    setSoftwareVersion(snapshot.val());
                });

            return () => detachListener();
        }
        setupSoftwareUpdater();
    }, [restauId]);

    return {
        softwareVersion,
        updateAvailable,
        updateSoftware,
        setUpdateAvailable,
    };
}
