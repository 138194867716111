import React from 'react';
import { pStyleH2, pStyleH3, pStyleH4, pStyleText } from './TemplateStyles';

export const TABLEROW_COLUMN_STYLE = {
    TWO_COLUMN_SPACE_BETWEEN: 'TWO_COLUMN_SPACE_BETWEEN',
    TWO_COLUMN_SPACE_BETWEEN: 'TWO_COLUMN_LEFT_ALIGHT',
    THREE_COLUMN: 'THREE_COLUMN',
};

export function TableRow({
    cell1,
    cell2,
    cell3,
    cell1Width = 16,
    cell2Width = 64,
    cell3Width = 20,
    borderTop,
    borderBottom,
    rowStyle,
}) {
    const group1Width = cell1Width + cell2Width;
    const subCell1Width = Math.floor((cell1Width * 100) / group1Width);
    const subCell2Width = Math.floor((cell2Width * 100) / group1Width);
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                ...(borderBottom ? { borderBottom: 'solid 1px' } : {}),
                ...(borderTop ? { borderTop: 'solid 1px' } : {}),
            }}
        >
            <div
                style={{
                    ...(cell3 ? { flex: '0 0 80%' } : { flex: '0 0 100%' }),
                    flex: `0 0 ${group1Width}%`,
                    display: 'flex',
                    flexWrap: 'wrap',
                    ...(rowStyle ==
                    TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN
                        ? { justifyContent: 'space-between' }
                        : { justifyContent: 'left' }),
                }}
            >
                <div
                    style={{
                        flex: '0 0 20%',
                        flex: `0 0 ${subCell1Width}%`,
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: '2px',
                        justifyContent: 'flex-start',
                    }}
                >
                    {cell1}
                </div>
                {typeof cell2 != 'undefined' && (
                    <div
                        style={{
                            flex: '0 0 80%',
                            flex: `0 0 ${subCell2Width}%`,
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginBottom: '2px',
                            ...(rowStyle ==
                            TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN
                                ? { justifyContent: 'flex-end' }
                                : { justifyContent: 'flex-start' }),
                        }}
                    >
                        {cell2}
                    </div>
                )}
            </div>
            {typeof cell3 != 'undefined' && (
                <div
                    style={{
                        flex: '2 0 20%',
                        flex: `2 0 ${cell3Width}%`,
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: '2px',
                        justifyContent: 'flex-end',
                    }}
                >
                    {cell3}
                </div>
            )}
        </div>
    );
}
