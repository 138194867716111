import dateFormat from 'dateformat';

export function getLocalDate(utcDateTimeStr) {
    if (!utcDateTimeStr) {
        return '';
    }
    var localDate = new Date(utcDateTimeStr);
    return dateFormat(localDate, 'mmm dd');
}

export function getLocalTime(utcDateTimeStr) {
    if (!utcDateTimeStr) {
        return '';
    }
    var localDate = new Date(utcDateTimeStr);
    return dateFormat(localDate, 'h:MM TT');
}

export function getLocalDateTime(utcDateTimeStr) {
    if (!utcDateTimeStr) {
        return '';
    }
    var localDate = new Date(utcDateTimeStr);
    return dateFormat(localDate, 'mmm dd, h:MM TT');
}

export function getLocalDateTimeWithYear(utcDateTimeStr) {
    if (!utcDateTimeStr) {
        return '';
    }
    var localDate = new Date(utcDateTimeStr);
    return dateFormat(localDate, 'mmm dd yyyy, h:MM TT');
}

export function getLocalTimeFromDateObj(utcDateTime) {
    try {
        return dateFormat(utcDateTime, 'h:MM TT');
    } catch {
        return '';
    }
}

export function getCurrentLocalDateTime() {
    var localDate = new Date();
    return dateFormat(localDate, 'mmm dd yyyy, h:MM TT');
}

// time in local timezone : https://stackoverflow.com/questions/12945003/format-date-as-yyyy-mm-ddthhmmss-sssz
//   var dateTime = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
export function getDateTime() {
    return new Date().toISOString();
}

//convert timeStr to today datetime object
// timeStr supported are "hh:mm am|pm" or "hh am|pm"
export function parseTodayTime(timeStr) {
    const now = new Date();
    const dateStr = now.toLocaleDateString();
    if (!timeStr.includes(':')) {
        timeStr = timeStr.replace(' ', ':00 ');
    }
    return Date.parse(dateStr + ' ' + timeStr);
}

export function getQsrTableIdLabel(takeoutorder, preStr, postStr) {
    if (takeoutorder['customerType'] === 'QSR-Dine-In') {
        return (
            (preStr ? preStr : '') +
            takeoutorder['tableId'] +
            (postStr ? postStr : '')
        );
    }
    return '';
}

export function filterObjectByEntry(obj, predicate) {
    return Object.fromEntries(Object.entries(obj).filter(predicate));
}

export function filterPrivateFields(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => !key.startsWith('_'))
    );
}

export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
    );
}

export function delay(t, v) {
    return new Promise(function(resolve) {
        setTimeout(resolve.bind(null, v), t);
    });
}
export function notUndefinedAndNull(str) {
    return !isUndefinedOrNull(str);
}

export function isUndefinedOrNull(str) {
    return typeof str == 'undefined' || str == null;
}

export function isUndefinedOrNullOrEmpty(str) {
    return typeof str == 'undefined' || str == null || str === '';
}
