import React, { useState } from 'react';
//import AsyncProgressRibbonButton from "../../CreateOrder/AsyncProgressRibbonButton";
import { RibbonButton } from '../../Navbar/Ribbon';
import { pomegranateRed } from '../../Styles/colors';
import CashOrCardDialog from './CashOrCardDialog';

export default function PayButton({ color = pomegranateRed, order, disabled }) {
    const [showPaymentModeDialog, setShowPaymentModeDailog] = useState(false);
    return (
        <>
            {showPaymentModeDialog && (
                <CashOrCardDialog
                    onClose={() => setShowPaymentModeDailog(false)}
                    order={order}
                />
            )}
            <RibbonButton
                color={color}
                onClick={e => {
                    setShowPaymentModeDailog(true);
                }}
                disabled={
                    showPaymentModeDialog || disabled || order.payment_success
                }
            >
                <b>{order.payment_success ? 'Paid' : 'Pay'}</b>
            </RibbonButton>
        </>
    );
}
