import styled from 'styled-components';
import {
    corianderGreen,
    pomegranateRed,
    whisperGray,
} from '../../Styles/colors';

export const CircleButton = styled.div`
    border: 2px;
    height: 20px;
    min-width: 20px;
    max-width: 42px;
    border-radius: 50%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    background-color: ${props => {
        const variants = {
            success: corianderGreen,
            error: pomegranateRed,
            default: whisperGray,
        };
        return (
            variants[props.variant] ||
            (props.selected ? corianderGreen : whisperGray)
        );
    }};
    text-decoration: none;
    display: inline-block;
    margin: 5px 5px;
`;

export const SmallCircleButton = styled(CircleButton)`
    height: 16px;
    width: 16px;
    padding: 16px;
`;
