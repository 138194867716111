import { useState } from 'react';

export function useNumOfPrints(defaultNum) {
    const [numOfPrints, setNumOfPrints] = useState(defaultNum);

    function onChange(e) {
        setNumOfPrints(+e.target.value);
    }

    return {
        numOfPrints,
        onChange,
        setNumOfPrints,
    };
}
