import React, { createContext, useEffect, useState } from 'react';
import { parseTodayTime } from '../../utils/Util';

//context providing access to this store.
const RestaurantContext = createContext({});
//const RestaurantProvider = RestaurantContext.Provider;

function RestaurantProvider({ value, children }) {
    const restauId = value.restauId;
    const daysOfTheWeek = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };

    const [outsideBizHours, setOutsideBizHours] = useState(true);
    async function getSpecialHours(regularHour, specialHours) {
        if (!specialHours) {
            return regularHour;
        }
        let today = new Date().toLocaleDateString();
        today = today.split('/').join('');
        if (!specialHours[today]) {
            return regularHour;
        }
        let day = daysOfTheWeek[new Date().getDay()];
        let newHours = { ...regularHour };
        if (specialHours[today]['holiday']) {
            delete newHours[day];
            return newHours;
        } else if (!specialHours[today]['hours']) {
            return regularHour;
        } else {
            newHours[day] = specialHours[today]['hours'];
            return newHours;
        }
    }
    useEffect(() => {
        if (restauId === 'None') {
            return;
        }
        const fetchOutsizeBizHoursFn = async () => {
            const database = window.customerApp.database();
            var hours = (await database
                .ref('menus/' + restauId + '/details/hours')
                .once('value')).val();
            const specialHours = (await database
                .ref('menus/' + restauId + '/details/special_hours')
                .once('value')).val();
            hours = await getSpecialHours(hours, specialHours);
            if (!hours) {
                return;
            }
            const now = new Date();
            const dayStr = now.toLocaleDateString('en-US', { weekday: 'long' });
            if (dayStr in hours) {
                const operationHours = Object.values(hours[dayStr]);
                let obh =
                    operationHours.findIndex(timings => {
                        return (
                            now >= parseTodayTime(timings.start) &&
                            now <= parseTodayTime(timings.end)
                        );
                    }) < 0; //now doesnt fall in any of open time range
                setOutsideBizHours(obh);
            } else {
                setOutsideBizHours(true); //closed for the day.
            }
        };
        fetchOutsizeBizHoursFn();
        const checkBizHoursTimer = setInterval(
            () => fetchOutsizeBizHoursFn(),
            1000 * 60 * 5
        ); //every min
        return () => clearInterval(checkBizHoursTimer);
    }, [restauId]);

    const allValues = {
        ...value,
        outsideBizHours,
    };
    return (
        <RestaurantContext.Provider value={allValues}>
            {children}
        </RestaurantContext.Provider>
    );
}
export { RestaurantContext, RestaurantProvider };
