import { useState } from 'react';

export function useChoice(defaultChoice) {
    const [value, setValue] = useState(defaultChoice);

    function onChange(e) {
        setValue(e.target.value);
    }

    function onChangeNumber(e) {
        setValue(+e.target.value);
    }

    function onChangeBoolean(e) {
        if (e.target.value === 'true') {
            setValue(true);
        } else {
            setValue(false);
        }
    }

    function onClick(value) {
        setValue(value);
    }

    return {
        value,
        onChange,
        onClick,
        setValue,
        onChangeNumber,
        onChangeBoolean,
    };
}
