import styled from 'styled-components';
import { pomegranateRed } from '../Styles/colors';

export const DialogContent = styled.div`
    // overflow: auto;
    min-height: 20px;
    padding: 0px 40px;
    padding-bottom: 40px;
`;

export const MenuStyled = styled.div`
    margin: ${props => (props.margin ? props.margin : '0px 50px 100px 50px')};

    // Add this part

    @media (max-width: 720px) {
        width: 100%;
        margin: auto;
        margin-bottom: ${props => (props.margin ? props.margin : '100px')};
    }
`;

export const MenuContent = styled(DialogContent)`
    padding: ${props => (props.padding ? props.padding : '20px')};
    height: 100%;
`;

export const FoodGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
    width: 100%;
    @media (max-width: 720px) {
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }
    @media (max-width: 300px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
`;

export const FoodLabel = styled.div`
    ${props =>
        props.img !== undefined
            ? `position: absolute;`
            : `justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 5px;
    `}
`;

export const FoodContainer = styled.div`
  position: relative;
  padding: 7px 0px;
  border-bottom: 1px solid rgb(39,174,96,0.5);
  background-color: ${props =>
      props.oos || props.hide ? '#c0392b11' : 'white'}

    &:hover {
      cursor: pointer;
      background-color: ${props =>
          props.oos || props.hide ? '#c0392b11' : '#e7e7e7'};
    }
`;

export const Description = styled.div`
    color: gray;
    font-size: 10px;
    margin-top: 3px;
`;

//Collapsible trigger={sectionName}
export const BadgeSpan = styled.span`
    color: #ffffff;
    border-radius: 5px;
    background-color: ${pomegranateRed};
    white-space: nowrap;
`;

export const HiddenSpan = styled.span`
    color: #ffffff;
    border-radius: 5px;
    background-color: #2980b9;
    white-space: nowrap;
`;

export const IncrementControl = styled.div`
    position: absolute;
    bottom: -5px;
    right: 0;
    font-size: 30px;
`;
