import React from 'react';
import styled from 'styled-components';

export function SoftwareUpdate({
    updateAvailable,
    updateSoftware,
    setUpdateAvailable,
}) {
    function showVersionAlert() {
        alert(
            "New Software Version Available. Confirm to reload the page.\nNote : Don't forget to enable beeping once you reload."
        );
        updateSoftware();
        setUpdateAvailable(false);
    }

    return <>{updateAvailable && showVersionAlert()}</>;
}
