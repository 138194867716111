import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { formatPrice } from '../Data/FoodData';
import { LabelContainer } from './FoodDialog';
const ChoiceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    // Add this part
    @media (max-width: 720px) {
        grid-template-columns: 1fr;
    }
`;

const ChoiceDiv = styled.div`
    margin-top: 10px;
`;

const CursorPointer = `cursor: pointer;`;

const RadioInput = styled.input`
    ${CursorPointer}
    margin-right: 10px;
`;

export function Choices({ openFood, choiceRadio }) {
    function radioOnChange(e) {
        choiceRadio.onChange(e);
    }

    var samePrice = true;
    for (var i = 1; i < openFood.choices.length; i++) {
        if (openFood.choices[i - 1].price !== openFood.choices[i].price) {
            samePrice = false;
        }
    }

    return (
        <ChoiceDiv>
            {openFood.choiceslabel ? (
                <LabelContainer>{openFood.choiceslabel}</LabelContainer>
            ) : (
                <LabelContainer>Choose one:</LabelContainer>
            )}
            <ChoiceGrid>
                {openFood.choices.map(choice => (
                    <Fragment key={choice.id}>
                        <label>
                            <RadioInput
                                type="radio"
                                id={choice.name}
                                name="choice"
                                value={choice.name}
                                checked={choiceRadio.value === choice.name}
                                onChange={radioOnChange}
                            />
                            {choice.name}
                            {choice.price && !samePrice && (
                                <span>
                                    {' '}
                                    ({formatPrice(choice.price / 100)})
                                </span>
                            )}
                        </label>
                        {/* <Label for={choice}> {choice.name} </Label>{" "} */}
                    </Fragment>
                ))}
            </ChoiceGrid>
        </ChoiceDiv>
    );
}
