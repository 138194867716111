import { useState } from 'react';

export function useTabs(defaultTab) {
    const [tab, setTab] = useState(defaultTab);
    const [feedCount, setFeedCount] = useState(0);
    return {
        tab,
        setTab,
        feedCount,
        setFeedCount,
    };
}
