export function getUniqueItemIdentifier(item) {
    let id = item.id;
    if (item.choice) {
        id += '-' + item.choice;
    }
    if (item.toppingsStr) {
        id += '-' + item.toppingsStr;
    }
    if (item.notes) {
        let notes = item.notes.split(' ').join('');
        id += '-' + notes;
    }
    if (item.modifierGroup && item.modifierGroup.modifiers) {
        for (let modifier of Object.values(item.modifierGroup.modifiers)) {
            let modifierStr = '';
            if (modifier.options) {
                for (let option of Object.values(modifier.options)) {
                    if (option.checked) {
                        if (modifierStr !== '') {
                            modifierStr += ',';
                        }
                        modifierStr += option.label;
                    }
                }
            }
            if (modifierStr !== '') {
                modifierStr = '(mod)' + modifier.id + modifierStr;
                id += '-' + modifierStr;
            }
        }
    }
    return id;
}
