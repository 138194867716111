import { getDateTime } from '../../utils/Util';

export async function acceptTakeoutOrder(
    restauId,
    orderId,
    estimatedTimeInMins
) {
    const database = window.customerApp.database();
    let updates = {};
    updates['takeout_orders/' + restauId + '/' + orderId + '/status'] = 'WIP';
    updates[
        'takeout_orders/' + restauId + '/' + orderId + '/accepted_timestamp'
    ] = getDateTime();
    updates[
        'takeout_orders/' +
            restauId +
            '/' +
            orderId +
            '/estimated_time_in_minutes'
    ] = estimatedTimeInMins;

    await database.ref().update(updates);
}

export async function getOrderStatus(restauId, orderId) {
    const database = window.customerApp.database();
    return (await database
        .ref('takeout_orders/' + restauId + '/' + orderId + '/status')
        .once('value')).val();
}
