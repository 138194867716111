import { useState } from 'react';

export function useHoldStatus(defaultStatus) {
    const [holdStatus, setHoldStatus] = useState(defaultStatus);

    function changeHoldStatus(key) {
        const newholdStatus = { ...holdStatus };
        if (!(key in newholdStatus)) {
            newholdStatus[key] = true;
        } else {
            newholdStatus[key] = !newholdStatus[key];
        }
        setHoldStatus(newholdStatus);
    }

    function getHoldStatus(key) {
        if (!(key in holdStatus)) {
            return false;
        } else {
            return holdStatus[key];
        }
    }

    function resetHoldStatus(key) {
        setHoldStatus({});
    }

    return {
        changeHoldStatus,
        getHoldStatus,
        resetHoldStatus,
        holdStatus,
    };
}

// const [toppings, setToppings] = useState(
//   defaultTopping || getDefaultToppings()
// );
// const toppingsList = [
//   "Extra Cheese",
//   "Pepperoni",
//   "Sausage",
//   "Onions",
//   "Peppers",
//   "Pineapple",
//   "Ham",
//   "Spinach",
//   "Artichokes",
//   "Mushrooms",
//   "Anchovies"
// ];

// function getDefaultToppings() {
//   return toppingsList.map(topping => ({
//     name: topping,
//     checked: false
//   }));
//}
