import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
    DialogContent,
    DialogFooter,
    ConfirmButton,
} from '../FoodDialog/FoodDialog';
import Config from '../config/Config';
import { formatPrice } from '../Data/FoodData';
import { getPrice } from '../FoodDialog/FoodDialog';
import { RibbonButton, RibbonButtonRed } from '../Navbar/Ribbon';
import { corianderGreen, pomegranateRed } from '../Styles/colors';
import Collapsible from '../Menu/Collapsible';
import { itemReadyInKitchen, itemCookingInKitchen } from '../Hooks/useOrders';
import { useHoldStatus } from '../Hooks/useHoldStatus';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Title } from '../Styles/title';
import { renderToString } from 'react-dom/server';
import { getDateTime, getLocalDateTime, getLocalTime } from '../utils/Util';
import { MergeAndPrintOrder } from './MergeAndPrintOrder';
import { RestaurantMenuOutlined } from '@material-ui/icons';
import { Menu2 } from '../Menu/Menu2';
import { isTakeoutMode, isDineInMode, readText } from '../Hooks/useOrders';
import { getTakeOutSubTotal } from './Order';
import { SearchTakeoutOrder } from './SearchTakeoutOrder';
import PrintDialog from './PrintDialog';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import { CreateOrderContext, ORDER_TYPE } from '../contexts/CreateOrderContext';
import { QsrAndTakeoutOrders } from './QsrAndTakeoutOrders';
import { MenuContext } from '../contexts/MenuContext';
import { PrintContext } from '../contexts/PrintContext';
import ReprintDialog from '../components/print/ReprintDialog';
import { deleteItem } from './Order';
import { bulkUpdateTableOrders } from '../apis/DineIn';
import ItemMetaData from '../components/modifiers/ItemMetaData';
import { ElaspedTimeSpan } from '../components/common/ElapsedTimeSpan';
import { markAllOrdersReady } from '../apis/Takeout/markAllOrdersReady';
import { RestaurantContext } from '../contexts/RestaurantContext';
import { AutoAcceptContext } from '../contexts/AutoAcceptContext';

const { default: axios } = require('axios');

//width: 100%;
const MenuStyled = styled.div`
    margin: 0px 50px 50px 50px;
    // Add this part
    @media (max-width: 720px) {
        width: 96%;
        margin: auto;
        margin-bottom: 100px;
    }
`;
const OrderStyled = styled.div`
    position: fixed;
    right: 0px;
    top: 48px;
    width: 340px;
    background-color: white;
    height: calc(100% - 48px);
    z-index: 10;
    box-shadow: 4px 0px 5px 4px grey;
    display: flex;
    flex-direction: column;

    @media (max-width: 720px) {
        position: relative;
        width: 100%;
    }
`;

const ButtonContainer = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
`;

export const ButtonContainerCenter = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    gap: 20px;
`;
export const ButtonContainerRight = styled.div`
    height: 60px;
    display: flex;
    justify-content: right;
`;

const OrderContent = styled(DialogContent)`
    padding: 10px;
    height: 100%;
`;

export const OrderContainer = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
    ${({ editable }) =>
        editable
            ? `
    &:hover {
      cursor: pointer;
      background-color: #e7e7e7;
    }
  `
            : `
    pointer-events: none; 
  `}
`;

export const OrderContainerNonClickable = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
`;

export const Title20px = styled(Title)`
    font-size: 20px;
`;

const OrderItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 35px 125px 15px 40px 40px 40px;
    justify-content: space-between;
`;

//  grid-template-columns: 20px 150px 20px 60px;
const SummonItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 50px 150px 100px;
    justify-content: space-between;
`;

const ToKitchenItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: ${({ showEditDelete }) =>
        showEditDelete ? `200px 40px 60px 60px 60px;` : `200px 40px 60px;`};
    justify-content: space-between;
`;

const ToCustomerItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 200px 40px 60px;
    justify-content: space-between;
`;

export const ClearButton = styled.div`
    color: white;
    height: 20px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    background-color: ${({ color }) => (color ? color : `${corianderGreen};`)};
`;
//  grid-template-columns: 20px 150px 20px 60px;

//  font-size: 20px;
export const DetailItem = styled.div``;

export const DetailItemGray = styled.div`
    color: gray;
`;

function sendOrder(orders, { email, displayName }) {
    const database = window.customerApp.database();
    var newOrderRef = database.ref('orders').push();
    const newOrders = orders.map(order => {
        return Object.keys(order).reduce((acc, orderKey) => {
            if (!order[orderKey]) {
                // undefined value
                return acc;
            }
            if (orderKey === 'toppings') {
                return {
                    ...acc,
                    [orderKey]: order[orderKey]
                        .filter(({ checked }) => checked)
                        .map(({ name }) => name),
                };
            }
            return {
                ...acc,
                [orderKey]: order[orderKey],
            };
        }, {});
    });
    newOrderRef.set({
        order: newOrders,
        email,
        displayName,
    });
}

const orderStatusDict = {
    WAITER: '🤵',
    COOK: '👨‍🍳',
    DELIVERED: '🍽',
};

export const CheckboxLabel = styled.label`
    cursor: pointer;
`;

// export const PrintReceipt = (takeoutOrder, restauName, salesTax, minCreditCardAmt ) => {
//   let printData = renderToString(<PrintReceipt restauName={restauName} takeoutOrder={takeoutOrder}
//         salesTax={salesTax} minCreditCardAmt={minCreditCardAmt}/>);
// }

export const passPrintBrowser = function(printData, numCopies) {
    let passprntUri = 'starpassprnt://v1/print/nopreview?';
    passprntUri =
        passprntUri +
        'size=3&back=' +
        encodeURIComponent(window.location.origin + '/status.html');
    passprntUri = passprntUri + '&html=' + encodeURIComponent(printData);
    if (numCopies >= 1) {
        window.location.href = passprntUri;
    }
};

const ctrlPrintBrowser = function(printData) {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write(printData);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
};

export const nativePrint = function(
    printData,
    numCopies,
    enqueueSnackbar,
    setPrintPending,
    successCallback,
    failureCallback,
    station
) {
    console.log('!!!!!!!!!!!!!!!!!!Printing native');
    window.webViewBridge.send(
        'print',
        { html: printData, numCopies, station },
        function(res) {
            console.log('Print successful!!!!!!!!!!');
            enqueueSnackbar(
                'Printing Successful. Number of copies = ' + numCopies,
                { variant: 'success' }
            );
            setPrintPending && setPrintPending('false');
            successCallback && successCallback();
        },
        function(err) {
            console.log('Print failed!!!!!!!!!!', err);

            window.webViewBridge.send(
                'print',
                { html: printData, numCopies, station },
                function(res) {
                    console.log('Print successful!!!!!!!!!!');
                    enqueueSnackbar(
                        'Printing Successful. Number of copies = ' + numCopies,
                        { variant: 'success' }
                    );
                    setPrintPending && setPrintPending('false');
                    successCallback && successCallback();
                },
                function(err) {
                    console.log('Print retry failed!!!!!!!!!!', err);

                    setPrintPending && setPrintPending('false');

                    //passPrintBrowser();
                    !failureCallback &&
                        alert(
                            'Print failed. Try Again. ' +
                                JSON.stringify(err || '')
                        );
                    failureCallback && failureCallback();
                }
            );

            //setPrintPending('false');
            //passPrintBrowser();
            //alert("Print failed. Try Again. " + err.message);
        }
    );
};

export const mergePrint = (
    printData,
    numCopies,
    enqueueSnackbar,
    setPrintPending,
    _successCallBack,
    _failureCallBack
) => {
    if (numCopies === 0) {
        enqueueSnackbar('No printing as number of copies required is 0.', {
            variant: 'warning',
        });
        return;
    }
    console.log('print....');
    let printerConfig = 'passprint';
    if (printerConfig === 'passprint') {
        if (window.ReactNativeWebView) {
            nativePrint(
                printData,
                numCopies,
                enqueueSnackbar,
                setPrintPending,
                _successCallBack,
                _failureCallBack,
                'kitchen'
            );
        } else {
            passPrintBrowser(printData, numCopies);
        }
    } else {
        ctrlPrintBrowser(printData);
    }
    return true;
};

export function Feed({
    isPaused,
    orders,
    setAcceptOrderDialog,
    restauId,
    summons,
    tab,
    setFeedCount,
    tableIds,
    setTab,
    setCheckForTableId,
    takeoutOrders,
    operationMode,
    restauName,
    setPlayBeep,
    enableAutoPlay,
    setEnableAutoPlay,
    setRefundOrder,
    enqueueSnackbar,
    setOpenFood,
    loggedIn,
    showPrintPrice,
    showReadFeature,
    minCreditCardAmt,
    minCreditCardFees,
    salesTax,
    mergePrints,
    restauAddress,
    restauPhoneNumber,
    diningScreenConfig,
    scheduledOrderConfig,
}) {
    const { oosMenu } = useContext(MenuContext);
    const { printOrderWithRetrySnackBar } = useContext(PrintContext);

    const [printPending, setPrintPending] = useState('false');
    const holdStatus = useHoldStatus({});
    const database = window.customerApp.database();

    const [open, setOpen] = React.useState(false);
    const [reprintOrder, setReprintOrder] = useState(null);
    const [reprintOpen, setReprintOpen] = useState(false);
    const { setInProgressOrderType } = useContext(CreateOrderContext);
    const { dineInSendToChefStatus, dispatchSendToChef } = useContext(
        AutoAcceptContext
    );

    const handlePrintClickOpen = takeoutOrders => {
        if (takeoutOrders) {
            let lastOrderNumber =
                takeoutOrders[
                    Object.keys(takeoutOrders)[
                        Object.keys(takeoutOrders).length - 1
                    ]
                ].number;
            const nextFromOrderNumber = getFromOrderValue(takeoutOrders);
            mergePrints.setFromOrderToPrint(nextFromOrderNumber);
            mergePrints.setToOrderToPrint(lastOrderNumber);
            mergePrints.setPrintPending(false);
            mergePrints.setErrorMessage('');
            if (!localStorage.getItem('lastToOrder')) {
                localStorage.setItem('lastToOrder', lastOrderNumber);
            }
        }
        setOpen(true);
    };

    const getFromOrderValue = takeoutOrders => {
        if (!localStorage.getItem('lastToOrder')) {
            return takeoutOrders[Object.keys(takeoutOrders)[0]].number;
        } else {
            const previousLastOrder = parseInt(
                localStorage.getItem('lastToOrder')
            );
            let keyFound = false;
            for (const key in takeoutOrders) {
                if (takeoutOrders.hasOwnProperty(key)) {
                    let takeoutOrder = takeoutOrders[key];
                    if (keyFound) {
                        return takeoutOrder.number;
                    }
                    if (takeoutOrder.number === previousLastOrder) {
                        keyFound = true;
                    }
                }
            }
            return previousLastOrder;
        }
    };

    const openReprint = updateOrders => {
        setReprintOrder({
            items: updateOrders,
            created_timestamp: getDateTime(),
        });
        setReprintOpen(true);
    };
    const closeRePrint = () => {
        setReprintOpen(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const _mergePrintCallBack = (
        printData,
        numCopies,
        _successCallBack,
        _failureCallBack
    ) => {
        mergePrint(
            printData,
            numCopies,
            enqueueSnackbar,
            setPrintPending,
            _successCallBack,
            _failureCallBack
        );
    };

    const assignToCook = (key, tableId) => {
        const database = window.customerApp.database();
        var updates = {};
        var rtkey = restauId + ':' + tableId;
        updates['orders/' + rtkey + '/' + key + '/status'] = 'COOK';
        updates['orders/' + rtkey + '/' + key + '/notify'] = true;

        if (orders[key].station) {
            for (var station of orders[key].station) {
                updates[
                    'orders/' + rtkey + '/' + key + '/' + station + 'status'
                ] = 'NOT_SEEN';
            }
        }

        //updates["orders/"+rtkey+"/" + key + "/cookstatus"] = "NOT_SEEN";
        database.ref().update(updates);
    };

    const deliverItem = (tableId, key) => {
        const database = window.customerApp.database();
        var updates = {};
        var rtkey = restauId + ':' + tableId;
        updates['orders/' + rtkey + '/' + key + '/status'] = 'DELIVERED';
        updates[
            'orders/' + rtkey + '/' + key + '/DELIVERED_timestamp'
        ] = getDateTime();
        database.ref().update(updates);
    };

    function clearSummon(tableId) {
        const database = window.customerApp.database();
        var updates = {};
        var rtkey = 'summons/' + restauId + '/' + tableId + '/';
        updates[rtkey + 'help'] = false;
        updates['activeusers/' + restauId + '/' + tableId + '/helpPlease'] = {
            status: false,
            actionBy: 'Host',
        };

        //updates[rtkey + "check"] = false;
        database.ref().update(updates);
    }

    function openTableForCheck(tableId) {
        setTab('TABLE');
        setCheckForTableId(tableId);
    }

    // async function updateOrderWithoutPrint(updateOrders, newStatus){
    //   const proceed = window.confirm('Are you sure you want to proceed without print');
    //   if(!proceed){
    //     return;
    //   }
    //   var updates = {};
    //   let tableId = "";
    //   const database = window.customerApp.database();
    //   var dt = getDateTime();
    //   for (var key of Object.keys(updateOrders)){
    //     var rtkey = restauId + ":" + updateOrders[key].tableId;
    //     tableId = updateOrders[key].tableId;
    //     updates["orders/"+rtkey+"/" + key + "/status"] = newStatus;
    //     updates["orders/"+rtkey+"/" + key + "/" + newStatus + "_timestamp"] = dt;

    //     if (newStatus === "COOK") {
    //       updates["orders/"+rtkey+"/" + key + "/notify"] = true;
    //       if (holdStatus.getHoldStatus(key)){
    //         updateOrders[key]['hold']=true;
    //         updates["orders/"+rtkey+"/" + key + "/hold"] = true;
    //       }
    //       if (orders[key].station){
    //         for (var station of orders[key].station){
    //           updates["orders/"+rtkey+"/" + key + "/" + station+ "status"] = "NOT_SEEN";
    //         }
    //       }
    //      // updates["orders/"+rtkey+"/" + key + "/cookstatus"] = "NOT_SEEN";
    //     } else {
    //       updates["orders/"+rtkey+"/" + key + "/notify"] = false;
    //     }
    //   }
    //   await database.ref().update(updates);

    // }

    function startBeep() {
        var snd = document.getElementById('beep');
        snd.play();
        setEnableAutoPlay(true);
    }

    // function placeOrder() {
    //   var updates = {};
    //   for (const key in draftOrders){
    //     updates["orders/"+restauTableKey+"/" + key + "/status"] = "WAITER";
    //   }
    //   database.ref().update(updates);
    // }

    var draftDineinTimestamp = {};
    var waiterDineinTimestamp = {};
    var cookDineinTimestamp = {};
    var readyDineinTimestamp = {};

    var orderByStatus = {};

    orderByStatus['WAITER'] = {};
    orderByStatus['COOK'] = {};
    // orderByStatus['DELIVERED'] = {};
    orderByStatus['READY'] = {};
    orderByStatus['DRAFT'] = {};
    // var holdStatus = {};
    for (const key in orders) {
        // if (!(orders[key].status in orderByStatus)) {
        //   orderByStatus[orders[key].status] = {};
        // }
        if (orders[key].status === 'WAITER') {
            if (!(orders[key].tableId in orderByStatus[orders[key].status])) {
                orderByStatus[orders[key].status][orders[key].tableId] = {};
                waiterDineinTimestamp[orders[key].tableId] = orders[key]
                    .submitted_timestamp
                    ? orders[key].submitted_timestamp
                    : null;
            }
            orderByStatus[orders[key].status][orders[key].tableId][key] =
                orders[key];
            // holdStatus[key] = false;
        } else if (orders[key].status === 'DRAFT') {
            if (!(orders[key].tableId in orderByStatus['DRAFT'])) {
                orderByStatus[orders[key].status][orders[key].tableId] = {};
                draftDineinTimestamp[orders[key].tableId] = orders[key]
                    .added_timestamp
                    ? orders[key].added_timestamp
                    : null;
            }
            orderByStatus[orders[key].status][orders[key].tableId][key] =
                orders[key];
            // holdStatus[key] = false;
        } else if (itemCookingInKitchen(orders[key])) {
            if (!(orders[key].tableId in orderByStatus['COOK'])) {
                orderByStatus['COOK'][orders[key].tableId] = {};
                cookDineinTimestamp[orders[key].tableId] = orders[key]
                    .COOK_timestamp
                    ? orders[key].COOK_timestamp
                    : null;
            }
            orderByStatus['COOK'][orders[key].tableId][key] = orders[key];
        } else if (itemReadyInKitchen(orders[key])) {
            if (!(orders[key].tableId in orderByStatus['READY'])) {
                orderByStatus['READY'][orders[key].tableId] = {};
                readyDineinTimestamp[orders[key].tableId] = orders[key]
                    .READY_timestamp
                    ? orders[key].READY_timestamp
                    : null;
            }
            orderByStatus['READY'][orders[key].tableId][key] = orders[key];
        }
    }

    var currTableIdsMap = {};
    for (var t of tableIds) {
        currTableIdsMap[t] = true;
    }
    var myfilteredSummon = {};
    var otherfilteredSummon = {};
    for (const summon in summons) {
        if ('check' in summons[summon] && summons[summon].check) {
            var checkSummon = {
                tableId: summons[summon].tableId,
                msg: 'Check',
                actionBy: summons[summon].checkActionBy,
            };
            if ('check_timestamp' in summons[summon]) {
                checkSummon['timestamp'] = summons[summon]['check_timestamp'];
            }
            if (currTableIdsMap[summons[summon].tableId]) {
                myfilteredSummon[summon + ':check'] = checkSummon;
            } else {
                otherfilteredSummon[summon + ':check'] = checkSummon;
            }
        }
        if ('help' in summons[summon] && summons[summon].help) {
            var helpSummon = {
                tableId: summons[summon].tableId,
                msg: 'Help',
                actionBy: summons[summon].helpActionBy,
            };
            if ('help_timestamp' in summons[summon]) {
                helpSummon['timestamp'] = summons[summon]['help_timestamp'];
            }
            if ('helpReason' in summons[summon]) {
                helpSummon['helpReason'] = summons[summon].helpReason;
            }
            if (currTableIdsMap[summons[summon].tableId]) {
                myfilteredSummon[summon + ':help'] = helpSummon;
            } else {
                otherfilteredSummon[summon + ':help'] = helpSummon;
            }
        }
    }

    var allOrdersAccepted = true;
    for (const tkey in takeoutOrders) {
        if (!('status' in takeoutOrders[tkey])) {
            // status will be WIP
            allOrdersAccepted = false;
            setPlayBeep(true);
            break;
        }
    }
    if (allOrdersAccepted) {
        setPlayBeep(false);
        const snd = document.getElementById('beep');
        if (snd) {
            snd.muted = true;
        }
    }

    var qsrDineinOrders = {};
    var otherTakeoutOrders = {};
    let deliveryOrders = {};
    for (const tkey in takeoutOrders) {
        if (takeoutOrders[tkey]['customerType'] === 'QSR-Dine-In' &&
        !takeoutOrders[tkey]['scheduledOrder']
        )
        {
            qsrDineinOrders[tkey] = takeoutOrders[tkey];
        } else if (
            takeoutOrders[tkey]['orderType'] === 'Delivery' &&
            !takeoutOrders[tkey]['scheduledOrder']
        ) {
            deliveryOrders[tkey] = takeoutOrders[tkey];
        } else {
            otherTakeoutOrders[tkey] = takeoutOrders[tkey];
        }
    }

    var feedCount = 0;
    if (isTakeoutMode(operationMode)) {
        feedCount += Object.keys(takeoutOrders).length;
    }
    if (isDineInMode(operationMode)) {
        feedCount +=
            Object.keys(myfilteredSummon).length +
            Object.keys(orderByStatus['WAITER']).length +
            Object.keys(orderByStatus['READY']).length;
    }
    setFeedCount(feedCount);

    var showDineInEditDeleteButton =
        diningScreenConfig &&
        diningScreenConfig.editDeleteOnFeed &&
        diningScreenConfig.editDeleteOnFeed.enabled
            ? true
            : false;

    var showSendToKitchenOrder = true;
    if (
        diningScreenConfig &&
        diningScreenConfig.sendToKitchenOrderOnFeed &&
        diningScreenConfig.sendToKitchenOrderOnFeed.enabled === false
    ) {
        showSendToKitchenOrder = false;
    }
    var showTableCalls = true;
    if (
        diningScreenConfig &&
        diningScreenConfig.tableCallsOnFeed &&
        diningScreenConfig.tableCallsOnFeed.enabled === false
    ) {
        showTableCalls = false;
    }

    if (tab !== 'FEED') {
        return <></>;
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'baseline', gap: 20 }}>
                {!enableAutoPlay && (
                    <ButtonContainer>
                        <RibbonButton onClick={() => startBeep()}>
                            <b>
                                <i className="fas fa-volume-up" /> Enable
                                Beeping
                            </b>
                        </RibbonButton>
                    </ButtonContainer>
                )}
                <div style={{ textAlign: 'center' }}>
                    {isPaused && (
                        <h1 style={{ color: 'red' }}>
                            <div>
                                Customers will not be able to place the orders.
                            </div>
                            <div>
                                Don't forget to resume orders to avoid revenue
                                loss.
                            </div>
                        </h1>
                    )}
                </div>
            </div>

            {reprintOpen && (
                <ReprintDialog onClose={closeRePrint} order={reprintOrder} />
            )}
            <MenuStyled>
                <OrderContent>
                    {oosMenu[0] && oosMenu[0]['foods'].length > 0 ? (
                        <div className={'blinking'}>
                            <Menu2
                                setOpenFood={setOpenFood}
                                menu={oosMenu}
                                margin={'0px'}
                                padding={'0px'}
                                showCount={true}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                    {isTakeoutMode(operationMode) && (
                        <React.Fragment key={'isTakeoutMode'}>
                            <SearchTakeoutOrder
                                tab={'Tasks'}
                                restauId={restauId}
                                enqueueSnackbar={enqueueSnackbar}
                                setRefundOrder={setRefundOrder}
                                minCreditCardFees={minCreditCardFees}
                                minCreditCardAmt={minCreditCardAmt}
                                salesTax={salesTax}
                                restauName={restauName}
                                setAcceptOrderDialog={setAcceptOrderDialog}
                                showPrintPrice={showPrintPrice}
                                restauAddress={restauAddress}
                                restauPhoneNumber={restauPhoneNumber}
                            />

                            {Object.keys(deliveryOrders).length > 0 && (
                                <QsrAndTakeoutOrders
                                    heading={'Delivery Orders:'}
                                    takeoutOrders={deliveryOrders}
                                    showReadFeature={showReadFeature}
                                    restauId={restauId}
                                    enqueueSnackbar={enqueueSnackbar}
                                    setRefundOrder={setRefundOrder}
                                    minCreditCardFees={minCreditCardFees}
                                    minCreditCardAmt={minCreditCardAmt}
                                    salesTax={salesTax}
                                    restauName={restauName}
                                    setAcceptOrderDialog={setAcceptOrderDialog}
                                    showPrintPrice={showPrintPrice}
                                    setTab={setTab}
                                />
                            )}
                            {Object.keys(qsrDineinOrders).length > 0 && (
                                <QsrAndTakeoutOrders
                                    heading={'Pending Dine-In Orders:'}
                                    takeoutOrders={qsrDineinOrders}
                                    showReadFeature={showReadFeature}
                                    restauId={restauId}
                                    enqueueSnackbar={enqueueSnackbar}
                                    setRefundOrder={setRefundOrder}
                                    minCreditCardFees={minCreditCardFees}
                                    minCreditCardAmt={minCreditCardAmt}
                                    salesTax={salesTax}
                                    restauName={restauName}
                                    setAcceptOrderDialog={setAcceptOrderDialog}
                                    showPrintPrice={showPrintPrice}
                                    setTab={setTab}
                                />
                            )}

                            {Object.keys(otherTakeoutOrders).length > 0 && (
                                <>
                                    <QsrAndTakeoutOrders
                                        heading={'Pending To-Go Orders:'}
                                        takeoutOrders={otherTakeoutOrders}
                                        showReadFeature={showReadFeature}
                                        restauId={restauId}
                                        enqueueSnackbar={enqueueSnackbar}
                                        setRefundOrder={setRefundOrder}
                                        minCreditCardFees={minCreditCardFees}
                                        minCreditCardAmt={minCreditCardAmt}
                                        salesTax={salesTax}
                                        restauName={restauName}
                                        setAcceptOrderDialog={
                                            setAcceptOrderDialog
                                        }
                                        showPrintPrice={showPrintPrice}
                                        setTab={setTab}
                                        scheduledOrderConfig={
                                            scheduledOrderConfig
                                        }
                                        isOrderAhead={false}
                                    />
                                    {scheduledOrderConfig &&
                                        scheduledOrderConfig.enabled && (
                                            <QsrAndTakeoutOrders
                                                heading={'Scheduled Orders:'}
                                                takeoutOrders={
                                                    otherTakeoutOrders
                                                }
                                                showReadFeature={
                                                    showReadFeature
                                                }
                                                restauId={restauId}
                                                enqueueSnackbar={
                                                    enqueueSnackbar
                                                }
                                                setRefundOrder={setRefundOrder}
                                                minCreditCardFees={
                                                    minCreditCardFees
                                                }
                                                minCreditCardAmt={
                                                    minCreditCardAmt
                                                }
                                                salesTax={salesTax}
                                                restauName={restauName}
                                                setAcceptOrderDialog={
                                                    setAcceptOrderDialog
                                                }
                                                showPrintPrice={showPrintPrice}
                                                setTab={setTab}
                                                scheduledOrderConfig={
                                                    scheduledOrderConfig
                                                }
                                                isOrderAhead={true}
                                            />
                                        )}
                                </>
                            )}

                            {Object.keys(takeoutOrders).length > 0 && (
                                <>
                                    <PrintDialog
                                        open={open}
                                        handleClose={handleClose}
                                        takeoutOrders={takeoutOrders}
                                        showPrintPrice={showPrintPrice}
                                        restauName={restauName}
                                        _mergePrintCallBack={
                                            _mergePrintCallBack
                                        }
                                        mergePrints={mergePrints}
                                    />
                                    <div style={{ marginTop: 30 }}>
                                        <ButtonContainerCenter>
                                            {/* <RibbonButton color={`${corianderGreen};`}  onClick={(e) => handlePrintClickOpen(takeoutOrders)}>
                                <b>Merge And Print Orders</b>
                    </RibbonButton> */}
                                            <AsyncProgressRibbonButton
                                                color={`${corianderGreen};`}
                                                onClick={e => {
                                                    if (
                                                        window.confirm(
                                                            'Are you sure you want to mark all paid orders as ready without sending SMS?'
                                                        )
                                                    )
                                                        return markAllOrdersReady(
                                                            restauId
                                                        );
                                                    else
                                                        return Promise.resolve();
                                                }}
                                                errorHandler={() => {
                                                    alert(
                                                        'Failed to mark all ready.'
                                                    );
                                                }}
                                            >
                                                <b>Mark all paid ready</b>
                                            </AsyncProgressRibbonButton>
                                        </ButtonContainerCenter>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    )}

                    {isDineInMode(operationMode) && (
                        <React.Fragment key={'isDineInMode'}>
                            {showTableCalls && (
                                <>
                                    {Object.keys(myfilteredSummon).length >
                                        0 && (
                                        <OrderContainer>
                                            <h3>My Table Calls: </h3>
                                        </OrderContainer>
                                    )}
                                    {Object.keys(myfilteredSummon).length > 0 &&
                                        Object.keys(myfilteredSummon).map(
                                            (key, index) => (
                                                <OrderContainerNonClickable
                                                    key={key}
                                                >
                                                    <SummonItem>
                                                        <div>
                                                            T-
                                                            {
                                                                myfilteredSummon[
                                                                    key
                                                                ].tableId
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                myfilteredSummon[
                                                                    key
                                                                ].msg
                                                            }{' '}
                                                            for{' '}
                                                            {
                                                                myfilteredSummon[
                                                                    key
                                                                ].actionBy
                                                            }
                                                            .&nbsp;
                                                            {myfilteredSummon[
                                                                key
                                                            ].helpReason ? (
                                                                <span>
                                                                    (
                                                                    {
                                                                        myfilteredSummon[
                                                                            key
                                                                        ]
                                                                            .helpReason
                                                                    }
                                                                    )
                                                                </span>
                                                            ) : (
                                                                <span />
                                                            )}
                                                            {myfilteredSummon[
                                                                key
                                                            ].timestamp && (
                                                                <ElaspedTimeSpan
                                                                    timestamp={
                                                                        myfilteredSummon[
                                                                            key
                                                                        ]
                                                                            .timestamp
                                                                    }
                                                                    thresholdMins={
                                                                        3
                                                                    }
                                                                    paddingLeft={
                                                                        '0px'
                                                                    }
                                                                    preText={
                                                                        'Requested '
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div>
                                                            {myfilteredSummon[
                                                                key
                                                            ].msg ===
                                                            'Check' ? (
                                                                <ClearButton
                                                                    onClick={() => {
                                                                        openTableForCheck(
                                                                            myfilteredSummon[
                                                                                key
                                                                            ]
                                                                                .tableId
                                                                        );
                                                                    }}
                                                                >
                                                                    Open
                                                                </ClearButton>
                                                            ) : (
                                                                <ClearButton
                                                                    onClick={() => {
                                                                        clearSummon(
                                                                            myfilteredSummon[
                                                                                key
                                                                            ]
                                                                                .tableId
                                                                        );
                                                                    }}
                                                                >
                                                                    Clear
                                                                </ClearButton>
                                                            )}
                                                        </div>
                                                    </SummonItem>
                                                </OrderContainerNonClickable>
                                            )
                                        )}
                                </>
                            )}{' '}
                            {/* {Object.keys(orderByStatus['WAITER']).length > 0 && <OrderContainer><h3>New Dine-In orders - Send to Bar/Kitchen: </h3> 
        </OrderContainer>} */}
                            {showSendToKitchenOrder && (
                                <>
                                    <OrderContainer>
                                        <h3>
                                            New Dine-In orders - Send to
                                            Bar/Kitchen:{' '}
                                        </h3>{' '}
                                    </OrderContainer>{' '}
                                    {Object.keys(orderByStatus['WAITER']).map(
                                        (tkey, index) => (
                                            <>
                                                <Collapsible
                                                    key={'WAITER' + tkey}
                                                    trigger={
                                                        <span>
                                                            Table - {tkey}{' '}
                                                            {waiterDineinTimestamp[
                                                                tkey
                                                            ] && (
                                                                <ElaspedTimeSpan
                                                                    timestamp={
                                                                        waiterDineinTimestamp[
                                                                            tkey
                                                                        ]
                                                                    }
                                                                    thresholdMins={
                                                                        2
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                    }
                                                    open={true}
                                                    triggerStyle={{
                                                        backgroundColor:
                                                            '#f6c3c3',
                                                    }}
                                                >
                                                    {Object.keys(
                                                        orderByStatus['WAITER'][
                                                            tkey
                                                        ]
                                                    ).length > 0 &&
                                                        Object.keys(
                                                            orderByStatus[
                                                                'WAITER'
                                                            ][tkey]
                                                        ).map((key, index) => (
                                                            <OrderContainerNonClickable
                                                                key={key}
                                                            >
                                                                <ToKitchenItem
                                                                    showEditDelete={
                                                                        showDineInEditDeleteButton
                                                                    }
                                                                >
                                                                    <div>
                                                                        {
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .quantity
                                                                        }
                                                                    </div>
                                                                    {showDineInEditDeleteButton && (
                                                                        <>
                                                                            <div
                                                                                style={{
                                                                                    cursor:
                                                                                        'pointer',
                                                                                }}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    setInProgressOrderType(
                                                                                        ORDER_TYPE.DINEIN
                                                                                    );
                                                                                    setOpenFood(
                                                                                        {
                                                                                            ...orders[
                                                                                                key
                                                                                            ],
                                                                                            index,
                                                                                            key,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    role="img"
                                                                                    aria-label="edit"
                                                                                    style={{
                                                                                        color:
                                                                                            '#27ae60',
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-edit" />
                                                                                </span>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    cursor:
                                                                                        'pointer',
                                                                                }}
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    deleteItem(
                                                                                        key,
                                                                                        orders[
                                                                                            key
                                                                                        ]
                                                                                            .name,
                                                                                        orders[
                                                                                            key
                                                                                        ]
                                                                                            .tableId,
                                                                                        restauId
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    role="img"
                                                                                    aria-label="delete"
                                                                                    style={{
                                                                                        color:
                                                                                            '#27ae60',
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    <div>
                                                                        <CheckboxLabel>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={
                                                                                    holdStatus
                                                                                        .getHoldStatus[
                                                                                        key
                                                                                    ]
                                                                                }
                                                                                onClick={() => {
                                                                                    holdStatus.changeHoldStatus(
                                                                                        key
                                                                                    );
                                                                                }}
                                                                            />
                                                                            Hold
                                                                        </CheckboxLabel>
                                                                    </div>
                                                                </ToKitchenItem>
                                                                <ItemMetaData
                                                                    item={
                                                                        orders[
                                                                            key
                                                                        ]
                                                                    }
                                                                />
                                                                {orders[key]
                                                                    .addedBy && (
                                                                    <DetailItemGray>
                                                                        Added by
                                                                        -{' '}
                                                                        {
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .addedBy
                                                                        }
                                                                    </DetailItemGray>
                                                                )}
                                                                {orders[key]
                                                                    .addedBy &&
                                                                    orders[key]
                                                                        .updatedBy &&
                                                                    orders[key]
                                                                        .addedBy !==
                                                                        orders[
                                                                            key
                                                                        ]
                                                                            .updatedBy && (
                                                                        <DetailItemGray>
                                                                            Updated
                                                                            by -{' '}
                                                                            {
                                                                                orders[
                                                                                    key
                                                                                ]
                                                                                    .updatedBy
                                                                            }
                                                                        </DetailItemGray>
                                                                    )}
                                                                {orders[key]
                                                                    .submitted_timestamp && (
                                                                    <DetailItemGray>
                                                                        Requested
                                                                        at{' '}
                                                                        {getLocalTime(
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .submitted_timestamp
                                                                        )}
                                                                    </DetailItemGray>
                                                                )}
                                                            </OrderContainerNonClickable>
                                                        ))}
                                                </Collapsible>
                                                <ButtonContainerCenter>
                                                    {/* <AsyncProgressRibbonButton onClick={() => 
              bulkUpdateTableOrders(orderByStatus['WAITER'][tkey],"COOK", restauId, holdStatus, enqueueSnackbar, printOrderWithRetrySnackBar)}>
                Send to Chef
            </AsyncProgressRibbonButton> */}
                                                    <RibbonButton
                                                        disabled={
                                                            dineInSendToChefStatus[
                                                                tkey
                                                            ]
                                                        }
                                                        onClick={() =>
                                                            bulkUpdateTableOrders(
                                                                orderByStatus[
                                                                    'WAITER'
                                                                ][tkey],
                                                                'COOK',
                                                                restauId,
                                                                holdStatus,
                                                                enqueueSnackbar,
                                                                printOrderWithRetrySnackBar,
                                                                tkey,
                                                                dispatchSendToChef
                                                            )
                                                        }
                                                    >
                                                        {dineInSendToChefStatus[
                                                            tkey
                                                        ] ? (
                                                            <CircularProgress
                                                                size={20}
                                                                style={{
                                                                    color:
                                                                        'black',
                                                                }}
                                                            />
                                                        ) : (
                                                            <>Send To Chef</>
                                                        )}
                                                    </RibbonButton>
                                                    <RibbonButton
                                                        onClick={() =>
                                                            openReprint(
                                                                orderByStatus[
                                                                    'WAITER'
                                                                ][tkey]
                                                            )
                                                        }
                                                    >
                                                        Reprint
                                                    </RibbonButton>
                                                    <AsyncProgressRibbonButton
                                                        onClick={() =>
                                                            bulkUpdateTableOrders(
                                                                orderByStatus[
                                                                    'WAITER'
                                                                ][tkey],
                                                                'COOK',
                                                                restauId,
                                                                holdStatus,
                                                                enqueueSnackbar
                                                            )
                                                        }
                                                    >
                                                        Update Without Print
                                                    </AsyncProgressRibbonButton>
                                                </ButtonContainerCenter>
                                            </>
                                        )
                                    )}
                                </>
                            )}
                            {diningScreenConfig &&
                                diningScreenConfig.draftOrderOnFeed &&
                                diningScreenConfig.draftOrderOnFeed.enabled && (
                                    <>
                                        <OrderContainer>
                                            <h3>
                                                Tables which have started adding
                                                items but not submitted yet:
                                            </h3>{' '}
                                        </OrderContainer>{' '}
                                        {orderByStatus['DRAFT'] &&
                                            Object.keys(
                                                orderByStatus['DRAFT']
                                            ).map((tkey, index) => (
                                                <>
                                                    <Collapsible
                                                        key={'DRAFT' + tkey}
                                                        trigger={
                                                            <span>
                                                                Table - {tkey}{' '}
                                                                {draftDineinTimestamp[
                                                                    tkey
                                                                ] && (
                                                                    <ElaspedTimeSpan
                                                                        timestamp={
                                                                            draftDineinTimestamp[
                                                                                tkey
                                                                            ]
                                                                        }
                                                                        thresholdMins={
                                                                            10
                                                                        }
                                                                    />
                                                                )}
                                                            </span>
                                                        }
                                                        open={false}
                                                        triggerStyle={{
                                                            backgroundColor:
                                                                '#FFFFE0',
                                                        }}
                                                    >
                                                        {Object.keys(
                                                            orderByStatus[
                                                                'DRAFT'
                                                            ][tkey]
                                                        ).length > 0 &&
                                                            Object.keys(
                                                                orderByStatus[
                                                                    'DRAFT'
                                                                ][tkey]
                                                            ).map(
                                                                (
                                                                    key,
                                                                    index
                                                                ) => (
                                                                    <OrderContainerNonClickable
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <ToKitchenItem
                                                                            showEditDelete={
                                                                                showDineInEditDeleteButton
                                                                            }
                                                                        >
                                                                            <div>
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .name
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .quantity
                                                                                }
                                                                            </div>
                                                                            {showDineInEditDeleteButton && (
                                                                                <>
                                                                                    <div
                                                                                        style={{
                                                                                            cursor:
                                                                                                'pointer',
                                                                                        }}
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            setInProgressOrderType(
                                                                                                ORDER_TYPE.DINEIN
                                                                                            );
                                                                                            setOpenFood(
                                                                                                {
                                                                                                    ...orders[
                                                                                                        key
                                                                                                    ],
                                                                                                    index,
                                                                                                    key,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            role="img"
                                                                                            aria-label="edit"
                                                                                            style={{
                                                                                                color:
                                                                                                    '#27ae60',
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-edit" />
                                                                                        </span>
                                                                                    </div>

                                                                                    <div
                                                                                        style={{
                                                                                            cursor:
                                                                                                'pointer',
                                                                                        }}
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            deleteItem(
                                                                                                key,
                                                                                                orders[
                                                                                                    key
                                                                                                ]
                                                                                                    .name,
                                                                                                orders[
                                                                                                    key
                                                                                                ]
                                                                                                    .tableId,
                                                                                                restauId
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            role="img"
                                                                                            aria-label="delete"
                                                                                            style={{
                                                                                                color:
                                                                                                    '#27ae60',
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-trash-alt" />
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <div>
                                                                                <CheckboxLabel>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={
                                                                                            holdStatus
                                                                                                .getHoldStatus[
                                                                                                key
                                                                                            ]
                                                                                        }
                                                                                        onClick={() => {
                                                                                            holdStatus.changeHoldStatus(
                                                                                                key
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    Hold
                                                                                </CheckboxLabel>
                                                                            </div>
                                                                        </ToKitchenItem>
                                                                        <ItemMetaData
                                                                            item={
                                                                                orders[
                                                                                    key
                                                                                ]
                                                                            }
                                                                        />
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .addedBy && (
                                                                            <DetailItemGray>
                                                                                Added
                                                                                by
                                                                                -{' '}
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .addedBy
                                                                                }{' '}
                                                                                {orders[
                                                                                    key
                                                                                ]
                                                                                    .added_timestamp && (
                                                                                    <span>
                                                                                        {' '}
                                                                                        at{' '}
                                                                                        {getLocalTime(
                                                                                            orders[
                                                                                                key
                                                                                            ]
                                                                                                .added_timestamp
                                                                                        )}
                                                                                    </span>
                                                                                )}
                                                                            </DetailItemGray>
                                                                        )}
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .addedBy &&
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .updatedBy &&
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .addedBy !==
                                                                                orders[
                                                                                    key
                                                                                ]
                                                                                    .updatedBy && (
                                                                                <DetailItemGray>
                                                                                    Updated
                                                                                    by
                                                                                    -{' '}
                                                                                    {
                                                                                        orders[
                                                                                            key
                                                                                        ]
                                                                                            .updatedBy
                                                                                    }
                                                                                    {orders[
                                                                                        key
                                                                                    ]
                                                                                        .updated_timestamp && (
                                                                                        <span>
                                                                                            {' '}
                                                                                            at{' '}
                                                                                            {getLocalTime(
                                                                                                orders[
                                                                                                    key
                                                                                                ]
                                                                                                    .updated_timestamp
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                </DetailItemGray>
                                                                            )}
                                                                    </OrderContainerNonClickable>
                                                                )
                                                            )}

                                                        <ButtonContainerCenter>
                                                            <AsyncProgressRibbonButton
                                                                onClick={() =>
                                                                    bulkUpdateTableOrders(
                                                                        orderByStatus[
                                                                            'DRAFT'
                                                                        ][tkey],
                                                                        'COOK',
                                                                        restauId,
                                                                        holdStatus,
                                                                        enqueueSnackbar,
                                                                        printOrderWithRetrySnackBar
                                                                    )
                                                                }
                                                            >
                                                                Send To Chef
                                                            </AsyncProgressRibbonButton>
                                                            <RibbonButton
                                                                onClick={async () =>
                                                                    openReprint(
                                                                        orderByStatus[
                                                                            'DRAFT'
                                                                        ][tkey]
                                                                    )
                                                                }
                                                            >
                                                                Reprint
                                                            </RibbonButton>
                                                            <AsyncProgressRibbonButton
                                                                onClick={async () =>
                                                                    bulkUpdateTableOrders(
                                                                        orderByStatus[
                                                                            'DRAFT'
                                                                        ][tkey],
                                                                        'COOK',
                                                                        restauId,
                                                                        holdStatus,
                                                                        enqueueSnackbar
                                                                    )
                                                                }
                                                            >
                                                                Update Without
                                                                Print
                                                            </AsyncProgressRibbonButton>
                                                        </ButtonContainerCenter>
                                                    </Collapsible>
                                                </>
                                            ))}
                                    </>
                                )}
                            {diningScreenConfig &&
                                diningScreenConfig.inKitchenOrderOnFeed &&
                                diningScreenConfig.inKitchenOrderOnFeed
                                    .enabled && (
                                    <>
                                        <OrderContainer>
                                            <h3>
                                                Dine-In Items Currently in
                                                Bar/Kitchen:{' '}
                                            </h3>{' '}
                                        </OrderContainer>

                                        {Object.keys(orderByStatus['COOK']).map(
                                            (tkey, index) => (
                                                <>
                                                    <Collapsible
                                                        key={
                                                            'Cook - Table - ' +
                                                            tkey
                                                        }
                                                        trigger={
                                                            <span>
                                                                Table - {tkey}{' '}
                                                                {cookDineinTimestamp[
                                                                    tkey
                                                                ] && (
                                                                    <ElaspedTimeSpan
                                                                        timestamp={
                                                                            cookDineinTimestamp[
                                                                                tkey
                                                                            ]
                                                                        }
                                                                        thresholdMins={
                                                                            15
                                                                        }
                                                                    />
                                                                )}
                                                            </span>
                                                        }
                                                        open={false}
                                                    >
                                                        {Object.keys(
                                                            orderByStatus[
                                                                'COOK'
                                                            ][tkey]
                                                        ).length > 0 &&
                                                            Object.keys(
                                                                orderByStatus[
                                                                    'COOK'
                                                                ][tkey]
                                                            ).map(
                                                                (
                                                                    key,
                                                                    index
                                                                ) => (
                                                                    // <OrderContainer editable>
                                                                    <OrderContainerNonClickable
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <ToCustomerItem>
                                                                            <div>
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .name
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .quantity
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <ClearButton
                                                                                    onClick={() => {
                                                                                        deliverItem(
                                                                                            tkey,
                                                                                            key
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <b>
                                                                                        Done
                                                                                    </b>
                                                                                </ClearButton>
                                                                            </div>
                                                                        </ToCustomerItem>
                                                                        <ItemMetaData
                                                                            item={
                                                                                orders[
                                                                                    key
                                                                                ]
                                                                            }
                                                                        />
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .addedBy && (
                                                                            <DetailItemGray>
                                                                                Added
                                                                                by
                                                                                -{' '}
                                                                                {
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .addedBy
                                                                                }
                                                                            </DetailItemGray>
                                                                        )}
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .addedBy &&
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .updatedBy &&
                                                                            orders[
                                                                                key
                                                                            ]
                                                                                .addedBy !==
                                                                                orders[
                                                                                    key
                                                                                ]
                                                                                    .updatedBy && (
                                                                                <DetailItemGray>
                                                                                    Updated
                                                                                    by
                                                                                    -{' '}
                                                                                    {
                                                                                        orders[
                                                                                            key
                                                                                        ]
                                                                                            .updatedBy
                                                                                    }
                                                                                </DetailItemGray>
                                                                            )}
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .submitted_timestamp && (
                                                                            <DetailItemGray>
                                                                                Submitted
                                                                                to
                                                                                tab
                                                                                at{' '}
                                                                                {getLocalTime(
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .submitted_timestamp
                                                                                )}
                                                                            </DetailItemGray>
                                                                        )}
                                                                        {orders[
                                                                            key
                                                                        ]
                                                                            .COOK_timestamp && (
                                                                            <DetailItemGray>
                                                                                Sent
                                                                                to
                                                                                kitchen
                                                                                at{' '}
                                                                                {getLocalTime(
                                                                                    orders[
                                                                                        key
                                                                                    ]
                                                                                        .COOK_timestamp
                                                                                )}
                                                                            </DetailItemGray>
                                                                        )}
                                                                    </OrderContainerNonClickable>
                                                                )
                                                            )}
                                                    </Collapsible>
                                                    <ButtonContainerCenter>
                                                        <AsyncProgressRibbonButton
                                                            onClick={() =>
                                                                bulkUpdateTableOrders(
                                                                    orderByStatus[
                                                                        'COOK'
                                                                    ][tkey],
                                                                    'DELIVERED',
                                                                    restauId,
                                                                    holdStatus,
                                                                    enqueueSnackbar
                                                                )
                                                            }
                                                        >
                                                            <b>
                                                                Send All: Table
                                                            </b>
                                                        </AsyncProgressRibbonButton>
                                                    </ButtonContainerCenter>
                                                </>
                                            )
                                        )}
                                    </>
                                )}
                            {Object.keys(orderByStatus['READY']).length > 0 && (
                                <OrderContainer>
                                    <h3>
                                        Dine-In Items Ready in Bar/Kitchen:{' '}
                                    </h3>
                                    {/* <DetailItem>Press 🍽 after delivery</DetailItem> */}
                                </OrderContainer>
                            )}
                            {Object.keys(orderByStatus['READY']).map(
                                (tkey, index) => (
                                    <Collapsible
                                        key={'Ready - Table - ' + tkey}
                                        trigger={
                                            <span>
                                                Table - {tkey}{' '}
                                                {readyDineinTimestamp[tkey] && (
                                                    <ElaspedTimeSpan
                                                        timestamp={
                                                            readyDineinTimestamp[
                                                                tkey
                                                            ]
                                                        }
                                                        thresholdMins={5}
                                                    />
                                                )}
                                            </span>
                                        }
                                        open={false}
                                    >
                                        {Object.keys(
                                            orderByStatus['READY'][tkey]
                                        ).length > 0 &&
                                            Object.keys(
                                                orderByStatus['READY'][tkey]
                                            ).map((key, index) => (
                                                // <OrderContainer editable>
                                                <OrderContainerNonClickable
                                                    key={key}
                                                >
                                                    <ToCustomerItem>
                                                        <div>
                                                            {orders[key].name}
                                                        </div>
                                                        <div>
                                                            {
                                                                orders[key]
                                                                    .quantity
                                                            }
                                                        </div>
                                                        <div>
                                                            <ClearButton
                                                                onClick={() => {
                                                                    deliverItem(
                                                                        tkey,
                                                                        key
                                                                    );
                                                                }}
                                                            >
                                                                <b>Done</b>
                                                            </ClearButton>
                                                        </div>
                                                    </ToCustomerItem>
                                                    <ItemMetaData
                                                        item={orders[key]}
                                                    />
                                                    {orders[key].addedBy && (
                                                        <DetailItemGray>
                                                            Added by -{' '}
                                                            {
                                                                orders[key]
                                                                    .addedBy
                                                            }
                                                        </DetailItemGray>
                                                    )}
                                                    {orders[key].addedBy &&
                                                        orders[key].updatedBy &&
                                                        orders[key].addedBy !=
                                                            orders[key]
                                                                .updatedBy && (
                                                            <DetailItemGray>
                                                                Updated by -{' '}
                                                                {
                                                                    orders[key]
                                                                        .updatedBy
                                                                }
                                                            </DetailItemGray>
                                                        )}
                                                    {orders[key]
                                                        .submitted_timestamp && (
                                                        <DetailItemGray>
                                                            Submitted to tab at{' '}
                                                            {getLocalTime(
                                                                orders[key]
                                                                    .submitted_timestamp
                                                            )}
                                                        </DetailItemGray>
                                                    )}
                                                    {orders[key]
                                                        .COOK_timestamp && (
                                                        <DetailItemGray>
                                                            Sent to kitchen at{' '}
                                                            {getLocalTime(
                                                                orders[key]
                                                                    .COOK_timestamp
                                                            )}
                                                        </DetailItemGray>
                                                    )}
                                                    {orders[key]
                                                        .READY_timestamp && (
                                                        <DetailItemGray>
                                                            Ready in kitchem at{' '}
                                                            {getLocalTime(
                                                                orders[key]
                                                                    .READY_timestamp
                                                            )}
                                                        </DetailItemGray>
                                                    )}
                                                </OrderContainerNonClickable>
                                            ))}
                                        <ButtonContainerCenter>
                                            <AsyncProgressRibbonButton
                                                onClick={() =>
                                                    bulkUpdateTableOrders(
                                                        orderByStatus['READY'][
                                                            tkey
                                                        ],
                                                        'DELIVERED',
                                                        restauId,
                                                        holdStatus,
                                                        enqueueSnackbar
                                                    )
                                                }
                                            >
                                                <b>Send All: Table</b>
                                            </AsyncProgressRibbonButton>
                                        </ButtonContainerCenter>
                                    </Collapsible>
                                )
                            )}
                        </React.Fragment>
                    )}

                    {/* <OrderContainer><h3>Other Table Calls: </h3></OrderContainer>
        {Object.keys(otherfilteredSummon).length > 0 && Object.keys(otherfilteredSummon).map((key, index) => (
            <OrderContainer editable>
              <SummonItem>
                <div>T-{otherfilteredSummon[key].tableId}</div>
                <div>{otherfilteredSummon[key].msg}</div>
                <div><ClearButton onClick={() => {clearSummon(otherfilteredSummon[key].tableId)}}>Clear</ClearButton></div>
              </SummonItem>
            </OrderContainer>
        ))} 
        */}
                </OrderContent>
            </MenuStyled>
        </>
    );
}
