import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { CreateOrderContext } from '../contexts/CreateOrderContext';
import { formatPrice } from '../Data/FoodData';
import {
    BadgeSpan,
    Description,
    FoodContainer,
    FoodLabel,
    HiddenSpan,
    IncrementControl,
} from './MenuStyles';

function FoodItem(props) {
    const {
        food,
        showPrice,
        showDescription,
        showChoice,
        showToppings,
        showNotes,
        setOpenFood,
        onAddToOrder,
    } = props;
    const [count, setCount] = useState(0);

    const updateCount = () => {
        setCount(count + 1);
    };

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (count === 0) {
            return;
        }
        const handler = setTimeout(() => {
            const order = {
                ...food,
                quantity: count,
            };
            onAddToOrder(order);
            enqueueSnackbar(`Added "${food.name} - ${count}"`, {
                variant: 'success',
            });
            setCount(0);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [count]);

    return (
        <FoodContainer
            oos={food.oos}
            hide={food.hide}
            onClick={() => {
                setOpenFood(food);
            }}
        >
            <div style={{ position: 'relative' }}>
                <div>
                    <FoodLabel>
                        <span opacity="0.5">{food.name} </span>
                        <div>
                            {food.oos ? (
                                <BadgeSpan>
                                    <b>&nbsp;Sold Out&nbsp;</b>
                                </BadgeSpan>
                            ) : (
                                ''
                            )}
                        </div>
                        {food.hide && food.oos ? <span>&nbsp;</span> : ''}
                        <div>
                            {food.hide ? (
                                <HiddenSpan>
                                    <b>&nbsp;Hidden&nbsp;</b>
                                </HiddenSpan>
                            ) : (
                                ''
                            )}
                        </div>

                        {showPrice && (
                            <div>
                                {food.pricelabel
                                    ? food.pricelabel
                                    : formatPrice(food.price / 100)}
                            </div>
                        )}
                    </FoodLabel>
                    {showDescription && <Description>{food.desc}</Description>}
                    {showChoice && food.choice && (
                        <Description> (+) {food.choice}</Description>
                    )}
                    {showToppings && food.toppingsStr && (
                        <Description> (+) {food.toppingsStr}</Description>
                    )}
                    {showNotes && food.notes && (
                        <Description>
                            {' '}
                            <i className="fas fa-info-circle" /> {food.notes}
                        </Description>
                    )}
                </div>
            </div>
            <IncrementControl>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={e => {
                        e.stopPropagation();
                        updateCount();
                    }}
                >
                    <i className="fas fa-plus-circle" />
                </IconButton>
            </IncrementControl>
        </FoodContainer>
    );
}

FoodItem.propTypes = {};

export default FoodItem;
