import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ToggleButton from './ToggleButton';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ gridColumns }) =>
        gridColumns ? `repeat(${gridColumns}, 1fr);` : `repeat(1, 1fr);`};
    // Add this part
    @media (max-width: 720px) {
        grid-template-columns: 1fr 1fr;
    }
`;
function TablesGrid(props) {
    const { gridColumns, tables, onClick } = props;
    return (
        <div>
            <Typography variant="h6" color="textPrimary">
                Your Tables
            </Typography>
            <Typography variant="caption" color="textSecondary">
                Select tables you are responsible for and want to get notified
                about
            </Typography>
            <GridContainer gridColumns={gridColumns}>
                {tables.map(({ name, checked }, index) => {
                    return (
                        <div key={name} style={{ padding: 4 }}>
                            <ToggleButton
                                label={name}
                                value={index}
                                selected={checked ? index : -1}
                                onClick={onClick}
                                fullWidth={true}
                            />
                        </div>
                    );
                })}
            </GridContainer>
        </div>
    );
}

TablesGrid.propTypes = {};

export default TablesGrid;
