export const discoverReader = function(
    secret,
    testMode,
    locationId,
    configure
) {
    console.log('Sendign request to discover readers...' + locationId);
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            console.log('inside native  view');
            window.webViewBridge.send(
                'discoverReader',
                { secret, testMode, locationId, configure },
                function(res) {
                    resolve();
                },
                function(err) {
                    console.log(err);
                    reject(err);
                }
            );
        } else {
            reject('Payment not supported in browsers currently.');
        }
    });
};
export const connectReader = function(secret, locationId) {
    console.log('Sendign request to connect readers...');
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send(
                'connectReader',
                { secret, locationId },
                function(res) {
                    resolve();
                },
                function(err) {
                    reject(err);
                }
            );
        } else {
            reject('Payment not supported in browsers currently.');
        }
    });
};
export const processPayment = function(amount, currency, metadata) {
    console.log('Processing payment for ', amount, currency);
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send(
                'processPayment',
                {
                    amount,
                    currency,
                    paymentDetails: {
                        amount,
                        currency,
                        applicationFeeAmount: 10,
                        metadata,
                    },
                },
                function(res) {
                    console.log('Process payment result ', res);
                    resolve(res);
                },
                function(err) {
                    console.log('Process payment error: ', err);
                    reject(err);
                }
            );
        } else {
            reject('Payment not supported in browsers currently.');
        }
    });
};
