export async function assignOrderHistoryId(restauId, tableId) {
    const database = window.customerApp.database();
    let orderHistoryId;
    let summonHistoryid = (await database
        .ref('summons/' + restauId + '/' + tableId + '/orderHistoryId')
        .once('value')).val();
    console.log('summonHistoryid', summonHistoryid);
    if (!summonHistoryid || summonHistoryid === '') {
        var histRef = database.ref('orderhistory/' + restauId).push();
        orderHistoryId = histRef.key;
        var updates = {};
        updates[
            'summons/' + restauId + '/' + tableId + '/orderHistoryId'
        ] = orderHistoryId;
        updates[
            'activeusers/' + restauId + '/' + tableId + '/orderHistoryId'
        ] = orderHistoryId;
        await database.ref().update(updates);
    } else {
        orderHistoryId = summonHistoryid;
    }
    return orderHistoryId;
}
