import { Button, CircularProgress, Grid, TextareaAutosize, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Config from "../config/Config";
const { default: axios } = require('axios');

export const Support = ({restauId,restauName,handleClose}) =>{
    const[phone,setPhone] = useState('');
    const[message,setMessage] = useState('');
    const[pending,setPending] = useState(false);

    const sendSupportRequest = async() =>{
        if (!phone || !message) {
            alert('Please provide Contact Number & Message.');
            return; 
        }
        var url = Config.call_for_support;
        const token = await window.customerApp
            .auth()
            .currentUser.getIdToken();
        setPending(true);
        try {
            const resp = await axios.post(url, {
                restauId,
                restauName,
                phone,
                message,
                headers: token
            });
            setPending(false);
            if(resp.data.status === 'SUCCESS'){
                handleClose();
                alert('Thanks for contacting StrideQ  we will contact you in 24 hours. For business critical issues please call  +1 (650) 282-1239');
            }
        }catch (error) {
            setPending(false);
            alert('Error while putting in service request. For business critical issues please call  +1 (650) 282-1239');
        }

    }
    return <div>
        <Grid container direction="column" spacing={2}>
            <Grid item>
            <TextField id="outlined-basic" label="Contact Number" variant="outlined" size="medium" value={phone} onChange={e=>setPhone(e.target.value)}/>
            </Grid>
            <Grid item >
            <TextareaAutosize aria-label="Support for ?" minRows={5} placeholder="Support for ?" style={{ width: 300 }} value={message} onChange={(e)=>setMessage(e.target.value)} />
            </Grid>
            <Grid item>
            <div>
            <Button
                                onClick={sendSupportRequest}
                                disabled={pending}
                                color="primary"
                                variant="contained"
                            >
            {pending ? (
                                    <CircularProgress
                                        size={20}
                                        style={{ color: 'black' }}
                                    />
                                ) : (
                                    <>Request Support</>
                                )}
                </Button>
                <Button
                                onClick={handleClose}
                                color="primary"
                                variant="outlined"
                                style={{margin:'20px'}}
                            > Close
                </Button>
                </div>
            </Grid>
        </Grid>

    </div>;
}