import React, { useState } from 'react';
import { useContext } from 'react';
import { PrintContext } from '../../contexts/PrintContext';
import { corianderGreen, pomegranateRed } from '../../Styles/colors';
import AsyncProgressRibbonButton from '../CreateOrder/AsyncProgressRibbonButton';
import { useSnackbar } from 'notistack';
import { reportError } from '../../apis/reportError';
import { Title20px } from '../../Order/Feed';

export default function OrderPrintButton({
    order,
    numCopies = 1,
    reprint = false,
    receipt = false,
    children,
}) {
    const [snackBarKey, setSnackBarKey] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { printOrder } = useContext(PrintContext);
    return (
        <AsyncPrintOrderButton
            {...{
                order,
                printOrder,
                numCopies,
                reprint,
                receipt,
                setSnackBarKey,
                closeSnackbar,
                enqueueSnackbar,
            }}
        >
            {children}
        </AsyncPrintOrderButton>
    );
}

function AsyncPrintOrderButton({
    order,
    printOrder,
    numCopies,
    reprint,
    receipt,
    onCompletion,
    setSnackBarKey,
    closeSnackbar,
    enqueueSnackbar,
    children,
    filterStations,
    onSuccess,
}) {
    return (
        <AsyncProgressRibbonButton
            color={
                order['payment_success'] || order.isDineIn
                    ? `${corianderGreen};`
                    : `${pomegranateRed};`
            }
            onClick={async () => {
                let errorMessage = null;
                let failedStations = null;
                let printResult = null;
                try {
                    printResult = await printOrder({
                        order,
                        numCopies,
                        reprint,
                        receipt,
                        filterStations,
                        isDineIn: order.isDineIn,
                    });
                    if (!printResult.success) {
                        failedStations = printResult.errors
                            .map(e => e.station)
                            .filter(e => e && e.length > 0);
                        //errorMessage = "Failed to print Order #" + order.number + (failedStations.length > 0 ? (" at stations: " + failedStations.join(", ")) : "");
                        errorMessage =
                            'Failed to print' +
                            (order.isDineIn
                                ? order.tableId
                                    ? ' Table: ' + order.tableId
                                    : ''
                                : order.number
                                ? ' Order ' + order.number
                                : '');
                    } else {
                        onSuccess && onSuccess();
                    }
                } catch (error) {
                    reportError(window.restauId, window.email, {
                        msg: {
                            message: 'Print order button exception',
                            numCopies,
                            receipt,
                            isDineIn: order.isDineIn,
                            filterStations,
                            error,
                        },
                    });
                    //console.log("error: ", error);
                    //errorMessage = "Failed to print. Please reprint Order #" + order.number;
                    errorMessage =
                        'Failed to print' +
                        (order.isDineIn
                            ? order.tableId
                                ? ' Table: ' + order.tableId
                                : ''
                            : order.number
                            ? ' Order ' + order.number
                            : '');
                } finally {
                    if (onCompletion)
                        try {
                            onCompletion();
                        } catch (e) {}
                }
                //console.log("errorMessage: ", errorMessage);
                if (errorMessage) {
                    let errorSnackBar = (
                        <RetryPrintButton
                            {...{
                                errorMessage,
                                order,
                                printOrder,
                                numCopies,
                                reprint,
                                receipt,
                                setSnackBarKey,
                                closeSnackbar,
                                enqueueSnackbar,
                                //errorMessage, order, numCopies, reprint, receipt, failedStations,
                                failedStations,
                                printResult,
                                onSuccess,
                                onCompletion: () => {
                                    try {
                                        setSnackBarKey(prev => {
                                            if (prev != null)
                                                closeSnackbar(prev);
                                            return null;
                                        });
                                    } catch (e) {}
                                },
                            }}
                        />
                    );
                    setSnackBarKey(
                        enqueueSnackbar(errorSnackBar, {
                            variant: 'error',
                            persist: true,
                        })
                    );
                }
            }}
        >
            {children}
        </AsyncProgressRibbonButton>
    );
}

export function RetryPrintButton({
    errorMessage,
    order,
    printOrder,
    numCopies,
    reprint,
    receipt,
    setSnackBarKey,
    closeSnackbar,
    enqueueSnackbar,
    failedStations,
    printResult,
    onCompletion,
    onSuccess,
}) {
    const filterStations =
        failedStations && failedStations.length > 0
            ? failedStations.reduce((a, v) => ({ ...a, [v]: v }), {})
            : null;
    return (
        <div>
            <Title20px>{errorMessage}</Title20px>
            {failedStations && failedStations.length > 0 && (
                <>
                    {printResult.errors.map(({ error, station }) => {
                        return (
                            <div
                                key={
                                    'or' +
                                    (order.number || order.id) +
                                    'st:' +
                                    station
                                }
                            >
                                <b>{station.toUpperCase()}</b>{' '}
                                {': ' + error.parsedErrorType}
                            </div>
                        );
                    })}
                </>
            )}
            <AsyncPrintOrderButton
                {...{
                    order,
                    printOrder,
                    numCopies,
                    reprint,
                    receipt,
                    onCompletion,
                    setSnackBarKey,
                    closeSnackbar,
                    enqueueSnackbar,
                    filterStations: failedStations,
                    onSuccess,
                }}
            >
                <b>
                    Retry{' '}
                    {failedStations && failedStations.length > 0
                        ? 'failed stations'
                        : ''}
                </b>
            </AsyncPrintOrderButton>
        </div>
    );
}
