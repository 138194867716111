import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    captureTerminalPayment,
    getTerminalSecret,
} from '../../apis/terminalPayments';
import eventBus, { NATIVE_EVENT_TYPE } from '../../utils/eventBus';
import { getDateTime } from '../../utils/Util';
import { RestaurantContext } from '../RestaurantContext';
import { discoverReader, connectReader, processPayment } from './payment';
import { useSnackbar } from 'notistack';
import { RibbonButton } from '../../Navbar/Ribbon';

//context providing access to this store.
const PaymentContext = createContext({});

const PaymentProvider = ({ children }) => {
    const { restauId } = useContext(RestaurantContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [terminalReaderConnected, setTerminalReaderConnected] = useState(
        false
    );
    const [terminalReader, setTerminalReader] = useState(false);
    const [terminalConnectedOnce, setTerminalConnectedOnce] = useState(false);

    useEffect(() => {
        const readerConnectedListener = reader => {
            setTerminalReaderConnected(true);
            setTerminalReader(reader);
            setTerminalConnectedOnce(true);
            enqueueSnackbar('Payment Card reader connected.', {
                variant: 'success',
            });
            setDisconnectSnackBarKey(prev => {
                if (prev) closeSnackbar(prev);
                return null;
            });
            //alert(JSON.stringify(reader));
        };
        eventBus.on(
            NATIVE_EVENT_TYPE.TERMINAL_READER_CONNECTED,
            readerConnectedListener
        );
        return () => {
            eventBus.remove(
                NATIVE_EVENT_TYPE.TERMINAL_READER_CONNECTED,
                readerConnectedListener
            );
        };
    }, []);

    useEffect(() => {
        const readerNotFoundListener = reader => {
            setTerminalReaderConnected(false);
            setTerminalReader(reader);
            enqueueSnackbar(
                'Payment Card reader not found. Please check device is on. Serial: ' +
                    (reader ? reader.serialNumber : ''),
                { variant: 'error', persist: true }
            );
        };
        eventBus.on(
            NATIVE_EVENT_TYPE.TERMINAL_READER_NOT_FOUND,
            readerNotFoundListener
        );
        return () => {
            eventBus.remove(
                NATIVE_EVENT_TYPE.TERMINAL_READER_NOT_FOUND,
                readerNotFoundListener
            );
        };
    }, []);

    const [disconnectSnackBarKey, setDisconnectSnackBarKey] = useState(null);
    // useEffect(() => {
    //     if(terminalReaderConnected && disconnectSnackBarKey) {
    //         try{
    //             closeSnackbar(disconnectSnackBarKey);
    //         } catch(e) {}; //ignore closing error
    //         setDisconnectSnackBarKey(null);
    //     }
    // }, [disconnectSnackBarKey, terminalReaderConnected]);

    const reconnectMsgBar = msg => (
        <div style={{ display: 'flex' }}>
            <div>{msg}</div>
            <RibbonButton
                onClick={() => {
                    reconnectReader();
                    setDisconnectSnackBarKey(prev => {
                        closeSnackbar(prev);
                        return null;
                    });
                }}
            >
                Reconnect
            </RibbonButton>
        </div>
    );
    useEffect(() => {
        if (!terminalConnectedOnce) return;
        //TERMINAL_READER_DISCONNECTED
        const readerDisconnectedListener = () => {
            setTerminalReaderConnected(false);
            let key = enqueueSnackbar(
                reconnectMsgBar(
                    'Payment Card reader disconnected. Check reader is on.'
                ),
                { variant: 'error', persist: true }
            );
            setDisconnectSnackBarKey(key);
            //alert(JSON.stringify(reader));
        };
        eventBus.on(
            NATIVE_EVENT_TYPE.TERMINAL_READER_DISCONNECTED,
            readerDisconnectedListener
        );
        return () => {
            eventBus.remove(
                NATIVE_EVENT_TYPE.TERMINAL_READER_DISCONNECTED,
                readerDisconnectedListener
            );
        };
    }, [terminalConnectedOnce]);

    useEffect(() => {
        //TERMINAL_READER_DISCONNECTED
        const readerFailedConnectingListener = () => {
            let key = enqueueSnackbar(
                reconnectMsgBar(
                    'Payment Card reader failed connecting. Check reader is on.'
                ),
                { variant: 'error', persist: true }
            );
            setDisconnectSnackBarKey(key);
            //alert(JSON.stringify(reader));
        };
        eventBus.on(
            NATIVE_EVENT_TYPE.TERMINAL_READER_FAILED_CONNECTING,
            readerFailedConnectingListener
        );
        return () => {
            eventBus.remove(
                NATIVE_EVENT_TYPE.TERMINAL_READER_FAILED_CONNECTING,
                readerFailedConnectingListener
            );
        };
    }, []);

    const [refreshConnectionToken, setRefreshConnectionToken] = useState(0);
    const reconnectReader = useCallback(() => {
        setRefreshConnectionToken(prev => prev + 1);
    }, []);

    useEffect(() => {
        const refreshListener = () => {
            reconnectReader();
            //alert(JSON.stringify(reader));
        };
        eventBus.on(
            NATIVE_EVENT_TYPE.TERMINAL_REFRESH_CONNECTION_TOKEN,
            refreshListener
        );
        return () => {
            eventBus.remove(
                NATIVE_EVENT_TYPE.TERMINAL_REFRESH_CONNECTION_TOKEN,
                refreshListener
            );
        };
    }, []);

    const fetchConnectionToken = async () => {
        try {
            const connectionTokenSecret = await getTerminalSecret(restauId);
            return connectionTokenSecret;
        } catch (e) {
            console.error('Failed to get token', e);
            throw e;
        }
    };
    //const [connectionTokenSecret, setConnectionTokenSecret] = useState(null);
    useEffect(() => {
        if (restauId === 'None') {
            return;
        }
        const connect = async () => {
            try {
                //const connectionTokenSecret = await fetchConnectionToken();
                //console.log("Recieved token: ", connectionTokenSecret);
                //setConnectionTokenSecret(token);
                await startReaderDiscovery();
            } catch (e) {
                console.error('Failed to connect to reader', e);
                //throw e;
            }
        };
        connect();
    }, [restauId]);

    const [connectionTokenSecret, setConnectionTokenSecret] = useState(null);
    useEffect(() => {
        if (restauId === 'None') {
            return;
        }
        const connect = async () => {
            try {
                //const connectionTokenSecret = await fetchConnectionToken();
                //console.log("Recieved token: ", connectionTokenSecret);
                //setConnectionTokenSecret(token);
                if (connectionTokenSecret) {
                    await connectReader(
                        connectionTokenSecret.secret,
                        connectionTokenSecret.locationId
                    );
                }
            } catch (e) {
                console.error('Failed to connect to reader', e);
                throw e;
            }
        };
        connect();
    }, [restauId, refreshConnectionToken]);

    // useEffect(() => {
    //     if(connectionTokenSecret == null) return;
    //     connectReader(connectionTokenSecret.secret);
    // }, [connectionTokenSecret]);

    const startReaderDiscovery = async (configure = false) => {
        const connectionTokenSecret = await fetchConnectionToken();
        setConnectionTokenSecret(connectionTokenSecret);
        await discoverReader(
            connectionTokenSecret.secret,
            connectionTokenSecret.testMode,
            connectionTokenSecret.locationId,
            configure
        );
    };

    const processCardPayment = useCallback(
        async (
            order,
            orderId,
            totalAmount,
            isDineIn = false,
            tableId,
            currency = 'usd'
        ) => {
            console.log('Order: ', orderId, totalAmount, isDineIn);
            try {
                //All metadata should be of type string
                const metadata = {
                    orderId: String(orderId),
                    isDineIn: String(isDineIn),
                    subTotal: String(order.payment_subtotal / 100),
                    tip: String(order.tip / 100),
                    server: String(order.server),
                    salesTax: String(order.payment_salestax / 100),
                };
                if (order.serviceFees) {
                    metadata.serviceFees = String(order.serviceFees / 100);
                }
                if (tableId) {
                    metadata.tableId = String(tableId);
                }
                const { amount, stripeId, status } = await processPayment(
                    totalAmount,
                    currency,
                    metadata
                );
                if (status == 1) {
                    const captureStatus = await captureTerminalPayment(
                        restauId,
                        amount,
                        stripeId,
                        orderId,
                        isDineIn
                    );
                    if (captureStatus) {
                        if (isDineIn) {
                            var updates = {};
                            //Update so waiter can know if payment is done.
                            updates[
                                'summons/' +
                                    restauId +
                                    '/' +
                                    tableId +
                                    '/payment_success'
                            ] = true;
                            await window.customerApp
                                .database()
                                .ref()
                                .update(updates);
                        }
                        return { status: true };
                    } else {
                        throw new Error('Failed to capture.');
                    }
                } else {
                    throw new Error('Failed in reader with status: ' + status);
                }
            } catch (err) {
                console.log('errro', err);
                throw err;
            }
            throw new Error('Unknown error');
        },
        [restauId]
    );

    const processCashPayment = useCallback(
        async order => {
            const database = window.customerApp.database();
            if (order.isDineIn) {
                const dineInOrder = order;
                var confirm = window.confirm(
                    'Confirm payment collected for table ' + dineInOrder.tableId
                );
                if (!confirm) {
                    return false;
                }
                var updates = {};

                // updates["activeusers/"+ restauId+ "/" + dineInOrder.tableId + "/forceDetach"] = true;
                // updates["activeusers/"+ restauId+ "/" + dineInOrder.tableId + "/orderHistoryId"] = dineInOrder.id;
                // await database.ref().update(updates);

                var dateTime = getDateTime();
                updates = {};
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].subTotal = dineInOrder.payment_subtotal;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].total = dineInOrder.payment_total;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].tip = dineInOrder.tip;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].time = dateTime;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].tableId = dineInOrder.tableId;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].items = dineInOrder.items;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].server = dineInOrder.server;
                // updates["orderhistory/" + restauId + "/" + dineInOrder.id].salesTax = dineInOrder.salesTax;

                //payment related
                updates[
                    'orderhistory/' +
                        restauId +
                        '/' +
                        dineInOrder.id +
                        '/payment_success'
                ] = true;
                updates[
                    'orderhistory/' +
                        restauId +
                        '/' +
                        dineInOrder.id +
                        '/paid_in_cash'
                ] = true;
                updates[
                    'orderhistory/' +
                        restauId +
                        '/' +
                        dineInOrder.id +
                        '/paid_in_store_timestamp'
                ] = dateTime;
                updates[
                    'orderhistory/' +
                        restauId +
                        '/' +
                        dineInOrder.id +
                        '/payment_success_timestamp'
                ] = dateTime;
                updates[
                    'orderhistory/' +
                        restauId +
                        '/' +
                        dineInOrder.id +
                        '/total_amount_paid'
                ] = dineInOrder.payment_total;

                //Update so waiter can know if payment is done.
                updates[
                    'summons/' +
                        restauId +
                        '/' +
                        dineInOrder.tableId +
                        '/payment_success'
                ] = true;

                //var rtkey = restauId + ":" + dineInOrder.tableId;
                // updates["orders/" + rtkey] = null;

                // updates["summons/" + restauId + "/" + dineInOrder.tableId] = null;
                // updates["activeusers/"+ restauId+ "/" + dineInOrder.tableId] = null;

                await database.ref().update(updates);
                return (
                    'Order at Table ' +
                    dineInOrder.tableId +
                    ' marked as Paid In Store.'
                );
            } else {
                const takeoutorder = order;
                var confirm = window.confirm(
                    'Confirm payment collected for order number ' +
                        takeoutorder.number
                );
                if (!confirm) {
                    return false;
                }
                var updates = {};
                var dt = getDateTime();
                var enqueueMsg;
                takeoutorder['payment_success'] = true;
                takeoutorder['paid_in_store'] = true;
                takeoutorder['paid_in_cash'] = true;
                takeoutorder['paid_in_store_timestamp'] = dt;
                if (takeoutorder['status'] === 'READY') {
                    takeoutorder['status'] = 'DONE';
                    takeoutorder['completed_timestamp'] = dt;
                    updates[
                        'takeout_orders_completed/' +
                            restauId +
                            '/' +
                            takeoutorder.id
                    ] = takeoutorder;
                    updates[
                        'takeout_orders/' + restauId + '/' + takeoutorder.id
                    ] = null;
                    enqueueMsg =
                        'Order ' + takeoutorder['number'] + ' completed.';
                } else {
                    takeoutorder['status'] = 'PAID_PENDING_READY';
                    updates[
                        'takeout_orders/' + restauId + '/' + takeoutorder.id
                    ] = takeoutorder;
                    enqueueMsg =
                        'Order ' +
                        takeoutorder['number'] +
                        ' marked as Paid In Store.';
                }

                try {
                    await database.ref().update(updates);
                    //enqueueSnackbar(enqueueMsg, {variant:'success'});
                    return enqueueMsg;
                } catch (error) {
                    throw error;
                    //enqueueSnackbar("Update to Order "+takeoutorder['number']+" unsuccessful. " + error, {variant:'error'});
                }
            }
        },
        [restauId]
    );

    const value = {
        terminalReader,
        terminalReaderConnected,
        processCardPayment,
        processCashPayment,
        //startReaderDiscovery: () => setRefreshConnectionToken(prev => prev+1),
        startReaderDiscovery,
        reconnectReader,
    };

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};

export { PaymentContext, PaymentProvider };
