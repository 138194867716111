import { useState } from 'react';

export function useTableTips(defaultStatus) {
    const [tableTips, setTableTips] = useState(defaultStatus);

    function setTableTip(key, val) {
        const newtableTips = { ...tableTips };
        newtableTips[key] = val;
        setTableTips(newtableTips);
    }

    function getTableTip(key) {
        if (!(key in tableTips)) {
            return null;
        } else {
            return tableTips[key];
        }
    }

    function deleteTableTip(key) {
        const newtableTips = { ...tableTips };
        delete newtableTips[key];
        setTableTips(newtableTips);
    }

    return {
        setTableTip,
        getTableTip,
        deleteTableTip,
    };
}
