import React from 'react';
import styled from 'styled-components';
//import { foods } from "../Data/FoodData";
import { formatPrice } from '../Data/FoodData';
import Collapsible from './Collapsible';
import { DialogContent } from '../FoodDialog/FoodDialog';
//import Collapsible from 'react-collapsible';
import { pomegranateRed } from '../Styles/colors';

export const MenuStyled = styled.div`
    margin: ${props => (props.margin ? props.margin : '0px 50px 100px 50px')};
    // Add this part
    @media (max-width: 720px) {
        width: 100%;
        margin: auto;
        margin-bottom: ${props => (props.margin ? props.margin : '100px')};
    }
`;

export const MenuContent = styled(DialogContent)`
    padding: ${props => (props.padding ? props.padding : '20px')};
    height: 100%;
`;

const FoodGrid = styled.div`
    @media (max-width: 720px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;

// ${props => props.img !== undefined && `
//   position: absolute;
//   justify-content: space-between;
// `}

//  background-color: rgba(255, 255, 255, 0.8);
// padding: 5px;
const FoodLabel = styled.div`
    ${props =>
        props.img !== undefined
            ? `position: absolute;`
            : `justify-content: space-between;   display: flex;`}
`;

const FoodContainer = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgb(39,174,96,0.5);
  background-color: ${props =>
      props.oos || props.hide ? '#c0392b11' : 'white'}
    &:hover {
      cursor: pointer;
      background-color: ${props =>
          props.oos || props.hide ? '#c0392b11' : '#e7e7e7'};
    }
`;

const Description = styled.div`
    color: gray;
    font-size: 10px;
    margin-top: 3px;
`;

//Collapsible trigger={sectionName}
const BadgeSpan = styled.span`
    color: #ffffff;
    border-radius: 5px;
    background-color: ${pomegranateRed};
    white-space: nowrap;
`;

const HiddenSpan = styled.span`
    color: #ffffff;
    border-radius: 5px;
    background-color: #2980b9;
    white-space: nowrap;
`;

export function Menu2({
    setOpenFood,
    menu,
    margin,
    padding,
    showCount,
    showDescription = false,
    showPrice = true,
    showChoice = false,
    showToppings = false,
    showNotes = false,
    openSectionIndexes = [],
}) {
    return (
        <MenuStyled margin={margin}>
            <MenuContent padding={padding}>
                {menu &&
                    Object.entries(menu).map(([sectionIndex, section]) =>
                        section ? (
                            <Collapsible
                                key={section.id}
                                trigger={
                                    section.name +
                                    ' ' +
                                    (showCount
                                        ? '(' +
                                          Object.entries(section.foods).length +
                                          ')'
                                        : '')
                                }
                                open={
                                    openSectionIndexes.includes(sectionIndex)
                                        ? true
                                        : false
                                }
                            >
                                <FoodGrid>
                                    {section.foods &&
                                        Object.entries(section.foods).map(
                                            ([idx, food]) =>
                                                food ? (
                                                    <FoodContainer
                                                        key={
                                                            section.id +
                                                            '-' +
                                                            food.id +
                                                            '-' +
                                                            idx
                                                        }
                                                        oos={food.oos}
                                                        hide={food.hide}
                                                        onClick={() => {
                                                            setOpenFood(food);
                                                        }}
                                                    >
                                                        <FoodLabel>
                                                            <div
                                                                style={{
                                                                    marginRight:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <span opacity="0.5">
                                                                    {food.name}{' '}
                                                                </span>
                                                                {food.oos ? (
                                                                    <BadgeSpan>
                                                                        <b>
                                                                            &nbsp;Sold
                                                                            Out&nbsp;
                                                                        </b>
                                                                    </BadgeSpan>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {food.hide &&
                                                                food.oos ? (
                                                                    <span>
                                                                        &nbsp;
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {food.hide ? (
                                                                    <HiddenSpan>
                                                                        <b>
                                                                            &nbsp;Hidden&nbsp;
                                                                        </b>
                                                                    </HiddenSpan>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>

                                                            {showPrice && (
                                                                <div>
                                                                    {food.pricelabel
                                                                        ? food.pricelabel
                                                                        : formatPrice(
                                                                              food.price /
                                                                                  100
                                                                          )}
                                                                </div>
                                                            )}
                                                        </FoodLabel>
                                                        {showDescription && (
                                                            <Description>
                                                                {food.desc}
                                                            </Description>
                                                        )}
                                                        {showChoice &&
                                                            food.choice && (
                                                                <Description>
                                                                    {' '}
                                                                    (+){' '}
                                                                    {
                                                                        food.choice
                                                                    }
                                                                </Description>
                                                            )}
                                                        {showToppings &&
                                                            food.toppingsStr && (
                                                                <Description>
                                                                    {' '}
                                                                    (+){' '}
                                                                    {
                                                                        food.toppingsStr
                                                                    }
                                                                </Description>
                                                            )}
                                                        {showNotes &&
                                                            food.notes && (
                                                                <Description>
                                                                    {' '}
                                                                    <i className="fas fa-info-circle" />{' '}
                                                                    {food.notes}
                                                                </Description>
                                                            )}
                                                    </FoodContainer>
                                                ) : (
                                                    <div key={idx} />
                                                )
                                        )}
                                </FoodGrid>
                            </Collapsible>
                        ) : (
                            <div key={sectionIndex} />
                        )
                    )}
            </MenuContent>
        </MenuStyled>
    );
}
