import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Title } from '../Styles/title';
import { corianderGreen } from '../Styles/colors';

// Styling a regular HTML input
//  width: 24px;
// const StyledInput = styled.input`
//   display: block;
//   margin: 20px 0px;
//   border: 1px solid lightblue;
// `;

const StyledInput = styled.input`
    display: block;
    border: 1px solid lightred;
    margin: 0px 10px;
`;

//  height: 24px;

const NotesContainer = styled(Title)`
    display: flex;
    margin: 20px 0px;
`;

export function RefundQtyInput(props) {
    const callbackRef = useCallback(inputElement => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    function handleItemReturnQty(e, key) {
        if (+e.target.value <= props.max) {
            props.returnQty.setItemReturnQty(key, e.target.value);
        } else {
            props.returnQty.setItemReturnQty(key, '');
        }
    }
    console.log('RefundQtyInput', props);

    //    ref={callbackRef}

    /*
  If wanted to use the commented section:
                <RefundQtyInput returnQty={returnQty}
                       itemkey={key} max={refundOrder['items'][key].quantity} placeholder=""
              ></RefundQtyInput>
  **/
    return (
        <StyledInput {...props} />
        // <StyledInput
        // key={props.itemkey}
        // type="number" pattern="[0-9]*" name={props.itemkey} placeholder={props.placeholder}
        // value={props.returnQty.getReturnQty(props.itemkey)}
        // style={{ width: "40px" }}
        // min="0" max={props.max}
        // onChange={(e) => handleItemReturnQty(e, props.itemkey)}
        // />
    );
}
