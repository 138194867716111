import { reportError } from '../reportError';

async function resetDBForOrderClosing(restauId, tableId) {
    const database = window.customerApp.database();
    let updates = {};
    let rtkey = restauId + ':' + tableId;
    updates['orders/' + rtkey] = null;

    updates['summons/' + restauId + '/' + tableId] = null;
    updates['activeusers/' + restauId + '/' + tableId] = null;

    await database.ref().update(updates);
}

export async function closeDineInOrder(restauId, tableId, id) {
    const database = window.customerApp.database();
    var updates = {};
    // TODO: confirm below
    //updates["activeusers/"+ restauId+ "/" + tableId + "/checkins"] = null;
    updates['activeusers/' + restauId + '/' + tableId + '/forceDetach'] = true;
    //For review after order close
    updates['activeusers/' + restauId + '/' + tableId + '/orderHistoryId'] = id;
    await database.ref().update(updates);

    await resetDBForOrderClosing(restauId, tableId);

    let summonData = (await database
        .ref('summons/' + restauId + '/' + tableId)
        .once('value')).val();
    if (summonData) {
        reportError(window.restauId, window.email, {
            msg: {
                message: 'summons is not cleaned properly',
                restauId,
                tableId,
                summonData: summonData,
            },
        });
        await resetDBForOrderClosing(restauId, tableId);
    }
}
