const NATIVE_EVENT_TYPE = {
    TERMINAL_READER_CONNECTED: 'TERMINAL_READER_CONNECTED',
    TERMINAL_READER_DISCONNECTED: 'TERMINAL_READER_DISCONNECTED',
    TERMINAL_REFRESH_CONNECTION_TOKEN: 'TERMINAL_REFRESH_CONNECTION_TOKEN',
    TERMINAL_READER_FAILED_CONNECTING: 'TERMINAL_READER_FAILED_CONNECTING',
    TERMINAL_READER_NOT_FOUND: 'TERMINAL_READER_NOT_FOUND',
};
const eventBus = {
    on(event, callback) {
        document.addEventListener(event, e => callback(e.detail));
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
        document.removeEventListener(event, callback);
    },
};

export default eventBus;
export { NATIVE_EVENT_TYPE };
