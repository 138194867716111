import React, { useContext } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import MenuContainer from '../../Menu/MenuContainer';

export default function UserTopItems({ userDetails = {}, setOpenFood }) {
    const { foodIdLookup } = useContext(MenuContext);
    const topItemsMenu = [
        { id: 'topItems', name: 'Items ordered history', foods: [] },
    ];
    if (
        userDetails &&
        userDetails.topItems &&
        userDetails.topItems.length > 0
    ) {
        userDetails.completedOrders &&
            (topItemsMenu[0].name += ` (Number of orders: ${
                userDetails.completedOrders.length
            })`);
        userDetails.topItems.forEach(
            ([, { itemId, choiceId, toppingsStr, toppingIds, notes }]) => {
                const pastItem = { ...foodIdLookup[itemId] };
                if (typeof choiceId !== 'undefined' && pastItem.choices) {
                    pastItem.choiceId = choiceId;
                    const selectedChoice = pastItem.choices.find(
                        c => c.id == choiceId
                    );
                    selectedChoice
                        ? (pastItem.choice = selectedChoice.name)
                        : console.error(
                              'Not found choice id in item',
                              choiceId,
                              pastItem
                          );
                }
                if (
                    typeof toppingIds !== 'undefined' &&
                    toppingIds.length > 0 &&
                    pastItem.toppings
                ) {
                    pastItem.toppingsStr = toppingsStr;
                    pastItem.toppings = pastItem.toppings.map(top => {
                        return toppingIds.includes(top.id)
                            ? { ...top, checked: true }
                            : top;
                    });
                }
                if (typeof notes !== 'undefined') {
                    pastItem.notes = notes;
                }
                //console.log("pastItem", pastItem);
                topItemsMenu[0].foods.push(pastItem);
            }
        );
    }

    return (
        <>
            {topItemsMenu[0]['foods'].length > 0 ? (
                <MenuContainer
                    setOpenFood={setOpenFood}
                    menu={topItemsMenu}
                    margin={'0px'}
                    padding={'0px'}
                    showPrice={false}
                    showDescription={false}
                    showChoice={true}
                    showToppings={true}
                    showNotes={true}
                    activeSection={topItemsMenu[0].id}
                />
            ) : (
                <div />
            )}
        </>
    );
}
