import React from 'react';
import { pStyleH2, pStyleH3, pStyleH4, pStyleText } from './TemplateStyles';

export default ({ restauName, address, phonenumber, showPoweredBy = true }) => {
    return (
        <div style={{ textAlign: 'center', wordWrap: 'break-word' }}>
            {restauName && <div style={pStyleH2}>{restauName}</div>}
            {address && <div style={pStyleText}>{address}</div>}
            {phonenumber && <div style={pStyleText}>{phonenumber}</div>}
            {showPoweredBy && (
                <div>
                    <span style={pStyleText}>powered by</span>
                    <span style={pStyleH3}>&nbsp;StrideQ</span>
                </div>
            )}
        </div>
    );
};
