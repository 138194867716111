import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useReducer,
} from 'react';
import { sendSms } from '../../apis/sendSms';
import { acceptTakeoutOrder, getOrderStatus } from '../../apis/Takeout';
import { OrderContext } from '../OrderContext';
import { PrintContext } from '../PrintContext';
import { RestaurantContext } from '../RestaurantContext';
import { useSnackbar } from 'notistack';
import { useHoldStatus } from '../../Hooks/useHoldStatus';
import { bulkUpdateTableOrders } from '../../apis/DineIn';
import { isDineInMode } from '../../Hooks/useOrders';

const dineInSendToChefReducer = (state, action) => {
    if (action.type === 'RESET') {
        return {};
    }
    var newState = { ...state };
    newState[action.tableId] = action.status;
    return newState;
};

//context providing access to this store.
const AutoAcceptContext = createContext({});
function AutoAcceptProvider({ children }) {
    const { enqueueSnackbar } = useSnackbar();
    const { restauId, restauName, outsideBizHours, setPlayBeep } = useContext(
        RestaurantContext
    );
    const {
        autoAcceptQueue,
        setAutoAcceptQueue,
        userSettings,
        dispatchOrder,
        orders,
    } = useContext(OrderContext);
    // orders object from OrderContext above is dineInOrders.
    const { printOrderWithRetrySnackBar } = useContext(PrintContext);
    const [inProgressAutoAcceptOrder, setInProgressAutoAcceptOrder] = useState(
        false
    );
    const emptyHoldStatus = useHoldStatus({});
    const [autoAcceptDineInInterval, setAutoAcceptDineInInterval] = useState();
    const AUTO_ACCEPT_TIMER_INTERVAL = 1000 * 30; // 30 secs.
    const PRINT_WHEN_TIME_ELAPSED_IN_SECS = 25;
    const [dineInSendToChefStatus, dispatchSendToChef] = useReducer(
        dineInSendToChefReducer,
        {}
    );

    const autoAcceptDineInFn = () => {
        console.log(
            'Running Timer Interval Fn with Ref: ',
            autoAcceptDineInInterval
        );
        console.log('All orders ', orders);

        let orderByStatus = {};
        orderByStatus['WAITER'] = {};

        let waiterDineinTimestamp = {};

        let d = new Date();
        for (const key in orders) {
            // order items already marked as send_for_autoaccept are not to be tried again.
            // as we wouldnt know which of the station printer would have been failed.
            if (
                orders[key].status === 'WAITER' &&
                !orders[key]['send_for_autoaccept']
            ) {
                if (
                    !(orders[key].tableId in orderByStatus[orders[key].status])
                ) {
                    orderByStatus[orders[key].status][orders[key].tableId] = {};
                    waiterDineinTimestamp[orders[key].tableId] = orders[key]
                        .submitted_timestamp
                        ? orders[key].submitted_timestamp
                        : null;
                }
                orderByStatus[orders[key].status][orders[key].tableId][key] =
                    orders[key];
            }
        }
        console.log(
            'After filtering',
            orderByStatus['WAITER'],
            waiterDineinTimestamp
        );

        for (const tid in orderByStatus['WAITER']) {
            if (!waiterDineinTimestamp[tid]) {
                continue;
            }
            let timeElapsedinSecs =
                (d - new Date(waiterDineinTimestamp[tid])) / 1000;
            if (timeElapsedinSecs > PRINT_WHEN_TIME_ELAPSED_IN_SECS) {
                console.log('Will print table ', tid);
                bulkUpdateTableOrders(
                    orderByStatus['WAITER'][tid],
                    'COOK',
                    restauId,
                    emptyHoldStatus,
                    enqueueSnackbar,
                    printOrderWithRetrySnackBar,
                    tid,
                    dispatchSendToChef,
                    setPlayBeep
                );
                dispatchOrder({
                    type: 'SEND_FOR_AUTOACCEPT',
                    payload: orderByStatus['WAITER'][tid],
                });
            }
        }
    };

    useEffect(() => {
        console.log('useEFFECT dinein autoaccept starts.');

        if (
            typeof printOrderWithRetrySnackBar == 'undefined' ||
            typeof dispatchOrder == 'undefined'
        ) {
            console.log(
                'printOrderWithRetrySnackBar/dispatchOrder still undefined.'
            );
            return;
        }
        // To add this later use this to demo after restaurant is closed.
        // if(outsideBizHours) {
        //   return;
        // }
        if (!(userSettings && isDineInMode(userSettings.operationMode))) {
            return;
        }
        if (
            !(
                userSettings &&
                userSettings.autoAcceptDineInConfig &&
                userSettings.autoAcceptDineInConfig.enabled
            )
        ) {
            if (autoAcceptDineInInterval) {
                clearInterval(autoAcceptDineInInterval);
                setAutoAcceptDineInInterval(null);
            }
            //dispatchSendToChef({type: 'RESET'});
            return;
        }
        // TODO : before production turn on these lines. not run autoaccept in browser.
        if (!window.ReactNativeWebView) {
            console.log('No auto accept in non app version');
            return;
        }

        // QUESTON: should we have this block
        // if (autoAcceptDineInInterval){
        //   clearInterval(autoAcceptDineInInterval);
        //   console.log('clearing interval ' , autoAcceptDineInInterval );
        // }

        const autoAcceptDineInTimer = setInterval(
            autoAcceptDineInFn,
            AUTO_ACCEPT_TIMER_INTERVAL
        ); //every 10 secs
        console.log(
            'starting auto accept dine-in timer',
            autoAcceptDineInTimer
        );
        // --> QUESTION
        //setAutoAcceptDineInInterval(autoAcceotDineInTimer);
        return () => {
            console.log(
                'clearing existing interval from RETURN',
                autoAcceptDineInTimer
            );
            clearInterval(autoAcceptDineInTimer);
        };
        // --> QUESTION// TODO : should clearInterval be for local variable autoAcceotDineInTimer or state variable autoAcceptDineInInterval
    }, [userSettings, printOrderWithRetrySnackBar]);

    useEffect(() => {
        if (
            inProgressAutoAcceptOrder ||
            typeof printOrderWithRetrySnackBar == 'undefined' ||
            autoAcceptQueue.length == 0
        ) {
            console.log('UseEffect takeout autoaccept');
            return;
        }
        if (outsideBizHours) {
            return;
        }
        if (
            !(
                userSettings &&
                userSettings.autoAcceptConfig &&
                userSettings.autoAcceptConfig.enabled
            )
        ) {
            if (autoAcceptQueue.length > 0) {
                console.log('Cleaning auto accept queue');
                setAutoAcceptQueue([]);
            }
            return;
        }

        const processingId = autoAcceptQueue[0].val.id;
        const callBack = () => {
            setAutoAcceptQueue(autoAcceptQueue => {
                if (
                    autoAcceptQueue.findIndex(
                        eac => eac.val.id == processingId
                    ) >= 0
                ) {
                    return autoAcceptQueue.filter(
                        eac => eac.val.id !== processingId
                    );
                } else {
                    return autoAcceptQueue;
                }
            }); //.slice(1)
            setInProgressAutoAcceptOrder(false);
        };
        setInProgressAutoAcceptOrder(processingId);
        autoAcceptTakeoutOrders(autoAcceptQueue[0], userSettings, callBack);
    }, [
        inProgressAutoAcceptOrder,
        autoAcceptQueue,
        userSettings,
        printOrderWithRetrySnackBar,
        outsideBizHours,
    ]);

    async function autoAcceptTakeoutOrders(
        { key: takeoutOrderKey, val: takeoutOrder },
        userSettings,
        callBack
    ) {
        const showPrintPrice =
            'printSettings' in userSettings &&
            'showPrintPrice' in userSettings['printSettings']
                ? userSettings['printSettings']['showPrintPrice']
                : true;
        const noOfPrints =
            'printSettings' in userSettings &&
            'defaultNumOfPrints' in userSettings['printSettings']
                ? userSettings['printSettings']['defaultNumOfPrints']
                : 1;
        const estimatedTimeInMins =
            userSettings['autoAcceptConfig'] &&
            userSettings['autoAcceptConfig'].estimatedTimeInMins;

        //If order status already accepted
        try {
            const currstatus = await getOrderStatus(restauId, takeoutOrder.id);
            console.log('currstatus', currstatus);
            if (currstatus && currstatus != '') {
                console.log('Order already accepted.');
                callBack();
                return;
            }
        } catch (e) {
            enqueueSnackbar(
                'Failed to auto accept Order #' +
                    takeoutOrder.orderUnderName +
                    '.',
                { variant: 'error' }
            );
        }

        const onPrintSuccess = async () => {
            try {
                await acceptTakeoutOrder(
                    restauId,
                    takeoutOrder.id,
                    estimatedTimeInMins
                );
                if (
                    takeoutOrder.phoneNumber &&
                    takeoutOrder.customerType !== 'QSR-Dine-In'
                ) {
                    try {
                        const token = await window.customerApp
                            .auth()
                            .currentUser.getIdToken();
                        const resultSms = await sendSms({
                            token,
                            restauId,
                            restauName,
                            orderId: takeoutOrder.id,
                            orderNumber: takeoutOrder.number,
                            phoneNumber: takeoutOrder.phoneNumber,
                            orderUnderName: takeoutOrder.orderUnderName,
                            customerType: takeoutOrder.customerType,
                            estimatedTimeInMins: estimatedTimeInMins,
                            isScheduledOrder: takeoutOrder.scheduledOrder,
                            scheduledDate:
                                takeoutOrder.scheduledDate +
                                ' ' +
                                takeoutOrder.scheduledTime,
                            deliveryScheduledForLater:takeoutOrder.deliveryScheduledForLater,
                            deliveryScheduledDate: takeoutOrder.deliveryScheduledDate,
                            deliveryScheduledTime: takeoutOrder.deliveryScheduledTime,
                            orderType: takeoutOrder.orderType

                        });
                    } catch (error) {
                        console.error('Failed to send sms for accept', error);
                    }
                } else {
                    console.log(
                        'Skipping to semd accept sms',
                        takeoutOrder.phoneNumber,
                        takeoutOrder.customerType
                    );
                }
                enqueueSnackbar(
                    'Order #' + takeoutOrder.orderUnderName + ' accepted.',
                    { variant: 'success' }
                );
            } catch (error) {
                enqueueSnackbar(
                    'Failed to accept Order #' + takeoutOrder.orderUnderName,
                    { variant: 'error' }
                );
                console.error('Failed to accept order', error);
            } finally {
                callBack();
            }
        };
        // const onPrintFailed = () => {
        //   enqueueSnackbar("Failed to print Order #"+takeoutOrder.orderUnderName + " Order Not accepted.", {variant:'error'});
        //   callBack();
        // }
        if (noOfPrints === 0) {
            await onPrintSuccess();
            callBack();
        } else {
            printOrderWithRetrySnackBar({
                order: takeoutOrder,
                numCopies: noOfPrints,
                onSuccess: onPrintSuccess,
            }).finally(() => callBack());
        }

        /* .then((printResult) => {
            if(printResult.success) { 
              onPrintSuccess();
            } else {
                const failedStations = printResult.errors.map(e => e.station).filter(e => e && e.length > 0);
                console.log("failedStations". failedStations, takeoutOrderKey);
                enqueueSnackbar(
                    "Failed to print Order #"+takeoutOrder.number + (failedStations.length > 0 ? (" at stations: " + failedStations.join(", ")) : ""), 
                    {variant:'error', persist: true}
                );
                // dispatchOrder({type: "UPDATE", key: takeoutOrderKey, 
                //   payload: { ...takeoutOrder, autoAcceptError: {
                //     failedStations: failedStations
                //   }
                // }});
                callBack();
            }
          }).catch((error) => {
              console.error("Failed to print", error, takeoutOrderKey);
              enqueueSnackbar("Failed to print. Please reprint Order #"+takeoutOrder.number, {variant:'error', persist: true});
            //   dispatchOrder({type: "UPDATE", key: takeoutOrderKey, 
            //       payload: { ...takeoutOrder, autoAcceptError: {
            //         error
            //       }
            //     }});
              callBack();
          }) */
        // printOrder(takeoutOrder, noOfPrints, false, showPrintPrice, restauName, false, null, null, enqueueSnackbar, () => {},
        //   onPrintSuccess, onPrintFailed);
    }

    const value = {
        inProgressAutoAcceptOrder,
        dispatchSendToChef,
        dineInSendToChefStatus,
    };
    return (
        <AutoAcceptContext.Provider value={value}>
            {children}
        </AutoAcceptContext.Provider>
    );
}

export { AutoAcceptContext, AutoAcceptProvider };
