import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { formatPrice } from '../Data/FoodData';
import { getPrice } from '../FoodDialog/FoodDialog';
import Collapsible from '../Menu/Collapsible';
import { RibbonButton } from '../Navbar/Ribbon';
import {
    DetailItem,
    OrderContainerNonClickable,
    Title20px,
    ClearButton,
} from './Feed';
import {
    getDateTime,
    getLocalDateTime,
    getLocalTimeFromDateObj,
    getQsrTableIdLabel,
} from '../utils/Util';
import { corianderGreen, pomegranateRed } from '../Styles/colors';
import {
    OrderContainer,
    getTakeOutSubTotal,
    ButtonContainerCenter,
} from './Order';
import CircularProgress from '@material-ui/core/CircularProgress';
import Config from '../config/Config';
import { readText } from '../Hooks/useOrders';
import { CreateOrderContext, ORDER_TYPE } from '../contexts/CreateOrderContext';
import OrderPrintButton from '../components/common/OrderPrintButton';
import { AutoAcceptContext } from '../contexts/AutoAcceptContext';
import PayButton from '../components/PayButton';
import { PaymentContext } from '../contexts/PaymentContext';
import ItemMetaData from '../components/modifiers/ItemMetaData';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import { OrderAheadSpan } from './OrderAheadSpan';

const { default: axios } = require('axios');

export const TakeoutItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 175px 70px 75px;
    justify-content: space-between;
    ${({ done }) => done && `background-color: lightgreen;`}
`;

export const TakeoutItemWithPrice = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 175px 70px 70px 70px;
    justify-content: space-between;
    ${({ done }) => done && `background-color: lightgreen;`}
`;

export function markTakeoutOrderAccepted(
    e,
    tkorder,
    setAcceptOrderDialog,
    restauId,
    restauName,
    isScheduledOrder = false
) {
    e.preventDefault();
    e.stopPropagation();
    setAcceptOrderDialog({
        close: false,
        takeoutOrder: tkorder,
        restauId,
        restauName,
        isScheduledOrder,
    });
}

function getReadyByTime(takeoutorder) {
    var d = getTimeInFuture(
        takeoutorder['accepted_timestamp'],
        takeoutorder['estimated_time_in_minutes']
    );
    return getLocalTimeFromDateObj(d);
}

function getTimeInFuture(timeString, minsInteger) {
    var d = new Date(Date.parse(timeString));
    d.setMinutes(d.getMinutes() + minsInteger);
    return d;
}

function getSendReadySmsRecommendation(takeoutorder, restauId) {
    if (restauId === 'mrbiryanid') {
        return 'yes';
    }

    if (takeoutorder['customerType'] === 'QSR-Dine-In') {
        return 'no';
    }
    const BUFFER = 15;
    var d = getTimeInFuture(
        takeoutorder['accepted_timestamp'],
        takeoutorder['estimated_time_in_minutes'] + BUFFER
    );
    // max worst case ready time for the order.

    var currD = new Date();
    if (currD < d) {
        return 'yes';
    }
    return 'no';
}

// export async function markTakeoutOrderPaidInStoreImpl(takeoutorder, restauId, enqueueSnackbar) {

//   var confirm = window.confirm("Confirm payment collected for order number "+ takeoutorder.number);
//   if (!confirm) {
//     return;
//   }
//   var updates = {};

//   var dt = getDateTime();
//   var enqueueMsg ;
//   const database = window.customerApp.database();
//   takeoutorder['payment_success'] = true;
//   takeoutorder['paid_in_store'] = true;
//   takeoutorder['paid_in_store_timestamp'] = dt;
//   if (takeoutorder['status'] === "READY"){
//     takeoutorder['status'] = "DONE";
//     takeoutorder['completed_timestamp'] = dt;
//     updates["takeout_orders_completed/"+restauId+"/" + takeoutorder.id ] = takeoutorder;
//     updates["takeout_orders/"+restauId+"/" + takeoutorder.id ] = null;
//     enqueueMsg = "Order "+takeoutorder['number']+" completed."
//   }else{
//     takeoutorder['status'] = "PAID_PENDING_READY";
//     updates["takeout_orders/"+restauId+"/" + takeoutorder.id ] = takeoutorder;
//     enqueueMsg = "Order "+takeoutorder['number']+" marked as Paid In Store."
//   }

//   try{
//     await database.ref().update(updates);
//     enqueueSnackbar(enqueueMsg, {variant:'success'});
//   }catch(error){
//     enqueueSnackbar("Update to Order "+takeoutorder['number']+" unsuccessful. " + error, {variant:'error'});
//   }
// };

export async function markTakeoutOrderDoneImpl(
    takeoutorder,
    restauId,
    restauName,
    enqueueSnackbar
) {
    var sendReadySmsRecommendation = getSendReadySmsRecommendation(
        takeoutorder,
        restauId
    );
    var msg =
        'Send Order Ready Text for order ' +
        takeoutorder.number +
        getQsrTableIdLabel(takeoutorder, ' for Table-') +
        ' ?' +
        "\n-- Enter 'yes', if you want to send the text." +
        "\n-- Enter 'no', if customer has already picked up the order and you don't want to send the text.";
    if (takeoutorder['payment_success']) {
        msg =
            'Confirm completion for order ' +
            takeoutorder.number +
            getQsrTableIdLabel(takeoutorder, ' for Table-') +
            '.\n\n' +
            msg;
    }
    var sendReadySms = window.prompt(msg, sendReadySmsRecommendation);
    if (!sendReadySms) {
        return;
    }
    const database = window.customerApp.database();
    var updates = {};
    var dt = getDateTime();

    if (takeoutorder['payment_success']) {
        takeoutorder['status'] = 'DONE';
        takeoutorder['completed_timestamp'] = dt;
        takeoutorder['ready_timestamp'] = dt;
        updates[
            'takeout_orders_completed/' + restauId + '/' + takeoutorder.id
        ] = takeoutorder;
        updates['takeout_orders/' + restauId + '/' + takeoutorder.id] = null;
    } else {
        takeoutorder['status'] = 'READY';
        takeoutorder['ready_timestamp'] = dt;
        updates[
            'takeout_orders/' + restauId + '/' + takeoutorder.id + '/status'
        ] = 'READY';
        updates[
            'takeout_orders/' +
                restauId +
                '/' +
                takeoutorder.id +
                '/ready_timestamp'
        ] = dt;
    }

    try {
        await database.ref().update(updates);
        if (takeoutorder['payment_success']) {
            enqueueSnackbar('Order ' + takeoutorder['number'] + ' completed.', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(
                'Order ' + takeoutorder['number'] + ' marked as ready.',
                { variant: 'success' }
            );
        }

        try {
            if (
                'phoneNumber' in takeoutorder &&
                sendReadySms.toLowerCase() === 'yes'
            ) {
                var url = Config.send_sms_api;
                const token = await window.customerApp
                    .auth()
                    .currentUser.getIdToken();
                const resp = await axios.post(url, {
                    restauId: restauId,
                    orderId: takeoutorder.id,
                    orderNum: takeoutorder['number'],
                    phoneNumber: takeoutorder['phoneNumber'],
                    orderUnderName: takeoutorder['orderUnderName'],
                    restauName: restauName,
                    orderType: takeoutorder['orderType'],
                    headers: token,
                });
                //enqueueSnackbar("Order "+takeoutOrders[tkey]['number']+" completed.", {variant:'success'});
            } else {
                console.log(
                    'Not sending the ready sms as promt input = ',
                    sendReadySms
                );
            }
        } catch (error) {
            enqueueSnackbar(
                'SMS unsuccessful for order ' +
                    takeoutorder['number'] +
                    '. ' +
                    error,
                { variant: 'error' }
            );
        }
    } catch (error) {
        enqueueSnackbar(
            'Update to order ' +
                takeoutorder['number'] +
                ' unsuccessful. ' +
                error,
            { variant: 'error' }
        );
    }
}

export function getScheduledDelivery(order){
    if(order.deliveryScheduledForLater){
        return " ["+order.deliveryScheduledDate+" "+order.deliveryScheduledTime+"] ";
    }else{
        return "";
    }
}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function readOrder(takeoutOrder) {
    readText('Order number ' + takeoutOrder['number']);
    await sleep(500);
    for (const i in takeoutOrder['items']) {
        const item = takeoutOrder['items'][i];
        await readOrderItem(item);
        await sleep(2000);
    }
}

async function readOrderItem(item) {
    readText(item['name']);
    await sleep(500);
    if ('choice' in item && item['choice']) {
        readText(item['choice']);
        await sleep(500);
    }
    if ('toppings' in item) {
        for (const topping of item['toppings']) {
            if ('checked' in topping && topping['checked']) {
                readText(topping['name']);
                await sleep(500);
            }
        }
    }
    if ('notes' in item && item['notes']) {
        readText('Special Instruction');
        await sleep(500);
        readText(item['notes']);
        await sleep(500);
    }
    readText(' quantity ' + item['quantity']);
}

export function QsrAndTakeoutOrders({
    heading,
    takeoutOrders,
    showReadFeature,
    salesTax,
    minCreditCardAmt,
    minCreditCardFees,
    setAcceptOrderDialog,
    restauId,
    restauName,
    showPrintPrice,
    enqueueSnackbar,
    setTab,
    setRefundOrder,
    scheduledOrderConfig,
    isOrderAhead = false,
}) {
    const { inProgressAutoAcceptOrder } = useContext(AutoAcceptContext);
    const { terminalReaderConnected, processCashPayment } = useContext(
        PaymentContext
    );
    const [pending, setPending] = useState('false');
    const { startEditOrder, setInProgressOrderType } = useContext(
        CreateOrderContext
    );

    const database = window.customerApp.database();

    const markRefundOrder = tkey => {
        setRefundOrder(takeoutOrders[tkey]);
    };

    function getToggleColor(order) {
        if (isOrderScheduled(order)) {
            if (order['payment_success']) {
                return '#fffacd';
            } else {
                return '#f6c3c3';
            }
        }
        if (order['payment_success']) {
            return '#c3f6c3';
        } else {
            return '#f6c3c3';
        }
    }
    function getLocalDate(order) {
        if (isOrderScheduled(order)) {
            return order['scheduledDate'] + ' ' + order['scheduledTime'];
        } else {
            return getLocalDateTime(order['created_timestamp']);
        }
    }

    function getDateFromString(localDate) {
        let d = new Date(localDate.split(' ')[0]);
        let s = localDate.split(' ')[2] + ' ' + localDate.split(' ')[3];
        let parts = s.match(/(\d+)\:(\d+) (\w+)/);
        let hours = /am/i.test(parts[3])
            ? parseInt(parts[1], 10)
            : parseInt(parts[1], 10) + 12;
        let minutes = parseInt(parts[2], 10);

        d.setHours(hours);
        d.setMinutes(minutes);
        return d;
    }
    function isScheduledOrderToMarkItReadyAhead(order) {
        if (!isOrderScheduled(order)) {
            return false;
        }
        let parsedDate = new Date();
        try {
            let localDate = getLocalDate(order);
            parsedDate = new Date(localDate.split(' ')[0]);
            let nd = new Date();
            var nh = (getDateFromString(localDate) - nd) / 36e5;
            if (nh >= 1) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log(e);
        }
        return false;
    }
    function isOrderScheduled(order) {
        if (!isOrderAhead) {
            return false;
        }
        if (scheduledOrderConfig && scheduledOrderConfig.enabled) {
            if (order.scheduledOrder) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    function isValidOrder(order) {
        if (isOrderAhead) {
            return isOrderScheduled(order);
        } else {
            if (!order.scheduledOrder) {
                return true;
            } else {
                return false;
            }
        }
    }
    const markTakeoutItemDone = (tkey, ikey) => {
        var updates = {};
        updates[
            'takeout_orders/' +
                restauId +
                '/' +
                tkey +
                '/items/' +
                ikey +
                '/status'
        ] = 'DONE';
        // WIP : accepts the order.
        //updates["takeout_orders/"+restauId+"/" + tkey + "/status"] = "WIP";
        database.ref().update(updates);
    };

    async function markTakeoutOrderDone(e, takeoutorder) {
        e.preventDefault();
        e.stopPropagation();
        if (isScheduledOrderToMarkItReadyAhead(takeoutorder)) {
            let confirm = window.confirm(
                'Are you sure you want to mark this scheduled order ready? Its ahead of more than one hour'
            );
            if (!confirm) {
                return;
            }
        }
        setPending(takeoutorder.id);
        markTakeoutOrderDoneImpl(
            takeoutorder,
            restauId,
            restauName,
            enqueueSnackbar
        );
        setPending('false');
    }

    // async function markTakeoutOrderPaidInStore(takeoutorder){
    //     setPending(takeoutorder.id);
    //     markTakeoutOrderPaidInStoreImpl(takeoutorder, restauId, enqueueSnackbar);
    //     setPending('false');
    // }
    function orderAheadTimeSpan(localDate) {
        return <span style={{ color: '#e74c3c' }}> {localDate}</span>;
    }

    
    return (
        <>
            <OrderContainerNonClickable>
                <h3>{heading}</h3>
            </OrderContainerNonClickable>
            {Object.keys(takeoutOrders).map(tkey =>
                !isValidOrder(takeoutOrders[tkey]) ? (
                    <></>
                ) : (
                    <React.Fragment key={'takeoutOrderContainer' + tkey}>
                        <Collapsible
                            key={'takeoutOrders' + tkey}
                            triggerElementProps={{
                                key: 'takeoutOrders-trigger' + tkey,
                            }}
                            trigger={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'baseline',
                                    }}
                                >
                                    <span>
                                        {'Order # ' +
                                            takeoutOrders[tkey]['number'] +
                                            ' / ' +
                                            getQsrTableIdLabel(
                                                takeoutOrders[tkey],
                                                'Table-',
                                                ' / '
                                            ) +
                                            (takeoutOrders[tkey][
                                                'orderUnderName'
                                            ]
                                                ? takeoutOrders[tkey][
                                                      'orderUnderName'
                                                  ] + ' / '
                                                : '') +
                                            (takeoutOrders[tkey][
                                                'payment_success'
                                            ]
                                                ? 'Paid ' +
                                                  (takeoutOrders[tkey][
                                                      'paid_in_store'
                                                  ]
                                                      ? '(Store) '
                                                      : '') +
                                                  '/ '
                                                : 'Unpaid / ') +
                                            takeoutOrders[tkey]['orderType'] + getScheduledDelivery(takeoutOrders[tkey])+
                                            ' / '}
                                        <OrderAheadSpan
                                            localDate={getLocalDate(
                                                takeoutOrders[tkey]
                                            )}
                                            isScheduledOrder={isOrderScheduled(
                                                takeoutOrders[tkey]
                                            )}
                                        />
                                    </span>
                                    <div style={{ marginRight: '20px' }}>
                                        <RibbonButton
                                            color={
                                                takeoutOrders[tkey][
                                                    'payment_success'
                                                ]
                                                    ? `${corianderGreen};`
                                                    : `${pomegranateRed};`
                                            }
                                            disabled={
                                                !takeoutOrders[tkey][
                                                    'status'
                                                ] ||
                                                takeoutOrders[tkey][
                                                    'status'
                                                ] === 'READY' ||
                                                pending === tkey
                                            }
                                            onClick={e =>
                                                markTakeoutOrderDone(
                                                    e,
                                                    takeoutOrders[tkey]
                                                )
                                            }
                                        >
                                            {pending === tkey ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{ color: 'black' }}
                                                />
                                            ) : (
                                                <>
                                                    <b>
                                                        Ready{' '}
                                                        {!isOrderAhead &&
                                                            takeoutOrders[tkey][
                                                                'accepted_timestamp'
                                                            ] &&
                                                            typeof takeoutOrders[
                                                                tkey
                                                            ][
                                                                'estimated_time_in_minutes'
                                                            ] !=
                                                                'undefined' && (
                                                                <span>
                                                                    (
                                                                    {getReadyByTime(
                                                                        takeoutOrders[
                                                                            tkey
                                                                        ]
                                                                    )}
                                                                    )
                                                                </span>
                                                            )}
                                                    </b>
                                                </>
                                            )}
                                        </RibbonButton>
                                    </div>
                                </div>
                            }
                            triggerStyle={{
                                backgroundColor: getToggleColor(
                                    takeoutOrders[tkey]
                                ),
                            }}
                            open={!takeoutOrders[tkey]['status'] ? true : false}
                        >
                            {/* <DetailItem>{takeoutOrders[tkey]['customerType']} / {takeoutOrders[tkey]['orderType']} / Phone Number : {takeoutOrders[tkey]['phoneNumber']? takeoutOrders[tkey]['phoneNumber']:"Not Given"}</DetailItem> */}
                            <div
                                className={
                                    takeoutOrders[tkey]['status']
                                        ? ''
                                        : 'blinking'
                                }
                            >
                                {takeoutOrders[tkey]['orderInstruction'] && (
                                    <div>
                                        {'Order Instruction :' +
                                            takeoutOrders[tkey][
                                                'orderInstruction'
                                            ]}
                                    </div>
                                )}

                                <div>
                                    Phone Number :{' '}
                                    {takeoutOrders[tkey]['phoneNumber']
                                        ? takeoutOrders[tkey]['phoneNumber']
                                        : 'Not Given'}
                                    &nbsp;/{' '}
                                    {takeoutOrders[tkey]['customerType']}
                                </div>
                                {takeoutOrders[tkey]['deliveryAddress'] && (
                                    <div>
                                        {'Delivery Address :' +
                                            takeoutOrders[tkey][
                                                'deliveryAddress'
                                            ]}
                                    </div>
                                )}
                                {takeoutOrders[tkey]['deliveryScheduledForLater'] && (
                                    <div>
                                        {'Delivery Schedule Time :' +
                                        getScheduledDelivery(takeoutOrders[tkey])
                                            }
                                    </div>
                                )}
                                

                                {Object.keys(takeoutOrders[tkey]['items']).map(
                                    key => (
                                        <div key={key}>
                                            {takeoutOrders[tkey]['items'][key]
                                                .quantity ? (
                                                <OrderContainerNonClickable>
                                                    <TakeoutItemWithPrice
                                                        done={
                                                            takeoutOrders[tkey][
                                                                'items'
                                                            ][key].status ===
                                                            'DONE'
                                                        }
                                                    >
                                                        <Title20px>
                                                            {
                                                                takeoutOrders[
                                                                    tkey
                                                                ]['items'][key]
                                                                    .name
                                                            }
                                                        </Title20px>
                                                        <Title20px>
                                                            Qty-
                                                            {
                                                                takeoutOrders[
                                                                    tkey
                                                                ]['items'][key]
                                                                    .quantity
                                                            }
                                                        </Title20px>
                                                        <div>
                                                            {formatPrice(
                                                                getPrice(
                                                                    takeoutOrders[
                                                                        tkey
                                                                    ]['items'][
                                                                        key
                                                                    ]
                                                                ) / 100
                                                            )}
                                                        </div>
                                                        <div>
                                                            {showReadFeature ? (
                                                                <ClearButton
                                                                    color={
                                                                        takeoutOrders[
                                                                            tkey
                                                                        ][
                                                                            'payment_success'
                                                                        ]
                                                                            ? `${corianderGreen};`
                                                                            : `${pomegranateRed};`
                                                                    }
                                                                    onClick={() =>
                                                                        readOrderItem(
                                                                            takeoutOrders[
                                                                                tkey
                                                                            ][
                                                                                'items'
                                                                            ][
                                                                                key
                                                                            ]
                                                                        )
                                                                    }
                                                                >
                                                                    <b>
                                                                        <i className="fas fa-volume-up" />{' '}
                                                                    </b>
                                                                </ClearButton>
                                                            ) : takeoutOrders[
                                                                  tkey
                                                              ]['items'][key]
                                                                  .status !==
                                                              'DONE' ? (
                                                                <ClearButton
                                                                    color={
                                                                        takeoutOrders[
                                                                            tkey
                                                                        ][
                                                                            'payment_success'
                                                                        ]
                                                                            ? `${corianderGreen};`
                                                                            : `${pomegranateRed};`
                                                                    }
                                                                    onClick={() => {
                                                                        markTakeoutItemDone(
                                                                            tkey,
                                                                            key
                                                                        );
                                                                    }}
                                                                >
                                                                    <b>Done</b>
                                                                </ClearButton>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </TakeoutItemWithPrice>
                                                    <ItemMetaData
                                                        item={
                                                            takeoutOrders[tkey][
                                                                'items'
                                                            ][key]
                                                        }
                                                    />
                                                </OrderContainerNonClickable>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    )
                                )}
                                {!takeoutOrders[tkey]['payment_success'] && (
                                    <OrderContainer>
                                        <TakeoutItemWithPrice>
                                            <div>Sub-Total</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    getTakeOutSubTotal(
                                                        takeoutOrders[tkey][
                                                            'items'
                                                        ]
                                                    ) / 100
                                                )}
                                            </div>
                                        </TakeoutItemWithPrice>
                                        <TakeoutItemWithPrice>
                                            <div>Tax</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    Math.round(
                                                        (getTakeOutSubTotal(
                                                            takeoutOrders[tkey][
                                                                'items'
                                                            ]
                                                        ) *
                                                            salesTax) /
                                                            100
                                                    ) / 100
                                                )}
                                            </div>
                                        </TakeoutItemWithPrice>
                                        {takeoutOrders[tkey][
                                            'minCreditCardFees'
                                        ] ? (
                                            <TakeoutItemWithPrice>
                                                <div>
                                                    Credit Card fees <br />{' '}
                                                    (order &lt;{' '}
                                                    {formatPrice(
                                                        minCreditCardAmt / 100
                                                    )}
                                                    )
                                                </div>
                                                <div />
                                                <div>
                                                    {formatPrice(
                                                        minCreditCardFees / 100
                                                    )}
                                                </div>
                                            </TakeoutItemWithPrice>
                                        ) : (
                                            ''
                                        )}
                                        <TakeoutItemWithPrice>
                                            <div>Tip</div>
                                            <div />
                                            <div>
                                                {takeoutOrders[tkey].tip
                                                    ? formatPrice(
                                                          takeoutOrders[tkey]
                                                              .tip / 100
                                                      )
                                                    : ''}
                                            </div>
                                        </TakeoutItemWithPrice>
                                        <TakeoutItemWithPrice>
                                            <div>Total</div>
                                            <div />
                                            <div>
                                                {formatPrice(
                                                    takeoutOrders[tkey][
                                                        'payment_total'
                                                    ] / 100
                                                )}
                                            </div>
                                        </TakeoutItemWithPrice>
                                        {takeoutOrders[tkey]
                                            .total_amount_paid && (
                                            <>
                                                <TakeoutItemWithPrice>
                                                    <div>Amount Paid</div>
                                                    <div />
                                                    <div>
                                                        {formatPrice(
                                                            takeoutOrders[tkey]
                                                                .total_amount_paid /
                                                                100
                                                        )}
                                                    </div>
                                                </TakeoutItemWithPrice>
                                                <TakeoutItemWithPrice>
                                                    <div>Unpaid Amount</div>
                                                    <div />
                                                    <div>
                                                        {formatPrice(
                                                            (takeoutOrders[tkey]
                                                                .payment_total -
                                                                takeoutOrders[
                                                                    tkey
                                                                ]
                                                                    .total_amount_paid) /
                                                                100
                                                        )}
                                                    </div>
                                                </TakeoutItemWithPrice>
                                            </>
                                        )}
                                    </OrderContainer>
                                )}
                                {/* !takeoutOrders[tkey]['status'] && (typeof takeoutOrders[tkey]["autoAcceptError"] != "undefined") && 
            <OrderContainer>
              { takeoutOrders[tkey]["autoAcceptError"].error && <TakeoutItemWithPrice>
                    <div>Print error</div>
                    <div style={{color: "red"}}>{takeoutOrders[tkey]["autoAcceptError"].error}</div>
                    <div/>
                </TakeoutItemWithPrice>}
                { takeoutOrders[tkey]["autoAcceptError"].failedStations && <TakeoutItemWithPrice>
                    <div>Print failed at stations </div>
                    <div style={{color: "red"}}>{takeoutOrders[tkey]["autoAcceptError"].failedStations.join(", ")}</div>
                    <div/>
                </TakeoutItemWithPrice>}
            </OrderContainer> */}
                            </div>

                            <ButtonContainerCenter>
                                <RibbonButton
                                    disabled={
                                        takeoutOrders[tkey]['status'] ||
                                        inProgressAutoAcceptOrder === tkey
                                    }
                                    color={
                                        takeoutOrders[tkey]['payment_success']
                                            ? `${corianderGreen};`
                                            : `${pomegranateRed};`
                                    }
                                    onClick={e =>
                                        markTakeoutOrderAccepted(
                                            e,
                                            takeoutOrders[tkey],
                                            setAcceptOrderDialog,
                                            restauId,
                                            restauName,
                                            isOrderScheduled(
                                                takeoutOrders[tkey]
                                            )
                                        )
                                    }
                                >
                                    {inProgressAutoAcceptOrder === tkey ? (
                                        <CircularProgress
                                            size={20}
                                            style={{ color: 'black' }}
                                        />
                                    ) : (
                                        <b>
                                            Accept{' '}
                                            {typeof takeoutOrders[tkey][
                                                'estimated_time_in_minutes'
                                            ] !== 'undefined' &&
                                                !isOrderAhead && (
                                                    <span>
                                                        (ETA:{' '}
                                                        {
                                                            takeoutOrders[tkey][
                                                                'estimated_time_in_minutes'
                                                            ]
                                                        }
                                                        m)
                                                    </span>
                                                )}
                                        </b>
                                    )}
                                </RibbonButton>
                                {showReadFeature ? (
                                    <RibbonButton
                                        color={
                                            takeoutOrders[tkey][
                                                'payment_success'
                                            ]
                                                ? `${corianderGreen};`
                                                : `${pomegranateRed};`
                                        }
                                        onClick={() =>
                                            readOrder(takeoutOrders[tkey])
                                        }
                                    >
                                        <b>
                                            <i className="fas fa-volume-up" />{' '}
                                        </b>
                                    </RibbonButton>
                                ) : (
                                    <></>
                                )}
                                <OrderPrintButton
                                    order={takeoutOrders[tkey]}
                                    reprint={true}
                                >
                                    <b>Print</b>
                                </OrderPrintButton>
                                {takeoutOrders[tkey]['payment_success'] ? (
                                    <RibbonButton
                                        color={`${corianderGreen};`}
                                        onClick={() => markRefundOrder(tkey)}
                                    >
                                        <b>Refund</b>
                                    </RibbonButton>
                                ) : (
                                    <RibbonButton
                                        color={`${pomegranateRed};`}
                                        onClick={() => {
                                            setInProgressOrderType(
                                                ORDER_TYPE.TAKEOUT
                                            );
                                            startEditOrder(takeoutOrders[tkey]);
                                            setTab('MENU');
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        <b>Edit/Cancel</b>
                                    </RibbonButton>
                                )}
                                {/* <RibbonButton color={takeoutOrders[tkey]['payment_success'] ? `${corianderGreen};`:`${pomegranateRed};`} disabled={!(takeoutOrders[tkey]['status']) || (takeoutOrders[tkey]['status'] === 'READY') || (pending === tkey)} onClick={(e) => markTakeoutOrderDone(e, takeoutOrders[tkey])}>
          {pending === tkey? <CircularProgress size={20} style={{'color': 'black'}}/> : <>
              <b>Ready {!isOrderAhead && takeoutOrders[tkey]['accepted_timestamp'] && (typeof takeoutOrders[tkey]['estimated_time_in_minutes'] != "undefined" ) && <span>({getReadyByTime(takeoutOrders[tkey])})</span>}</b>
          </>}
        </RibbonButton> */}
                                {!takeoutOrders[tkey]['payment_success'] &&
                                    (terminalReaderConnected ? (
                                        <PayButton
                                            order={takeoutOrders[tkey]}
                                        />
                                    ) : (
                                        <AsyncProgressRibbonButton
                                            isDisabled={
                                                !takeoutOrders[tkey]['status']
                                            }
                                            color={pomegranateRed}
                                            onClick={async () => {
                                                try {
                                                    const resultMsg = await processCashPayment(
                                                        takeoutOrders[tkey]
                                                    );
                                                    if (
                                                        resultMsg &&
                                                        resultMsg.length > 0
                                                    ) {
                                                        enqueueSnackbar(
                                                            resultMsg,
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                    }
                                                } catch (error) {
                                                    enqueueSnackbar(
                                                        'Accepting cash Payment failed.' +
                                                            error,
                                                        { variant: 'error' }
                                                    );
                                                }
                                            }}
                                        >
                                            <b>Paid</b>
                                        </AsyncProgressRibbonButton>
                                    ))
                                    //     <RibbonButton color={pomegranateRed} onClick={() => markTakeoutOrderPaidInStore(takeoutOrders[tkey])}
                                    //     disabled={!(takeoutOrders[tkey]['status']) || (pending === tkey)}>
                                    //   {pending === tkey? <CircularProgress size={20} style={{'color': 'black'}}/> : <b>Paid</b>}
                                    // </RibbonButton>
                                }
                            </ButtonContainerCenter>
                        </Collapsible>
                    </React.Fragment>
                )
            )}
        </>
    );
}
