import { useState, useEffect } from 'react';

export function usePausedState(restauId) {
    const [isPaused, setPausedState] = useState(false);

    function detachListener() {
        const database = window.customerApp.database();
        var pauseOrderRef = database.ref(
            'menus/' + restauId + '/details/pause_orders'
        );
        pauseOrderRef.off();
        console.log('Stopping pause order Listener', restauId);
    }

    useEffect(() => {
        if (restauId === 'None') {
            return;
        }
        console.log('CREATING PAUSE STATE LISTENER', restauId);
        const database = window.customerApp.database();
        database
            .ref('menus/' + restauId + '/details/pause_orders')
            .on('value', snapshot => {
                setPausedState(snapshot.val());
            });

        return () => detachListener();
    }, [restauId]);

    return {
        isPaused,
        setPausedState,
    };
}
