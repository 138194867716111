import React from 'react';
import {
    ModifierDiv,
    LabelContainer,
    ModifierGrid,
    CheckboxLabel,
    ModifierCheckbox,
} from './ModifierStyle';
import { formatPrice } from '../../Data/FoodData';
import { getQuatity } from './ModifierUtil';

export default function ModifierCheckBox({ modifier, onChange }) {
    function isDisabled(modifier, isChecked) {
        let max = modifier.max;
        let count = 0;
        for (let option of modifier.options) {
            if (option.checked) {
                count++;
            }
        }
        if (max && max === count && !isChecked) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <ModifierDiv>
            {modifier.label ? (
                <LabelContainer style={{ paddingBottom: '3px' }}>
                    <b>{modifier.label}</b>
                </LabelContainer>
            ) : (
                <LabelContainer style={{ paddingBottom: '3px' }}>
                    <b>Options:</b>{' '}
                </LabelContainer>
            )}
            <ModifierGrid>
                {modifier.options &&
                    modifier.options.map((option, i) => (
                        <CheckboxLabel
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '5px',
                            }}
                        >
                            <ModifierCheckbox
                                type="checkbox"
                                checked={
                                    option.checked ? option.checked : false
                                }
                                id={option.id}
                                disabled={isDisabled(modifier, option.checked)}
                                name={modifier.id}
                                onClick={e => {
                                    onChange(modifier.id, option.id);
                                }}
                            />
                            <span>
                                {option.label}
                                {option.price
                                    ? ' (+' +
                                      formatPrice(
                                          (option.price *
                                              getQuatity(option.quantity)) /
                                              100
                                      ) +
                                      ')'
                                    : ''}
                            </span>
                        </CheckboxLabel>
                    ))}
            </ModifierGrid>
        </ModifierDiv>
    );
}
