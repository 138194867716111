import { getModifierStr } from '../../FoodDialog/ModifierGroup/ModifierUtil';
import { getDateTime } from '../../utils/Util';

export default function addOrder(restauId, tableId, order, status) {
    const database = window.customerApp.database();
    var key = restauId + ':' + tableId;
    var newOrderRef = database.ref('orders/' + key).push();

    var newOrder = {
        id: order.id,
        quantity: order.quantity,
        name: order.name,
        status: status,
        price: order.price,
        key: newOrderRef.key,
        tableId: tableId,
        notify: true,
        addedBy: 'Host',
        added_timestamp: getDateTime(),
    };

    if (order.station) {
        newOrder['station'] = order.station;
        if (status === 'COOK') {
            for (var station of order.station) {
                newOrder[station + 'status'] = 'NOT_SEEN';
            }
        }
    }

    if (order.isCustomItem) {
        newOrder['isCustomItem'] = order['isCustomItem'];
    }
    if (order.img) {
        newOrder['img'] = order.img;
    }
    if (order.desc) {
        newOrder['desc'] = order.desc;
    }
    if (order.toppings) {
        let toppingsStr = order.toppings
            .filter(t => t.checked)
            .map(topping => topping.name)
            .join(', ');
        if (toppingsStr !== '') {
            newOrder['toppingsStr'] = toppingsStr;
        }
        newOrder['toppings'] = order.toppings;
    }

    if (order.choice) {
        newOrder['choiceId'] = order.choiceId;
        newOrder['choice'] = order.choice;
        newOrder['choices'] = order.choices;
    }

    if (order.notes && order.notes !== '') {
        newOrder['notes'] = order.notes;
    }

    if (order.modifierGroup) {
        newOrder['modifierGroup'] = order.modifierGroup;
        newOrder['modifiersStr'] = getModifierStr(order);
    }

    newOrderRef.set(newOrder);
    //setOrders([...orders, order]);
}
