import React, { useEffect } from 'react';
import { MenuContent, MenuStyled } from './MenuStyles';
import Section from './Section';

export function Menu({
    setOpenFood,
    menu,
    margin,
    padding,
    showCount,
    showDescription = false,
    showPrice = true,
    showChoice = false,
    showToppings = false,
    showNotes = false,
    activeSection,
    onSectionClick,
    onAddToOrder,
}) {
    const handleChange = sectionId => {
        if (activeSection === sectionId) {
            onSectionClick('');
        } else {
            onSectionClick(sectionId);
        }
    };

    return (
        <MenuStyled margin={margin}>
            <MenuContent padding={padding}>
                {menu &&
                    Object.entries(menu).map(([sectionIndex, section]) =>
                        section ? (
                            <Section
                                key={`section-${section.id}`}
                                setOpenFood={setOpenFood}
                                showCount={showCount}
                                showDescription={showDescription}
                                showPrice={showPrice}
                                showChoice={showChoice}
                                showToppings={showToppings}
                                showNotes={showNotes}
                                section={section}
                                open={activeSection === section.id}
                                defaultExpanded={menu.length === 1}
                                onChange={handleChange}
                                onAddToOrder={onAddToOrder}
                            />
                        ) : (
                            <div key={sectionIndex} />
                        )
                    )}
            </MenuContent>
        </MenuStyled>
    );
}
