import styled from 'styled-components';
import React from 'react';
export default function ItemMetaData({ item }) {
    const DetailItem = styled.div``;
    function getModifierGroup(item) {
        let detailedItems = [];
        if (item.modifierGroup && item.modifierGroup.modifiers) {
            let modifiers = Object.values(item.modifierGroup.modifiers);

            for (let modifier of modifiers) {
                let modifierStr = '';
                if (modifier.options) {
                    for (let option of Object.values(modifier.options)) {
                        if (option.checked) {
                            if (modifierStr !== '') {
                                modifierStr += ',';
                            }
                            modifierStr += option.label;
                        }
                    }
                }

                if (modifierStr !== '') {
                    detailedItems.push(
                        <DetailItem> (+) {modifierStr}</DetailItem>
                    );
                }
            }
        }
        return detailedItems;
    }

    return (
        <>
            {item.choice && <DetailItem> (+) {item.choice}</DetailItem>}
            {item.toppingsStr && (
                <DetailItem> (+) {item.toppingsStr}</DetailItem>
            )}
            {item.modifierGroup && getModifierGroup(item)}
            {item.notes && (
                <DetailItem>
                    {' '}
                    <i className="fas fa-info-circle" /> {item.notes}
                </DetailItem>
            )}
        </>
    );
}
