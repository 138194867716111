import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { RibbonButton } from '../../Navbar/Ribbon';

export default function AsyncProgressRibbonButton({
    onClick,
    isDisabled = false,
    errorHandler,
    style,
    children,
    color,
}) {
    const [inProgress, setInProgress] = useState(false);
    return (
        <RibbonButton
            style={style}
            color={color}
            onClick={e => {
                setInProgress(true);
                onClick(e)
                    .catch(error => errorHandler && errorHandler(error))
                    .finally(() => {
                        setInProgress(false);
                    });
            }}
            disabled={isDisabled || inProgress}
        >
            {inProgress ? (
                <CircularProgress size={20} style={{ color: 'black' }} />
            ) : (
                children
            )}
        </RibbonButton>
    );
}
