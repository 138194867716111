export function validateNumberKeyPress(event) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if (key < 48 || key > 57) {
        return false;
    } else if (key === 190 || key === 110) {
        if (event.target.value.includes('.')) {
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
}
