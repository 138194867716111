import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import React, { useState } from 'react';
import styled from 'styled-components';
import ItemMetaData from '../components/modifiers/ItemMetaData';
import Config from '../config/Config';
import { formatPrice } from '../Data/FoodData';
import {
    ConfirmButton,
    Dialog,
    DialogBanner,
    DialogBannerName,
    DialogContent,
    DialogFooter,
    DialogShadow,
    getPriceWithToppings,
} from '../FoodDialog/FoodDialog';
import { getModifierPrice } from '../FoodDialog/ModifierGroup/ModifierUtil';
import { NotesInput } from '../FoodDialog/NotesInput';
import { useChoice } from '../Hooks/useChoice';
import { useNumberInput } from '../Hooks/useNumberInput';
import { useReturnQty } from '../Hooks/useReturnQty';
import { RadioInput } from '../Navbar/SettingsDialog';
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';
import { getLocalDateTime, getQsrTableIdLabel } from '../utils/Util';
import { ButtonContainerCenter, OrderContainerNonClickable } from './Feed';
import { RefundQtyInput } from './RefundQtyInput';

const { default: axios } = require('axios');

function handleItemReturnQty(e, key, returnQty, maxQty) {
    if (+e.target.value <= maxQty) {
        returnQty.setItemReturnQty(key, e.target.value);
    } else {
        returnQty.setItemReturnQty(key, '');
    }
}

const RefundItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 150px 75px 75px 100px;
    justify-content: space-between;
`;

const RefundLineItemButton = styled(Title)`
    color: white;
    height: 20px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    width: 30px;
    cursor: pointer;
    background-color: ${corianderGreen};
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export function RefundDialogContainer({
    refundOrder,
    setRefundOrder,
    salesTax,
    restauId,
    enqueueSnackbar,
    restauName,
}) {
    const [pending, setPending] = useState(false);
    const returnQty = useReturnQty({});
    const reasonRadio = useChoice('');
    var actualTipInCents = Math.round(refundOrder.tip);
    const tipAmt = useNumberInput({ min: 0, max: actualTipInCents / 100 });
    const deliveryFeesInCents = Math.round(refundOrder.deliveryFees?refundOrder.deliveryFees:0);
    const deliveryAmt = useNumberInput({
        min: 0,
        max: deliveryFeesInCents / 100,
    });
    var minCCFeesPaidInCents = refundOrder.minCreditCardFees
        ? refundOrder.minCreditCardFees
        : 0;
    const minCCFeesPaidAmt = useNumberInput({
        min: 0,
        max: minCCFeesPaidInCents / 100,
    });

    const customRefund = useNumberInput({
        min: 0,
        max: refundOrder.payment_total / 100,
    });
    function close() {
        setRefundOrder();
    }

    function setRefundAll() {
        var qty = {};
        for (const key in refundOrder['items']) {
            //console.log(key, refundOrder['items'][key].quantity);
            qty[key] = refundOrder['items'][key].quantity;
            // THIS DOESNT WORK //returnQty.setItemReturnQty(key, refundOrder['items'][key].quantity);
        }
        returnQty.setReturnQty(qty);

        tipAmt.setValue(actualTipInCents / 100);
        deliveryAmt.setValue(deliveryFeesInCents/100);
        minCCFeesPaidAmt.setValue(minCCFeesPaidInCents / 100);
    }

    function validateNumber(event) {
        var key = window.event ? event.keyCode : event.which;
        console.log(event.keyCode);
        console.log(event.target.value);
        if (event.keyCode === 8 || event.keyCode === 46) {
            return true;
        } else if (key < 48 || key > 57) {
            return false;
        } else {
            return true;
        }
    }

    function validateDecimalNumber(event) {
        var key = window.event ? event.keyCode : event.which;
        console.log(event.keyCode);
        console.log(event.target.value);
        if (event.keyCode === 8 || event.keyCode === 46) {
            return true;
        } else if (key < 48 || key > 57) {
            return false;
        } else if (key === 190 || key === 110) {
            if (event.target.value.includes('.')) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    function getRefundSubTotal(order) {
        const subtotal = Object.keys(order).reduce((total, key) => {
            return (
                total +
                returnQty.getReturnQty(key) *
                    (getPriceWithToppings(order[key]) +
                        getModifierPrice(order[key]))
            );
        }, 0);
        return subtotal;
    }

    var refundSubTotalInCents = Math.round(
        getRefundSubTotal(refundOrder['items'])
    );
    var refundTaxInCents = Math.round((refundSubTotalInCents * salesTax) / 100);
    var refundTotalInCents = refundSubTotalInCents + refundTaxInCents;
    if (tipAmt.value) {
        var tipInCents = Math.round(parseFloat(tipAmt.value) * 100); //tipAmt.value always in $
        refundTotalInCents += tipInCents;
    }
    if (minCCFeesPaidAmt.value) {
        var minCCFeesInCents = Math.round(
            parseFloat(minCCFeesPaidAmt.value) * 100
        ); //tipAmt.value always in $
        refundTotalInCents += minCCFeesInCents;
    }
    if(deliveryAmt.value){
        let delvAmtInCents = Math.round(parseFloat(deliveryAmt.value) * 100);
        refundTotalInCents += delvAmtInCents;
    }

    if (refundTotalInCents > refundOrder.payment_total) {
        refundTotalInCents = refundOrder.payment_total;
    }

    function setRefundTipAmt(e) {
        if (+e.target.value > actualTipInCents / 100) {
            tipAmt.setValue(actualTipInCents / 100);
        } else {
            tipAmt.setValue(+e.target.value);
        }
    }
    function setRefundDeliveryFeeAmt(e) {
        if (+e.target.value > deliveryFeesInCents / 100) {
            deliveryAmt.setValue(deliveryFeesInCents / 100);
        } else {
            deliveryAmt.setValue(+e.target.value);
        }
    }

    function setRefundCreditCardFeesAmt(e) {
        if (+e.target.value > minCCFeesPaidInCents / 100) {
            minCCFeesPaidAmt.setValue(minCCFeesPaidInCents / 100);
        } else {
            minCCFeesPaidAmt.setValue(+e.target.value);
        }
    }

    async function finalizeRefund() {
        setPending(true);
        var refundType = 'itemized';
        var refundAmt = refundTotalInCents;
        var refundTip = 0;
        var refundMinCCFees = 0;
        let refundDeliveryFee =0;
        if (tipAmt.value) {
            refundTip = Math.round(parseFloat(tipAmt.value) * 100); //tipAmt.value always in $
        }
        if(deliveryAmt.value){
            refundDeliveryFee = Math.round(parseFloat(deliveryAmt.value) * 100); 
        }
        if (minCCFeesPaidAmt.value) {
            refundMinCCFees = Math.round(
                parseFloat(minCCFeesPaidAmt.value) * 100
            ); //minCCFeesPaidAmt.value always in $
        }

        var refundItems = null;
        if (customRefund.value) {
            refundType = 'custom';
            refundAmt = Math.round(parseFloat(customRefund.value) * 100);
        } else {
            refundItems = {};
            for (const key in refundOrder['items']) {
                if (returnQty.getReturnQty(key)) {
                    var refundItem = {};
                    refundItem['quantity'] = returnQty.getReturnQty(key);
                    if ('id' in refundOrder['items'][key]) {
                        refundItem['id'] = refundOrder['items'][key].id;
                    }
                    refundItem['name'] = refundOrder['items'][key].name;
                    //TODO: Not sure if price is needed, need to do price on server.
                    refundItem['price'] = refundOrder['items'][key].price;
                    refundItems[key] = refundItem;
                }
            }
        }
        if (refundAmt === 0) {
            setPending(false);
            return;
        }

        if (refundAmt >= refundOrder.payment_total) {
            refundAmt = refundOrder.payment_total;
        }
        var msg;
        if (refundOrder.payment_success) {
            msg =
                'Please confirm ' +
                refundType +
                ' refund for order ' +
                refundOrder.number +
                '.\nRefund Amount = ' +
                formatPrice(refundAmt / 100);
        } else {
            msg =
                'Please confirm ' +
                refundType +
                ' cancelling for order ' +
                refundOrder.number +
                '.\nCancelling Amount = ' +
                formatPrice(refundAmt / 100);
        }
        var r = window.confirm(msg);
        if (r === true) {
            console.log('You pressed refund OK!');
            //var base_url = 'https://us-central1-sliceline-takeout.cloudfunctions.net/'
            //var base_url = 'http://localhost:5001/sliceline-takeout/us-central1/'
            var url = Config.refund_api;
            try {
                const token = await window.customerApp
                    .auth()
                    .currentUser.getIdToken();
                // refundTotal, refundTip in cents. price for each item as in original format cents/$.
                const resp = await axios.post(url, {
                    headers: token,
                    restauId,
                    orderId: refundOrder.id,
                    orderNum: refundOrder.number,
                    refundType,
                    refundTotal: refundAmt,
                    refundTax: refundTaxInCents,
                    refundItems,
                    refundTip,
                    refundMinCCFees,
                    refundDeliveryFee,
                    restauName,
                    reason: reasonRadio.value,
                    orderStatus: refundOrder.status,
                });
                //console.log("refund response", resp);
                if (resp['data']['refund_status']) {
                    enqueueSnackbar(
                        'Refund successful for order ' + refundOrder.number,
                        { variant: 'success' }
                    );
                } else {
                    enqueueSnackbar(
                        'Refund not successful for order ' +
                            refundOrder.number +
                            '. ' +
                            resp['data']['msg'],
                        { variant: 'error' }
                    );
                }
            } catch (error) {
                //console.log('axios catch', error);
                enqueueSnackbar(
                    'Refund not successful for order ' +
                        refundOrder.number +
                        '. ' +
                        error,
                    { variant: 'error' }
                );
            }
        } else {
            console.log('You pressed Cancel!');
        }
        setPending(false);
        setRefundOrder();
        return;
    }

    return refundOrder ? (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                <DialogBanner>
                    <DialogBannerName>
                        <Typography variant="h5">
                            Refund Order {refundOrder.number}{' '}
                            {getQsrTableIdLabel(refundOrder, ' for Table-')}
                        </Typography>
                    </DialogBannerName>
                    <CloseButtonContainer>
                        <IconButton aria-label="close">
                            <Close onClick={close} />
                        </IconButton>
                    </CloseButtonContainer>
                </DialogBanner>
                <DialogContent>
                    <div>
                        {getLocalDateTime(refundOrder['created_timestamp'])}
                    </div>
                    <div>
                        Amount Paid -{' '}
                        {formatPrice(refundOrder.payment_total / 100.0)}
                    </div>
                    <div>
                        {refundOrder['customerType']} /{' '}
                        {refundOrder['orderType']} / Phone Number :{' '}
                        {refundOrder['phoneNumber']
                            ? refundOrder['phoneNumber']
                            : 'Not Given'}
                    </div>
                    <br />
                    <div>Refund Reason:</div>
                    <label>
                        <RadioInput
                            type="radio"
                            id="oos"
                            name="refund_reason"
                            value="out of stock"
                            checked={'out of stock' === reasonRadio.value}
                            onChange={reasonRadio.onChange}
                        />
                        Out of stock
                    </label>
                    <br />
                    <label>
                        <RadioInput
                            type="radio"
                            id="close"
                            name="refund_reason"
                            value="closing early/closed"
                            checked={
                                'closing early/closed' === reasonRadio.value
                            }
                            onChange={reasonRadio.onChange}
                        />
                        Closing early/closed
                    </label>
                    <br />
                    <label>
                        <RadioInput
                            type="radio"
                            id="other"
                            name="refund_reason"
                            value="other"
                            checked={'other' === reasonRadio.value}
                            onChange={reasonRadio.onChange}
                        />
                        Other
                    </label>
                    <br />

                    <OrderContainerNonClickable>
                        <RefundItem>
                            <div>
                                <Title>Itemized Refund</Title>
                            </div>
                            <div>
                                Total
                                <br />
                                Qty
                            </div>
                            <div>
                                Refund
                                <br />
                                Qty
                                <br />
                                <ButtonContainerCenter>
                                    <ConfirmButton
                                        onClick={() => {
                                            setRefundAll();
                                        }}
                                    >
                                        <b>All</b>
                                    </ConfirmButton>
                                </ButtonContainerCenter>
                            </div>
                            <div>
                                Refund
                                <br />
                                Amount
                            </div>
                        </RefundItem>
                    </OrderContainerNonClickable>
                    {Object.keys(refundOrder['items']).map((key, index) => (
                        <OrderContainerNonClickable key={key}>
                            <RefundItem>
                                <div>{refundOrder['items'][key].name}</div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {refundOrder['items'][key].quantity}
                                    <RefundLineItemButton
                                        onClick={() => {
                                            returnQty.setItemReturnQty(
                                                key,
                                                refundOrder['items'][key]
                                                    .quantity
                                            );
                                        }}
                                    >
                                        <b>=&gt;</b>
                                    </RefundLineItemButton>
                                </div>
                                <div>
                                    <RefundQtyInput
                                        value={returnQty.getReturnQty(key)}
                                        name={key}
                                        type="number"
                                        pattern="[0-9]*"
                                        key={key}
                                        max={refundOrder['items'][key].quantity}
                                        placeholder=""
                                        min="0"
                                        style={{ width: '40px' }}
                                        onKeyDown={validateNumber}
                                        onChange={e =>
                                            handleItemReturnQty(
                                                e,
                                                key,
                                                returnQty,
                                                refundOrder['items'][key]
                                                    .quantity
                                            )
                                        }
                                    />

                                    {/* <input
                  key={key}
                  type="number" pattern="[0-9]*" name={key} placeholder=""
                  value={returnQty.getReturnQty(key)}
                  style={{ width: "40px" }}
                  onKeyDown={validateNumber}
                  min="0" max={refundOrder['items'][key].quantity}
                  onChange={(e) => handleItemReturnQty(e,key, returnQty,refundOrder['items'][key].quantity)}
                /> */}
                                </div>
                                <div>
                                    {formatPrice(
                                        (returnQty.getReturnQty(key) *
                                            (getPriceWithToppings(
                                                refundOrder['items'][key]
                                            ) +
                                                getModifierPrice(
                                                    refundOrder['items'][key]
                                                ))) /
                                            100
                                    )}
                                </div>
                            </RefundItem>
                            <ItemMetaData item={refundOrder['items'][key]} />
                        </OrderContainerNonClickable>
                    ))}

                    <OrderContainerNonClickable>
                        <RefundItem>
                            <div>Refund Sub-Total</div>
                            <div />
                            <div />
                            <div>
                                {formatPrice(refundSubTotalInCents / 100)}
                            </div>
                        </RefundItem>
                        <RefundItem>
                            <div>Refund Tax</div>
                            <div />
                            <div />
                            <div>{formatPrice(refundTaxInCents / 100)}</div>
                        </RefundItem>
                        <RefundItem>
                            <div>Refund Tip</div>
                            <div>{formatPrice(actualTipInCents / 100)}</div>
                            <div />
                            <div>
                                {/* <NotesInput {...tipAmt} placeholder="" label="$" style={{width: "40px" }}></NotesInput> */}
                                $
                                <input
                                    key="refund-tip"
                                    type="number"
                                    pattern="[0-9]*"
                                    name="refund-tip"
                                    placeholder=""
                                    value={tipAmt.value}
                                    style={{ width: '40px' }}
                                    onKeyDown={validateDecimalNumber}
                                    onChange={setRefundTipAmt}
                                />
                            </div>
                        </RefundItem>
                        {minCCFeesPaidInCents ? (
                            <RefundItem>
                                <div>Refund Credit Card Fees</div>
                                <div>
                                    {formatPrice(minCCFeesPaidInCents / 100)}
                                </div>
                                <div />
                                <div>
                                    {/* <NotesInput {...tipAmt} placeholder="" label="$" style={{width: "40px" }}></NotesInput> */}
                                    $
                                    <input
                                        key="refund-min-cc-fees"
                                        type="number"
                                        pattern="[0-9]*"
                                        name="refund-min-cc-fees"
                                        placeholder=""
                                        value={minCCFeesPaidAmt.value}
                                        style={{ width: '40px' }}
                                        onKeyDown={validateDecimalNumber}
                                        onChange={setRefundCreditCardFeesAmt}
                                    />
                                </div>
                            </RefundItem>
                        ) : (
                            <></>
                        )}

                        {deliveryFeesInCents ? (
                            <RefundItem>
                                <div>Refund Delivery Fees</div>
                                <div>
                                    {formatPrice(deliveryFeesInCents / 100)}
                                </div>
                                <div />
                                <div>
                                    {/* <NotesInput {...tipAmt} placeholder="" label="$" style={{width: "40px" }}></NotesInput> */}
                                    $
                                    <input
                                        key="refund-min-cc-fees"
                                        type="number"
                                        pattern="[0-9]*"
                                        name="refund-min-cc-fees"
                                        placeholder=""
                                        value={deliveryAmt.value}
                                        style={{ width: '40px' }}
                                        onKeyDown={validateDecimalNumber}
                                        onChange={setRefundDeliveryFeeAmt}
                                    />
                                </div>
                            </RefundItem>
                        ) : (
                            <></>
                        )}

                        <RefundItem>
                            <div>Refund Total</div>
                            <div />
                            <div />
                            <div>
                                {formatPrice(refundTotalInCents / 100)}
                                {/* {tipAmt.value? formatPrice(getRefundSubTotal(refundOrder['items'])*(1+salesTax/100.0) + parseFloat(tipAmt.value)):
              formatPrice(getRefundSubTotal(refundOrder['items'])*(1+salesTax/100.0))} */}
                            </div>
                        </RefundItem>
                    </OrderContainerNonClickable>

                    <OrderContainerNonClickable>
                        <ButtonContainerCenter>
                            <h3>OR</h3>
                        </ButtonContainerCenter>
                    </OrderContainerNonClickable>

                    <OrderContainerNonClickable>
                        <NotesInput
                            {...customRefund}
                            placeholder=""
                            label="Custom Refund amount: $"
                            style={{ width: '100px' }}
                        />
                    </OrderContainerNonClickable>
                </DialogContent>
                <DialogFooter>
                    <ConfirmButton
                        disabled={
                            pending ||
                            (refundTotalInCents === 0 &&
                                (!customRefund.value ||
                                    customRefund.value === ''))
                        }
                        onClick={() => {
                            //setOrders([]);
                            finalizeRefund();
                        }}
                    >
                        {pending ? (
                            <CircularProgress
                                size={20}
                                style={{ color: 'black' }}
                            />
                        ) : (
                            <b>Refund</b>
                        )}
                    </ConfirmButton>
                </DialogFooter>
            </Dialog>
        </>
    ) : (
        <div />
    );
}

export function RefundDialog(props) {
    if (!props.refundOrder) return null;
    return <RefundDialogContainer {...props} />;
}
