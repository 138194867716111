import React from 'react';

const OrderContext = React.createContext({});
const OrderProvider = OrderContext.Provider;
/*function OrderProvider( { children } ) {
     const { restauId } = useContext(RestaurantContext);

    const [orders, dispatchOrder] = useReducer(orderReducer, {});
    const [takeoutOrders, dispatchTakeoutOrder] = useReducer(takeoutOrderReducer, {});
    const [completedTakeoutOrders, dispatchCompletedTakeoutOrder] = useReducer(takeoutOrderReducer, {});

    

    const [autoAcceptQueue, setAutoAcceptQueue] = useState([]);


    function distinguishTakeoutOrder(key, val){
      if (!('status' in val) || val.status === 'WIP' || val.status === 'READY' || val.status === 'PAID_PENDING_READY'){
        console.log('takeout order data updated : ', key, val);
        dispatchTakeoutOrder({type: "UPDATE", key: key, payload: val});
        if (!('status' in val)){
          readText('New Order ' + val.number);
          enqueueSnackbar("New Order "+val.number+" received.", {variant:'success'});
          setPlayBeep(true);
          //const snd = document.getElementById("beep");
          //snd.muted = false;
          beep();
          //TODO: This isnt working. the ideas was to scroll the feed to bottom to quickly accept the new order. 
          //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
          //autoAcceptTakeoutOrders(val, userSettings);
          if(window.ReactNativeWebView) {
            setAutoAcceptQueue(autoAcceptQueue => [...autoAcceptQueue, { key, val }]);
          }
        }
  
      } else if (val.status === 'DONE') {
        //dispatchCompletedTakeoutOrder({type: "APPEND", key: key, payload: val});
        // if you dont delete it from takeoutOrder it will remain in both places.   
        dispatchTakeoutOrder({type: "DELETE", key: key});
      }else {
        dispatchTakeoutOrder({type: "DELETE", key: key}); // Ideally this not needed.
        //dispatchCompletedTakeoutOrder({type: "DELETE", key: key});
      }
    }


    const { printOrder } = useContext(PrintContext);
  console.log(": printOrder cointext", printOrder);
  const [inProgressAutoAcceptOrder, setInProgressAutoAcceptOrder] = useState(false);
  useEffect(() => {
    console.log("printOrder accept", printOrder);
    if(inProgressAutoAcceptOrder || typeof printOrder == "undefined"
      || autoAcceptQueue.length == 0) {
      console.log("Not ready to auto accept");
      return;
    }
    if(!(userSettings && userSettings.autoAcceptConfig && userSettings.autoAcceptConfig.enabled)){
      if(autoAcceptQueue.length > 0) {
        console.log("Cleaning auto accept queue");
        setAutoAcceptQueue([]);
      }
      return;
    }
    console.log("Ready for autoaccept");
    
    const callBack = () => {
      setAutoAcceptQueue((autoAcceptQueue) => autoAcceptQueue.slice(1));
      setInProgressAutoAcceptOrder(false);
    }
    setInProgressAutoAcceptOrder(autoAcceptQueue[0].val.id);
    autoAcceptTakeoutOrders(autoAcceptQueue[0], userSettings, callBack);
  }, [inProgressAutoAcceptOrder, autoAcceptQueue, userSettings, printOrder])
  //

  function autoAcceptTakeoutOrders({ key: takeoutOrderKey, val: takeoutOrder }, userSettings, callBack) {
    const showPrintPrice = ('printSettings' in userSettings && 'showPrintPrice' in userSettings['printSettings']) ? 
        userSettings['printSettings']['showPrintPrice'] : true;
    const noOfPrints = 'printSettings' in userSettings && 'defaultNumOfPrints' in userSettings['printSettings'] ? 
        userSettings['printSettings']['defaultNumOfPrints'] : 1;
    const estimatedTimeInMins =  userSettings['autoAcceptConfig'] && userSettings['autoAcceptConfig'].estimatedTimeInMins;
    
    const onPrintSuccess = async () => {
      try {
        await acceptTakeoutOrder(restauId, takeoutOrder.id, estimatedTimeInMins);
        if (takeoutOrder.phoneNumber && takeoutOrder.customerType !== 'QSR-Dine-In' ){
          try {
            const token = await window.customerApp.auth().currentUser.getIdToken()
            const resultSms = await sendSms({token, restauId, restauName, 
                orderId: takeoutOrder.id, 
                orderNumber: takeoutOrder.number, 
                phoneNumber: takeoutOrder.phoneNumber, 
                orderUnderName: takeoutOrder.orderUnderName, 
                customerType: takeoutOrder.customerType, 
                estimatedTimeInMins: estimatedTimeInMins
            });
          } catch (error) {
            console.error("Failed to send sms for accept", error);
          }
        }else{
          console.log("Skipping to semd accept sms", takeoutOrder.phoneNumber, takeoutOrder.customerType);
        }
        enqueueSnackbar("Order #"+takeoutOrder.orderUnderName + " accepted.", {variant:'success'});
      } catch (error) {
        enqueueSnackbar("Failed to accept Order #"+takeoutOrder.orderUnderName, {variant:'error'});
        console.error("Failed to accept order" , error);
      } finally {
        callBack();
      }
    }
    // const onPrintFailed = () => {
    //   enqueueSnackbar("Failed to print Order #"+takeoutOrder.orderUnderName + " Order Not accepted.", {variant:'error'});
    //   callBack();
    // }
    printOrder({order: takeoutOrder, numCopies: noOfPrints})
      .then((printResult) => {
        if(printResult.success) { 
          onPrintSuccess();
        } else {
            const failedStations = printResult.errors.map(e => e.station).filter(e => e.length > 0);
            console.log("failedStations". failedStations, takeoutOrderKey);
            enqueueSnackbar(
                "Failed to print Order #"+takeoutOrder.number + (failedStations.length > 0 ? (" at stations: " + failedStations.join(", ")) : ""), 
                {variant:'error'}
            );
            dispatchOrder({type: "UPDATE", key: takeoutOrderKey, 
              payload: { ...takeoutOrder, autoAcceptError: {
                failedStations: failedStations
              }
            }});
            callBack();
        }
      }).catch((error) => {
          console.error("Failed to print", error, takeoutOrderKey);
          enqueueSnackbar("Failed to print. Please reprint Order #"+takeoutOrder.number, {variant:'error'});
          dispatchOrder({type: "UPDATE", key: takeoutOrderKey, 
              payload: { ...takeoutOrder, autoAcceptError: {
                error
              }
            }});
          callBack();
      })
    // printOrder(takeoutOrder, noOfPrints, false, showPrintPrice, restauName, false, null, null, enqueueSnackbar, () => {}, 
    //   onPrintSuccess, onPrintFailed);
  }

    function setTakeoutListener(restauId){
    const database = window.customerApp.database();
    console.log("setTakeoutListener");
    var takeoutRef = database.ref("takeout_orders/"+restauId);

    takeoutRef.on('child_changed', function(data) {
      if (data.key === 'order_num'){
        return;
      }
      const val = data.val();
      if ('payment_success' in val){
        distinguishTakeoutOrder(data.key, val);
      }

    });
    takeoutRef.on('child_added', function(data) {
      if (data.key === 'order_num'){
        return;
      }
      const val = data.val();
      if ('payment_success' in val){
        distinguishTakeoutOrder(data.key, val);
      }
    });

    takeoutRef.on('child_removed', function(data) {
      if (data.key === 'order_num'){
        return;
      }
      dispatchTakeoutOrder({type: "DELETE", key: data.key});
    });

    const numberOfCompletedOrders = 20;
    var ref = database.ref("takeout_orders_completed/"+restauId);
    ref.orderByKey().limitToLast(numberOfCompletedOrders).on("child_added", function(snapshot) {
        console.log(snapshot.key);
        dispatchCompletedTakeoutOrder({type: "APPEND", key: snapshot.key, payload: snapshot.val()});
    });
    ref.orderByKey().limitToLast(numberOfCompletedOrders).on("child_removed", function(snapshot) {
      console.log(snapshot.key);
      dispatchCompletedTakeoutOrder({type: "DELETE", key: snapshot.key});

    });
    ref.orderByKey().limitToLast(numberOfCompletedOrders).on("child_changed", function(snapshot) {
      dispatchCompletedTakeoutOrder({type: "UPDATE", key: snapshot.key, payload: snapshot.val()});
    });

  }

  function setDineInListener(tableIds, restauId){
    if (restauId === "None") {
      return;
    }
    
    dispatchOrder({type: "RESET"});
    const database = window.customerApp.database();
    console.log('setDineInListener : tableIDs', tableIds);
    for (var tid of tableIds) {
      var restauTableKey = restauId + ":" + tid;
      var orderRef = database.ref("orders/"+restauTableKey);
      // orderRef.once('value').then(function(snapshot) {
      //   snapshot.forEach(function(childSnapshot) {
      //     var childKey = childSnapshot.key;
      //     var childData = childSnapshot.val();
      //     orders[childKey] = childData;
      //   });
      // });
      orderRef.on('child_added', function(data) {
        console.log('child_added', data.val());
        dispatchOrder({type: "APPEND", key: data.key, payload: data.val()});
        // const newOrders = {...orders};  
        // newOrders[data.key] = data.val();
        // setOrders(newOrders);

        // orders[data.key] = data.val();
        // setOrders(orders);
      });
      
      orderRef.on('child_changed', function(data) {
        console.log('child_changed', data.val());
        dispatchOrder({type: "UPDATE", key: data.key, payload: data.val()});
      });
      
      orderRef.on('child_removed', function(data) {
        console.log('child_removed', data.val());
        dispatchOrder({type: "DELETE", key: data.key, payload: data.val()});
      });
    }
  }

  function detachTakeoutListener(){
    const database = window.customerApp.database();
    var takeoutRef = database.ref("takeout_orders/"+restauId);
    takeoutRef.off();
    console.log("Stopping takeout Listener", restauId);
  }

  function detachDineInListener(){
    const database = window.customerApp.database();
    for (var tid of tableIds) {
      var restauTableKey = restauId + ":" + tid;
      var orderRef = database.ref("orders/"+restauTableKey);
      orderRef.off();
      console.log("Stopping Summon Listener", restauId, tid);
    }
  }

  function detachAllListener(){
    detachTakeoutListener();
    detachDineInListener();
  }

  
  useEffect(() => {
    if(!restauId) {
      return;
    }
    return () => {
      dispatchOrder({type: "RESET"});
      dispatchTakeoutOrder({type: "RESET"});
      dispatchCompletedTakeoutOrder({type: "RESET"});
    }
    async function setupApp(){
      if(!isLoggedIn){
        detachAllListener();
        dispatchOrder({type: "RESET"});
        dispatchTakeoutOrder({type: "RESET"});
        dispatchCompletedTakeoutOrder({type: "RESET"});
        return;
      }
      var result = await getRestauAndTableId();
      if (result['restauId'] !== "None"){
        getRestauNameAndMenuAndTables(result['restauId'], result['tableIds']);
      }
      return () => detachAllListener()
    }
    setupApp();
  }, [restauId]);

  useEffect(() => {
    if(restauId === 'None'){
      return;
    }
    if (isDineInMode(operationMode)){
      setDineInListener(tableIds, restauId);
      return () => detachDineInListener();
    }
  }, [tableIds, restauId, operationMode]);

  useEffect(() => {
    if(restauId === 'None') {
      return;
    }
    if (isTakeoutMode(operationMode)){
        setTakeoutListener(restauId);
        return () => detachTakeoutListener()
    }
  }, [restauId, operationMode]); 

  const value = {};

  return <OrderContext.Provider value={ value }>
      { children }
  </OrderContext.Provider>
}*/

export { OrderContext, OrderProvider };
