import { useState } from 'react';

export function useMergePrint(defaultNum) {
    const [fromOrderToPrint, setFromOrderToPrint] = useState(0);
    const [toOrderToPrint, setToOrderToPrint] = useState(0);
    const [printPending, setPrintPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function onFromOrderToPrintChange(e) {
        setFromOrderToPrint(e.target.value);
    }
    function onToOrderToPrintChange(e) {
        setToOrderToPrint(e.target.value);
    }

    return {
        fromOrderToPrint,
        toOrderToPrint,
        printPending,
        errorMessage,
        setErrorMessage,
        setPrintPending,
        setFromOrderToPrint,
        setToOrderToPrint,
        onFromOrderToPrintChange,
        onToOrderToPrintChange,
    };
}
