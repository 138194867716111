import { reportError, reportWarning } from '../../apis/reportError';
import { delay } from '../../utils/Util';

export const print = function({ html, numCopies, station, retry = true }) {
    return new Promise((resolve, reject) => {
        if (window.ReactNativeWebView) {
            window.webViewBridge.send(
                'print',
                { html, numCopies, station },
                function(res) {
                    resolve();
                },
                function(err) {
                    err.parsedErrorType = parseError(err);

                    if (retry && isRetriableError(err.parsedErrorType)) {
                        reportWarning(window.restauId, window.email, {
                            msg: {
                                message: 'Print failure',
                                numCopies,
                                station,
                                error: err,
                            },
                            error: err,
                        });
                        delay(10000).then(() => {
                            print({ html, numCopies, station, retry: false })
                                .then(val => resolve(val)) //resolve original returned promise when retry succeeds
                                .catch(err => reject(err)); //reject original returned promise when retry succeeds
                        });
                    } else {
                        reportError(window.restauId, window.email, {
                            msg: {
                                message: 'Print failure',
                                numCopies,
                                station,
                                error: err,
                            },
                            error: err,
                        });
                        reject(err);
                    }
                }
            );
        } else {
            resolve();
            passPrintBrowser(html, numCopies);
        }
    });
};

export const PRINT_ERROR_TYPE = {
    /**has Device error, and based on status */
    OUT_OF_PAPER: 'OUT_OF_PAPER',
    COVER_OPEN: 'COVER_OPEN',
    OTHER_DEVICE_ERROR: 'DEVICE_ERROR',
    /** Other errors */
    DEVICE_IN_USE: 'DEVICE_IN_USE',
    DEVICE_NOT_FOUND: 'DEVICE_NOT_FOUND',
    OTHER: 'OTHER_ERROR',
};

const parseError = function(err) {
    if (err && err.nativeError && err.nativeError.message) {
        if (
            err.nativeError.message.includes(
                'The device is in use by another process.'
            )
        ) {
            return PRINT_ERROR_TYPE.DEVICE_IN_USE;
        } else if (err.nativeError.message.includes('Device not found.')) {
            return PRINT_ERROR_TYPE.DEVICE_NOT_FOUND;
        } else if (err.nativeError.message.includes('Device has error.')) {
            if (
                (err._status && err._status._paperEmpty) ||
                (err.wrapperError &&
                    err.wrapperError._status &&
                    err.wrapperError._status._paperEmpty)
            ) {
                return PRINT_ERROR_TYPE.OUT_OF_PAPER;
            } else if (
                (err._status && err._status._coverOpen) ||
                (err.wrapperError &&
                    err.wrapperError._status &&
                    err.wrapperError._status._coverOpen)
            ) {
                return PRINT_ERROR_TYPE.COVER_OPEN;
            }
            return PRINT_ERROR_TYPE.OTHER_DEVICE_ERROR; //dont retry if device has errors.
        }
    }
    return PRINT_ERROR_TYPE.OTHER;
};

const isRetriableError = function(errType) {
    if (
        errType == PRINT_ERROR_TYPE.OUT_OF_PAPER ||
        errType == PRINT_ERROR_TYPE.OTHER_DEVICE_ERROR ||
        errType == PRINT_ERROR_TYPE.COVER_OPEN
    ) {
        return false;
    }
    return true;
};

const passPrintBrowser = function(printData, numCopies) {
    let passprntUri = 'starpassprnt://v1/print/nopreview?';
    passprntUri =
        passprntUri +
        'size=3&back=' +
        encodeURIComponent(window.location.origin + '/status.html');
    passprntUri = passprntUri + '&html=' + encodeURIComponent(printData);
    if (numCopies >= 1) {
        window.location.href = passprntUri;
    }
};
