import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { MergeAndPrintOrder } from './MergeAndPrintOrder';
import { renderToString } from 'react-dom/server';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ConfirmButton } from '../FoodDialog/FoodDialog';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function PrintDialog(props) {
    function creteMergeId(item) {
        let id = item.id;
        if (item.choice) {
            id += '-' + item.choice;
        }
        if (item.toppingsStr) {
            id += '-' + item.toppingsStr;
        }
        if (item.notes) {
            let notes = item.notes.split(' ').join('');
            id += '-' + notes;
        }
        return id;
    }
    function mergeItems(mergeddOrders, items) {
        if (!items) {
            return;
        }
        for (const key in items) {
            let item = items[key];
            let mergeKey = creteMergeId(item);

            if (!mergeddOrders[item.id]) {
                mergeddOrders[item.id] = {};
            }
            if (!mergeddOrders[item.id][mergeKey]) {
                mergeddOrders[item.id][mergeKey] = item;
            } else {
                let existingItem = mergeddOrders[item.id][mergeKey];
                console.log(
                    'Id: ' +
                        item.id +
                        ' Quantity: ' +
                        item.quantity +
                        ' existing: ' +
                        existingItem.quantity
                );
                console.log(existingItem);
                mergeddOrders[item.id][mergeKey].quantity =
                    existingItem.quantity + item.quantity;
            }
        }
    }
    function mergePrint(props) {
        let {
            takeoutOrders,
            showPrices,
            restauName,
            _mergePrintCallBack,
            handleClose,
        } = props;
        let takeoutOrdersCloned = JSON.parse(JSON.stringify(takeoutOrders));
        let fromNumberToPrint = props.mergePrints.fromOrderToPrint;
        let toNumberToPrint = props.mergePrints.toOrderToPrint;
        if (!takeoutOrdersCloned || toNumberToPrint < fromNumberToPrint) {
            return;
        }
        let mergedOrders = {};
        mergedOrders['items'] = {};
        for (const key in takeoutOrdersCloned) {
            if (takeoutOrdersCloned.hasOwnProperty(key)) {
                let takeoutOrder = takeoutOrdersCloned[key];
                const orderNumber = parseInt(takeoutOrder.number);
                if (
                    orderNumber >= fromNumberToPrint &&
                    orderNumber <= toNumberToPrint &&
                    (takeoutOrder.status === 'WIP' || !takeoutOrder.status)
                ) {
                    mergeItems(mergedOrders['items'], takeoutOrder.items);
                }
            }
        }

        let printData = renderToString(
            <MergeAndPrintOrder
                takeoutOrder={mergedOrders}
                showPrices={showPrices}
                restauName={restauName}
                from={fromNumberToPrint}
                to={toNumberToPrint}
            />
        );
        console.log(printData);
        props.mergePrints.setPrintPending(true);
        _mergePrintCallBack(printData, 1, _successCallBack, _failureCallBack);
    }
    function _successCallBack() {
        props.mergePrints.setPrintPending(false);
        props.handleClose();
        let toOrder = props.mergePrints.toOrderToPrint;
        if (toOrder) {
            localStorage.setItem('lastToOrder', toOrder);
        }
    }

    function _failureCallBack() {
        props.mergePrints.setErrorMessage('Merge Print Failed!');
        props.mergePrints.setPrintPending(false);
        return;
    }

    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}
            >
                Merge And Print Range orders
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    id="outlined-basic"
                    label="From Order #"
                    defaultValue={props.mergePrints.fromOrderToPrint}
                    variant="outlined"
                    type="number"
                    autoFocus
                    onChange={e => {
                        props.mergePrints.onFromOrderToPrintChange(e);
                    }}
                />
                <div style={{ paddingTop: '20px' }} />
                <TextField
                    id="outlined-basic"
                    label="To Order #"
                    defaultValue={props.mergePrints.toOrderToPrint}
                    variant="outlined"
                    type="number"
                    onChange={e => {
                        props.mergePrints.onToOrderToPrintChange(e);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <b style={{ color: 'red' }}>{props.mergePrints.errorMessage}</b>
                <ConfirmButton
                    onClick={() => {
                        mergePrint(props);
                    }}
                >
                    {props.mergePrints.printPending ? (
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                    ) : (
                        'Print'
                    )}
                </ConfirmButton>
                <ConfirmButton
                    style={{ leftMargin: '10px' }}
                    onClick={() => {
                        props.handleClose();
                    }}
                >
                    Close
                </ConfirmButton>
            </DialogActions>
        </Dialog>
    );
}
