import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

function ToggleSwitch(props) {
    const { onChange, label, checked, name } = props;
    const handleChange = event => {
        onChange(event.target.checked);
    };
    console.log(checked, typeof checked);
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    );
}

ToggleSwitch.propTypes = {};

export default ToggleSwitch;
