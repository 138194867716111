import React from 'react';
import styled from 'styled-components';
import { Title } from '../Styles/title';
import { corianderGreen } from '../Styles/colors';

// Styling a regular HTML input
//  width: 24px;
// const StyledInput = styled.input`
//   display: block;
//   margin: 20px 0px;
//   border: 1px solid lightblue;
// `;

const StyledInput = styled.input`
    display: block;
    border: 1px solid lightred;
    margin: 0px 10px;
    width: 200px;
    height: 25px;
`;

//  height: 24px;

export const NotesContainer = styled(Title)`
    display: flex;
    margin: 20px 0px;
`;

export function NotesInput(props) {
    return (
        <NotesContainer>
            <div>{props.label && props.label}</div>
            <StyledInput {...props} />
        </NotesContainer>
    );
}
