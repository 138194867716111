import React, { useState, useEffect } from 'react';
import { pomegranateRed } from '../../Styles/colors';

export function ElaspedTimeSpan({
    timestamp,
    thresholdMins = 240,
    preText = '',
    postText = '',
    paddingLeft = '10px',
}) {
    const calcTime = t => Math.round((new Date() - new Date(t)) / 60000);
    const [mins, setMins] = useState(calcTime(timestamp));
    const isOverThreshold = mins >= thresholdMins;
    useEffect(() => {
        const int = setInterval(() => {
            setMins(calcTime(timestamp));
        }, 60000);
        return () => clearInterval(int);
    }, []);
    var minOrMins = mins % 60 == 1 ? 'min' : 'mins';

    return (
        <span
            style={{
                paddingLeft: paddingLeft,
                ...(isOverThreshold ? { color: pomegranateRed } : {}),
            }}
        >
            ({preText}
            {mins < 1
                ? 'Just now'
                : mins > 60
                ? `${Math.floor(mins / 60)} hour ${mins % 60} ${minOrMins} ago`
                : `${mins} ${minOrMins} ago`}
            {postText})
        </span>
    );
}
