import React from 'react';
import styled from 'styled-components';
import {
    EmptySpace,
    Header,
    HtmlContainer,
    OrderHeader,
    OrderItems,
    OrderTotal,
    StationFooter,
} from './components';
import {
    pStyleH1,
    pStyleH2,
    pStyleH3,
    pStyleH4,
} from './components/TemplateStyles';

export function PrintOrderDineIn({
    takeoutOrder,
    reprint,
    stationDetails = {},
    showPrices,
    restauName,
    address,
    phonenumber = '',
}) {
    //on hold items
    const holdItems = Object.fromEntries(
        Object.entries(takeoutOrder.items).filter(([, item]) => item.hold)
    );
    const restItems = Object.fromEntries(
        Object.entries(takeoutOrder.items).filter(([, item]) => !item.hold)
    );

    const isCancelled =
        takeoutOrder.isUpdate &&
        (!takeoutOrder.items || Object.keys(takeoutOrder.items).length == 0);
    const isUpdated = takeoutOrder.isUpdate && !isCancelled;

    const tableItem = Object.entries(takeoutOrder.items).find(
        ([key, item]) => typeof item['tableId'] != 'undefined'
    );

    const tableId = tableItem ? tableItem[1].tableId : '';
    const isTogo = takeoutOrder.orderType == 'To-go';

    const timeStamp = takeoutOrder.updated_timestamp
        ? takeoutOrder.updated_timestamp
        : takeoutOrder.created_timestamp;

    return (
        <HtmlContainer>
            <EmptySpace />
            <Header
                {...{ restauName, address, phonenumber, showPoweredBy: false }}
            />
            <OrderHeader
                {...{
                    isCancelled,
                    isUpdated,
                    number: takeoutOrder.number,
                    orderUnderName: takeoutOrder.orderUnderName,
                    isTogo,
                    tableId,
                    timeStamp,
                    paymentStatus: takeoutOrder.payment_success == true 
                }}
            />
            {!isCancelled && (
                <OrderItems
                    {...{
                        takeoutOrder: { ...takeoutOrder, items: restItems },
                        showPrices,
                        drawLinesInBetween: true,
                    }}
                />
            )}
            {!isCancelled && Object.keys(holdItems).length > 0 && (
                <div
                    style={{
                        textAlign: 'center',
                        ...pStyleH3,
                        marginTop: '5px',
                    }}
                >
                    [Hold]
                </div>
            )}
            {!isCancelled && Object.keys(holdItems).length > 0 && (
                <OrderItems
                    {...{
                        takeoutOrder: { ...takeoutOrder, items: holdItems },
                        showPrices,
                        drawLinesInBetween: true,
                        showHeader: false,
                    }}
                />
            )}
            {showPrices && <OrderTotal {...{ takeoutOrder }} />}
            <StationFooter {...stationDetails} />
            {reprint && (
                <div style={{ textAlign: 'center', ...pStyleH1 }}>Reprint</div>
            )}
        </HtmlContainer>
    );
}
