import styled from 'styled-components';
import React from 'react';
import { formatPrice } from '../Data/FoodData';
import { LabelContainer } from './FoodDialog';

const ToppingGrid = styled.div`
    display: grid;
    grid-template-columns: ${({ gridColumns }) =>
        gridColumns ? `repeat(${gridColumns}, 1fr);` : `repeat(1, 1fr);`};
    // Add this part
    @media (max-width: 720px) {
        grid-template-columns: 1fr;
    }
`;

const ToppingDiv = styled.div`
    margin-top: 10px;
`;

const ToppingCheckbox = styled.input`
    margin-right: 10px;
    cursor: pointer;
`;

const CheckboxLabel = styled.label`
    cursor: pointer;
`;

export function Toppings({
    toppings,
    checkTopping,
    toppingslabel,
    gridColumns,
}) {
    return (
        <ToppingDiv>
            {toppingslabel ? (
                <LabelContainer>{toppingslabel}</LabelContainer>
            ) : (
                <LabelContainer>Options: </LabelContainer>
            )}
            <ToppingGrid gridColumns={gridColumns}>
                {toppings.map((topping, i) => (
                    <CheckboxLabel key={topping.id}>
                        <ToppingCheckbox
                            type="checkbox"
                            checked={topping.checked}
                            onClick={() => {
                                checkTopping(i);
                            }}
                        />
                        {topping.name}
                        {topping.price ? (
                            <span> (+{formatPrice(topping.price / 100)})</span>
                        ) : (
                            ''
                        )}
                    </CheckboxLabel>
                ))}
            </ToppingGrid>
        </ToppingDiv>
    );
}
