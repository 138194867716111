import React from 'react';
import PropTypes from 'prop-types';
import ToggleSwitch from './ToggleSwitch';

function SettingToggle(props) {
    const {
        checked,
        labelOn,
        labelOff,
        label,
        description,
        name,
        onChange,
    } = props;
    return (
        <div>
            <ToggleSwitch
                name={name}
                label={
                    <>
                        <em>{checked ? labelOn : labelOff} </em>
                        <span>{label}</span>
                        <span>{description}</span>
                    </>
                }
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
}

SettingToggle.propTypes = {};
SettingToggle.defaultProps = {
    labelOn: 'Show',
    labelOff: 'Hide',
    label: '',
    description: '',
};

export default SettingToggle;
