import React from 'react';
import { formatPrice } from '../../../../Data/FoodData';
import { getPrice } from '../../../../FoodDialog/FoodDialog';
import { TableRow, TABLEROW_COLUMN_STYLE } from './TableRow';
import { pStyleH3, pStyleH4, pStyleText } from './TemplateStyles';

// const partialWidthFlex = {
//     flex: "0 80%"
// }
// const fullWidthFlex = {
//     flex: "0 100%"
// }

export default ({ takeoutOrder }) => {
    const hasTip = takeoutOrder.tip > 0 || takeoutOrder.payment_success == true;
    const checkAmount =
        (takeoutOrder.payment_subtotal || 0) +
        (takeoutOrder.payment_salestax || 0) +
        (takeoutOrder.serviceFees || 0) +
        (takeoutOrder.minCreditCardFees || 0);
    return (
        <div style={{ ...pStyleText, marginTop: '5px', marginBottom: '20px' }}>
            {typeof takeoutOrder.payment_subtotal != 'undefined' && (
                <TableRow
                    cell1={'Sub Total'}
                    cell2={
                        <span style={pStyleH3}>
                            {formatPrice(takeoutOrder.payment_subtotal / 100)}
                        </span>
                    }
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}
            {typeof takeoutOrder.payment_salestax != 'undefined' && (
                <TableRow
                    cell1={'Sales Tax'}
                    cell2={formatPrice(takeoutOrder.payment_salestax / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}
            {takeoutOrder.minCreditCardFees > 0 && (
                <TableRow
                    cell1={'Credit card fees'}
                    cell2={formatPrice(takeoutOrder.minCreditCardFees / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}
            {takeoutOrder.deliveryFees && takeoutOrder.deliveryFees > 0 && (
                <TableRow
                    cell1={'Delivery fees'}
                    cell2={formatPrice(takeoutOrder.deliveryFees / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}

            {takeoutOrder.serviceFees && takeoutOrder.serviceFees > 0 ? (
                <TableRow
                    cell1={'Service charge'}
                    cell2={formatPrice(takeoutOrder.serviceFees / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            ): null}

            {takeoutOrder.convenientFee && takeoutOrder.convenientFee > 0 && (
                <TableRow
                    cell1={'Convenient fees'}
                    cell2={formatPrice(takeoutOrder.convenientFee / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}
            
            {hasTip && (
                <TableRow
                    cell1={'Tip'}
                    cell2={formatPrice((takeoutOrder.tip || 0) / 100)}
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                />
            )}
            {!hasTip && checkAmount > 0 && (
                <>
                    <TableRow
                        cell1={<span style={pStyleH4}>Check Amount</span>}
                        cell2={
                            <span style={pStyleH3}>
                                {formatPrice(checkAmount / 100)}
                            </span>
                        }
                        cell1Width={50}
                        cell2Width={50}
                        rowStyle={
                            TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN
                        }
                        borderTop={true}
                    />
                    <TableRow
                        cell1={<span style={pStyleH4}>Tip</span>}
                        cell1Width={50}
                        rowStyle={
                            TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN
                        }
                    />
                </>
            )}


            {takeoutOrder.total_amount_paid > 0 &&
                takeoutOrder.total_amount_paid !=
                    takeoutOrder.payment_total && (
                    <TableRow
                        cell1={'Amount paid'}
                        cell2={formatPrice(
                            takeoutOrder.total_amount_paid / 100
                        )}
                        cell1Width={50}
                        cell2Width={50}
                        rowStyle={
                            TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN
                        }
                    />
                )}
            {typeof takeoutOrder.payment_total != 'undefined' && (
                <TableRow
                    cell1={<span style={pStyleH4}>Total Amount</span>}
                    cell2={
                        <span style={pStyleH3}>
                            {hasTip
                                ? formatPrice(takeoutOrder.payment_total / 100)
                                : ''}
                        </span>
                    }
                    cell1Width={50}
                    cell2Width={50}
                    rowStyle={TABLEROW_COLUMN_STYLE.TWO_COLUMN_SPACE_BETWEEN}
                    borderTop={true}
                />
            )}


            {/*         
        { takeoutOrder.payment_subtotal && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          <div style={{textAlign: "left"}}>Sub Total</div>
          <div style={{textAlign: "right"}}>{formatPrice(takeoutOrder.payment_subtotal/100)}</div>
        </div> }
        { takeoutOrder.payment_salestax && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          <div style={{textAlign: "left"}}>Sales Tax</div>
          <div style={{textAlign: "right"}}>{formatPrice(takeoutOrder.payment_salestax/100)}</div>
        </div> }
        { takeoutOrder.minCreditCardFees > 0 && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          <div style={{textAlign: "left"}}>Credit card fees</div>
          <div style={{textAlign: "right"}}>{formatPrice(takeoutOrder.minCreditCardFees/100)}</div>
        </div> }
        { takeoutOrder.tip > 0 && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
          <div style={{textAlign: "left"}}>Tip</div>
          <div style={{textAlign: "right"}}>{formatPrice(takeoutOrder.tip/100)}</div>
        </div> }
        { takeoutOrder.total_amount_paid > 0 && (takeoutOrder.total_amount_paid != takeoutOrder.payment_total) && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", ...pStyleH4}}>
          <div style={{textAlign: "left"}}>Amount paid</div>
          <div style={{textAlign: "right" , ...pStyleH3}}>{formatPrice(takeoutOrder.total_amount_paid/100)}</div>
        </div> }
        { takeoutOrder.payment_total && <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderTop: "solid 1px", ...pStyleH4}}>
          <div style={{textAlign: "left"}}>Total Amount</div>
          <div style={{textAlign: "right" , ...pStyleH3}}>{formatPrice(takeoutOrder.payment_total/100)}</div>
        </div> } */}
        </div>
    );
};
