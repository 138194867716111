import React from 'react';
import styled from 'styled-components';
import {
    EmptySpace,
    Header,
    HtmlContainer,
    OrderHeader,
    OrderItems,
    OrderTotal,
    StationFooter,
} from './components';
import { pStyleH1, pStyleH2, pStyleH4 } from './components/TemplateStyles';

export function PrintOrder({
    takeoutOrder,
    reprint,
    stationDetails = {},
    showPrices,
    restauName,
    address,
    phonenumber = '',
}) {
    const isCancelled =
        takeoutOrder.isUpdate &&
        (!takeoutOrder.items || Object.keys(takeoutOrder.items).length == 0);
    const isUpdated = takeoutOrder.isUpdate && !isCancelled;

    const tableId = takeoutOrder['tableId'];
    const isTogo = takeoutOrder.orderType == 'To-go';

    const timeStamp = takeoutOrder.updated_timestamp
        ? takeoutOrder.updated_timestamp
        : takeoutOrder.created_timestamp;

    return (
        <HtmlContainer>
            <EmptySpace />
            <Header
                {...{ restauName, address, phonenumber, showPoweredBy: false }}
            />
            <OrderHeader
                {...{
                    isCancelled,
                    isUpdated,
                    number: takeoutOrder.number,
                    orderUnderName: takeoutOrder.orderUnderName,
                    isTogo,
                    tableId,
                    timeStamp,
                    paymentStatus: takeoutOrder.payment_success == true,
                    orderInstruction: takeoutOrder.orderInstruction,
                    scheduledOrder: takeoutOrder.scheduledOrder,
                    scheduledDate: takeoutOrder.scheduledDate,
                    scheduledTime: takeoutOrder.scheduledTime,
                    deliveryScheduledForLater: takeoutOrder.deliveryScheduledForLater,
                    deliveryScheduledDate:takeoutOrder.deliveryScheduledDate,
                    deliveryScheduledTime: takeoutOrder.deliveryScheduledTime,
                    orderType: takeoutOrder.orderType
                }}
            />
            {!isCancelled && (
                <OrderItems
                    {...{ takeoutOrder, showPrices, drawLinesInBetween: true }}
                />
            )}
            {showPrices && <OrderTotal {...{ takeoutOrder }} />}
            <StationFooter {...stationDetails} />
            {reprint && (
                <div style={{ textAlign: 'center', ...pStyleH1 }}>Reprint</div>
            )}
        </HtmlContainer>
    );
}
