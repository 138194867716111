import React from 'react';
import {
    assignOrderHistoryId,
    closeDineInOrder,
    updatePaymentsDineIn,
} from '../apis/DineIn';
import {
    Dialog,
    DialogContent,
    DialogShadow,
    DialogFooter,
    ConfirmButton,
} from '../FoodDialog/FoodDialog';
import { getDateTime } from '../Hooks/useOrders';
import { getSubTotal } from './Order';

export function OrderDialog({
    loggedIn,
    openOrderDialog,
    setOpenOrderDialog,
    restauId,
    tableId,
    tableOrders,
    summons,
    salesTax,
    serviceCharge
}) {
    function close() {
        setOpenOrderDialog();
    }
    async function closeOrder() {
        //const database = window.customerApp.database();

        var orderHistoryId = await assignOrderHistoryId(restauId, tableId);

        // var updates = {};

        // var orderHistoryId;
        // var summon = summons[tableId];
        // if (!(summon) || !('orderHistoryId' in summon) || summon.orderHistoryId===""){
        //   var histRef = database.ref("orderhistory/" + restauId).push();
        //   orderHistoryId = histRef.key
        // } else {
        //   orderHistoryId = summon.orderHistoryId;
        // }

        // updates["activeusers/"+ restauId+ "/" + tableId + "/forceDetach"] = true;
        // updates["activeusers/"+ restauId+ "/" + tableId + "/orderHistoryId"] = orderHistoryId;
        // await database.ref().update(updates);

        //updates = {};

        let dateTime = getDateTime();
        let subTotal = getSubTotal(tableOrders);
        let st = Math.round((subTotal * salesTax) / 100);
        let serviceFees = Math.round((subTotal * serviceCharge) / 100);
        let tip = 0;
        if (summons && summons[tableId] && summons[tableId].tip) {
            tip = summons[tableId].tip;
        }
        let total = subTotal + st + tip + serviceFees;
        //var result = {subTotal, total, tip, time: dateTime, tableId: tableId, items : tableOrders, server: loggedIn.email, salesTax : st }

        await updatePaymentsDineIn(restauId, {
            tableId,
            id: orderHistoryId,
            payment_subtotal: subTotal,
            payment_total: total,
            tip,
            items: tableOrders,
            server: loggedIn.email,
            payment_salestax: st,
            serviceFees: serviceFees
        });

        //histRef.set(result);
        //updates["orderhistory/" + restauId + "/" + orderHistoryId] = result;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].subTotal = subTotal;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].total = total;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].tip = tip;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].time = dateTime;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].tableId = tableId;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].items = tableOrders;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].server = loggedIn.email;
        // updates["orderhistory/" + restauId + "/" + orderHistoryId].salesTax = st;

        await closeDineInOrder(restauId, tableId, orderHistoryId);
        // var rtkey = restauId + ":" + tableId;

        // //database.ref("orders/"+rtkey).remove();
        // updates["orders/" + rtkey] = null;

        // // var rtkey = "summons/" + restauId + "/" + tableId + "/";
        // // updates[rtkey + "check"] = false;
        // // updates[rtkey + "help"] = false;
        // // updates[rtkey + "orderHistoryId"] = "";
        // updates["summons/" + restauId + "/" + tableId] = null;
        // updates["activeusers/"+ restauId+ "/" + tableId] = null;

        // database.ref().update(updates);
    }

    return openOrderDialog ? (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                <DialogContent>
                    <h3>Closing Table-{tableId} </h3>
                    <p>
                        Once you confirm, all items for this table will be
                        deleted across your and customer devices.
                    </p>
                </DialogContent>
                <DialogFooter>
                    <ConfirmButton
                        onClick={() => {
                            //setOrders([]);
                            closeOrder();
                            setOpenOrderDialog();
                        }}
                    >
                        Confirm
                    </ConfirmButton>
                </DialogFooter>
            </Dialog>
        </>
    ) : (
        <div />
    );
}
