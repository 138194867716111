import React, { useContext, useState } from 'react';
import { MenuContext } from '../contexts/MenuContext';
import { OOSDialog } from './OOSDialog';
import styled from 'styled-components';
import { FoodLabel } from '../Menu/FoodGrid';
import { corianderGreen, pomegranateRed } from '../Styles/colors';
import { Title } from '../Styles/title';
import { formatPrice } from '../Data/FoodData';
import { QuantityInput } from './QuantityInput';
import { useQuantity } from '../Hooks/useQuantity';
import { Toppings } from './Toppings';
import { useToppings } from '../Hooks/useToppings';
import { useChoice } from '../Hooks/useChoice';
import { NotesInput } from './NotesInput';
import { useNotes } from '../Hooks/useNotes';
import { Choices } from './Choices';
import { TableInput } from './TableInput';
import { isDineInMode, isTakeoutMode } from '../Hooks/useOrders';
import { CreateOrderContext, ORDER_TYPE } from '../contexts/CreateOrderContext';
import {
    getDateTime,
    isUndefinedOrNull,
    notUndefinedAndNull,
} from '../utils/Util';
import { useModifiers } from '../Hooks/useModifiers';
import { ModifierGroup } from './ModifierGroup/ModifierGroup';
import {
    validateOrderSelection,
    getModifierPrice,
    getModifierStr,
} from './ModifierGroup/ModifierUtil';
import { PriceInput } from './PriceInput';
import Config from '../config/Config';

const { default: axios } = require('axios');
export const Dialog = styled.div`
    width: 540px;
    background-color: white;
    position: fixed;
    top: 75px;
    z-index: 11;
    max-height: calc(100% - 175px);
    left: calc(50% - 270px);
    display: flex;
    flex-direction: column;

    // Add this part
    @media (max-width: 720px) {
        width: 100%;
        left: 0px;
        z-index: 12;
    }
`;

export const DialogContent = styled.div`
    overflow: auto;
    min-height: 20px;
    padding: 0px 40px;
    padding-bottom: 40px;
`;

export const DialogFooter = styled.div`
    box-shadow: 0px -2px 10px 0px grey;
    min-height: 60px;
    display: flex;
    justify-content: center;
`;

export const DialogFooterJustify = styled.div`
    box-shadow: 0px -2px 10px 0px grey;
    min-height: 60px;
    display: flex;
    justify-content: justify;
`;

export const ConfirmButton = styled(Title)`
    margin: 10px;
    color: white;
    height: 20px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const ConfirmButtonRed = styled(Title)`
    margin: 10px;
    color: white;
    height: 20px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${pomegranateRed};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const DialogShadow = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.7;
    z-index: 11;
`;

export const DialogBanner = styled.div`
    min-height: 200px;
    margin-bottom: 20px;
    ${({ img }) =>
        img ? `background-image: url(${img});` : `min-height: 50px;`}
    background-position: center;
    background-size: cover;
    position: relative;
`;

// export const DialogBannerName = styled(FoodLabel)`
//   font-size: 30px;
//   padding: 5px 40px;
//   top: ${({ img }) => (img ? `100px` : `20px`)};
// `;
export const DialogBannerName = styled(FoodLabel)`
    font-weight: 900;
    padding: 20px 40px 10px;
    box-shadow: 0px 2px 0px 0px #e7e7e7;
`;

// const pricePerTopping = 0.5;
// export function getPrice(order) {
//   return (
//     order.quantity *
//     (order.price +
//       order.toppings.filter(t => t.checked).length * pricePerTopping)
//   );
// }

export function getPriceWithToppings(order) {
    var toppingsPrice = 0;
    if (order.toppings) {
        for (var t of order.toppings) {
            if (t.checked) {
                if ('price' in t) {
                    toppingsPrice += t.price;
                }
            }
        }
    }
    return order.price + toppingsPrice;
}

export function getPrice(order) {
    return (
        order.quantity * (getPriceWithToppings(order) + getModifierPrice(order))
    );
}

function hasToppings(food) {
    return food.section === 'Pizza';
}

const FoodDescription = styled.p`
    display: flex;
    margin: 20px;
`;

export const LabelContainer = styled(Title)`
    display: flex;
    height: 24px;
`;

const StyledInput = styled.input`
    display: block;
    margin: 20px 0px;
    border: 1px solid lightred;
`;

const TableContainer = styled(Title)`
    display: flex;
    margin: 20px 0px;
`;

function FoodDialogContainer({
    openFood,
    setOpenFood,
    orders,
    tableId,
    restauId,
    tableIds,
    setTableId,
    feedCount,
    setFeedCount,
    enqueueSnackbar,
    operationMode,
    oosDialog,
    setOOSDialog,
    loggedIn,
}) {
    const { inProgressOrderType, addItem, udpateItem } = useContext(
        CreateOrderContext
    );
    const quantity = useQuantity(openFood && openFood.quantity);
    const toppingsHook = useToppings(openFood.toppings);
    const choiceRadio = useChoice(openFood.choice);
    const notes = useNotes(openFood.notes);
    const modifiersHook = useModifiers(openFood.modifierGroup);
    const isEditing = openFood.index > -1;
    const customPrice = useNotes(
        openFood.price ? '' + openFood.price / 100 : '0'
    );

    if (isEditing) {
        setTableId(openFood.tableId);
    }

    function close() {
        setOpenFood();
    }

    const order = {
        ...openFood,
        quantity: quantity.value,
        toppings: toppingsHook.toppings,
        choice: choiceRadio.value,
        notes: notes.value,
        modifierGroup: modifiersHook.value,
    };

    if ('choices' in order) {
        for (var c of order.choices) {
            if (c.name === order.choice) {
                order.choiceId = c.id;
                if ('price' in c) {
                    order.price = c.price;
                }
            }
        }
    }

    function validateCustomPrice(order) {
        if (!order.isCustomItem) {
            return true;
        }

        if (
            isNaN(parseFloat(customPrice.value)) ||
            parseFloat(customPrice.value) < 0
        ) {
            alert(
                'Price you entered : ' + customPrice.value + ' is not valid.'
            );
            return false;
        } else {
            let priceInCents = Math.round(parseFloat(customPrice.value) * 100);
            order.price = priceInCents;
            return true;
        }
    }

    // Needs to change
    function editOrder() {
        if (!validateOrderSelection(order)) {
            return;
        }

        if (!validateCustomPrice(order)) {
            return;
        }

        const database = window.customerApp.database();
        var newOrder = {
            id: order.id,
            quantity: order.quantity,
            name: order.name,
            status: order.status,
            price: order.price,
            key: order.key,
            tableId: order.tableId,
            notify: true,
            addedBy: order.addedBy,
            updatedBy: 'Host',
            added_timestamp: order.added_timestamp,
            updated_timestamp: getDateTime(),
        };

        if (order.isCustomItem) {
            newOrder['isCustomItem'] = order['isCustomItem'];
        }
        if (order.submitted_timestamp) {
            newOrder['submitted_timestamp'] = order['submitted_timestamp'];
        }
        if (order.COOK_timestamp) {
            newOrder['COOK_timestamp'] = order['COOK_timestamp'];
        }
        if (order.READY_timestamp) {
            newOrder['READY_timestamp'] = order['READY_timestamp'];
        }
        if (order.DELIVERED_timestamp) {
            newOrder['DELIVERED_timestamp'] = order['DELIVERED_timestamp'];
        }

        if (order.placedBy) {
            newOrder['placedBy'] = order['placedBy'];
        }

        if (order.station) {
            newOrder['station'] = order.station;
            for (var station of order.station) {
                if (station + 'status' in order) {
                    // needed else error if Host edits item before it is send to kitchen
                    newOrder[station + 'status'] = order[station + 'status'];
                }
            }
        }
        if (order.img) {
            newOrder['img'] = order.img;
        }
        if (order.desc) {
            newOrder['desc'] = order.desc;
        }
        if (order.toppings) {
            let toppingsStr = order.toppings
                .filter(t => t.checked)
                .map(topping => topping.name)
                .join(', ');
            if (toppingsStr !== '') {
                newOrder['toppingsStr'] = toppingsStr;
            }
            newOrder['toppings'] = order.toppings;
        }

        if (order.choice) {
            newOrder['choiceId'] = order.choiceId;
            newOrder['choice'] = order.choice;
            newOrder['choices'] = order.choices;
        }

        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }
        if (order.modifierGroup) {
            newOrder['modifierGroup'] = order.modifierGroup;
            newOrder['modifiersStr'] = getModifierStr(order);
        }

        var updates = {};
        var rtkey = restauId + ':' + order.tableId;
        updates['orders/' + rtkey + '/' + order.key] = newOrder;
        database.ref().update(updates);
        close();
    }

    function addOrderToCart() {
        addOrder('DRAFT');
    }

    function addOrderToCook() {
        addOrder('COOK');
    }

    async function markItemOOS() {
        let oosDialogData = { ...oosDialog };
        if (!oosDialogData) {
            oosDialogData = {};
        }
        oosDialogData.show = true;
        oosDialogData.section_idx = openFood.section_idx;
        oosDialogData.food_idx = openFood.food_idx;
        oosDialogData.name = openFood.name;
        setOOSDialog(oosDialogData);
        close();
    }

    function markItemBackInStock() {
        var markBackInStock = window.confirm(
            'Do you want to mark ' + openFood.name + ' back in stock?'
        );
        if (
            isUndefinedOrNull(openFood.section_idx) ||
            isUndefinedOrNull(openFood.food_idx)
        )
            return;
        if (markBackInStock) {
            const database = window.customerApp.database();
            var updates = {};
            updates['/oos'] = false;
            database
                .ref(
                    'menus/' +
                        restauId +
                        '/menu/' +
                        openFood.section_idx +
                        '/foods/' +
                        openFood.food_idx
                )
                .update(updates)
                .then(async function() {
                    enqueueSnackbar('Marked ' + openFood.name + ' in stock', {
                        variant: 'success',
                    });
                    let audit = {
                        type: 'MARK-INSTOCK-Manual',
                        itemId: openFood.food_idx,
                        itemName: openFood.name,
                        email: loggedIn ? loggedIn.email : '',
                    };
                    var url = Config.move_audit_log;
                    const token = await window.customerApp
                        .auth()
                        .currentUser.getIdToken();
                    axios.post(url, {
                        restauId,
                        action: 'OOS',
                        audit,
                        headers: token,
                    });
                })
                .catch(function(error) {
                    var msg = 'Error updating ' + openFood.name + '.';
                    if (error.message) {
                        msg += ' Error : ' + error.message;
                    }
                    enqueueSnackbar(msg, { variant: 'error' });
                });
        }
        close();
    }

    function hideItem() {
        console.log(
            'food',
            'menus/' +
                restauId +
                '/menu/' +
                openFood.section_idx +
                '/foods/' +
                openFood.food_idx
        );
        if (
            isUndefinedOrNull(openFood.section_idx) ||
            isUndefinedOrNull(openFood.food_idx)
        )
            return;
        var shouldHide = window.confirm(
            'Do you want to hide ' + openFood.name + '?'
        );
        if (shouldHide) {
            const database = window.customerApp.database();
            var updates = {};
            updates['/hide'] = true;
            database
                .ref(
                    'menus/' +
                        restauId +
                        '/menu/' +
                        openFood.section_idx +
                        '/foods/' +
                        openFood.food_idx
                )
                .update(updates)
                .then(async function() {
                    enqueueSnackbar('Hidden ' + openFood.name, {
                        variant: 'success',
                    });
                    let audit = {
                        type: 'HIDE',
                        itemId: openFood.food_idx,
                        itemName: openFood.name,
                        email: loggedIn ? loggedIn.email : '',
                    };
                    var url = Config.move_audit_log;
                    const token = await window.customerApp
                        .auth()
                        .currentUser.getIdToken();
                    axios.post(url, {
                        restauId,
                        action: 'HIDE',
                        audit,
                        headers: token,
                    });
                })
                .catch(function(error) {
                    var msg = 'Error updating ' + openFood.name + '.';
                    if (error.message) {
                        msg += ' Error : ' + error.message;
                    }
                    enqueueSnackbar(msg, { variant: 'error' });
                });
        }
        close();
    }

    function unHideItem() {
        if (
            isUndefinedOrNull(openFood.section_idx) ||
            isUndefinedOrNull(openFood.food_idx)
        )
            return;
        var shouldUnHide = window.confirm(
            'Do you want to unhide ' + openFood.name + '?'
        );
        if (shouldUnHide) {
            const database = window.customerApp.database();
            var updates = {};
            updates['/hide'] = false;
            database
                .ref(
                    'menus/' +
                        restauId +
                        '/menu/' +
                        openFood.section_idx +
                        '/foods/' +
                        openFood.food_idx
                )
                .update(updates)
                .then(async function() {
                    enqueueSnackbar('Unhidden ' + openFood.name, {
                        variant: 'success',
                    });
                    let audit = {
                        type: 'UNHIDE',
                        itemId: openFood.food_idx,
                        itemName: openFood.name,
                        email: loggedIn ? loggedIn.email : '',
                    };
                    var url = Config.move_audit_log;
                    const token = await window.customerApp
                        .auth()
                        .currentUser.getIdToken();
                    axios.post(url, {
                        restauId,
                        action: 'HIDE',
                        audit,
                        headers: token,
                    });
                })
                .catch(function(error) {
                    var msg = 'Error updating ' + openFood.name + '.';
                    if (error.message) {
                        msg += ' Error : ' + error.message;
                    }
                    enqueueSnackbar(msg, { variant: 'error' });
                });
        }
        close();
    }

    function addOrder(status) {
        if (!validateOrderSelection(order)) {
            return;
        }
        if (!validateCustomPrice(order)) {
            return;
        }

        const database = window.customerApp.database();
        var key = restauId + ':' + tableId;
        var newOrderRef = database.ref('orders/' + key).push();
        // order['toppings'] = order.toppings.filter(t => t.checked)
        //               .map(topping => topping.name)
        //               .join(", ")

        var newOrder = {
            id: order.id,
            quantity: order.quantity,
            name: order.name,
            status: status,
            price: order.price,
            key: newOrderRef.key,
            tableId: tableId,
            notify: true,
            addedBy: 'Host',
            added_timestamp: getDateTime(),
        };

        if (order.station) {
            newOrder['station'] = order.station;
            if (status === 'COOK') {
                for (var station of order.station) {
                    newOrder[station + 'status'] = 'NOT_SEEN';
                }
            }
        }

        if (order.isCustomItem) {
            newOrder['isCustomItem'] = order['isCustomItem'];
        }
        if (order.img) {
            newOrder['img'] = order.img;
        }
        if (order.desc) {
            newOrder['desc'] = order.desc;
        }
        if (order.toppings) {
            let toppingsStr = order.toppings
                .filter(t => t.checked)
                .map(topping => topping.name)
                .join(', ');
            if (toppingsStr !== '') {
                newOrder['toppingsStr'] = toppingsStr;
            }
            newOrder['toppings'] = order.toppings;
        }

        if (order.choice) {
            newOrder['choiceId'] = order.choiceId;
            newOrder['choice'] = order.choice;
            newOrder['choices'] = order.choices;
        }

        if (order.notes && order.notes !== '') {
            newOrder['notes'] = order.notes;
        }

        if (order.modifierGroup) {
            newOrder['modifierGroup'] = order.modifierGroup;
            newOrder['modifiersStr'] = getModifierStr(order);
        }

        newOrderRef.set(newOrder);
        //setOrders([...orders, order]);
        close();
    }

    function placeTakeoutOrder() {
        if (!validateOrderSelection(order)) {
            return;
        }

        if (!validateCustomPrice(order)) {
            return;
        }

        let toppingsStr;
        if (order.toppings) {
            toppingsStr = order.toppings
                .filter(t => t.checked)
                .map(topping => topping.name)
                .join(', ');
        }

        if (isEditing) {
            udpateItem(
                openFood.index,
                order.id,
                order.name,
                order.quantity,
                order.price,
                order.desc,
                toppingsStr,
                order.toppings,
                order.choice,
                order.choiceId,
                order.choices,
                order.notes,
                order.modifierGroup
            );
        } else {
            addItem(
                order.id,
                order.name,
                order.quantity,
                order.price,
                order.desc,
                toppingsStr,
                order.toppings,
                order.choice,
                order.choiceId,
                order.choices,
                order.notes,
                'DRAFT',
                order.station,
                order.modifierGroup,
                order.isCustomItem
            );
        }
        close();
    }

    return (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                {/* <DialogBanner>        </DialogBanner> */}
                <DialogBannerName> {openFood.name} </DialogBannerName>
                <DialogContent>
                    {openFood.desc && (
                        <FoodDescription>{openFood.desc}</FoodDescription>
                    )}

                    {/* <TableInput tableIds={tableIds} tableId={tableId}  setTableId={setTableId} disabled={isEditing}/> */}
                    {isDineInMode(operationMode) &&
                        inProgressOrderType == ORDER_TYPE.DINEIN && (
                            <TableContainer>
                                <b>Table - {tableId}</b>
                            </TableContainer>
                        )}
                    <QuantityInput quantity={quantity} />

                    {openFood.choices && (
                        <Choices
                            openFood={openFood}
                            choiceRadio={choiceRadio}
                        />
                    )}

                    {openFood.toppings && (
                        <Toppings
                            {...toppingsHook}
                            toppingslabel={openFood.toppingslabel}
                        />
                    )}

                    {openFood.modifierGroup && (
                        <ModifierGroup modifierHook={modifiersHook} />
                    )}

                    {openFood.isCustomItem && (
                        <PriceInput
                            {...customPrice}
                            placeholder="1.23"
                            label="Price"
                        />
                    )}

                    <NotesInput
                        {...notes}
                        placeholder="Type in here"
                        label="Notes"
                    />
                    {/* <NotesContainer>Notes:</NotesContainer>
        <StyledInput
          {...notes}
          placeholder="Type in here"
        /> */}
                </DialogContent>
                <DialogFooter>
                    {isDineInMode(operationMode) &&
                        inProgressOrderType == ORDER_TYPE.DINEIN && (
                            <ConfirmButton
                                onClick={isEditing ? editOrder : addOrderToCart}
                                disabled={
                                    openFood.choices && !choiceRadio.value
                                }
                            >
                                <b>
                                    {isEditing
                                        ? `Update order `
                                        : `Add to cart`}
                                </b>
                            </ConfirmButton>
                        )}
                    {isTakeoutMode(operationMode) &&
                        inProgressOrderType == ORDER_TYPE.TAKEOUT && (
                            <ConfirmButton
                                onClick={placeTakeoutOrder}
                                // onClick={isEditing ? () => {
                                //     udpateItem(openFood.index, order.id, order.name, order.quantity, order.price)
                                //     close();
                                //   } :
                                //   () => {
                                //   addItem(order.id, order.name, order.quantity, order.price);
                                //   close();
                                // }}
                                disabled={
                                    openFood.choices && !choiceRadio.value
                                }
                            >
                                <b>
                                    {isEditing
                                        ? `Update order `
                                        : `Add to order`}
                                </b>
                            </ConfirmButton>
                        )}

                    {notUndefinedAndNull(openFood.section_idx) &&
                        notUndefinedAndNull(openFood.food_idx) &&
                        (openFood.oos ? (
                            <ConfirmButtonRed
                                onClick={() => markItemBackInStock()}
                            >
                                <b>Back in Stock</b>
                            </ConfirmButtonRed>
                        ) : (
                            <ConfirmButton onClick={() => markItemOOS()}>
                                <b>Out of Stock</b>
                            </ConfirmButton>
                        ))}

                    {notUndefinedAndNull(openFood.section_idx) &&
                        notUndefinedAndNull(openFood.food_idx) &&
                        (openFood.hide ? (
                            <ConfirmButtonRed onClick={() => unHideItem()}>
                                <b>Unhide</b>
                            </ConfirmButtonRed>
                        ) : (
                            <ConfirmButton onClick={() => hideItem()}>
                                <b>Hide</b>
                            </ConfirmButton>
                        ))}
                </DialogFooter>
            </Dialog>
        </>
    );
}

export function FoodDialog(props) {
    const { oosDialog, setOOSDialog } = useContext(MenuContext);
    if (!props.openFood) return null;
    return (
        <FoodDialogContainer
            {...props}
            oosDialog={oosDialog}
            setOOSDialog={setOOSDialog}
        />
    );
}
