import React from 'react';
import { pStyleH2, pStyleH3, pStyleH4, pStyleText } from './TemplateStyles';

export default ({ station, stationNumber, totalStations }) => {
    const stationText =
        typeof station != 'undefined'
            ? station + ' (' + stationNumber + '/' + totalStations + ') '
            : '';
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                ...pStyleText,
                marginTop: '5px',
            }}
        >
            <span>{stationText}&nbsp;</span>
            <span style={pStyleH4}>StrideQ&nbsp;</span>
        </div>
    );
};
