import React from 'react';
import styled from 'styled-components';
import { corianderGreen, pomegranateRed } from '../Styles/colors';
import { Title } from '../Styles/title';

//position: fixed;
const NavbarStyled = styled.div`
    background-color: #efefef;
    padding-top: 2px;
    border-bottom-color: ${corianderGreen};
    border-bottom-width: thin;
    border-bottom-style: solid;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: space-between;
`;

const Logo = styled(Title)`
    font-size: 20px;
    color: white;
    text-shadow: 1px 1px 4px #380502;
`;

export const Feed = styled.div`
    color: white;
    font-size: 20px;
`;

export const Orders = styled.div`
    color: white;
    font-size: 20px;
`;

export const Menu = styled.div`
    color: white;
    font-size: 20px;
`;

export const RibbonButton = styled(Title)`
    // margin: 10px;
    color: white;
    height: 18px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: ${({ color }) => (color ? color : `${corianderGreen};`)};
    ${({ disabled }) =>
        disabled &&
        `
    opacity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
    ${({ style }) => {
        return (
            (style && style.width && ` width: ${style.width};`) +
            (style && style.height && ` height: ${style.height};`) +
            (style && style.padding && ` padding: ${style.padding};`)
        );
    }}
`;

export const TabButton = styled(Title)`
    margin: 10px 10px 0px 10px;
    color: white;
    height: 18px;
    border-radius: 5px 5px 0px 0px;
    font-size: 16px;
    padding: 14px 10px 10px 10px;
    text-align: center;
    cursor: pointer;
    background-color: grey;
    border-bottom-color: grey;
    border-bottom-width: thick;
    border-bottom-style: solid;
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: ${corianderGreen}; 
    pointer-events: none; 
  `}
`;

export const RibbonButtonRed = styled(Title)`
    margin: 10px;
    color: white;
    height: 18px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    background-color: ${pomegranateRed};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

const LoginButton = styled.span`
    cursor: pointer;
`;

export function Ribbon({
    operationMode,
    tab,
    setTab,
    feedCount,
    restauId,
    isPaused,
}) {
    function changeTab(tab) {
        setTab(tab);
    }

    return (
        <NavbarStyled>
            <Feed>
                <TabButton
                    disabled={tab == 'FEED'}
                    onClick={() => {
                        setTab('FEED');
                    }}
                >
                    <b>
                        <i className="fas fa-tasks" /> Active
                        {feedCount > 0 ? (
                            <span
                                style={{
                                    color: pomegranateRed,
                                    fontSize: '20px',
                                    margin: '-30px -8px',
                                    position: 'absolute',
                                }}
                                className="fa-stack"
                            >
                                <span
                                    style={{ color: pomegranateRed }}
                                    className="fas fa-circle fa-stack-1x"
                                />
                                <strong
                                    style={{ color: 'white', fontSize: '15px' }}
                                    className="fa-stack-1x"
                                >
                                    {feedCount}
                                </strong>
                            </span>
                        ) : (
                            <span />
                        )}
                    </b>{' '}
                </TabButton>
            </Feed>
            <Orders>
                <TabButton
                    disabled={tab == 'TABLE'}
                    onClick={() => {
                        setTab('TABLE');
                    }}
                >
                    <b>
                        <i className="fas fa-shopping-cart" />{' '}
                        {operationMode === 'TAKEOUT' ? 'Completed' : 'Orders'}
                    </b>
                </TabButton>
                {/* <span role="img" aria-label="pizza slice">
          🍕
        </span> */}
            </Orders>
            <Menu>
                <TabButton
                    disabled={tab == 'MENU'}
                    onClick={() => {
                        setTab('MENU');
                    }}
                >
                    <b>
                        <i className="fas fa-utensils" /> Menu
                    </b>
                </TabButton>
            </Menu>
        </NavbarStyled>
    );
}
