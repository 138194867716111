let function_url =
    'http://localhost:5001/sliceline-takeout-stage-4bf89/us-central1/';
function_url = "https://us-central1-sliceline-takeout-stage-4bf89.cloudfunctions.net/"
let firebase_db = 'https://sliceline-waiter.firebaseio.com';
let secondary_db =
    'https://sliceline-takeout-stage-4bf89-default-rtdb.firebaseio.com/';

const devConfig = {
    environment: 'Dev-',
    secondaryAppConfig: {
        appId: '1:856193594445:web:e4515098abad087c48d804',
        apiKey: 'AIzaSyCDvtBiowtLtnulX9VYRPUSHJFgWQH-0N8',
        authDomain: 'sliceline-takeout-stage-4bf89.firebaseapp.com',
        databaseURL: secondary_db,
        projectId: 'sliceline-takeout-stage-4bf89',
        storageBucket: 'sliceline-takeout-stage-4bf89.appspot.com',
        messagingSenderId: '856193594445',
        measurementId: 'G-169KN2X4E8',
    },
    firebaseConfig: {
        apiKey: 'AIzaSyATPb-S8IjTpONlyxhUER7bQ_UbhPFCqCw',
        authDomain: 'sliceline-waiter.firebaseapp.com',
        databaseURL: firebase_db,
        projectId: 'sliceline-waiter',
        storageBucket: 'sliceline-waiter.appspot.com',
        messagingSenderId: '593509092637',
        appId: '1:593509092637:web:83aaa5c3b184855a60a5f4',
        measurementId: 'G-PLH3Y078NM',
    },

    accept_sms_api: function_url + 'send_accept_sms',
    send_sms_api: function_url + 'send_sms',
    refund_api: function_url + 'refund',
    restauid_api: function_url + 'get_restauid',
    update_settings_api: function_url + 'update_user_settings',
    create_takeout_order: function_url + 'create_takeout_order',
    update_takeout_order: function_url + 'update_takeout_order',
    get_inprogress_call: function_url + 'get_inprogress_call',
    report_error: function_url + 'report_error',
    report_warning: function_url + 'report_warning',
    get_terminal_secret: function_url + 'get_terminal_secret',
    capture_terminal_payment: function_url + 'capture_terminal_payment',
    add_takeout_order_tip: function_url + 'add_takeout_order_tip',
    todays_dine_in_reports: function_url + 'todays_dine_in_reports',
    mark_all_orders_ready: function_url + 'make_all_takeout_orders_ready',
    move_audit_log: function_url + 'move_audit_log',
    sync_thirdparty_menu: function_url + 'sync_thirdparty_menu',
    call_for_support: function_url + 'call_for_support',
};

export default devConfig;