import React, { useState, useEffect, useContext, useMemo } from 'react';
import { RestaurantContext } from '../RestaurantContext';
// import { enhanceMenuWithStation } from "../Hooks/useOrders";

const POPULAR_SECTION_ID = 'popular';

function enhanceMenuWithStationAndModifiers(
    menu,
    popularItems,
    defaultStations,
    modifiers
) {
    var oosMenu = [
        { id: 'oos', name: 'Currently Out of Stock Items', foods: [] },
    ];
    var popularSection = {
        id: POPULAR_SECTION_ID,
        name: 'Popular Items',
        foods: [],
    };
    const foodIdLookup = {};
    //console.log(popularItems);
    for (const [section_idx, section] of Object.entries(menu)) {
        if (!section.foods) {
            continue;
        }
        for (const [food_idx, food] of Object.entries(section.foods)) {
            food['section_idx'] = section_idx;
            food['food_idx'] = food_idx;

            //copy modifiers to menu.
            if (food['modifiers'] && modifiers) {
                let modifierGroup = {};
                modifierGroup['modifiers'] = [];
                for (let mod of food['modifiers']) {
                    if (modifiers[mod]) {
                        let modf = { ...modifiers[mod] };
                        modifierGroup['modifiers'].push(modf);
                    }
                }
                if (modifierGroup.modifiers.length > 0) {
                    food['modifierGroup'] = modifierGroup;
                }
            }

            if (!('station' in food)) {
                if ('station' in section) {
                    food['station'] = section['station'];
                } else if (defaultStations) {
                    food['station'] = defaultStations;
                }
            }
            if (food['oos']) {
                oosMenu[0]['foods'].push(food);
            }
            if (food['id'] in popularItems) {
                popularSection['foods'][popularItems[food['id']]] = food;
            }
            foodIdLookup[food['id']] = food;
        }
    }
    if (popularSection['foods'].length > 0) {
        menu = [popularSection, ...Object.values(menu)];
    }
    return [menu, oosMenu, foodIdLookup];
}

const MenuContext = React.createContext({});

function MenuProvider({ children }) {
    const { restauId } = useContext(RestaurantContext);

    const [menu, setMenu] = useState([]);
    const [oosMenu, setOosMenu] = useState([]);
    const [foodIdLookup, setFoodIdLookup] = useState({});
    const [oosDialog, setOOSDialog] = useState({ show: false });
    const [defaultStations, setDefaultStations] = useState(['receipt']);
    const [
        thirdPartyIntegrationConfig,
        setThirdPartyIntegrationConfig,
    ] = useState(null);

    const stations = useMemo(
        () => [
            ...new Set(
                Object.values(menu)
                    .flatMap(section =>
                        section.foods ? Object.values(section.foods) : []
                    )
                    .filter(s => s)
                    .flatMap(food =>
                        food.station ? Object.values(food.station) : []
                    )
                    .filter(s => s)
                    .map(s => s.toLowerCase())
            ),
        ],
        [menu]
    );

    function detachListener() {
        const database = window.customerApp.database();
        var oosRef = database.ref('menus/' + restauId + '/menu/');
        oosRef.off();
        let modRef = database.ref('menus/' + restauId + '/modifiers/');
        modRef.off();
        console.log('Stopping menuListener', restauId);
    }

    /*
     TODO: restructure DB to have menu , modifiers , popularItems, defaultStations under one key.  
    */
    async function refreshListners(restauId, database) {
        var popularItems = (await database
            .ref('menus/' + restauId + '/popularItems')
            .once('value')).val();
        if (!popularItems) {
            popularItems = {};
        }
        let defaultStations = (await database
            .ref('menus/' + restauId + '/defaultStations')
            .once('value')).val();
        let modifiers = (await database
            .ref('menus/' + restauId + '/modifiers')
            .once('value')).val();
        let newMenu = (await database
            .ref('menus/' + restauId + '/menu')
            .once('value')).val();
        const integrationConfig = (await database
            .ref('integrations/config/' + restauId)
            .once('value')).val();
        var [menu, oosMenu, foodIdLookup] = enhanceMenuWithStationAndModifiers(
            newMenu,
            popularItems,
            defaultStations,
            modifiers
        );
        setMenu(menu);
        setOosMenu(oosMenu);
        setFoodIdLookup(foodIdLookup);
        setDefaultStations(defaultStations);
        setThirdPartyIntegrationConfig(integrationConfig);
    }

    useEffect(() => {
        if (restauId === 'None') {
            return;
        }
        console.log('CREATING Menu LISTENER', restauId);
        const database = window.customerApp.database();
        database
            .ref('menus/' + restauId + '/menu/')
            .on('value', async snapshot => {
                refreshListners(restauId, database);
            });

        database
            .ref('menus/' + restauId + '/modifiers/')
            .on('value', async snapshot => {
                refreshListners(restauId, database);
            });

        // database.ref("menus/" + restauId + "/defaultStations").on("value", async (snapshot) => {
        //   refreshListners(restauId,database);
        // });

        return () => detachListener();
    }, [restauId]);

    const value = {
        menu,
        setMenu,
        oosMenu,
        foodIdLookup,
        stations,
        oosDialog,
        setOOSDialog,
        defaultStations,
        thirdPartyIntegrationConfig,
    };

    return (
        <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
    );
}

export { MenuContext, MenuProvider, POPULAR_SECTION_ID };
