import React, { useContext } from 'react';
import { MenuContext } from '../contexts/MenuContext';
import Config from '../config/Config';

import {
    Dialog,
    DialogContent,
    DialogShadow,
    DialogFooter,
    ConfirmButton,
} from '../FoodDialog/FoodDialog';

const { default: axios } = require('axios');

export function OOSDialog({ enqueueSnackbar, restauId, loggedIn }) {
    const { oosDialog, setOOSDialog } = useContext(MenuContext);
    function close() {
        let oosDialogData = { ...oosDialog };
        oosDialogData = {};
        oosDialogData.show = false;
        setOOSDialog(oosDialogData);
    }
    function markOOStockNDates() {
        console.log(oosDialog);
        if (
            !oosDialog.show ||
            !oosDialog.section_idx ||
            !oosDialog.food_idx ||
            !oosDialog.name
        ) {
            return;
        }
        let markOOS = window.confirm(
            'Do you want to mark ' +
                oosDialog.name +
                ' out of stock till manually mark it back?'
        );
        if (markOOS) {
            const database = window.customerApp.database();
            let updates = {};
            updates['/oos'] = true;
            database
                .ref(
                    'menus/' +
                        restauId +
                        '/menu/' +
                        oosDialog.section_idx +
                        '/foods/' +
                        oosDialog.food_idx
                )
                .update(updates)
                .then(async function() {
                    enqueueSnackbar(
                        'Marked ' + oosDialog.name + ' out of stock',
                        { variant: 'success' }
                    );
                    let audit = {
                        type: 'MARK-OOS-Manual',
                        itemId: oosDialog.food_idx,
                        itemName: oosDialog.name,
                        email: loggedIn ? loggedIn.email : '',
                    };
                    var url = Config.move_audit_log;
                    const token = await window.customerApp
                        .auth()
                        .currentUser.getIdToken();
                    axios.post(url, {
                        restauId,
                        action: 'OOS',
                        audit,
                        headers: token,
                    });

                    close();
                })
                .catch(function(error) {
                    let msg = 'Error updating ' + oosDialog.name + '.';
                    if (error.message) {
                        msg += ' Error : ' + error.message;
                    }
                    enqueueSnackbar(msg, { variant: 'error' });
                });
        }
    }

    async function markOOSToday() {
        console.log(oosDialog);
        if (
            !oosDialog.show ||
            !oosDialog.section_idx ||
            !oosDialog.food_idx ||
            !oosDialog.name
        ) {
            return;
        }
        let markOOS = window.confirm(
            'Do you want to mark ' +
                oosDialog.name +
                ' out of stock Only Today?'
        );
        if (markOOS) {
            const database = window.customerApp.database();
            let updates = {};
            updates['/oos'] = true;
            await database
                .ref(
                    'menus/' +
                        restauId +
                        '/menu/' +
                        oosDialog.section_idx +
                        '/foods/' +
                        oosDialog.food_idx
                )
                .update(updates)
                .then(async function() {
                    let oosUpdate = {
                        oos: true,
                        section_idx: oosDialog.section_idx,
                        food_idx: oosDialog.food_idx,
                    };
                    await database
                        .ref('oosToday/' + restauId + '/')
                        .push(oosUpdate);
                    enqueueSnackbar(
                        'Marked ' + oosDialog.name + ' out of stock',
                        { variant: 'success' }
                    );
                    let audit = {
                        type: 'MARK-OOS-AUTO',
                        itemId: oosDialog.food_idx,
                        itemName: oosDialog.name,
                        email: loggedIn ? loggedIn.email : '',
                    };
                    var url = Config.move_audit_log;
                    const token = await window.customerApp
                        .auth()
                        .currentUser.getIdToken();
                    axios.post(url, {
                        restauId,
                        action: 'OOS',
                        audit,
                        headers: token,
                    });

                    close();
                })
                .catch(function(error) {
                    let msg = 'Error updating ' + oosDialog.name + '.';
                    if (error.message) {
                        msg += ' Error : ' + error.message;
                    }
                    enqueueSnackbar(msg, { variant: 'error' });
                });
        }
    }

    async function closeOrder() {}

    return oosDialog.show ? (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                <DialogContent>
                    <h3>Out of Stock </h3>
                    <p>
                        Mark Out of Stock for today or more days
                        <br />
                        <br />
                        <span style={{ color: 'red' }}>
                            ** Marked as "Today" will automatically back to
                            stock next day
                        </span>
                    </p>
                </DialogContent>
                <DialogFooter>
                    <ConfirmButton
                        onClick={() => {
                            markOOSToday();
                        }}
                    >
                        Only Today
                    </ConfirmButton>
                    <ConfirmButton
                        onClick={() => {
                            markOOStockNDates();
                        }}
                    >
                        Till manually change
                    </ConfirmButton>
                </DialogFooter>
            </Dialog>
        </>
    ) : (
        <div />
    );
}
