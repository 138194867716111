import React, { useContext, useState, useReducer } from 'react';
import { Navbar } from './Navbar/Navbar';
import { Ribbon } from './Navbar/Ribbon';
import { SettingsDialog } from './Navbar/SettingsDialog';
import { Banner } from './Banner/Banner';
import { MenuWrapper } from './Menu/MenuWrapper';
import { FoodDialog } from './FoodDialog/FoodDialog';
import { GlobalStyle } from './Styles/GlobalStyle';
import { Feed } from './Order/Feed';
import { useOpenFood } from './Hooks/useOpenFood';
import { useOrders } from './Hooks/useOrders';
import { useTabs } from './Hooks/useTabs';
import { usePausedState } from './Hooks/usePausedState';
import { useTitle } from './Hooks/useTitle';
import { useAuthentication } from './Hooks/useAuthentication';
import { OrderDialog } from './Order/OrderDialog';
import { AcceptDialog } from './Order/AcceptDialog';
import { useOrderDialog } from './Hooks/useOrderDialog';
import { useShowOrders } from './Hooks/useShowOrders';
import { useShowSettings } from './Hooks/useShowSettings';
import { Order } from './Order/Order';
import { useRefundOrder } from './Hooks/useRefundOrder';
import { RefundDialog } from './Order/RefundDialog';
import { useAcceptOrderDialog } from './Hooks/useAcceptOrderDialog';
import { Login } from './Login';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useNumOfPrints } from './Hooks/useNumOfPrints';
import { useSoftwareVersion } from './Hooks/useSoftwareVersion';
import { SoftwareUpdate } from './Navbar/SoftwareUpdate';
import { CreateOrderProvider } from './contexts/CreateOrderContext';
import { RestaurantProvider } from './contexts/RestaurantContext';
import { PrintProvider } from './contexts/PrintContext/printContext';
import { MenuProvider } from './contexts/MenuContext';
// import { PrintOrder } from "./contexts/PrintContext/templates/PrintOrder";
// import { PrintReceipt } from "./contexts/PrintContext/templates/PrintReceipt";
import { OrderProvider } from './contexts/OrderContext';
import { AutoAcceptProvider } from './contexts/AutoAcceptContext';
import { PrintOrder } from './contexts/PrintContext/templates/PrintOrder';
import ErrorBoundary from './components/ErrorBoundary';
import { reportError } from './apis/reportError';
import { PaymentProvider } from './contexts/PaymentContext';
import eventBus from './utils/eventBus';
import { PrintOrderDineIn } from './contexts/PrintContext/templates/PrintOrderDineIn';
import { guid } from './utils/Util';
import { OOSDialog } from './FoodDialog/OOSDialog';
import useScript from './Hooks/useScript';

function MyApp() {
    useScript('//js.hs-scripts.com/21878431.js');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const auth = useAuthentication();
    const orders = useOrders(auth.loggedIn, enqueueSnackbar);
    const openFood = useOpenFood();
    const refundOrder = useRefundOrder();
    const orderDialog = useOrderDialog();
    const showOrders = useShowOrders();
    const showSettings = useShowSettings();
    const acceptDialog = useAcceptOrderDialog();

    const tabs = useTabs('FEED');
    const pausedState = usePausedState(orders.restauId);
    const softwareVersion = useSoftwareVersion(orders.restauId);

    //const restauAndTable = useRestauAndTable();

    //useTitle({ ...openFood, ...orders });

    //{showOrders.showOrders && <Order {...orders} {...openFood} {...auth} {...orderDialog}  />}
    // Above one doesnt rerender if the orders changes ?

    // <Menu {...openFood} {...orders} />  confirm how to pass only part of properties
    //orders.restauId ="demohari"

    return !auth.loggedIn ? (
        <Login {...auth} />
    ) : orders.restauId === 'None' ? (
        <div>
            Loading... <br />
            If its taking more then couple of seconds, there can be issue
            getting Restau Id. Please login with right credentials.
        </div>
    ) : (
        <>
            <audio
                id="beep"
                style={{ display: 'none' }}
                controls
                preload="auto"
                autobuffer="true"
            >
                <source src="beep.mp3" />
            </audio>
            <div id="softwareVersion" style={{ display: 'none' }}>
                20230204
            </div>
            {/* {
      //Test print template
        <PrintOrderDineIn { ...{ 
          takeoutOrder: { 
            created_timestamp: 1626647583,
            isUpdate: false, 
            items: [
              { notes: "les spicy jain", name: "Biryani paneer Biryani paneer Biryani paneer Biryani paneer Biryani paneer Biryani paneer", quantity: 2, price: 1245, addedBy: "Ani" },
              { name: "Cutmirchi", toppings: [{name: "Tp", checked: true}, {name:"Tp2"}], choice: "Pound", quantity: 1, price: 345 , addedBy: "Host" }
            ],
            number: 212, 
            orderUnderName: "Asvasuesdfdsgdfgfd", 
            tableId: 25,
            payment_subtotal: 2534,
            payment_salestax: 234,
            //tip: 12,
            minCreditCardFees: 23,
            payment_total: 26.56,
            payment_success: true          
          },
          stationDetails: {station: "Kitchen", stationNumber: 2, totalStations: 5},
          reprint: false, 
          //receipt: true,
          showPrices: true,
          restauName: "Mr Biryani Mipitas", 
          address: "dsfsdfsfdsfdsdsfs sdfds sdfds fdsfdsfddfsdfsdfsdfsdfgdsgdfgdfgfdgdfgdfggdfgdfgdfgfdgdfgdfgdfgdf1244 Landess Ave, Milpitas CA 95035", 
          phonenumber: "+14085645343" }} /> } */}
            <SoftwareUpdate {...softwareVersion} />
            <GlobalStyle />
            <RestaurantProvider
                value={{
                    restauId: orders.restauId,
                    restauName: orders.restauName,
                    salesTax: orders.salesTax,
                    restauAddress: orders.restauAddress,
                    restauPhoneNumber: orders.restauPhoneNumber,
                    settings: {
                        stationPrintingEnabled: orders.stationPrintingEnabled,
                        showPrintPrice: orders.showPrintPrice,
                    },
                    setPlayBeep: orders.setPlayBeep,
                }}
            >
                <PaymentProvider>
                    <MenuProvider>
                        <PrintProvider>
                            <OrderProvider
                                value={{
                                    autoAcceptQueue: orders.autoAcceptQueue,
                                    setAutoAcceptQueue:
                                        orders.setAutoAcceptQueue,
                                    userSettings: orders.userSettings,
                                    dispatchOrder: orders.dispatchOrder,
                                    orders: orders.orders,
                                    operationMode: orders.operationMode,
                                }}
                            >
                                <AutoAcceptProvider>
                                    <CreateOrderProvider>
                                        <SettingsDialog
                                            {...auth}
                                            {...orders}
                                            {...showSettings}
                                            {...softwareVersion}
                                        />
                                        <AcceptDialog
                                            {...acceptDialog}
                                            {...orders}
                                        />
                                        <OrderDialog
                                            {...auth}
                                            {...orderDialog}
                                            {...orders}
                                        />

                                        <RefundDialog
                                            {...refundOrder}
                                            {...orders}
                                        />
                                        <OOSDialog
                                            {...openFood}
                                            {...orders}
                                            {...tabs}
                                            {...auth}
                                        />
                                        <FoodDialog
                                            {...openFood}
                                            {...orders}
                                            {...tabs}
                                            {...auth}
                                        />
                                        <Navbar
                                            {...auth}
                                            {...orders}
                                            {...showOrders}
                                            {...showSettings}
                                            {...pausedState}
                                        />
                                        <Ribbon
                                            {...tabs}
                                            {...orders}
                                            {...pausedState}
                                        />
                                        {tabs.tab === 'FEED' && (
                                            <Feed
                                                {...refundOrder}
                                                {...orders}
                                                {...openFood}
                                                {...auth}
                                                {...acceptDialog}
                                                {...orderDialog}
                                                {...tabs}
                                                {...pausedState}
                                            />
                                        )}
                                        {tabs.tab === 'TABLE' && (
                                            <Order
                                                {...refundOrder}
                                                {...orders}
                                                {...openFood}
                                                {...auth}
                                                {...acceptDialog}
                                                {...orderDialog}
                                                {...tabs}
                                            />
                                        )}
                                        {tabs.tab === 'MENU' && (
                                            <MenuWrapper
                                                {...openFood}
                                                {...orders}
                                                {...auth}
                                            />
                                        )}
                                    </CreateOrderProvider>
                                </AutoAcceptProvider>
                            </OrderProvider>
                        </PrintProvider>
                    </MenuProvider>
                </PaymentProvider>
            </RestaurantProvider>

            {/* {tabs.tab === "MENU" &&  (<>{orders.template === "tuba" ? 
              (<MenuWrapper {...openFood} {...orders} {...auth} />) : 
              (<Menu {...openFood} {...orders} />)}</>)} */}
        </>
    );
}

function SnackbarCloseButton({ id }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(id)}>
            <IconClose />
        </IconButton>
    );
}

function App() {
    return (
        <ErrorBoundary>
            <SnackbarProvider
                dense
                preventDuplicate
                maxSnack={30}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                action={key => <SnackbarCloseButton key={key} id={key} />}
                disableWindowBlurListener={true}
                hideIconVariant={true}
                // iconVariant={{
                //   success: '✅',
                //   error: '✖️',
                //   warning: '⚠️',
                //   info: 'ℹ️',
                // }}
            >
                <MyApp />
            </SnackbarProvider>
        </ErrorBoundary>
    );
}

window.onerror = (msg, url, lineNo, columnNo, error) => {
    console.log('Error handled: ', msg, url, lineNo, columnNo, error);
    try {
        reportError(window.restauId, window.email, {
            msg,
            url,
            lineNo,
            columnNo,
            error,
        });
    } catch (e) {}
};

(function() {
    var promiseChain = Promise.resolve();

    var callbacks = {};

    var init = function() {
        window.webViewBridge = {
            /**
             * send message to the React-Native WebView onMessage handler
             * @param targetFunc - name of the function to invoke on the React-Native side
             * @param data - data to pass
             * @param success - success callback
             * @param error - error callback
             */
            send: function(targetFunc, data, success, error) {
                var msgObj = {
                    targetFunc: targetFunc,
                    data: data || {},
                };

                if (success || error) {
                    msgObj.msgId = guid();
                }

                var msg = JSON.stringify(msgObj);

                promiseChain = promiseChain
                    .then(function() {
                        return new Promise(function(resolve, reject) {
                            console.log('sending message ' + msgObj.targetFunc);

                            if (msgObj.msgId) {
                                callbacks[msgObj.msgId] = {
                                    onsuccess: success,
                                    onerror: error,
                                };
                            }

                            window.ReactNativeWebView.postMessage(msg);

                            resolve();
                        });
                    })
                    .catch(function(e) {
                        console.error('rnBridge send failed ' + e.message);
                    });
            },
        };

        window.addEventListener('message', function(e) {
            console.log('message received from react native', e.data);

            var message;
            try {
                message = JSON.parse(e.data);
            } catch (err) {
                console.error(
                    'failed to parse message from react-native ' + err
                );
                return;
            }

            //trigger callback
            if (message.args && callbacks[message.msgId]) {
                if (message.isSuccessfull) {
                    callbacks[message.msgId].onsuccess.apply(
                        null,
                        message.args
                    );
                } else {
                    callbacks[message.msgId].onerror.apply(null, message.args);
                }
                delete callbacks[message.msgId];
            } else {
                if (message.msgId && message.msgId.startsWith('NativeEvent')) {
                    //alert("Received event", message);
                    eventBus.dispatch(message.event, message.data);
                } else {
                    console.log('ignoring message since no callbacks or args');
                }
            }
        });
    };

    if (typeof window.webViewBridge === 'undefined') {
        init();
    }
})();

export default App;
