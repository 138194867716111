import React from 'react';
import { TableRow } from '.';
import { formatPrice } from '../../../../Data/FoodData';
import { getPrice } from '../../../../FoodDialog/FoodDialog';
import { TABLEROW_COLUMN_STYLE } from './TableRow';
import {
    pStyleH3,
    pStyleH4,
    pStyleText,
    pStyleTextItalic,
} from './TemplateStyles';
import { ModifierGroup } from './ModifierGroup';
import { getUniqueItemIdentifier } from './../../../../utils/orderUtil';
// const partialWidthFlex = {
//     flex: "0 80%"
// }
// const fullWidthFlex = {
//     flex: "0 100%"
// }

export default ({
    takeoutOrder,
    showPrices = true,
    highLightItem = true,
    drawLinesInBetween = false,
    showHeader = true,
}) => {
    const rowStyle = showPrices
        ? TABLEROW_COLUMN_STYLE.THREE_COLUMN
        : TABLEROW_COLUMN_STYLE.TWO_COLUMN_LEFT_ALIGHT;
    //Merging same items and adding quantities
    const mergedItemsByKey = Object.entries(takeoutOrder.items).reduce(
        (prev, [originalKey, curr]) => {
            const itemUniqKey = getUniqueItemIdentifier(curr);
            if (prev[itemUniqKey]) {
                prev[itemUniqKey].quantity += curr.quantity;
                if (
                    curr.addedBy &&
                    prev[itemUniqKey].addedByList.indexOf(curr.addedBy) < 0
                ) {
                    prev[itemUniqKey].addedByList.push(curr.addedBy);
                }
            } else {
                prev[itemUniqKey] = {
                    ...curr,
                    originalKey,
                    addedByList: curr.addedBy ? [curr.addedBy] : [],
                };
            }
            return prev;
        },
        {}
    );
    const mergedItems = Object.fromEntries(
        Object.values(mergedItemsByKey).map(item => [
            item.originalKey,
            {
                ...item,
                addedBy:
                    item.addedByList.length > 0
                        ? item.addedByList.join(',')
                        : null,
            },
        ])
    );

    return (
        <div
            style={{
                ...pStyleText,
                borderBottom: 'solid 1px',
                marginTop: '5px',
            }}
        >
            {showHeader && (
                <TableRow
                    cell1={'Qty'}
                    cell2={'Item'}
                    cell3={showPrices ? 'Total' : null}
                    borderBottom={true}
                    rowStyle={rowStyle}
                />
            )}
            {Object.entries(mergedItems)
                .filter(([itemId, item]) => item.quantity && item.quantity > 0)
                .map(([itemId, item], index) => (
                    <TableRow
                        key={'oi' + itemId}
                        cell1={
                            <div style={highLightItem ? pStyleH3 : pStyleText}>
                                {item.quantity}
                            </div>
                        }
                        cell2={
                            <div
                                style={{
                                    ...(highLightItem ? pStyleH4 : pStyleText),
                                    marginBottom: '20px',
                                }}
                            >
                                <div>{item.name}</div>
                                {typeof item.choice != 'undefined' && (
                                    <div
                                        style={
                                            highLightItem
                                                ? pStyleH4
                                                : pStyleText
                                        }
                                    >
                                        + {item.choice}
                                    </div>
                                )}
                                {typeof item.toppings != 'undefined' &&
                                    item.toppings
                                        .filter(t => t.checked)
                                        .map(t => (
                                            <div
                                                key={'tp' + itemId + t.id}
                                                style={
                                                    highLightItem
                                                        ? pStyleH4
                                                        : pStyleText
                                                }
                                            >
                                                + {t.name}
                                            </div>
                                        ))}
                                {typeof item.modifierGroup != 'undefined' && (
                                    <ModifierGroup
                                        item={item}
                                        style={
                                            highLightItem
                                                ? pStyleH4
                                                : pStyleText
                                        }
                                    />
                                )}

                                {typeof item.notes != 'undefined' && (
                                    <div
                                        style={
                                            highLightItem
                                                ? pStyleH3
                                                : pStyleText
                                        }
                                    >
                                        [Note] {item.notes}
                                    </div>
                                )}
                                {typeof item.addedBy != 'undefined' &&
                                    item.addedBy &&
                                    item.addedBy != 'Host' && (
                                        <div style={pStyleTextItalic}>
                                            ( {item.addedBy} )
                                        </div>
                                    )}
                            </div>
                        }
                        cell3={
                            showPrices ? (
                                <div style={pStyleText}>
                                    {formatPrice(getPrice(item) / 100)}
                                </div>
                            ) : null
                        }
                        borderTop={drawLinesInBetween && index != 0}
                    />
                ))}
        </div>
    );
    // return <div style={{...pStyleText, borderBottom: "solid 1px"}}>
    //     <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", borderBottom: "solid 1px"}}>
    //         <div style={{ ...(showPrices? partialWidthFlex : fullWidthFlex), display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
    //             <div style={{flex: "0 10%", marginBottom: "2px", textAlign: "left"}}>Qty</div>
    //             <div style={{flex: "0 90%", marginBottom: "2px", textAlign: "left"}}>Item</div>
    //         </div>
    //         {showPrices && <div style={{flex: "0 20%", marginBottom: "2px", textAlign: "right"}}>Total</div>}
    //     </div>
    //     {Object.entries(takeoutOrder.items)
    //         .filter(([itemId, item]) => item.quantity && item.quantity > 0)
    //         .map(([itemId, item], index) => (
    //             <div key={"oi"+itemId} style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between",
    //                     ...(drawLinesInBetween && index != 0 ? { borderTop: "solid 1px" } : {}),
    //                     marginTop: "5px"}}>
    //                 <div style={{ ...(showPrices? partialWidthFlex : fullWidthFlex), display: "flex", flexWrap: "wrap", justifyContent: "left"}}>
    //                     <div style={{flex: "0 10%", marginBottom: "2px", textAlign: "left", ...(highLightItem?pStyleH3:pStyleText)}}>{item.quantity}</div>
    //                     <div style={{flex: "0 90%", marginBottom: "2px", textAlign: "left", ...(highLightItem?pStyleH3:pStyleText)}}>
    //                         <div>{item.name}</div>
    //                         {typeof item.choice != "undefined" && (
    //                             <div>+ {item.choice}</div>
    //                         )}
    //                         {typeof item.toppings != "undefined" && item.toppings
    //                             .filter(t => t.checked)
    //                             .map(t => (<div key={"tp"+itemId+t.id}>+ {t.name}</div>))
    //                         }
    //                         {typeof item.notes != "undefined" && (
    //                             <div>[Note] {item.notes}</div>
    //                         )}
    //                     </div>
    //                 </div>
    //                 {showPrices && <div style={{flex: "0 20%", marginBottom: "2px", textAlign: "right"}}>{formatPrice(getPrice(item)/100)}</div>}
    //             </div>
    //         ))
    //     }
    // </div>
};
