import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { getInprogressCall } from '../../apis/getInprogressCall';
import { createTakeoutOrder, udpateTakeoutOrder } from '../../apis/placeOrder';
import { sendSms } from '../../apis/sendSms';

//import {useNotes} from "../Hooks/useNotes";
import {
    ORDER_TYPE,
    CreateOrderContext,
} from '../../contexts/CreateOrderContext';
import { RestaurantContext } from '../../contexts/RestaurantContext';
import { MenuContext } from '../../contexts/MenuContext';
import { formatPrice } from '../../Data/FoodData';
import { getPrice } from '../../FoodDialog/FoodDialog';
import Collapsible from '../../Menu/Collapsible';
import { RibbonButton } from '../../Navbar/Ribbon';
import { DetailItem, Title20px } from '../../Order/Feed';
import { ButtonContainerCenter, OrderContainer } from '../../Order/Order';
import { validateNumberKeyPress } from '../../utils/ValidationUtils';
import { filterPrivateFields } from '../../utils/Util';
import { CircleButton, SmallCircleButton } from '../common/CircleButton';
import AsyncProgressRibbonButton from './AsyncProgressRibbonButton';
import TipInput from './TipInput';
import UserTopItems from './UserTopItems';
import { pomegranateRed } from '../../Styles/colors';
import { PrintContext } from '../../contexts/PrintContext';
import ItemMetaData from '../../components/modifiers/ItemMetaData';
import Config from '../../config/Config';
import PhoneNumber from '../../Navbar/PhoneNumber';
import { TextField } from '@material-ui/core';
import ToggleGroup from '../../Navbar/ToggleGroup';
const { default: axios } = require('axios');

const orderTypes = [
    {
        value: 'To-go',
        label: 'To-go',
    },
    {
        value: 'Dine-in',
        label: 'Dine-in',
    },
];

export default function CreateOrder({
    setOpenFood,
    salesTax,
    defaultNumOfPrints,
    minCreditCardAmt,
    minCreditCardFees,
    enqueueSnackbar,
}) {
    const { restauId, restauName } = useContext(RestaurantContext);
    const { thirdPartyIntegrationConfig } = useContext(MenuContext);

    const {
        takeoutOrders,
        takeoutOrder,
        inProgressOrderType,
        inProgressTakeoutOrderIndex,
        setInProgressOrderType,
        setInProgressTakeoutOrderIndex,
        removeItem,
        setTip,
        setTogoOrDineIn,
        setPhoneNumber,
        setOrderUnderName,
        setEstimatedTimeInMins,
        setNoOfPrints,
        setUserDetails,
        startNewOrder,
        clearOrder,
    } = useContext(CreateOrderContext);

    const subTotal = getSubTotal(takeoutOrder.items);
    const tax = Math.round(subTotal * (salesTax / 100.0));

    const tipAmount =
        takeoutOrder.tip > 0 ? getTip(subTotal, takeoutOrder.tip) : 0;
    const creditCardFees = subTotal < minCreditCardAmt ? minCreditCardFees : 0;
    const total =
        subTotal == 0 ? 0 : subTotal + tax + tipAmount + creditCardFees;

    const orderUnderNameInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    //console.log("takeoutOrder: ", takeoutOrder, inProgressOrderType ,defaultNumOfPrints);
    //debugger;

    const { printOrderWithRetrySnackBar } = useContext(PrintContext);

    const submitOrder = async takeoutOrder => {
        // const orderObj = { restauId,
        //         items: takeoutOrder.items,
        //         tip: tipAmount,
        //         phoneNumber: takeoutOrder.phoneNumber,
        //         orderUnderName: takeoutOrder.orderUnderName,
        //         orderType: takeoutOrder._togoOrDineIn,
        //         customerType: "Phone",
        //         estimatedTimeInMins: takeoutOrder.estimatedTimeInMins,
        //         orderStatus: "WIP"
        //     };
        const orderObj = {
            restauId,
            ...filterPrivateFields(takeoutOrder),
            tip: tipAmount,
        };
        const token = await window.customerApp.auth().currentUser.getIdToken();
        let result = null;
        try {
            result = await (takeoutOrder.isUpdate
                ? udpateTakeoutOrder(token, orderObj)
                : createTakeoutOrder(token, orderObj));
            console.log('result', result);
        } catch (e) {
            enqueueSnackbar('Try again or contact support. Error:  ' + e, {
                variant: 'error',
            });
            return;
        }
        if (result.status != 200) {
            enqueueSnackbar(
                'Try again or contact support. Error:  ' + result.status,
                { variant: 'error' }
            );
            return;
        }
        if (takeoutOrder.isUpdate) {
            if (result.data.status == false) {
                enqueueSnackbar('Failed to udpate order. ' + result.data.msg, {
                    variant: 'error',
                });
                return;
            }
        } else {
            if (!result.data.orderNum) {
                console.log('Failed to created order. Result', result);
                enqueueSnackbar('Failed to create order.', {
                    variant: 'error',
                });
                return;
            }
        }

        printOrderWithRetrySnackBar({
            order: {
                ...takeoutOrder,
                tip: tipAmount,
                payment_subtotal: subTotal,
                payment_salestax: tax,
                minCreditCardFees: creditCardFees,
                payment_total: total,
                number: result.data.orderNum,
                created_timestamp: result.data.created_timestamp,
            },
            numCopies: 1,
        });
        /* .then((printResult) => {
                if(!printResult.success) {
                    const failedStations = printResult.errors.map(e => e.station).filter(e => e && e.length > 0);
                    enqueueSnackbar(
                        "Failed to print Order #"+result.data.orderNum + (failedStations.length > 0 ? (" at stations: " + failedStations.join(", ")) : ""), 
                        {variant:'error', persist: true}
                    );    
                }
            }).catch((error) => {
                console.error("Failed to print", error);
                enqueueSnackbar("Failed to print. Please reprint Order #"+result.data.orderNum, {variant:'error', persist: true});
            }); */

        //console.log("Print result2", await rsp);
        // printOrder({...takeoutOrder,
        //         id: result.data.orderId,
        //         number: result.data.orderNum,
        //         //orderType: takeoutOrder._togoOrDineIn,
        //         //customerType: "Phone",
        //         ...(result.data.updated_timestamp && {updated_timestamp: result.data.updated_timestamp}),
        //         created_timestamp: result.data.created_timestamp,
        //         payment_subtotal: subTotal,
        //         payment_salestax: tax,
        //         minCreditCardFees: result.data.minCreditCardFees,
        //         tip: tipAmount,
        //         payment_total: total
        //     },
        //     (typeof takeoutOrder.noOfPrints == "undefined" ? defaultNumOfPrints.numOfPrints : takeoutOrder.noOfPrints), takeoutOrder.isUpdate, showPrintPrice, restauName, false, null, null, enqueueSnackbar, ()=>{}, null,
        //     ()=>{enqueueSnackbar("Failed to print. Please reprint from Feed. Order #"+result.data.orderNum, {variant:'error'});});

        const isOrderCancelled =
            takeoutOrder.isUpdate &&
            Object.keys(takeoutOrder.items).length == 0;

        isOrderCancelled
            ? enqueueSnackbar(
                  'Order #' + result.data.orderNum + ' cancelled.',
                  { variant: 'success' }
              )
            : takeoutOrder.isUpdate
            ? enqueueSnackbar('Order #' + result.data.orderNum + ' udpated.', {
                  variant: 'success',
              })
            : enqueueSnackbar('Order #' + result.data.orderNum + ' accepted.', {
                  variant: 'success',
              });

        if (!isOrderCancelled) {
            const msgObj = {
                token,
                restauId,
                restauName,
                orderId: result.data.orderId,
                orderNumber: result.data.orderNum,
                phoneNumber: takeoutOrder.phoneNumber,
                orderUnderName: takeoutOrder.orderUnderName,
                customerType: takeoutOrder.customerType,
                estimatedTimeInMins: takeoutOrder.estimatedTimeInMins,
                deliveryScheduledForLater:takeoutOrder.deliveryScheduledForLater,
                deliveryScheduledDate: takeoutOrder.deliveryScheduledDate,
                deliveryScheduledTime: takeoutOrder.deliveryScheduledTime,
                orderType: takeoutOrder.orderType
                
            };
            const resultSms = await sendSms(msgObj);
            console.log('msgObj', msgObj, resultSms);
        }
        clearOrder();
    };
    async function syncMenuFromThirdParty() {
        const url = Config.sync_thirdparty_menu;
        const token = await window.customerApp.auth().currentUser.getIdToken();
        await axios
            .post(url, { restauId: restauId, headers: token })
            .then(response => {
                if (response.data.status === 'SUCCESS') {
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(response.data.message, {
                        variant: 'error',
                        persist: true,
                    });
                }
            })
            .catch(function(error) {
                enqueueSnackbar('Menu Sync is not successfull', {
                    variant: 'error',
                    persist: true,
                });
            });
    }

    return (
        <div onClick={() => setInProgressOrderType(ORDER_TYPE.TAKEOUT)}>
            <InputRow>
                {thirdPartyIntegrationConfig &&
                    thirdPartyIntegrationConfig.isSyncMenu && (
                        <AsyncProgressRibbonButton
                            onClick={async () => await syncMenuFromThirdParty()}
                        >
                            <b>{'Sync Menu from Clover'}</b>
                        </AsyncProgressRibbonButton>
                    )}
                <InputItem>
                    <h3>WalkIn/Phone Order</h3>
                </InputItem>
                <InputItem>
                    {takeoutOrders.map((eachOrder, index) => {
                        return (
                            <CircleButton
                                key={index}
                                variant={
                                    inProgressOrderType == ORDER_TYPE.TAKEOUT &&
                                    index == inProgressTakeoutOrderIndex
                                        ? 'success'
                                        : 'default'
                                }
                                onClick={() => {
                                    setInProgressTakeoutOrderIndex(index);
                                }}
                            >
                                {takeoutOrders[index].isUpdate
                                    ? takeoutOrders[index].number
                                    : index + 1}
                            </CircleButton>
                        );
                    })}
                    <CircleButton
                        selected={false}
                        onClick={() => {
                            startNewOrder();
                            phoneNumberInputRef.current.focus();
                        }}
                    >
                        +
                    </CircleButton>
                </InputItem>
            </InputRow>
            {inProgressOrderType == ORDER_TYPE.TAKEOUT && (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {takeoutOrder.isUpdate && <label>Phone</label>}
                            {!takeoutOrder.isUpdate && (
                                <AsyncProgressRibbonButton
                                    style={{
                                        display: 'inline-block',
                                        padding: '8px',
                                    }}
                                    onClick={async () => {
                                        const result = await getInprogressCall(
                                            restauId
                                        );
                                        if (result) {
                                            result.phoneNumber &&
                                                setPhoneNumber(
                                                    result.phoneNumber
                                                );
                                            if (result.userDetails) {
                                                result.userDetails
                                                    .orderUnderName &&
                                                    setOrderUnderName(
                                                        result.userDetails
                                                            .orderUnderName
                                                    );
                                                setUserDetails(
                                                    result.userDetails
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <b>Phone</b>
                                </AsyncProgressRibbonButton>
                            )}
                            <div style={{ maxWidth: 150 }}>
                                <PhoneNumber
                                    placeholder="phone#"
                                    label="Phone Number"
                                    value={takeoutOrder.phoneNumber || ''}
                                    onChange={e =>
                                        setPhoneNumber(e.target.value)
                                    }
                                    onKeyDown={e =>
                                        !(
                                            validateNumberKeyPress(e) ||
                                            e.key === 'Enter'
                                        ) && e.preventDefault()
                                    }
                                    onKeyPress={e => {
                                        e.key === 'Enter' &&
                                            orderUnderNameInputRef.current.focus();
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ maxWidth: 150 }}>
                            <TextField
                                // fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                placeholder="Name/ Initials"
                                type="text"
                                variant="outlined"
                                margin="dense"
                                size="small"
                                inputRef={orderUnderNameInputRef}
                                value={takeoutOrder.orderUnderName || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e =>
                                    setOrderUnderName(e.target.value)
                                }
                                onKeyPress={e => {
                                    e.key === 'Enter' &&
                                        orderUnderNameInputRef.current.blur();
                                }}
                            />
                        </div>
                        <div>
                            <ToggleGroup
                                name="order_type"
                                options={orderTypes}
                                selected={takeoutOrder._togoOrDineIn}
                                onClick={setTogoOrDineIn}
                            />
                        </div>
                    </div>
                    {(!takeoutOrder.items || takeoutOrder.items.length == 0) &&
                    !takeoutOrder.isUpdate ? (
                        <>
                            <Title20px>
                                Select item from menu to Order.
                            </Title20px>
                            {(takeoutOrders.length > 1 ||
                                (takeoutOrder.items &&
                                    takeoutOrder.items.length > 0) ||
                                (takeoutOrder.phoneNumber &&
                                    takeoutOrder.phoneNumber.length > 0) ||
                                (takeoutOrder.orderUnderName &&
                                    takeoutOrder.orderUnderName.length >
                                        0)) && (
                                <ButtonContainerCenter>
                                    <RibbonButton onClick={clearOrder}>
                                        <b>Cancel</b>
                                    </RibbonButton>
                                </ButtonContainerCenter>
                            )}
                        </>
                    ) : (
                        <>
                            <Title20px>Items to be ordered: </Title20px>
                            {takeoutOrder.items.map((orderItem, index) => (
                                <OrderContainer
                                    key={index + '-' + orderItem.id}
                                    editable
                                >
                                    <OrderItem
                                        onClick={() => {
                                            setOpenFood({
                                                ...orderItem,
                                                index,
                                            });
                                        }}
                                    >
                                        <Title20px>{orderItem.name}</Title20px>
                                        <Title20px>
                                            {orderItem.quantity}
                                        </Title20px>
                                        <div>
                                            <span
                                                role="img"
                                                aria-label="edit"
                                                style={{ color: '#27ae60' }}
                                            >
                                                <i className="fas fa-edit" />
                                            </span>
                                        </div>
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                removeItem(index);
                                            }}
                                        >
                                            <span
                                                role="img"
                                                aria-label="delete"
                                                style={{ color: '#27ae60' }}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </span>
                                        </div>
                                        <RightAlignPrice>
                                            {formatPrice(
                                                getPrice(orderItem) / 100
                                            )}
                                        </RightAlignPrice>
                                    </OrderItem>
                                    <ItemMetaData item={orderItem} />
                                </OrderContainer>
                            ))}
                            <Collapsible
                                trigger={
                                    <BillItem>
                                        <div>Total</div>
                                        <RightAlignPrice>
                                            {formatPrice(total / 100)}
                                        </RightAlignPrice>
                                    </BillItem>
                                }
                                open={false}
                                triggerStyle={{
                                    padding: '5px 5px 5px 15px',
                                    marginTop: '5px',
                                }}
                            >
                                <OrderContainerEditableNoColor>
                                    <BillItem>
                                        <div>Subtotal</div>
                                        <RightAlignPrice>
                                            {formatPrice(subTotal / 100)}
                                        </RightAlignPrice>
                                    </BillItem>
                                    {minCreditCardAmt &&
                                    subTotal < minCreditCardAmt ? (
                                        <BillItem>
                                            <div>
                                                Credit Card fees <br /> (order
                                                &lt;{' '}
                                                {formatPrice(
                                                    minCreditCardAmt / 100
                                                )}
                                                )
                                            </div>
                                            <RightAlignPrice>
                                                {formatPrice(
                                                    minCreditCardFees / 100
                                                )}
                                            </RightAlignPrice>
                                        </BillItem>
                                    ) : (
                                        ''
                                    )}
                                    <BillItem>
                                        <div>Tax</div>
                                        <RightAlignPrice>
                                            {formatPrice(tax / 100)}
                                        </RightAlignPrice>
                                    </BillItem>
                                    <BillItem>
                                        <div>Tip</div>
                                        <div />
                                        <TipInput
                                            {...{
                                                tip: takeoutOrder.tip,
                                                setTip,
                                            }}
                                        />
                                        <RightAlignPrice>
                                            {formatPrice(tipAmount / 100)}
                                        </RightAlignPrice>
                                    </BillItem>
                                    <BillItem>
                                        <div>Total</div>
                                        <RightAlignPrice>
                                            {formatPrice(total / 100)}
                                        </RightAlignPrice>
                                    </BillItem>
                                    {takeoutOrder.total_amount_paid && (
                                        <BillItem>
                                            <div>Amount Paid</div>
                                            <RightAlignPrice>
                                                {formatPrice(
                                                    takeoutOrder.total_amount_paid /
                                                        100
                                                )}
                                            </RightAlignPrice>
                                        </BillItem>
                                    )}
                                </OrderContainerEditableNoColor>
                            </Collapsible>
                            <InputRow>
                                <InputItem>
                                    <label>ETA (mins):</label>
                                </InputItem>
                                <InputItem>
                                    {ESTIMATED_WAIT_MINUTES.map(min => (
                                        <SmallCircleButton
                                            key={'min' + min}
                                            selected={
                                                min ===
                                                takeoutOrder.estimatedTimeInMins
                                            }
                                            onClick={() =>
                                                setEstimatedTimeInMins(min)
                                            }
                                        >
                                            {min == 0 ? '-' : min}
                                        </SmallCircleButton>
                                    ))}
                                </InputItem>
                            </InputRow>
                            <InputRow>
                                <InputItem>
                                    <label>Number of prints:</label>
                                    <input
                                        type="radio"
                                        id="print0"
                                        name="noOfPrints"
                                        value="0"
                                        checked={
                                            (typeof takeoutOrder.noOfPrints ==
                                                'undefined' &&
                                                defaultNumOfPrints.numOfPrints ==
                                                    0) ||
                                            0 === takeoutOrder.noOfPrints
                                        }
                                        onChange={() =>
                                            console.log('Changed!!') ||
                                            setNoOfPrints(0)
                                        }
                                    />
                                    <label>0</label>
                                    <input
                                        type="radio"
                                        id="print1"
                                        name="noOfPrints"
                                        value="1"
                                        checked={
                                            (typeof takeoutOrder.noOfPrints ==
                                                'undefined' &&
                                                defaultNumOfPrints.numOfPrints ==
                                                    1) ||
                                            1 === takeoutOrder.noOfPrints
                                        }
                                        onChange={() => setNoOfPrints(1)}
                                    />
                                    <label>1</label>
                                    {window.ReactNativeWebView && (
                                        <>
                                            <input
                                                type="radio"
                                                id="print2"
                                                name="noOfPrints"
                                                value="2"
                                                checked={
                                                    (typeof takeoutOrder.noOfPrints ==
                                                        'undefined' &&
                                                        defaultNumOfPrints.numOfPrints ==
                                                            2) ||
                                                    2 ===
                                                        takeoutOrder.noOfPrints
                                                }
                                                onChange={() =>
                                                    setNoOfPrints(2)
                                                }
                                            />
                                            <label>2</label>
                                        </>
                                    )}
                                </InputItem>
                            </InputRow>
                            <ButtonContainerCenter>
                                <AsyncProgressRibbonButton
                                    color={
                                        takeoutOrder.isUpdate
                                            ? pomegranateRed
                                            : null
                                    }
                                    onClick={() => submitOrder(takeoutOrder)}
                                >
                                    <b>
                                        {takeoutOrder.isUpdate
                                            ? 'Update order'
                                            : 'Place order'}
                                    </b>
                                </AsyncProgressRibbonButton>
                                {takeoutOrder.isUpdate && (
                                    <AsyncProgressRibbonButton
                                        color={pomegranateRed}
                                        onClick={async () => {
                                            if (
                                                window.confirm(
                                                    'Delete the order?'
                                                )
                                            ) {
                                                await submitOrder({
                                                    ...takeoutOrder,
                                                    items: [],
                                                });
                                            }
                                        }}
                                    >
                                        <b>{'Cancel order'}</b>
                                    </AsyncProgressRibbonButton>
                                )}
                                <RibbonButton onClick={clearOrder}>
                                    <b>Clear</b>
                                </RibbonButton>
                            </ButtonContainerCenter>
                        </>
                    )}
                    <UserTopItems
                        userDetails={takeoutOrder._userDetails}
                        setOpenFood={setOpenFood}
                    />
                </div>
            )}
        </div>
    );
}

const ESTIMATED_WAIT_MINUTES = [0, 10, 15, 20, 25, 30, 35, 40, 45, 60];

function getTip(value, tipPercentArg) {
    if (tipPercentArg) {
        var ret = (value / 100.0) * parseFloat(tipPercentArg);
        ret = Math.round(ret);
        return ret;
    }
    return 0;
}
function getSubTotal(items) {
    if (!items || items.length == 0) return 0;
    const subtotal = items.reduce((total, item) => {
        return total + getPrice(item);
    }, 0);
    return subtotal;
}

const InputRow = styled.div`
    //display: inline-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    & div > input[type='radio'] {
        margin-right: 5px;
        margin-top: auto;
    }
    & div > label {
        margin-right: 5px;
    }
`;
const InputItem = styled.div`
    //display: inline-block;
    margin-right: 20px;
    margin-bottom: 5px;
`;
const StyledInput = styled.input`
    padding: 4px 2px;
`;

const OrderItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 150px 30px 35px 35px 60px;
    justify-content: space-between;
`;

const BillItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: auto 60px;
    justify-content: space-between;
`;

const RightAlignPrice = styled.div`
    text-align: right;
    margin-right: 25px;
`;

const OrderContainerEditableNoColor = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
`;
