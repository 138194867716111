import React, { useContext, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogShadow,
    DialogFooter,
    ConfirmButton,
} from '../FoodDialog/FoodDialog';
import styled from 'styled-components';
import Config from '../config/Config';
import { getDateTime } from '../Hooks/useOrders';
import { getSubTotal } from './Order';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CircleButton } from '../components/common/CircleButton';
import { getQsrTableIdLabel } from '../utils/Util';
import { PrintContext } from '../contexts/PrintContext';

const { default: axios } = require('axios');

export const ComboInput = styled.select`
    margin-left: 7px;
    margin-right: 6px;
    height: 35px;
    width: 60px;
`;
export function AcceptDialog({
    defaultNumOfPrints,
    acceptOrderDialog,
    setAcceptOrderDialog,
    enqueueSnackbar,
    showPrintPrice,
}) {
    const [pending, setPending] = useState(false);
    const [hour, setHour] = useState('0');
    const [minute, setMinute] = useState('15');
    const { printOrderWithRetrySnackBar } = useContext(PrintContext);

    let waitTime = { hour: '0', minute: '15' };
    async function closeOrder() {
        setAcceptOrderDialog({ close: true });
    }
    async function acceptOrder(e) {
        e.preventDefault();
        e.stopPropagation();
        //setPending(true);
        setPending(acceptOrderDialog.takeoutOrder.id);
        try {
            if (
                acceptOrderDialog.takeoutOrder.phoneNumber &&
                acceptOrderDialog.takeoutOrder.customerType !== 'QSR-Dine-In'
            ) {
                var url = Config.accept_sms_api;
                waitTime.hour = hour;
                waitTime.minute = minute;
                const token = await window.customerApp
                    .auth()
                    .currentUser.getIdToken();
                const resp = await axios.post(url, {
                    restauId: acceptOrderDialog.restauId,
                    restauName: acceptOrderDialog.restauName,
                    orderNum: acceptOrderDialog.takeoutOrder.number,
                    orderUnderName:
                        acceptOrderDialog.takeoutOrder.orderUnderName,
                    orderId: acceptOrderDialog.takeoutOrder.id,
                    phoneNumber: acceptOrderDialog.takeoutOrder.phoneNumber,
                    customerType: acceptOrderDialog.takeoutOrder.customerType,
                    isScheduledOrder: isScheduledOrder(),
                    scheduledDate: getScheduledDate(),
                    waitTime: waitTime,
                    deliveryScheduledForLater: acceptOrderDialog.takeoutOrder.deliveryScheduledForLater,
                    deliveryScheduledDate: acceptOrderDialog.takeoutOrder.deliveryScheduledDate,
                    deliveryScheduledTime: acceptOrderDialog.takeoutOrder.deliveryScheduledTime,
                    orderType: acceptOrderDialog.takeoutOrder.orderType,
                    headers: token,
                });
            } else {
                console.log(
                    'Not calling send_accept_sms api',
                    acceptOrderDialog.takeoutOrder.phoneNumber,
                    acceptOrderDialog.takeoutOrder.customerType
                );
            }
            var estimatedTimeInMins =
                parseInt(waitTime['hour']) * 60 + parseInt(waitTime['minute']);
            const database = window.customerApp.database();
            let updates = {};
            updates[
                'takeout_orders/' +
                    acceptOrderDialog.restauId +
                    '/' +
                    acceptOrderDialog.takeoutOrder.id +
                    '/status'
            ] = 'WIP';
            updates[
                'takeout_orders/' +
                    acceptOrderDialog.restauId +
                    '/' +
                    acceptOrderDialog.takeoutOrder.id +
                    '/accepted_timestamp'
            ] = getDateTime();
            if(!isScheduledOrder()){
                updates[
                    'takeout_orders/' +
                        acceptOrderDialog.restauId +
                        '/' +
                        acceptOrderDialog.takeoutOrder.id +
                        '/estimated_time_in_minutes'
                ] = estimatedTimeInMins;
            }

            await database.ref().update(updates);
            enqueueSnackbar(
                'Accepted order # ' +
                    acceptOrderDialog.takeoutOrder.number +
                    '.',
                { variant: 'success' }
            );
        } catch (error) {
            enqueueSnackbar(
                'Error in Accepting order # ' +
                    acceptOrderDialog.takeoutOrder.number +
                    '.' +
                    error,
                { variant: 'error' }
            );
        }

        //setPending(false);
        //printOrder(acceptOrderDialog.takeoutOrder, defaultNumOfPrints.numOfPrints, false, showPrintPrice, acceptOrderDialog.restauName, false, null, null, enqueueSnackbar, setPending);
        printOrderWithRetrySnackBar({
            order: acceptOrderDialog.takeoutOrder,
            numCopies: defaultNumOfPrints.numOfPrints,
        }).finally(() => {
            setPending(false);
        });
        setAcceptOrderDialog({ close: true });
    }
    // function handleHourChange(event) {
    //   waitTime.hour = event.target.value;
    // }
    // function handleMinuteChange(event) {
    //   waitTime.minute = event.target.value;
    // }

    function isScheduledOrder() {
        if (acceptOrderDialog.isScheduledOrder) {
            return true;
        } else {
            return false;
        }
    }
    function getScheduledDate() {
        if (isScheduledOrder()) {
            return (
                acceptOrderDialog.takeoutOrder.scheduledDate +
                ' ' +
                acceptOrderDialog.takeoutOrder.scheduledTime
            );
        } else {
            return null;
        }
    }
    function handleHourChange(val) {
        waitTime.hour = val;
        setHour(val);
    }
    function handleMinuteChange(val) {
        waitTime.minute = val;
        setMinute(val);
    }

    // function handlePrintChange(event) {
    //   setNumOfPrints(+event.target.value);
    //   //waitTime.minute = +event.target.value;
    // }

    function close() {
        setAcceptOrderDialog({ close: true });
    }
    var mins = [
        '0',
        '5',
        '10',
        '15',
        '20',
        '25',
        '30',
        '35',
        '40',
        '45',
        '50',
        '55',
    ];
    var hrs = ['0', '1', '2', '3'];
    return acceptOrderDialog && !acceptOrderDialog.close ? (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                <DialogContent>
                    <h3>
                        Accept Order # {acceptOrderDialog.takeoutOrder.number}{' '}
                        {getQsrTableIdLabel(
                            acceptOrderDialog.takeoutOrder,
                            ' for Table-'
                        )}{' '}
                    </h3>
                    Your order is expected to be 
                    {isScheduledOrder() &&
                        ' ready on ' +
                            acceptOrderDialog.takeoutOrder.scheduledDate +
                            ' ' +
                            acceptOrderDialog.takeoutOrder.scheduledTime}
                    {acceptOrderDialog.takeoutOrder.orderType === 'Delivery' && acceptOrderDialog.takeoutOrder.deliveryScheduledForLater &&
                         ' deliver on ' +
                         acceptOrderDialog.takeoutOrder.deliveryScheduledDate +
                         ' ' +
                         acceptOrderDialog.takeoutOrder.deliveryScheduledTime
                    }
                    {acceptOrderDialog.takeoutOrder.orderType === 'Delivery' && !acceptOrderDialog.takeoutOrder.deliveryScheduledForLater &&
                        ' deliver '
                    }
                    <br /> <br />
                    {!isScheduledOrder() && !acceptOrderDialog.takeoutOrder.deliveryScheduledForLater && (
                        <>
                            in
                            <h3>Hours</h3>
                            {hrs.map(hr => (
                                <CircleButton
                                    key={'hr' + hr}
                                    selected={hr === hour}
                                    onClick={() => handleHourChange(hr)}
                                >
                                    {hr}
                                </CircleButton>
                            ))}
                            <h3>Minutes</h3>
                            {mins.map(min => (
                                <CircleButton
                                    key={'min' + min}
                                    selected={min === minute}
                                    onClick={() => handleMinuteChange(min)}
                                >
                                    {min}
                                </CircleButton>
                            ))}
                            {/* Hours
          <ComboInput name="hour" id="hour"  onChange={(e)=>{handleHourChange(e)}} >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="2">3</option>
           </ComboInput>
           Minutes
           <ComboInput name="minute" id="minute"  onChange={handleMinuteChange} >
             
            <option value="10">10</option>
            <option value="15" selected="selected">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="35">35</option>
            <option value="40">40</option>
            <option value="45">45</option>
            <option value="50">50</option>

           </ComboInput> */}
                        </>
                    )}
                    <br /> <br />
                    Number of print copies
                    <ComboInput
                        name="print"
                        id="print"
                        value={defaultNumOfPrints.numOfPrints}
                        onChange={defaultNumOfPrints.onChange}
                    >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        {window.ReactNativeWebView && (
                            <option value="2">2</option>
                        )}
                        {/* {numOfPrints === 0 ? (<option value="0" selected>0</option>) : (<option value="0">0</option>) }
              {numOfPrints === 1 ? (<option value="1" selected>1</option>) : (<option value="1">1</option>) } */}
                        {/* {numOfPrints === 2 ? (<option value="2" selected>2</option>) : (<option value="2">2</option>) }
              {numOfPrints === 3 ? (<option value="3" selected>3</option>) : (<option value="3">3</option>) } */}
                    </ComboInput>
                </DialogContent>
                <DialogFooter>
                    <ConfirmButton
                        disabled={pending === acceptOrderDialog.takeoutOrder.id}
                        onClick={e => {
                            acceptOrder(e);
                        }}
                    >
                        {pending === acceptOrderDialog.takeoutOrder.id ? (
                            <CircularProgress
                                size={20}
                                style={{ color: 'black' }}
                            />
                        ) : (
                            <>Confirm</>
                        )}
                    </ConfirmButton>

                    <ConfirmButton
                        onClick={() => {
                            close();
                        }}
                    >
                        Close
                    </ConfirmButton>
                </DialogFooter>
            </Dialog>
        </>
    ) : (
        <div />
    );
}
