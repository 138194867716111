import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import FoodItem from './FoodItem';
import { FoodGrid } from './MenuStyles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    heading: {
        flexShrink: 0,
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
    },
}));

export default function Section(props) {
    const classes = useStyles();
    const {
        setOpenFood,
        showCount,
        showDescription,
        showPrice,
        showChoice,
        showToppings,
        showNotes,
        open,
        defaultExpanded,
        section,
        onChange,
        onAddToOrder,
    } = props;

    const count = showCount
        ? `(${section.foods ? section.foods.length : ''})`
        : '';
    return (
        <div
            id={`section-${section.id}`}
            className={classes.root}
            style={{
                margin: '10px 0',
            }}
        >
            <Accordion
                expanded={open}
                defaultExpanded={defaultExpanded}
                onChange={() => onChange(section.id)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${section.name}-content`}
                    id={`${section.id}-header`}
                    style={{ backgroundColor: '#ddd' }}
                >
                    <Typography className={classes.heading}>
                        {section.name} {count}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FoodGrid>
                        {section.foods &&
                            section.foods.map((food, index) => {
                                return (
                                    <FoodItem
                                        key={`${section.id}-${
                                            food.id
                                        }-${index}`}
                                        food={food}
                                        showPrice={showPrice}
                                        showDescription={showDescription}
                                        showChoice={showChoice}
                                        showToppings={showToppings}
                                        showNotes={showNotes}
                                        setOpenFood={setOpenFood}
                                        onAddToOrder={onAddToOrder}
                                    />
                                );
                            })}
                    </FoodGrid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
