import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogBannerName,
    DialogContent,
    DialogFooter,
    DialogShadow,
} from '../../FoodDialog/FoodDialog';
import { CheckboxLabel, Title20px } from '../../Order/Feed';
import { Title } from '../../Styles/title';
import { useHoldStatus } from '../../Hooks/useHoldStatus';

import AsyncProgressRibbonButton from '../CreateOrder/AsyncProgressRibbonButton';
import { RestaurantContext } from '../../contexts/RestaurantContext';
import { PrintContext } from '../../contexts/PrintContext';
import { RibbonButton } from '../../Navbar/Ribbon';
import { Toppings } from '../../FoodDialog/Toppings';
import { useToppings } from '../../Hooks/useToppings';
import styled from 'styled-components';

export default function ReprintDialog({ onClose, order, isItemSelect }) {
    const { enqueueSnackbar } = useSnackbar();
    const rePrintStatus = useHoldStatus({});

    const { printOrderWithRetrySnackBar } = useContext(PrintContext);
    const Title18px = styled(Title)`
        font-size: 18px;
    `;
    const OrderContainer = styled.div`
        padding: 5px 0px;
        border-bottom: 1px solid rgb(39, 30, 96, 0.5);
        ${({ editable }) =>
            editable
                ? `
            &:hover {
            cursor: pointer;
            background-color: #e7e7e7;
            }
        `
                : `
            pointer-events: none; 
        `}
    `;

    const OrderItem = styled.div`
        padding: 5px 0px;
        display: grid;
        grid-template-columns: 125px 30px 35px;
        justify-content: space-between;
        font-size: 10px;
    `;
    const { settings: { stationPrintingEnabled = false } = {} } = useContext(
        RestaurantContext
    );
    let stations = [];
    let stationsMap = {};
    let tableId = '';

    for (var key of Object.keys(order.items)) {
        tableId = order.items[key].tableId;
        if (!order.items[key] || !order.items[key].station) {
            continue;
        }
        for (let s of order.items[key].station) {
            stationsMap[s] = true;
        }
    }
    let checked = false;
    if (isItemSelect) {
        checked = true;
    }
    for (const s in stationsMap) {
        stations.push({ id: s, name: s, checked: checked });
    }

    const toppingsHook = useToppings(stations);
    async function printAllSelection() {
        for (let key of Object.keys(order.items)) {
            await rePrintStatus.changeHoldStatus(key);
        }
    }
    async function reprint() {
        let filterStations = {};
        for (let s of toppingsHook.toppings) {
            if (s.checked) {
                filterStations[s.name] = true;
            }
        }
        let newOrder = JSON.parse(JSON.stringify(order));

        if (
            isItemSelect &&
            rePrintStatus &&
            Object.keys(rePrintStatus).length > 0
        ) {
            let itemSelected = false;

            for (let key of Object.keys(newOrder.items)) {
                if (rePrintStatus.getHoldStatus(key)) {
                    itemSelected = true;
                }
                if (!rePrintStatus.getHoldStatus(key)) {
                    delete newOrder.items[key];
                }
            }
            if (!itemSelected) {
                alert('Please select items to be printed');
                return false;
            }
        }

        newOrder.tableId = tableId;
        newOrder.isDineIn = true;
        const printResult = await printOrderWithRetrySnackBar({
            order: newOrder,
            reprint: true,
            filterStations: Object.keys(filterStations),
            onSuccess: () => {
                enqueueSnackbar('Reprint successful for Table-' + tableId, {
                    variant: 'success',
                });
                onClose();
            },
        });
        /* if (!printResult.success) {
            const failedStations = printResult.errors.map(e => e.station).filter(e => e && e.length > 0);
            let errorMessage = "Failed to print for Table-" + tableId + (failedStations.length > 0 ? (" at stations: " + failedStations.join(", ")) : "");
            enqueueSnackbar(errorMessage, { variant: 'error', persist: true });
            alert(errorMessage);
            return;
        } else {
            enqueueSnackbar("Reprint successful for Table-" + tableId, { variant: 'success' });
            onClose();
            return;
        } */
    }

    return (
        <>
            <DialogShadow onClick={onClose} />

            <Dialog>
                <DialogBannerName>
                    Reprint for Table :{tableId}
                </DialogBannerName>
                <DialogContent>
                    {!stationPrintingEnabled ? (
                        <></>
                    ) : (
                        <Toppings
                            {...toppingsHook}
                            toppingslabel={
                                'Select stations you want to reprint'
                            }
                        />
                    )}

                    {isItemSelect && (
                        <OrderContainer key={key} editable>
                            <OrderItem>
                                <Title18px>Item</Title18px>
                                <Title18px>Quantity</Title18px>
                                <Title18px>
                                    Print
                                    {/* <input  id="printAll"
                              type="checkbox"
                              onClick={e=> {
                                  
                                   printAllSelection();
                                   console.log('checkbox clicked');
                                
                              }}
                            />*/}
                                </Title18px>
                            </OrderItem>
                        </OrderContainer>
                    )}
                    {isItemSelect &&
                        Object.keys(order.items).length > 0 &&
                        Object.keys(order.items).map((key, index) => (
                            <OrderContainer key={key} editable>
                                <OrderItem>
                                    <Title18px>
                                        {order.items[key].name}
                                    </Title18px>
                                    <Title18px>
                                        {order.items[key].quantity}
                                    </Title18px>
                                    <CheckboxLabel
                                        style={{ cursor: 'default' }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={rePrintStatus.getHoldStatus(
                                                key
                                            )}
                                            onClick={e => {
                                                e.stopPropagation();
                                                rePrintStatus.changeHoldStatus(
                                                    key
                                                );
                                            }}
                                        />
                                        <span />
                                    </CheckboxLabel>
                                </OrderItem>
                            </OrderContainer>
                        ))}
                </DialogContent>
                <DialogFooter>
                    <AsyncProgressRibbonButton
                        style={{
                            width: '200px',
                            padding: '10px 20px 10px 20px',
                        }}
                        onClick={() => reprint()}
                    >
                        <b>Reprint</b>
                    </AsyncProgressRibbonButton>
                    <RibbonButton onClick={async () => onClose()}>
                        <b>Close</b>
                    </RibbonButton>
                </DialogFooter>
            </Dialog>
        </>
    );
}
