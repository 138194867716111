export const pStyleH1 = {
    fontSize: '50px',
    fontWeight: 900, //"bold",
    letterSpacing: '2px', // 0.05em,
};
export const pStyleH2 = {
    fontSize: '50px',
    fontWeight: 300, //"lighter"
    letterSpacing: '2px', // 0.05em,
};
export const pStyleH3 = {
    fontSize: '35px',
    fontWeight: 900, //bolder
    letterSpacing: '2px', // 0.05em,
};
export const pStyleH4 = {
    fontSize: '35px',
    fontWeight: 300, //"normal"
    letterSpacing: '2px', // 0.05em,
};
export const pStyleText = {
    fontSize: '28px',
    fontWeight: 300, //"lighter"
    letterSpacing: '2px', // 0.05em,
};
export const pStyleTextItalic = {
    fontSize: '28px',
    fontWeight: 300, //"lighter"
    letterSpacing: '2px', // 0.05em,
    fontStyle: 'italic',
};
