import React, { useState } from 'react';
import { SmallCircleButton } from './CircleButton';

const ESTIMATED_WAIT_MINUTES = [1, 2, 3, 4, 5, 6, 7];
export default function ScheduledDaysInput({
    estimatedTimeInMins,
    setEstimatedTimeInMins,
}) {
    const defaultOrEstimated =
        estimatedTimeInMins && estimatedTimeInMins >= 0
            ? estimatedTimeInMins
            : 0;
    return (
        <>
            {ESTIMATED_WAIT_MINUTES.map(min => (
                <SmallCircleButton
                    key={min}
                    selected={min === defaultOrEstimated}
                    onClick={() => setEstimatedTimeInMins(min)}
                >
                    {min == 0 ? '-' : min}
                </SmallCircleButton>
            ))}
        </>
    );
}
