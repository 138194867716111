import Config from '../config/Config';
import { post } from './default';

export function createTakeoutOrder(
    token,
    {
        restauId,
        items,
        tip,
        phoneNumber,
        orderUnderName,
        orderType,
        customerType,
        checkinId,
        estimatedTimeInMins,
        orderStatus,
    }
) {
    //console.log("Creating Order for:", {restauId, items, tip, phoneNumber, orderUnderName, orderType, customerType, checkinId, estimatedTimeInMins, orderStatus, accepted_timestamp});
    const url = Config.create_takeout_order;
    return post(url, {
        headers: token,
        restauId,
        items,
        tip,
        phoneNumber,
        orderUnderName,
        orderType,
        customerType,
        checkinId,
        estimatedTimeInMins,
        orderStatus,
    });
}
export function udpateTakeoutOrder(
    token,
    {
        restauId,
        id,
        number,
        items,
        tip,
        phoneNumber,
        orderUnderName,
        orderType,
        estimatedTimeInMins,
        orderStatus,
    }
) {
    const url = Config.update_takeout_order;
    return post(url, {
        headers: token,
        restauId,
        id,
        number,
        items,
        tip,
        phoneNumber,
        orderUnderName,
        orderType,
        estimatedTimeInMins,
        orderStatus,
    });
}
export function addTipTakeoutOrder(token, { restauId, id, tipInCents }) {
    const url = Config.add_takeout_order_tip;
    return post(url, {
        headers: token,
        restauId,
        orderId: id,
        tipInCents,
    }).then(result => {
        if (result.status == 200 && result.data && result.data.status) {
            return result.data;
        } else {
            throw new Error(
                result.data
                    ? result.data
                    : 'Failed with status: ' + result.status
            );
        }
    });
}
