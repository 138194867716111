import { Button, IconButton, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close } from '@material-ui/icons';
import Email from '@material-ui/icons/Email';
import Smartphone from '@material-ui/icons/Smartphone';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import EstimatedWaitTimeInput from '../components/common/EstimatedWaitTimeInput';
import ScheduledDaysInput from '../components/common/ScheduledDaysInput';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import Config from '../config/Config';
import { MenuContext } from '../contexts/MenuContext';
import { PaymentContext } from '../contexts/PaymentContext';
import { RestaurantContext } from '../contexts/RestaurantContext';
import {
    DialogContent,
    DialogFooter,
    DialogShadow,
} from '../FoodDialog/FoodDialog';
import { useChoice } from '../Hooks/useChoice';
import { isDineInMode, isTakeoutMode } from '../Hooks/useOrders';
import { useToppings } from '../Hooks/useToppings';
import PriceField from './PriceField';
import { RibbonButton } from './Ribbon';
import SettingToggle from './SettingToggle';
import TablesGrid from './TablesGrid';
import ToggleGroup from './ToggleGroup';

export const Dialog = styled.div`
    width: calc(100% - 30px);
    background-color: white;
    position: fixed;
    top: 15px;
    z-index: 11;
    max-height: calc(100% - 30px);
    left: 15px;
    display: flex;
    flex-direction: column;

    // Add this part
    @media (max-width: 720px) {
        width: 100%;
        left: 0px;
        z-index: 12;
    }
`;

export const DialogBanner = styled.div`
    min-height: 200px;
    padding: 20px;
    // margin-bottom: 20px;
    ${({ img }) =>
        img ? `background-image: url(${img});` : `min-height: auto;`}
    background-position: center;
    background-size: cover;
    border-bottom: 1px solid #ccc;
    position: relative;
`;
const CloseButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const SettingsDialogContent = styled(DialogContent)`
    padding-top: 20px;
`;

const SettingsSection = styled.div`
    margin-bottom: 20px;
`;

const modeOptions = [
    {
        value: 'TAKEOUT',
        label: 'Takeout / QSR',
    },
    {
        value: 'DINE_IN',
        label: 'FSR Dine-in',
    },
    {
        value: 'BOTH',
        label: 'Both',
    },
];

const numCopiesWeb = [
    {
        value: 0,
        label: 0,
    },
    {
        value: 1,
        label: 1,
    },
];

const numCopiesRn = [
    {
        value: 0,
        label: 0,
    },
    {
        value: 1,
        label: 1,
    },
    {
        value: 2,
        label: 2,
    },
];

const { default: axios } = require('axios');

export const RadioInput = styled.input`
    cursor: pointer;
    margin-right: 10px;
`;

function SettingsContainer({
    loggedInUser,
    loggedIn,
    restauId,
    tableIds,
    setTableIds,
    tableIdsAll,
    toggleShowSettings,
    playSound,
    setPlaySound,
    dispatchOrder,
    operationMode,
    setOperationMode,
    softwareVersion,
    showPrintPrice,
    setShowPrintPrice,
    setUserSettings,
    defaultNumOfPrints,
    enqueueSnackbar,
    showReadFeature,
    setShowReadFeature,
    autoAcceptConfig,
    setAutoAcceptConfig,
    autoAcceptDineInConfig,
    setAutoAcceptDineInConfig,
    prepTimeConfig,
    setPrepTimeConfig,
    scheduledOrderConfig,
    setScheduledOrderConfig,
    deliveryConfig,
    setDeliveryConfig,
    payLaterToken,
    stationPrintingEnabled,
    setStationPrintingEnabled,
    diningScreenConfig,
    setDiningScreenConfig,
    deliveryThreshold,
    setDeliveryThreshold,
    deliveryFees,
    setDeliveryFees,
    salesTax,
}) {
    const [pending, setPending] = useState(false);
    const operationModeRadio = useChoice(operationMode);
    const showPriceRadio = useChoice(showPrintPrice);
    const defaultNumOfPrintsRadio = useChoice(defaultNumOfPrints.numOfPrints);
    const showReadFeatureRadio = useChoice(showReadFeature);
    const autoAcceptRadio = useChoice(
        autoAcceptConfig && autoAcceptConfig.enabled ? true : false
    );
    const autoAcceptDineInRadio = useChoice(
        autoAcceptDineInConfig && autoAcceptDineInConfig.enabled ? true : false
    );
    const prepTimeRadio = useChoice(
        prepTimeConfig && prepTimeConfig.enabled ? true : false
    );
    const scheduleRadio = useChoice(
        scheduledOrderConfig && scheduledOrderConfig.enabled ? true : false
    );
    const deliveryRadio = useChoice(
        deliveryConfig && deliveryConfig.enabled ? true : false
    );

    var myTableCall = true;
    if (
        diningScreenConfig &&
        diningScreenConfig.tableCallsOnFeed &&
        diningScreenConfig.tableCallsOnFeed.enabled === false
    ) {
        myTableCall = false;
    }
    const myTableCallRadio = useChoice(myTableCall);
    var sendToKitchenOrder = true;
    if (
        diningScreenConfig &&
        diningScreenConfig.sendToKitchenOrderOnFeed &&
        diningScreenConfig.sendToKitchenOrderOnFeed.enabled === false
    ) {
        sendToKitchenOrder = false;
    }
    const sendToKitchenOrderRadio = useChoice(sendToKitchenOrder);
    const draftOrderRadio = useChoice(
        diningScreenConfig &&
            diningScreenConfig.draftOrderOnFeed &&
            diningScreenConfig.draftOrderOnFeed.enabled
            ? true
            : false
    );
    const inKitchenOrderRadio = useChoice(
        diningScreenConfig &&
            diningScreenConfig.inKitchenOrderOnFeed &&
            diningScreenConfig.inKitchenOrderOnFeed.enabled
            ? true
            : false
    );
    const editDeleteRadio = useChoice(
        diningScreenConfig &&
            diningScreenConfig.editDeleteOnFeed &&
            diningScreenConfig.editDeleteOnFeed.enabled
            ? true
            : false
    );
    const [autoAcceptEtaMins, setAutoAcceptEtaMins] = useState(
        autoAcceptConfig && autoAcceptConfig.estimatedTimeInMins
    );
    const [prepTimeEtaMins, setPrepTimeEtaMins] = useState(
        prepTimeConfig && prepTimeConfig.estimatedTimeInMins
    );
    const [deliveryTimeEtaMins, setDeliveryTimeEtaMins] = useState(
        deliveryConfig && deliveryConfig.estimatedTimeInMins
    );
    const [deliveryToken, setDeliveryToken] = useState(
        deliveryConfig && deliveryConfig.deliveryToken
    );
    const [scheduledOrderDays, setScheduledOrderDays] = useState(
        scheduledOrderConfig && scheduledOrderConfig.scheduledOrderDays
    );

    const stationPrintingEnabledRadio = useChoice(stationPrintingEnabled);

    const { outsideBizHours } = useContext(RestaurantContext);
    const { stations } = useContext(MenuContext);
    const {
        startReaderDiscovery,
        terminalReader,
        terminalReaderConnected,
    } = useContext(PaymentContext);
    var playSoundSetting = playSound;
    function getDefaulSettings(tableIdsAll, tableIds) {
        var settings = [];
        var mapTableIdToSetting = {};
        for (var tableId of tableIdsAll) {
            var tableObj = { name: tableId, checked: false };
            mapTableIdToSetting[tableId] = tableObj;
            settings.push(tableObj);
        }
        for (var tableId of tableIds) {
            mapTableIdToSetting[tableId].checked = true;
        }
        return settings;
    }
    const toppingsHook = useToppings(getDefaulSettings(tableIdsAll, tableIds));

    function close() {
        toggleShowSettings();
    }

    async function saveSettings() {
        //setPlaySound(playSoundSetting);
        setPending(true);
        var newTableIds = [];
        for (var setting of toppingsHook.toppings) {
            if (setting.checked) {
                newTableIds.push(setting.name);
            }
        }
        setTableIds(newTableIds);
        setOperationMode(operationModeRadio.value);
        setShowPrintPrice(showPriceRadio.value);
        setShowReadFeature(showReadFeatureRadio.value);
        defaultNumOfPrints.setNumOfPrints(defaultNumOfPrintsRadio.value);
        setAutoAcceptConfig({
            enabled: autoAcceptRadio.value,
            estimatedTimeInMins: autoAcceptEtaMins,
        });
        setAutoAcceptDineInConfig({ enabled: autoAcceptDineInRadio.value });
        setPrepTimeConfig({
            enabled: prepTimeRadio.value,
            estimatedTimeInMins: prepTimeEtaMins,
        });
        setScheduledOrderConfig({
            enabled: scheduleRadio.value,
            scheduledOrderDays: scheduledOrderDays,
        });
        setDeliveryConfig({
            enabled: deliveryRadio.value,
            estimatedTimeInMins: deliveryTimeEtaMins,
            deliveryFees: deliveryFees ? deliveryFees : 0,
            deliveryThreshold: deliveryThreshold ? deliveryThreshold : 0,
            deliveryToken: deliveryToken,
        });
        setDiningScreenConfig({
            draftOrderOnFeed: { enabled: draftOrderRadio.value },
            inKitchenOrderOnFeed: { enabled: inKitchenOrderRadio.value },
            editDeleteOnFeed: { enabled: editDeleteRadio.value },
            sendToKitchenOrderOnFeed: {
                enabled: sendToKitchenOrderRadio.value,
            },
            tableCallsOnFeed: { enabled: myTableCallRadio.value },
        });
        setStationPrintingEnabled(stationPrintingEnabledRadio.value);
        try {
            var settings = {};
            settings['operationMode'] = operationModeRadio.value;
            settings['showReadFeature'] = showReadFeatureRadio.value;
            settings['tableIds'] = newTableIds;
            settings['printSettings'] = {
                showPrintPrice: showPriceRadio.value,
                defaultNumOfPrints: defaultNumOfPrintsRadio.value,
                stationPrintingEnabled: stationPrintingEnabledRadio.value,
            };
            settings['autoAcceptConfig'] = {
                enabled: autoAcceptRadio.value,
                estimatedTimeInMins: autoAcceptEtaMins,
            };
            settings['autoAcceptDineInConfig'] = {
                enabled: autoAcceptDineInRadio.value,
            };
            settings['diningScreenConfig'] = {
                draftOrderOnFeed: { enabled: draftOrderRadio.value },
                inKitchenOrderOnFeed: { enabled: inKitchenOrderRadio.value },
                editDeleteOnFeed: { enabled: editDeleteRadio.value },
                sendToKitchenOrderOnFeed: {
                    enabled: sendToKitchenOrderRadio.value,
                },
                tableCallsOnFeed: { enabled: myTableCallRadio.value },
            };
            settings['prepTimeConfig'] = {
                enabled: prepTimeRadio.value,
                estimatedTimeInMins: prepTimeEtaMins,
            };
            settings['scheduledOrderConfig'] = {
                enabled: scheduleRadio.value,
                scheduledOrderDays: scheduledOrderDays,
            };
            settings['deliveryConfig'] = {
                enabled: deliveryRadio.value,
                estimatedTimeInMins: deliveryTimeEtaMins,
                deliveryFees: deliveryFees ? deliveryFees : 0,
                deliveryThreshold: deliveryThreshold ? deliveryThreshold : 0,
                deliveryToken: deliveryToken,
            };

            var url = Config.update_settings_api;
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            const resp = await axios.post(url, {
                restauId,
                settings,
                headers: token,
            });
            setUserSettings(settings);
            if ('success' in resp['data'] && resp['data']['success']) {
                enqueueSnackbar('Settings update successful.', {
                    variant: 'success',
                });
                let audit = {
                    type: 'UPDATE-SETTINGS',
                    email: loggedIn ? loggedIn.email : '',
                };
                var url = Config.move_audit_log;
                const token = await window.customerApp
                    .auth()
                    .currentUser.getIdToken();
                axios.post(url, {
                    restauId,
                    action: 'SETTINGS',
                    audit,
                    headers: token,
                });
            } else {
                enqueueSnackbar(
                    'Settings update unsuccessful. ' + resp['data']['msg'],
                    { variant: 'error' }
                );
            }
        } catch (error) {
            enqueueSnackbar('Settings update unsuccessful. ' + error, {
                variant: 'error',
            });
        }
        setPending(false);
        close();
    }

    function getDeliveryFeesPrice(deliveryFees) {
        if (deliveryFees) {
            return deliveryFees / 100;
        } else {
            return '';
        }
    }
    function setDeliveryFeessInCents(value) {
        if (isNaN(value)) {
            return '';
        } else {
            setDeliveryFees(value * 100);
        }
    }
    function getDeliveryThreshold(deliveryThreshold) {
        if (deliveryThreshold) {
            return deliveryThreshold / 100;
        } else {
            return '';
        }
    }
    function setDeliveryThresholdValue(value) {
        if (isNaN(value)) {
            return '';
        } else {
            setDeliveryThreshold(value * 100);
        }
    }
    function showPriceRadioOnChange(e) {
        if (e.target.value === 'true') {
            showPriceRadio.setValue(true);
        } else {
            showPriceRadio.setValue(false);
        }
    }

    function logout() {
        var logoutConfirmation = window.confirm('Do you want to logout?');
        if (logoutConfirmation) {
            window.customerApp
                .auth()
                .signOut()
                .then(function() {
                    console.log('Logout successful');
                })
                .catch(function(error) {
                    console.log('logout error');
                });
            toggleShowSettings();
        }
    }

    var payLaterUri =
        (window.location.hostname.includes('stage')
            ? 'https://stage.strideq.com/'
            : 'https://order.strideq.com/') + restauId;

    return (
        <>
            <DialogShadow onClick={close} />
            <Dialog>
                <DialogBanner>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Typography variant="h5">Settings</Typography>
                        <Typography
                            variant="h6"
                            align="center"
                            color="textSecondary"
                        >
                            &nbsp;Welcome {loggedIn.email}!
                        </Typography>
                    </div>
                    <CloseButtonContainer>
                        <IconButton aria-label="close">
                            <Close onClick={close} />
                        </IconButton>
                    </CloseButtonContainer>
                </DialogBanner>
                <SettingsDialogContent>
                    <SettingsSection>
                        <Typography variant="h6" color="textPrimary">
                            Print Settings
                        </Typography>

                        {window.ReactNativeWebView && stations.length > 0 && (
                            <SettingToggle
                                name="station-printing"
                                description="Station Printing"
                                checked={stationPrintingEnabledRadio.value}
                                onChange={stationPrintingEnabledRadio.onClick}
                                labelOn="Enable"
                                labelOff="Disable"
                            />
                        )}

                        {window.ReactNativeWebView && (
                            <div style={{ margin: 10 }}>
                                <RibbonButton
                                    onClick={() => {
                                        const configureStations = stationPrintingEnabledRadio.value
                                            ? stations
                                            : [];
                                        window.webViewBridge.send(
                                            'printerSettings',
                                            {
                                                stations: configureStations,
                                            }
                                        ); //call and forget
                                    }}
                                >
                                    Configure Printer
                                </RibbonButton>
                            </div>
                        )}

                        <SettingToggle
                            name="showprice"
                            description="Prices in Ticket"
                            checked={showPriceRadio.value}
                            onChange={showPriceRadio.onClick}
                        />

                        {
                            // Why this div ?
                        }
                        <div
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center',
                            }}
                        >
                            <Typography>
                                Default number of prints on accept:&nbsp;
                            </Typography>
                            <ToggleGroup
                                name="numCopiesWeb"
                                options={
                                    window.ReactNativeWebView
                                        ? numCopiesRn
                                        : numCopiesWeb
                                }
                                selected={defaultNumOfPrintsRadio.value}
                                onClick={defaultNumOfPrintsRadio.onClick}
                            />
                        </div>
                    </SettingsSection>

                    <SettingsSection>
                        <div>
                            <Typography variant="h6" color="textPrimary">
                                Choose Mode
                            </Typography>
                            <ToggleGroup
                                name="operationMode"
                                options={modeOptions}
                                selected={operationModeRadio.value}
                                onClick={operationModeRadio.onClick}
                            />
                        </div>
                    </SettingsSection>
                    {isDineInMode(operationModeRadio.value) && (
                        <>
                            <SettingsSection>
                                <TablesGrid
                                    gridColumns={5}
                                    tables={toppingsHook.toppings}
                                    onClick={toppingsHook.checkTopping}
                                />
                            </SettingsSection>

                            <SettingsSection>
                                {/** !window.ReactNativeWebView && ... Ensures autoaccept available only in tablet mode */}
                                {window.ReactNativeWebView && (
                                    <>
                                        <SettingToggle
                                            name="autoAcceptdinein"
                                            description="Auto accept dine-in orders"
                                            checked={
                                                autoAcceptDineInRadio.value
                                            }
                                            onChange={
                                                autoAcceptDineInRadio.onClick
                                            }
                                            labelOn="Enable"
                                            labelOff="Disable"
                                        />
                                    </>
                                )}
                            </SettingsSection>
                            <SettingsSection>
                                <Typography variant="h6" color="textPrimary">
                                    Display on Active Tab
                                </Typography>

                                <SettingToggle
                                    name="myTableCall"
                                    description="Table Calls (help/check)"
                                    checked={myTableCallRadio.value}
                                    onChange={myTableCallRadio.onClick}
                                />

                                <SettingToggle
                                    name="sendToKitchenOrder"
                                    description="dine-in items currently with the server and which needs to be sent to kitchen"
                                    checked={sendToKitchenOrderRadio.value}
                                    onChange={sendToKitchenOrderRadio.onClick}
                                />

                                <SettingToggle
                                    name="draftOrder"
                                    description="tables which have started adding items but haven't submitted"
                                    checked={draftOrderRadio.value}
                                    onChange={draftOrderRadio.onClick}
                                />

                                <SettingToggle
                                    name="kitchenOrder"
                                    description="dine-in items currently in kitchen/bar"
                                    checked={inKitchenOrderRadio.value}
                                    onChange={inKitchenOrderRadio.onClick}
                                />

                                <SettingToggle
                                    name="editdelete"
                                    description="edit/delete options for items before sending to chef"
                                    checked={editDeleteRadio.value}
                                    onChange={editDeleteRadio.onClick}
                                />
                            </SettingsSection>
                        </>
                    )}

                    {isTakeoutMode(operationModeRadio.value) &&
                        window.ReactNativeWebView && (
                            <SettingsSection>
                                <Typography variant="h6" color="textPrimary">
                                    Takeout/QSR Auto Accept Settings
                                </Typography>
                                <SettingToggle
                                    name="autoAccept"
                                    description="Auto accept orders"
                                    checked={autoAcceptRadio.value}
                                    onChange={autoAcceptRadio.onClick}
                                    labelOn="Enable"
                                    labelOff="Disable"
                                />
                                {outsideBizHours && (
                                    <Typography>
                                        Auto accept paused since out of business
                                        hours.
                                    </Typography>
                                )}
                                {autoAcceptRadio.value && (
                                    <>
                                        <div>
                                            <Typography>
                                                Default wait time for auto
                                                accept orders
                                            </Typography>
                                            <EstimatedWaitTimeInput
                                                estimatedTimeInMins={
                                                    autoAcceptEtaMins
                                                }
                                                setEstimatedTimeInMins={
                                                    setAutoAcceptEtaMins
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </SettingsSection>
                        )}

                    <SettingsSection>
                        <SettingToggle
                            name="prepTime"
                            description="Prep Time to customers"
                            checked={prepTimeRadio.value}
                            onChange={prepTimeRadio.onClick}
                        />
                        {prepTimeRadio.value && (
                            <>
                                <Typography>
                                    Current Prep time (minutes):{' '}
                                </Typography>
                                <div>
                                    <EstimatedWaitTimeInput
                                        estimatedTimeInMins={prepTimeEtaMins}
                                        setEstimatedTimeInMins={
                                            setPrepTimeEtaMins
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </SettingsSection>

                    {isTakeoutMode(operationModeRadio.value) && (
                        <SettingsSection>
                            <SettingToggle
                                name="scheduleOrder"
                                description="Schedule Order (days)"
                                checked={scheduleRadio.value}
                                onChange={scheduleRadio.onClick}
                                labelOn="Enable"
                                labelOff="Disable"
                            />
                            {scheduleRadio.value && (
                                <>
                                    <div>
                                        <ScheduledDaysInput
                                            estimatedTimeInMins={
                                                scheduledOrderDays
                                            }
                                            setEstimatedTimeInMins={
                                                setScheduledOrderDays
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </SettingsSection>
                    )}

                    <SettingsSection>
                        <Typography variant="h6" color="textPrimary">
                            Miscellaneous
                        </Typography>

                        {window.ReactNativeWebView && (
                            <div style={{ margin: 10 }}>
                                <AsyncProgressRibbonButton
                                    onClick={() => startReaderDiscovery(true)}
                                    errorHandler={msg => {
                                        if (msg) alert(JSON.stringify(msg));
                                    }}
                                >
                                    Discover payment readers
                                </AsyncProgressRibbonButton>
                                {terminalReader && (
                                    <Typography variant="body2" align="center">
                                        Reader configured:{' '}
                                        {terminalReader.serialNumber}, Status:{' '}
                                        {terminalReaderConnected
                                            ? 'Connected'
                                            : 'Not connected'}
                                    </Typography>
                                )}
                            </div>
                        )}
                        {payLaterToken && (
                            <div>
                                <Typography>
                                    Link to take phone orders:{' '}
                                    <a
                                        href={`${payLaterUri}?payLaterToken=${payLaterToken}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Here
                                    </a>
                                </Typography>
                            </div>
                        )}
                        <SettingToggle
                            name="read"
                            description="Order Read-out feature"
                            checked={showReadFeatureRadio.value}
                            onChange={showReadFeatureRadio.onClick}
                            labelOn="Enable"
                            labelOff="Disable"
                        />
                    </SettingsSection>

                    {isTakeoutMode(operationModeRadio.value) && (
                        <SettingsSection>
                            <Typography variant="h6" color="textPrimary">
                                Order Delivery
                            </Typography>
                            <SettingToggle
                                name="deliveryOrder"
                                description="Delivery"
                                checked={deliveryRadio.value}
                                onChange={deliveryRadio.onClick}
                                labelOn="Enable"
                                labelOff="Disable"
                            />

                            {deliveryRadio.value && (
                                <>
                                    {deliveryToken && (
                                        <Typography>
                                            Link to take delivery orders:{' '}
                                            <a
                                                href={`${payLaterUri}?deliveryToken=${deliveryToken}`}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                Here
                                            </a>
                                        </Typography>
                                    )}
                                    <div>
                                        <Typography>
                                            Estimated delivery time:
                                        </Typography>
                                        <EstimatedWaitTimeInput
                                            estimatedTimeInMins={
                                                deliveryTimeEtaMins
                                            }
                                            setEstimatedTimeInMins={
                                                setDeliveryTimeEtaMins
                                            }
                                        />
                                    </div>

                                    {/*<div>
                                        <PriceField
                                            id="estimated-delivery-fees"
                                            label="Estimated delivery fees"
                                            value={
                                                getDeliveryFeesPrice(
                                                    deliveryFees
                                                ) * 100
                                            }
                                            onChange={e =>
                                                setDeliveryFeessInCents(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <div>
                                        <PriceField
                                            id="free-delivery-amount-above"
                                            label="Free delivery amount above"
                                            value={
                                                getDeliveryThreshold(
                                                    deliveryThreshold
                                                ) * 100
                                            }
                                            onChange={e =>
                                                setDeliveryThresholdValue(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div> */}
                                </>
                            )}
                        </SettingsSection>
                    )}

                    <SettingsSection>
                        <div>
                            <Typography variant="body2">
                                For customer service:
                            </Typography>
                            <div style={{ display: 'flex', gap: 20 }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 8,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Email fontSize="small" color="disabled" />
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Email{' '}
                                        <a href="mailto:support@strideq.com">
                                            support@strideq.com
                                        </a>
                                    </Typography>
                                </div>
                                &nbsp;
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 8,
                                        alignItems: 'center',
                                    }}
                                >
                                    <Smartphone
                                        fontSize="small"
                                        color="disabled"
                                    />
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Call{' '}
                                        <a href="tel:6502821239">
                                            +1 (650) 282-1239
                                        </a>
                                    </Typography>
                                </div>
                                &nbsp;
                                <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Software Version - {softwareVersion}
                                    </Typography>
                                </div>
                                &nbsp;
                                <div>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Sales Tax - {salesTax}%
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </SettingsSection>
                </SettingsDialogContent>
                <DialogFooter>
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <Button
                                onClick={saveSettings}
                                disabled={pending}
                                color="primary"
                                variant="contained"
                            >
                                {pending ? (
                                    <CircularProgress
                                        size={20}
                                        style={{ color: 'black' }}
                                    />
                                ) : (
                                    <>Update Settings</>
                                )}
                            </Button>
                        </div>
                        &nbsp;
                        <div>
                            <Button
                                onClick={logout}
                                color="primary"
                                variant="outlined"
                            >
                                Logout
                            </Button>
                        </div>
                    </div>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export function SettingsDialog(props) {
    if (!props.showSettings) return null;
    return <SettingsContainer {...props} />;
}
