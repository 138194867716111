import Config from '../config/Config';
import { post } from './default';

export async function getInprogressCall(restauId) {
    //console.log("Creating Order for:", {restauId, items, tip, phoneNumber, orderUnderName, orderType, customerType, checkinId, estimatedTimeInMins, orderStatus, accepted_timestamp});
    const url = Config.get_inprogress_call;
    const token = await window.customerApp.auth().currentUser.getIdToken();
    const result = await post(url, { restauId, headers: token });
    if (result.status == 200 && result.data.send_success_status) {
        return result.data;
    } else {
        console.log('getInprogressCall result', restauId, result.status);
        return null;
    }
}
