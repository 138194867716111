import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { bulkUpdateTableOrders } from '../apis/DineIn';
import { CircleButton } from '../components/common/CircleButton';
import CreateOrder from '../components/CreateOrder';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import ItemMetaData from '../components/modifiers/ItemMetaData';
import Search, { createFilter } from '../components/Search';
import { CreateOrderContext, ORDER_TYPE } from '../contexts/CreateOrderContext';
import { MenuContext, POPULAR_SECTION_ID } from '../contexts/MenuContext';
import { PrintContext } from '../contexts/PrintContext';
import { useHoldStatus } from '../Hooks/useHoldStatus';
import { isDineInMode, isTakeoutMode } from '../Hooks/useOrders';
import { RibbonButton } from '../Navbar/Ribbon';
import { CheckboxLabel, DetailItemGray, Title20px } from '../Order/Feed';
import {
    ButtonContainerCenter,
    deleteItem,
    OrderContainer,
} from '../Order/Order';
import { corianderGreen } from '../Styles/colors';
import MenuContainer from './MenuContainer';
import { MenuContent, MenuStyled } from './MenuStyles';
import SectionList from './SectionList';
import TextField from '@material-ui/core/TextField';

const OrderItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 150px 30px 35px 35px 60px;
    justify-content: space-between;
`;

const CancelButton = styled.div`
    margin: 0px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
    width: 80px;
    cursor: pointer;
    background-color: ${corianderGreen};
`;
const searchInputStyle = {
    padding: '7px',
    width: '60%',
    maxWidth: '200px',
    marginRight: '5px',
};
const SearchInputContainer = styled.div`
    display: flex;
    align-content: center;
    margin: 20px 10px 0px 10px;
    @media (max-width: 1000px) {
        margin: 20px 10px 0px 10px;
    }
    @media (max-width: 720px) {
        margin: 20px 10px 0px 10px;
    }
`;

export function MenuWrapper({
    orders,
    operationMode,
    setOpenFood,
    tableId,
    setTableId,
    tableIds,
    restauId,
    loggedIn,
    showPrintPrice,
    salesTax,
    defaultNumOfPrints,
    minCreditCardAmt,
    minCreditCardFees,
    enqueueSnackbar,
}) {
    const holdStatus = useHoldStatus({});
    var draftOrderByTables = {};
    draftOrderByTables[tableId] = {};

    const { inProgressOrderType, setInProgressOrderType, addItem } = useContext(
        CreateOrderContext
    );

    for (const key in orders) {
        if (orders[key].tableId !== tableId) {
            continue;
        }
        if (orders[key].status === 'DRAFT') {
            draftOrderByTables[orders[key].tableId][key] = orders[key];
        }
    }

    const { menu } = useContext(MenuContext);
    const { printOrderWithRetrySnackBar } = useContext(PrintContext);

    //search filter
    const [filteredMenu, setFilteredMenu] = useState(menu);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSection, setActiveSection] = useState('');

    const handleSectionClick = id => {
        setActiveSection(id);
    };
    useEffect(() => {
        let tempMenu = menu;
        if (searchTerm && searchTerm.trim().length > 0 && menu) {
            const filtersFoods = menu
                .filter(sec => sec.id != POPULAR_SECTION_ID)
                .flatMap(sec => sec.foods)
                .filter(createFilter(searchTerm, ['name'], { fuzzy: false }));
            tempMenu = [
                {
                    id: 'searchResult',
                    name: 'Results for ' + searchTerm,
                    foods: filtersFoods,
                },
            ];
        }
        setFilteredMenu(tempMenu);
    }, [menu, searchTerm]);

    function openCustomFoodDialog() {
        setOpenFood({
            name: 'Custom item',
            price: 0,
            isCustomItem: true,
            status: 'DRAFT',
            id: 'custom_item',
        });
    }

    return (
        <MenuStyled>
            <MenuContent>
                {isTakeoutMode(operationMode) && (
                    <CreateOrder
                        {...{
                            setOpenFood,
                            salesTax,
                            defaultNumOfPrints,
                            minCreditCardAmt,
                            minCreditCardFees,
                            enqueueSnackbar,
                            showPrintPrice,
                        }}
                    />
                )}
                {isDineInMode(operationMode) && (
                    <div
                        onClick={() =>
                            setInProgressOrderType(ORDER_TYPE.DINEIN)
                        }
                    >
                        <h3>Select Table:</h3>

                        {tableIds.map(tid => (
                            <CircleButton
                                key={tid}
                                selected={
                                    inProgressOrderType == ORDER_TYPE.DINEIN &&
                                    tid === tableId
                                }
                                onClick={() => {
                                    setInProgressOrderType(ORDER_TYPE.DINEIN);
                                    setTableId(tid);
                                }}
                            >
                                {tid}
                            </CircleButton>
                        ))}
                        {inProgressOrderType == ORDER_TYPE.DINEIN && tableId && (
                            <>
                                <h3>
                                    Items to be ordered for table - {tableId}:
                                </h3>
                                {Object.keys(draftOrderByTables[tableId]).map(
                                    (key, index) => (
                                        <OrderContainer key={key} editable>
                                            <OrderItem
                                                onClick={() => {
                                                    setOpenFood({
                                                        ...orders[key],
                                                        index,
                                                        key,
                                                    });
                                                }}
                                            >
                                                <Title20px>
                                                    {orders[key].name}
                                                </Title20px>
                                                <Title20px>
                                                    {orders[key].quantity}
                                                </Title20px>
                                                <div>
                                                    <span
                                                        role="img"
                                                        aria-label="edit"
                                                        style={{
                                                            color: '#27ae60',
                                                        }}
                                                    >
                                                        <i className="fas fa-edit" />
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        deleteItem(
                                                            key,
                                                            orders[key].name,
                                                            orders[key].tableId,
                                                            restauId
                                                        );
                                                    }}
                                                >
                                                    <span
                                                        role="img"
                                                        aria-label="delete"
                                                        style={{
                                                            color: '#27ae60',
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </span>
                                                </div>
                                                <div>
                                                    <CheckboxLabel>
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                holdStatus
                                                                    .getHoldStatus[
                                                                    key
                                                                ]
                                                            }
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                holdStatus.changeHoldStatus(
                                                                    key
                                                                );
                                                            }}
                                                        />
                                                        Hold
                                                    </CheckboxLabel>
                                                </div>
                                            </OrderItem>
                                            <ItemMetaData item={orders[key]} />
                                            {orders[key].addedBy && (
                                                <DetailItemGray>
                                                    Added by -{' '}
                                                    {orders[key].addedBy}
                                                </DetailItemGray>
                                            )}
                                            {orders[key].addedBy &&
                                                orders[key].updatedBy &&
                                                orders[key].addedBy !==
                                                    orders[key].updatedBy && (
                                                    <DetailItemGray>
                                                        Updated by -{' '}
                                                        {orders[key].updatedBy}
                                                    </DetailItemGray>
                                                )}
                                        </OrderContainer>
                                    )
                                )}
                                {Object.keys(draftOrderByTables[tableId])
                                    .length > 0 && (
                                    <ButtonContainerCenter>
                                        <AsyncProgressRibbonButton
                                            onClick={() =>
                                                bulkUpdateTableOrders(
                                                    draftOrderByTables[tableId],
                                                    'COOK',
                                                    restauId,
                                                    holdStatus,
                                                    enqueueSnackbar,
                                                    printOrderWithRetrySnackBar
                                                )
                                            }
                                        >
                                            <b>Send To Chef</b>
                                        </AsyncProgressRibbonButton>
                                    </ButtonContainerCenter>
                                )}
                            </>
                        )}
                    </div>
                )}

                <TextField
                    id="search"
                    name="search"
                    label="Search"
                    placeholder="Search"
                    type="search"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    value={searchTerm}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => setSearchTerm(e.target.value)}
                />

                <SectionList
                    filteredMenu={filteredMenu}
                    onSectionClick={handleSectionClick}
                />

                <MenuContainer
                    setOpenFood={setOpenFood}
                    menu={filteredMenu}
                    margin={'0px'}
                    padding={'0px'}
                    activeSection={activeSection}
                    showCount={searchTerm && searchTerm.length > 0}
                    searchTerm={searchTerm}
                    tableId={tableId}
                />
                <ButtonContainerCenter>
                    <RibbonButton
                        color={`${corianderGreen};`}
                        onClick={e => openCustomFoodDialog()}
                    >
                        <b>Add Custom Item</b>
                    </RibbonButton>
                </ButtonContainerCenter>
            </MenuContent>
        </MenuStyled>
    );
}
