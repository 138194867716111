import devConfig from './dev';
import prodConfig from './prod';
import stageConfig from './stage';

let Config;
let env_value = '';

if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('192.168')
) {
    env_value = 'dev';
    Config = devConfig;
} else if (window.location.hostname.includes('stage')) {
    env_value = 'stage';
    Config = stageConfig;
} else {
    env_value = 'prod';
    Config = prodConfig;
    console.log = function() {};
}
console.log(env_value);
export default Config;
