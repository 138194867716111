//all actions supported by this store.
export const ACTIONS = {
    SET_INPROGRESS_ORDER_TYPE: 'SET_INPROGRESS_ORDER_TYPE',
    SET_INPROGRESS_ORDER_INDEX: 'SET_INPROGRESS_ORDER_INDEX',
    ADD_ITEM: 'ADD_ITEM',
    UPDATE_ITEM: 'UPDATE_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    SET_TIP: 'SET_TIP',
    SET_TOGO_OR_DINEIN: 'SET_TOGO_OR_DINEIN',
    SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
    SET_ORDER_UNDER_NAME: 'SET_ORDER_UNDER_NAME',
    ESTIMATED_TIME_IN_MINS: 'ESTIMATED_TIME_IN_MINS',
    SET_NO_OF_PRINTS: 'SET_NO_OF_PRINTS',
    SET_USER_DETAILS: 'SET_USER_DETAILS',
    //TODO Toppings, Choices, Notes
    CLEAR_ORDER: 'CLEAR_ORDER',
    START_NEW_ORDER: 'START_NEW_ORDER',
    START_EDIT_ORDER: 'START_EDIT_ORDER',
};

export const initTakeoutOrder = (
    lastSelectedEstimatedWaitTime,
    originalOrder
) => {
    const orderDetails = {
        phoneNumber: '',
        orderUnderName: '',
        tip: 0,
        estimatedTimeInMins: lastSelectedEstimatedWaitTime || 0,
        items: [],
        customerType: 'Phone',
        orderStatus: 'WIP',
        orderType: 'To-go',
        //Internal states
        _togoOrDineIn: 'To-go',
    };
    if (originalOrder) {
        Object.assign(orderDetails, originalOrder);
        orderDetails.isUpdate = true;
        //convert items if object to array
        orderDetails.items = Object.values(orderDetails.items);
        //delete orderDetails.order_history; //remove history object if any
        //calculate all internal states from originalOrder
        orderDetails._togoOrDineIn = originalOrder.orderType;
        orderDetails.tip = Math.round(
            (originalOrder.tip * 100.0) / originalOrder.payment_subtotal
        );
    }
    return orderDetails;
};

//logic to perform based on action
export const createOrderReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_INPROGRESS_ORDER_TYPE: {
            state.inProgressOrderType = action.payload;
            return;
        }
        case ACTIONS.SET_INPROGRESS_ORDER_INDEX: {
            state.inProgressTakeoutOrderIndex = action.payload;
            return;
        }
        case ACTIONS.CLEAR_ORDER: {
            state.takeoutOrders.splice(state.inProgressTakeoutOrderIndex, 1);
            if (state.inProgressTakeoutOrderIndex > 0) {
                state.inProgressTakeoutOrderIndex =
                    state.inProgressTakeoutOrderIndex - 1;
            }
            if (state.takeoutOrders.length == 0) {
                state.takeoutOrders.push(
                    initTakeoutOrder(state.lastSelectedEstimatedWaitTime)
                );
            }
            return;
        }
        case ACTIONS.START_NEW_ORDER: {
            state.takeoutOrders.push(
                initTakeoutOrder(state.lastSelectedEstimatedWaitTime)
            );
            state.inProgressTakeoutOrderIndex = state.takeoutOrders.length - 1;
            return;
        }
        case ACTIONS.START_EDIT_ORDER: {
            state.takeoutOrders.push(
                initTakeoutOrder(
                    state.lastSelectedEstimatedWaitTime,
                    action.payload
                )
            );
            state.inProgressTakeoutOrderIndex = state.takeoutOrders.length - 1;
            return;
        }
    }

    const takeoutOrder = state.takeoutOrders[state.inProgressTakeoutOrderIndex];
    switch (action.type) {
        case ACTIONS.ADD_ITEM: {
            !takeoutOrder.items && (takeoutOrder.items = []);
            //Dont merge with existing item, even if same id, choice, toppings & notes.
            const newItem = {
                id: action.payload.id,
                name: action.payload.name,
                quantity: action.payload.quantity,
                price: action.payload.price,
            };
            typeof action.payload.desc !== 'undefined' &&
                (newItem.desc = action.payload.desc);
            typeof action.payload.toppingsStr !== 'undefined' &&
                (newItem.toppingsStr = action.payload.toppingsStr);
            typeof action.payload.toppings !== 'undefined' &&
                (newItem.toppings = action.payload.toppings);
            typeof action.payload.choice !== 'undefined' &&
                (newItem.choice = action.payload.choice);
            typeof action.payload.choiceId !== 'undefined' &&
                (newItem.choiceId = action.payload.choiceId);
            typeof action.payload.choices !== 'undefined' &&
                (newItem.choices = action.payload.choices);
            typeof action.payload.notes !== 'undefined' &&
                (newItem.notes = action.payload.notes);
            typeof action.payload.status !== 'undefined' &&
                (newItem.status = action.payload.status);
            typeof action.payload.station !== 'undefined' &&
                (newItem.station = action.payload.station);
            typeof action.payload.modifierGroup !== 'undefined' &&
                (newItem.modifierGroup = action.payload.modifierGroup);
            typeof action.payload.isCustomItem !== 'undefined' &&
                (newItem.isCustomItem = action.payload.isCustomItem);
            takeoutOrder.items.push(newItem);
            break;
        }
        case ACTIONS.UPDATE_ITEM: {
            const index = action.payload.index;
            if (index >= 0 && index < takeoutOrder.items.length) {
                typeof action.payload.quantity !== 'undefined' &&
                    (takeoutOrder.items[index].quantity =
                        action.payload.quantity);
                typeof action.payload.desc !== 'undefined' &&
                    (takeoutOrder.items[index].desc = action.payload.desc);
                typeof action.payload.toppingsStr !== 'undefined' &&
                    (takeoutOrder.items[index].toppingsStr =
                        action.payload.toppingsStr);
                typeof action.payload.toppings !== 'undefined' &&
                    (takeoutOrder.items[index].toppings =
                        action.payload.toppings);
                typeof action.payload.choice !== 'undefined' &&
                    (takeoutOrder.items[index].choice = action.payload.choice);
                typeof action.payload.choiceId !== 'undefined' &&
                    (takeoutOrder.items[index].choiceId =
                        action.payload.choiceId);
                typeof action.payload.choices !== 'undefined' &&
                    (takeoutOrder.items[index].choices =
                        action.payload.choices);
                typeof action.payload.notes !== 'undefined' &&
                    (takeoutOrder.items[index].notes = action.payload.notes);
                typeof action.payload.price !== 'undefined' &&
                    (takeoutOrder.items[index].price = action.payload.price);
                typeof action.payload.modifierGroup !== 'undefined' &&
                    (takeoutOrder.items[index].modifierGroup =
                        action.payload.modifierGroup);
            } else {
                console.error('Updating invalid index: ', index);
            }
            break;
        }
        case ACTIONS.REMOVE_ITEM: {
            const index = action.payload;
            if (index >= 0 && index < takeoutOrder.items.length) {
                takeoutOrder.items.splice(index, 1);
            } else {
                console.error('Deleting invalid index: ', index);
            }
            break;
        }
        case ACTIONS.SET_TIP: {
            takeoutOrder.tip = action.payload;
            break;
        }
        case ACTIONS.SET_TOGO_OR_DINEIN: {
            takeoutOrder._togoOrDineIn = action.payload;
            takeoutOrder.orderType = action.payload;
            break;
        }
        case ACTIONS.SET_PHONE_NUMBER: {
            takeoutOrder.phoneNumber = action.payload;
            break;
        }
        case ACTIONS.SET_ORDER_UNDER_NAME: {
            takeoutOrder.orderUnderName = action.payload;
            break;
        }
        case ACTIONS.ESTIMATED_TIME_IN_MINS: {
            takeoutOrder.estimatedTimeInMins = action.payload;
            state.lastSelectedEstimatedWaitTime = action.payload;
            break;
        }
        case ACTIONS.SET_NO_OF_PRINTS: {
            takeoutOrder.noOfPrints = action.payload;
            break;
        }
        case ACTIONS.SET_USER_DETAILS: {
            takeoutOrder._userDetails = action.payload;
            break;
        }
        default:
            throw new Error('Unsupported action type.');
    }

    return;
};
