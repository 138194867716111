import Config from '../config/Config';
import { post } from './default';

export async function getTerminalSecret(restauId) {
    //console.log("Creating Order for:", {restauId, items, tip, phoneNumber, orderUnderName, orderType, customerType, checkinId, estimatedTimeInMins, orderStatus, accepted_timestamp});
    const url = Config.get_terminal_secret;
    const token = await window.customerApp.auth().currentUser.getIdToken();
    const result = await post(url, { restauId, headers: token });
    if (result.status == 200 && result.data.status) {
        return result.data;
    } else {
        console.log('getTerminalSecret result', restauId, result.status);
        return null;
    }
}

export async function captureTerminalPayment(
    restauId,
    amount,
    paymentIntentId,
    orderId,
    isDineIn,
    retry = true
) {
    //console.log("Creating Order for:", {restauId, items, tip, phoneNumber, orderUnderName, orderType, customerType, checkinId, estimatedTimeInMins, orderStatus, accepted_timestamp});
    const url = Config.capture_terminal_payment;
    const token = await window.customerApp.auth().currentUser.getIdToken();
    const result = await post(url, {
        restauId,
        headers: token,
        paymentIntentId,
        amount,
        orderId,
        isDineIn,
    });
    if (result.status == 200 && result.data.status) {
        return true;
    } else {
        if (retry) {
            return captureTerminalPayment(
                restauId,
                amount,
                paymentIntentId,
                orderId,
                isDineIn,
                false
            );
        } else {
            console.log('getTerminalSecret result', restauId, result.status);
            return false;
        }
    }
}
