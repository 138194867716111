import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function ToggleButton(props) {
    const {
        label,
        value,
        selected,
        isFirst,
        isLast,
        fullWidth,
        onClick,
    } = props;

    const style = {
        borderRadius: 0,
        marginRight: 3,
    };
    const handleClick = () => {
        onClick(value);
    };

    return (
        <Button
            value={value}
            color="primary"
            variant={value === selected ? 'contained' : 'outlined'}
            onClick={handleClick}
            style={style}
            fullWidth={fullWidth}
        >
            {label}
        </Button>
    );
}

ToggleButton.propTypes = {};

export default ToggleButton;
