import { formatPrice } from '../Data/FoodData';
import { getSubTotal, OrderContainer, ButtonContainerCenter } from './Order';
import { getPrice } from '../FoodDialog/FoodDialog';
import { renderToString } from 'react-dom/server';

import { getLocalDate, getLocalTime } from '../utils/Util';
import React, { useState } from 'react';
import Collapsible from '../Menu/Collapsible';
import {
    Title20px,
    DetailItemGray,
    passPrintBrowser,
    nativePrint,
    ClearButton,
} from './Feed';
import { SearchOrderText } from './SearchTakeoutOrder';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ItemMetaData from '../components/modifiers/ItemMetaData';
import Config from '../config/Config';
import styled from 'styled-components';
import AsyncProgressRibbonButton from '../components/CreateOrder/AsyncProgressRibbonButton';
import OrderPrintButton from '../components/common/OrderPrintButton';
const { default: axios } = require('axios');

const OrderContainerEditableNoColor = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
`;
const RightAlignPrice = styled.div`
    text-align: right;
`;
const OrderItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 125px 30px 35px 35px 35px 75px;
    justify-content: space-between;
`;

const TableTipItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: 125px 135px 125px;
    justify-content: space-between;
    align-items: center;
`;

const SearchOrderContainer = styled.div`
    display: flex;
    // justify-content: space-between;
    align-items: center;
    column-gap: 2%;
    padding: 20px 0px 10px 0px;
    border-bottom: 1px solid rgb(39, 174, 96, 0.5);
`;

export function DineInTodaysOrder({
    restauId,
    salesTax,
    serviceCharge,
    enqueueSnackbar,
    setPrintPending,
    restauName,
    restauAddress,
    restauPhoneNumber,
    loggedIn,
}) {
    const [todaysOrder, setTodaysOrder] = useState(null);
    const [todaysTableId, setTodaysTableId] = useState(null);
    const [isSearch, setSearch] = useState(false);
    const [orderSearchStDate, setorderSearchStDate] = useState(new Date());
    const [orderSearchEndDate, setOrderSearchEndDate] = useState(new Date());

    const validateOrderSearchDate = (date, isStartDate) => {
        const today = new Date();

        if (date > today) {
            //alert('Date cannot be later than Todays date.');
            enqueueSnackbar('Date cannot be later than Todays date.', {
                variant: 'error',
                persist: false,
            });
            return;
        }
        if (!isStartDate) {
            if (orderSearchStDate > date) {
                //alert('End Date cannot be prior to Start date.');
                enqueueSnackbar('End Date cannot be prior to Start date.', {
                    variant: 'error',
                    persist: false,
                });
                return;
            }
        }
        if (isStartDate) {
            setorderSearchStDate(date);
        } else {
            setOrderSearchEndDate(date);
        }
    };

    async function loadTodaysOrder() {
        const url = Config.todays_dine_in_reports;
        const token = await window.customerApp.auth().currentUser.getIdToken();
        setTodaysOrder(null);
        //const today = new Date();
        const resp = await axios
            .post(url, {
                restauId: restauId,
                headers: token,
                startDate:
                    orderSearchStDate.getMonth() +
                    1 +
                    '/' +
                    orderSearchStDate.getDate() +
                    '/' +
                    orderSearchStDate.getFullYear(),
                endDate:
                    orderSearchEndDate.getMonth() +
                    1 +
                    '/' +
                    orderSearchEndDate.getDate() +
                    '/' +
                    orderSearchEndDate.getFullYear(),
            })
            .catch(function(error) {
                enqueueSnackbar('Error while loading Dine-In order', {
                    variant: 'error',
                    persist: true,
                });
                return;
            });

        if (resp.data && Object.keys(resp.data).length > 0) {
            setTodaysOrder(resp.data);
        } else {
            enqueueSnackbar('No Dine-in Data found', {
                variant: 'error',
                persist: false,
            });
        }
        return;
    }

    function searchTable() {
        let isRecordFound = false;
        if (!todaysTableId) {
            return;
        }
        for (const key of Object.keys(todaysOrder)) {
            if (todaysTableId === todaysOrder[key].tableId) {
                isRecordFound = true;
                break;
            }
        }
        if (isRecordFound) {
            setSearch(true);
        } else {
            enqueueSnackbar('No record found for Table-' + todaysTableId, {
                variant: 'error',
                persist: false,
            });
        }
    }
    function geTipPercentage(subTotal, tip) {
        if (!tip || !subTotal) {
            return 0;
        }
        return Math.round((100 * tip) / subTotal);
    }
    return (
        <>
            <OrderContainer>
                <h3>Past Dine-In Orders </h3>
            </OrderContainer>
            <div>
                <SearchOrderContainer>
                    <div>
                        <h4
                            className="datepicker__title"
                            style={{ margin: '0 auto' }}
                        >
                            {' '}
                            From{' '}
                        </h4>
                        <DatePicker
                            selected={orderSearchStDate}
                            onChange={date =>
                                validateOrderSearchDate(date, true)
                            }
                        />
                    </div>
                    <div>
                        <h4
                            className="datepicker__title"
                            style={{ margin: '0 auto' }}
                        >
                            {' '}
                            To{' '}
                        </h4>
                        <DatePicker
                            selected={orderSearchEndDate}
                            onChange={date =>
                                validateOrderSearchDate(date, false)
                            }
                        />
                    </div>
                    <AsyncProgressRibbonButton
                        onClick={() => loadTodaysOrder()}
                    >
                        Load Past Dine In Orders
                    </AsyncProgressRibbonButton>
                </SearchOrderContainer>
            </div>
            <div>
                <SearchOrderContainer>
                    {todaysOrder && Object.keys(todaysOrder).length > 0 ? (
                        <>
                            <SearchOrderText>
                                <h4 style={{ margin: '0 auto' }}>
                                    Search Table Number :
                                </h4>
                            </SearchOrderText>
                            <SearchOrderText>
                                <label>
                                    <input
                                        type="text"
                                        name="table_number"
                                        placeholder="Table #"
                                        style={{
                                            width: '50px',
                                            margin: '0 auto',
                                        }}
                                        value={todaysTableId}
                                        onChange={e => {
                                            setSearch(false);
                                            setTodaysTableId(e.target.value);
                                        }}
                                    />
                                </label>
                            </SearchOrderText>
                            <div>
                                <ClearButton onClick={() => searchTable()}>
                                    <b>Search</b>
                                </ClearButton>
                            </div>
                            <div>
                                <ClearButton
                                    onClick={() => {
                                        setSearch(false);
                                        setTodaysTableId('');
                                    }}
                                >
                                    <b>Clear</b>
                                </ClearButton>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </SearchOrderContainer>
            </div>

            {todaysOrder &&
                Object.keys(todaysOrder).length > 0 &&
                Object.keys(todaysOrder).map((tkey, index) =>
                    (!isSearch) ||
                    (isSearch &&
                        todaysTableId === todaysOrder[tkey].tableId) ? (
                        <Collapsible
                            key={'Dinein Table' + tkey}
                            trigger={
                                (todaysOrder[tkey].time
                                    ? getLocalDate(todaysOrder[tkey].time)
                                    : '') +
                                ' - ' +
                                'Table ' +
                                todaysOrder[tkey].tableId +
                                ' - ' +
                                (todaysOrder[tkey].time
                                    ? getLocalTime(todaysOrder[tkey].time)
                                    : '')
                            }
                        >
                            {todaysOrder[tkey].items &&
                                Object.keys(todaysOrder[tkey].items).length >
                                    0 &&
                                Object.keys(todaysOrder[tkey].items).map(
                                    (key, index) => (
                                        <OrderContainer key={key} editable>
                                            <OrderItem>
                                                <Title20px>
                                                    {
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].name
                                                    }
                                                </Title20px>
                                                <Title20px>
                                                    {
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].quantity
                                                    }
                                                </Title20px>
                                                <div>
                                                    {
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].status
                                                    }
                                                </div>
                                                <div />
                                                <div />
                                                <RightAlignPrice>
                                                    {formatPrice(
                                                        getPrice(
                                                            todaysOrder[tkey]
                                                                .items[key]
                                                        ) / 100
                                                    )}
                                                </RightAlignPrice>
                                            </OrderItem>
                                            <ItemMetaData
                                                item={
                                                    todaysOrder[tkey].items[key]
                                                }
                                            />
                                            {todaysOrder[tkey].items[key]
                                                .addedBy && (
                                                <DetailItemGray>
                                                    Added by -{' '}
                                                    {
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].addedBy
                                                    }
                                                </DetailItemGray>
                                            )}
                                            {todaysOrder[tkey].items[key]
                                                .addedBy &&
                                                todaysOrder[tkey].items[key]
                                                    .updatedBy &&
                                                todaysOrder[tkey].items[key]
                                                    .addedBy !==
                                                    todaysOrder[tkey].items[key]
                                                        .updatedBy && (
                                                    <DetailItemGray>
                                                        Updated by -{' '}
                                                        {
                                                            todaysOrder[tkey]
                                                                .items[key]
                                                                .updatedBy
                                                        }
                                                    </DetailItemGray>
                                                )}
                                            {todaysOrder[tkey].items[key]
                                                .submitted_timestamp && (
                                                <DetailItemGray>
                                                    Submitted at{' '}
                                                    {getLocalTime(
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].submitted_timestamp
                                                    )}
                                                </DetailItemGray>
                                            )}
                                            {todaysOrder[tkey].items[key]
                                                .COOK_timestamp && (
                                                <DetailItemGray>
                                                    Sent to kitchen at{' '}
                                                    {getLocalTime(
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].COOK_timestamp
                                                    )}
                                                </DetailItemGray>
                                            )}
                                            {todaysOrder[tkey].items[key]
                                                .READY_timestamp && (
                                                <DetailItemGray>
                                                    Ready in kitchem at{' '}
                                                    {getLocalTime(
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].READY_timestamp
                                                    )}
                                                </DetailItemGray>
                                            )}
                                            {todaysOrder[tkey].items[key]
                                                .DELIVERED_timestamp && (
                                                <DetailItemGray>
                                                    Served to customer at{' '}
                                                    {getLocalTime(
                                                        todaysOrder[tkey].items[
                                                            key
                                                        ].DELIVERED_timestamp
                                                    )}
                                                </DetailItemGray>
                                            )}
                                        </OrderContainer>
                                    )
                                )}

                            {todaysOrder[tkey].items &&
                                Object.keys(todaysOrder[tkey].items).length >
                                    0 && (
                                    <>
                                        <OrderContainerEditableNoColor>
                                            <OrderItem>
                                                <div>Sub-Total</div>
                                                <div /> <div /> <div /> <div />
                                                <RightAlignPrice>
                                                    {formatPrice(
                                                        todaysOrder[tkey]
                                                            .subTotal / 100
                                                    )}
                                                </RightAlignPrice>
                                            </OrderItem>
                                            <OrderItem>
                                                <div>Tax</div>
                                                <div /> <div /> <div /> <div />
                                                <RightAlignPrice>
                                                    {formatPrice(
                                                        todaysOrder[tkey]
                                                            .salesTax / 100
                                                    )}
                                                </RightAlignPrice>
                                            </OrderItem>
                                            {todaysOrder[tkey].serviceFees ? (
                                                <OrderItem>
                                                    <div>
                                                        Service Charge{' '}
                                                        {serviceCharge}%
                                                    </div>
                                                    <div /> <div /> <div />{' '}
                                                    <div />
                                                    <RightAlignPrice>
                                                        {formatPrice(
                                                            todaysOrder[tkey]
                                                                .serviceFees /
                                                                100
                                                        )}
                                                    </RightAlignPrice>
                                                </OrderItem>
                                            ) : null}
                                            <TableTipItem>
                                                <div>Tip</div>
                                                <div> </div>
                                                <RightAlignPrice>
                                                    {'(' +
                                                        geTipPercentage(
                                                            todaysOrder[tkey]
                                                                .subTotal,
                                                            todaysOrder[tkey]
                                                                .tip
                                                        ) +
                                                        '%) '}
                                                    {formatPrice(
                                                        todaysOrder[tkey].tip
                                                            ? todaysOrder[tkey]
                                                                  .tip / 100
                                                            : 0
                                                    )}
                                                </RightAlignPrice>
                                            </TableTipItem>
                                            <OrderItem>
                                                <div>Total</div>
                                                <div /> <div /> <div /> <div />
                                                <RightAlignPrice>
                                                    {formatPrice(
                                                        todaysOrder[tkey]
                                                            .total / 100
                                                    )}
                                                </RightAlignPrice>
                                            </OrderItem>
                                        </OrderContainerEditableNoColor>
                                        <ButtonContainerCenter>
                                            <OrderPrintButton
                                                order={{
                                                    ...todaysOrder[tkey],
                                                    isDineIn: true,
                                                    payment_total:
                                                        todaysOrder[tkey].total,
                                                    payment_subtotal:
                                                        todaysOrder[tkey]
                                                            .subTotal,
                                                    payment_salestax:
                                                        todaysOrder[tkey]
                                                            .salesTax,
                                                    serviceFees:
                                                        todaysOrder[tkey]
                                                            .serviceFees,
                                                    payment_success: true, //since closed orders will always be paid.
                                                }}
                                                receipt={true}
                                            >
                                                <b>Print Receipt</b>
                                            </OrderPrintButton>
                                        </ButtonContainerCenter>
                                    </>
                                )}
                        </Collapsible>
                    ) : (
                        <></>
                    )
                )}
        </>
    );
}
