import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import StrideQLogo from './img/strideQ-logo.png';

export function Login({ loggedInUser, loggedIn }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setErrors] = useState('');

    function handleForm(e) {
        e.preventDefault();

        window.customerApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(res => {
                console.log(res);

                if (res.user) {
                    loggedInUser(res.user);
                }
            })
            .catch(e => {
                setErrors(e.message);
            });
    }

    return (
        <>
            <div style={{ maxWidth: 400, margin: 'auto', paddingTop: 80 }}>
                <div className="flex flexDir" style={{ marginBottom: 20 }}>
                    <img alt="logo" src={StrideQLogo} style={{ height: 48 }} />
                </div>
                <div>
                    <TextField
                        fullWidth
                        id="outlined-email-input"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="current-email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                    <TextField
                        fullWidth
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={password}
                        style={{ width: '100%', marginBottom: '20px' }}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                        fullWidth
                        style={{
                            width: '100%',
                            marginBottom: '20px',
                        }}
                        variant="contained"
                        color="primary"
                        className="loginBtn text-transform btn-filled"
                        onClick={handleForm}
                        style={{ color: 'white' }}
                        type="submit"
                    >
                        Sign In
                    </Button>
                </div>
            </div>
        </>
    );
}
