import { useState, useEffect, useReducer } from 'react';
import { useNumOfPrints } from './useNumOfPrints';
import { useMergePrint } from './useMergePrint';
import Config from '../config/Config';
import { sendSms } from '../apis/sendSms';
import { acceptTakeoutOrder } from '../apis/Takeout';
const { default: axios } = require('axios');

// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
// var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
// var dateTime = date+' '+time;

// time in local timezone : https://stackoverflow.com/questions/12945003/format-date-as-yyyy-mm-ddthhmmss-sssz
//   var dateTime = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
export function getDateTime() {
    var dateTime = new Date().toISOString();
    return dateTime;
}

export function readText(text) {
    if (window.ReactNativeWebView) {
        window.webViewBridge.send('speak', text); //call and forget
    } else {
        var msg = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(msg);
    }
}

export function beep() {
    if (window.ReactNativeWebView) {
        window.webViewBridge.send('beep', null); //call and forget
    } else {
        const snd = document.getElementById('beep');
        if (snd) {
            snd.muted = false;
            snd.play();
        }
    }
}
export function isTakeoutMode(operationMode) {
    if (operationMode === 'TAKEOUT' || operationMode === 'BOTH') {
        return true;
    }
    return false;
}

export function isDineInMode(operationMode) {
    if (operationMode === 'DINE_IN' || operationMode === 'BOTH') {
        return true;
    }
    return false;
}

export function itemCookingInKitchen(order) {
    if (order.status !== 'COOK') {
        return false;
    }
    if (!('station' in order)) {
        return true;
    }
    for (var s of order.station) {
        if (order[s + 'status'] !== 'COMPLETED') {
            return true;
        }
    }
    return false;
}

export function itemReadyInKitchen(order) {
    if (order.status !== 'COOK') {
        return false;
    }
    if (!('station' in order)) {
        return false;
    }
    for (var s of order.station) {
        if (order[s + 'status'] !== 'COMPLETED') {
            return false;
        }
    }
    return true;
}
//const clonedeep = require('lodash/cloneDeep')
const orderReducer = (state, action) => {
    switch (action.type) {
        case 'DELETE':
            var newOrders = { ...state };
            delete newOrders[action.key];
            //playBeep();

            return newOrders;
        case 'UPDATE':
            var newOrders = { ...state };
            newOrders[action.key] = action.payload;
            if (
                action.payload.status === 'WAITER' ||
                itemReadyInKitchen(action.payload)
            ) {
                //beep();
                readText('beep');
            }
            return newOrders;

        case 'APPEND':
            var newOrders = { ...state };
            newOrders[action.key] = action.payload;
            return newOrders;
        case 'RESET':
            return {};

        case 'SEND_FOR_AUTOACCEPT':
            var newOrders = { ...state };
            for (const k in action.payload) {
                //newOrders[k] = action.payload[k];
                newOrders[k]['send_for_autoaccept'] = true;
            }
            return newOrders;

        //case 'SOUND_SETTING'
        default:
            throw new Error();
    }
};

const takeoutOrderReducer = (state, action) => {
    if (action.payload && action.payload.status == 'VOID') {
        return state;
    }
    switch (action.type) {
        case 'DELETE':
            var newOrders = { ...state };
            delete newOrders[action.key];
            return newOrders;

        case 'UPDATE':
            var newOrders = { ...state };
            newOrders[action.key] = action.payload;
            console.log('Takeout new state ', newOrders);
            return newOrders;

        case 'APPEND':
            var newOrders = { ...state };
            newOrders[action.key] = action.payload;
            return newOrders;

        case 'RESET':
            return {};
        //case 'SOUND_SETTING'
        default:
            throw new Error();
    }
};

export function useOrders(isLoggedIn, enqueueSnackbar) {
    const [orders, dispatchOrder] = useReducer(orderReducer, {});
    const [takeoutOrders, dispatchTakeoutOrder] = useReducer(
        takeoutOrderReducer,
        {}
    );
    const [completedTakeoutOrders, dispatchCompletedTakeoutOrder] = useReducer(
        takeoutOrderReducer,
        {}
    );

    const [summons, setSummons] = useState({});
    const [restauId, setRestauId] = useState('None');
    const [tableId, setTableId] = useState('None');
    const [tableOrders, setTableOrders] = useState({});

    const [tableIds, setTableIds] = useState([]);
    const [tableIdsAll, setTableIdsAll] = useState([]);

    const [listener, setListener] = useState('None');
    const [orderRef, setOrderRef] = useState('None');
    const [restauName, setRestauName] = useState('None');
    const [restauAddress, setRestauAddress] = useState();
    const [restauPhoneNumber, setRestauPhoneNumber] = useState();

    const [template, setTemplate] = useState('');
    const [playSound, setPlaySound] = useState('on');
    const [checkForTableId, setCheckForTableId] = useState('');
    const [salesTax, setSalesTax] = useState(0);
    const [serviceCharge, setserviceCharge] = useState(0);

    const [operationMode, setOperationMode] = useState('TAKEOUT');
    const [showPrintPrice, setShowPrintPrice] = useState(true);
    const [userSettings, setUserSettings] = useState({});
    const defaultNumOfPrints = useNumOfPrints(1);
    const mergePrints = useMergePrint();
    const [showReadFeature, setShowReadFeature] = useState(false);
    const [payLaterToken, setPayLaterToken] = useState(null);
    const [deliveryFees, setDeliveryFees] = useState(null);
    const [deliveryThreshold, setDeliveryThreshold] = useState(null);
    const [autoAcceptConfig, setAutoAcceptConfig] = useState(null);
    const [autoAcceptDineInConfig, setAutoAcceptDineInConfig] = useState(null);
    const [prepTimeConfig, setPrepTimeConfig] = useState(null);
    const [scheduledOrderConfig, setScheduledOrderConfig] = useState(null);
    const [deliveryConfig, setDeliveryConfig] = useState(null);
    const [diningScreenConfig, setDiningScreenConfig] = useState(null);
    const [stationPrintingEnabled, setStationPrintingEnabled] = useState(false);

    const [playBeep, setPlayBeep] = useState(false);
    const [playBeepInterval, setPlayBeepInterval] = useState();
    const [enableAutoPlay, setEnableAutoPlay] = useState(
        window.ReactNativeWebView ? true : false
    );

    const [minCreditCardAmt, setMinCreditCardAmt] = useState(0);
    const [minCreditCardFees, setMinCreditCardFees] = useState(0);

    async function getRestauAndTableId() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        console.log('Restau ID:::::::::::::');
        console.log(params.get('restauId'));
        console.log(params);
        const tableId = params.get('tableId') ? params.get('tableId') : '';
        const tableIds = params.get('tableIds') ? params.get('tableIds') : '';
        var tableIdsArr = tableIds !== '' ? tableIds.split(',') : [];

        setTableId(tableId);
        if (tableIds !== '') {
            setTableIds(tableIdsArr);
            setTableIdsAll(tableIdsArr);
        }

        //var restauId = params.get('restauId')? params.get('restauId'): 'None';
        var restauId = 'None';

        var url = Config.restauid_api;
        try {
            const token = await window.customerApp
                .auth()
                .currentUser.getIdToken();
            const resp = await axios.post(url, { headers: token });
            //console.log("refund response", resp);
            if (!resp['data']['success']) {
                enqueueSnackbar(
                    'Failure to get Restau Id. ' + resp['data']['msg'],
                    { variant: 'error' }
                );
            } else {
                restauId = resp['data']['restauId'];
                if ('settings' in resp['data']) {
                    var settings = resp['data']['settings'];
                    setUserSettings(settings);
                    if ('operationMode' in settings) {
                        setOperationMode(settings['operationMode']);
                    }
                    if (
                        'printSettings' in settings &&
                        'showPrintPrice' in settings['printSettings']
                    ) {
                        setShowPrintPrice(
                            settings['printSettings']['showPrintPrice']
                        );
                    }
                    if (
                        'printSettings' in settings &&
                        'defaultNumOfPrints' in settings['printSettings']
                    ) {
                        if (
                            !window.ReactNativeWebView &&
                            settings['printSettings']['defaultNumOfPrints'] > 1
                        ) {
                            defaultNumOfPrints.setNumOfPrints(1);
                        } else {
                            defaultNumOfPrints.setNumOfPrints(
                                settings['printSettings']['defaultNumOfPrints']
                            );
                        }
                    }
                    if (
                        window.ReactNativeWebView &&
                        'printSettings' in settings &&
                        'stationPrintingEnabled' in settings['printSettings']
                    ) {
                        setStationPrintingEnabled(
                            settings['printSettings']['stationPrintingEnabled']
                        );
                    }
                    if (tableIds === '' && 'tableIds' in settings) {
                        setTableIds(settings['tableIds']);
                    }
                    if ('showReadFeature' in settings) {
                        setShowReadFeature(settings['showReadFeature']);
                    }
                    if ('autoAcceptConfig' in settings) {
                        setAutoAcceptConfig(settings['autoAcceptConfig']);
                    }
                    if ('autoAcceptDineInConfig' in settings) {
                        setAutoAcceptDineInConfig(
                            settings['autoAcceptDineInConfig']
                        );
                    }
                    if ('diningScreenConfig' in settings) {
                        setDiningScreenConfig(settings['diningScreenConfig']);
                    }
                }
            }
        } catch (error) {
            var msg =
                'Failure to get Restau Id. ' +
                (error.message ? error.message : '');
            enqueueSnackbar(msg, { variant: 'error' });
        }

        setRestauId(restauId);
        window.restauId = restauId; //for global error reporting

        console.log(restauId);
        console.log(tableId);
        console.log(window.location.href);

        //window.history.replaceState()
        return { restauId, tableId, tableIds: tableIdsArr };
    }

    function notifyForSummon(summons) {
        if (playSound !== 'on') {
            // Currently it is always on. As from settings even if you update,
            // the value is not reflected here.
            return;
        }
        for (const summon in summons) {
            if ('check' in summons[summon] && summons[summon].check) {
                //TODO: check if summon is in tableIds. Else dont notify.
                readText('Check table ' + summon);
            } else if ('help' in summons[summon] && summons[summon].help) {
                readText('Help table ' + summon);
            }
        }
    }

    function setSummonListener(restauId) {
        const database = window.customerApp.database();
        var summonRef = database.ref('summons/' + restauId);
        //let listener =
        summonRef.on('value', function(snapshot) {
            //debugger;
            if (snapshot.val() === null) {
                setSummons({});
            } else {
                setSummons(snapshot.val());
                notifyForSummon(snapshot.val());
            }
        });
    }

    const [autoAcceptQueue, setAutoAcceptQueue] = useState([]);

    function distinguishTakeoutOrder(key, val) {
        if (
            !('status' in val) ||
            val.status === 'WIP' ||
            val.status === 'READY' ||
            val.status === 'PAID_PENDING_READY'
        ) {
            console.log('takeout order data updated : ', key, val);
            dispatchTakeoutOrder({ type: 'UPDATE', key: key, payload: val });
            if (!('status' in val)) {
                readText('New Order ' + val.number);
                enqueueSnackbar('New Order ' + val.number + ' received.', {
                    variant: 'success',
                });
                setPlayBeep(true);
                //const snd = document.getElementById("beep");
                //snd.muted = false;
                beep();
                //TODO: This isnt working. the ideas was to scroll the feed to bottom to quickly accept the new order.
                //window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
                //autoAcceptTakeoutOrders(val, userSettings);
                if (window.ReactNativeWebView) {
                    setAutoAcceptQueue(autoAcceptQueue => [
                        ...autoAcceptQueue,
                        { key, val },
                    ]);
                }
            }
        } else if (val.status === 'DONE') {
            //dispatchCompletedTakeoutOrder({type: "APPEND", key: key, payload: val});
            // if you dont delete it from takeoutOrder it will remain in both places.
            dispatchTakeoutOrder({ type: 'DELETE', key: key });
        } else {
            dispatchTakeoutOrder({ type: 'DELETE', key: key }); // Ideally this not needed.
            //dispatchCompletedTakeoutOrder({type: "DELETE", key: key});
        }
    }

    function setTakeoutListener(restauId) {
        const database = window.customerApp.database();
        console.log('setTakeoutListener');
        var takeoutRef = database.ref('takeout_orders/' + restauId);

        takeoutRef.on('child_changed', function(data) {
            if (data.key === 'order_num') {
                return;
            }
            const val = data.val();
            if ('payment_success' in val) {
                distinguishTakeoutOrder(data.key, val);
            }
        });
        takeoutRef.on('child_added', function(data) {
            if (data.key === 'order_num') {
                return;
            }
            const val = data.val();
            if ('payment_success' in val) {
                distinguishTakeoutOrder(data.key, val);
            }
        });

        takeoutRef.on('child_removed', function(data) {
            if (data.key === 'order_num') {
                return;
            }
            dispatchTakeoutOrder({ type: 'DELETE', key: data.key });
        });

        const numberOfCompletedOrders = 20;
        var ref = database.ref('takeout_orders_completed/' + restauId);
        ref.orderByKey()
            .limitToLast(numberOfCompletedOrders)
            .on('child_added', function(snapshot) {
                console.log(snapshot.key);
                dispatchCompletedTakeoutOrder({
                    type: 'APPEND',
                    key: snapshot.key,
                    payload: snapshot.val(),
                });
            });
        ref.orderByKey()
            .limitToLast(numberOfCompletedOrders)
            .on('child_removed', function(snapshot) {
                console.log(snapshot.key);
                dispatchCompletedTakeoutOrder({
                    type: 'DELETE',
                    key: snapshot.key,
                });
            });
        ref.orderByKey()
            .limitToLast(numberOfCompletedOrders)
            .on('child_changed', function(snapshot) {
                dispatchCompletedTakeoutOrder({
                    type: 'UPDATE',
                    key: snapshot.key,
                    payload: snapshot.val(),
                });
            });
    }

    function setDineInListener(tableIds, restauId) {
        if (restauId === 'None') {
            return;
        }

        dispatchOrder({ type: 'RESET' });
        const database = window.customerApp.database();
        console.log('setDineInListener : tableIDs', tableIds);
        for (var tid of tableIds) {
            var restauTableKey = restauId + ':' + tid;
            var orderRef = database.ref('orders/' + restauTableKey);
            // orderRef.once('value').then(function(snapshot) {
            //   snapshot.forEach(function(childSnapshot) {
            //     var childKey = childSnapshot.key;
            //     var childData = childSnapshot.val();
            //     orders[childKey] = childData;
            //   });
            // });
            orderRef.on('child_added', function(data) {
                console.log('child_added', data.val());
                dispatchOrder({
                    type: 'APPEND',
                    key: data.key,
                    payload: data.val(),
                });
                // const newOrders = {...orders};
                // newOrders[data.key] = data.val();
                // setOrders(newOrders);

                // orders[data.key] = data.val();
                // setOrders(orders);
            });

            orderRef.on('child_changed', function(data) {
                console.log('child_changed', data.val());
                dispatchOrder({
                    type: 'UPDATE',
                    key: data.key,
                    payload: data.val(),
                });
            });

            orderRef.on('child_removed', function(data) {
                console.log('child_removed', data.val());
                dispatchOrder({
                    type: 'DELETE',
                    key: data.key,
                    payload: data.val(),
                });
            });
        }
    }

    function getRestauNameAndMenuAndTables(restauId, tableIds) {
        const database = window.customerApp.database();
        var menuRef = database.ref('menus/' + restauId);
        //let listener =
        menuRef.once('value').then(function(snapshot) {
            var result = snapshot.val();
            setRestauName((result && result.restaurantName) || 'None');
            setRestauAddress(result && result.restaurantAddress);
            setRestauPhoneNumber(result && result.restaurantPhoneNumber);

            if (result) {
                // menu state is now being set in MenuContext
                // var enhancedMenu = enhanceMenuWithStation(result.menu);
                // setMenu(enhancedMenu);
                setTemplate(result.template);
                setSalesTax(result.salesTax);
                if (result.payments) {
                    if (result.payments.salesTax) {
                        setSalesTax(result.payments.salesTax);
                    }
                    if (result.payments.serviceCharge) {
                        setserviceCharge(result.payments.serviceCharge);
                    }
                    if (result.payments.minCreditCardAmt) {
                        setMinCreditCardAmt(result.payments.minCreditCardAmt);
                    }

                    if (result.payments.minCreditCardFees) {
                        setMinCreditCardFees(result.payments.minCreditCardFees);
                    }
                    if (result.payments.deliveryThreshold) {
                        setDeliveryThreshold(result.payments.deliveryThreshold);
                    }
                    if (result.payments.deliveryFees) {
                        setDeliveryFees(result.payments.deliveryFees);
                    }
                }

                if (result.details) {
                    if (result.details.enablePayLater) {
                        setPayLaterToken(result.details.payLaterToken);
                    }
                    if ('prepTimeConfig' in result.details) {
                        setPrepTimeConfig(result.details['prepTimeConfig']);
                    }
                    if ('scheduledOrderConfig' in result.details) {
                        setScheduledOrderConfig(
                            result.details['scheduledOrderConfig']
                        );
                    }

                    if ('deliveryConfig' in result.details) {
                        setDeliveryConfig(result.details['deliveryConfig']);
                    }
                }
            }

            // console.log(window.location.href)
            // window.history.replaceState({}, document.title, window.location.href.split("?")[0])
            // //window.location.href =  window.location.href.split("?")[0];
            // console.log(window.location.href)

            if (tableIds.length == 0) {
                if (result && result.tables && result.tables.length > 0) {
                    //setTableIds((result && result.tables));

                    // debugger;
                    // setTableId(result.tables[0]); // Default table selected while ordering.
                    setTableIdsAll(result && result.tables);
                }
            }
            //console.log(result.menu);
        });
    }

    //https://stackoverflow.com/questions/51902759/remove-off-for-firebase-listeners
    function detachSummonListener() {
        const database = window.customerApp.database();
        var summonRef = database.ref('summons/' + restauId);
        summonRef.off();
        console.log('Stopping Summon Listener', restauId);
    }
    function detachTakeoutListener() {
        const database = window.customerApp.database();
        var takeoutRef = database.ref('takeout_orders/' + restauId);
        takeoutRef.off();
        console.log('Stopping takeout Listener', restauId);
    }

    function detachAllListener() {
        detachSummonListener();
        detachTakeoutListener();
        detachDineInListener();
    }

    function detachAllDineInListener() {
        detachSummonListener();
        detachDineInListener();
    }

    function detachDineInListener() {
        const database = window.customerApp.database();
        for (var tid of tableIds) {
            var restauTableKey = restauId + ':' + tid;
            var orderRef = database.ref('orders/' + restauTableKey);
            orderRef.off();
            console.log('Stopping Summon Listener', restauId, tid);
        }
    }

    useEffect(() => {
        async function setupApp() {
            if (!isLoggedIn) {
                detachAllListener();
                setRestauName('');
                dispatchOrder({ type: 'RESET' });
                dispatchTakeoutOrder({ type: 'RESET' });
                dispatchCompletedTakeoutOrder({ type: 'RESET' });
                return;
            }
            var result = await getRestauAndTableId();
            if (result['restauId'] !== 'None') {
                getRestauNameAndMenuAndTables(
                    result['restauId'],
                    result['tableIds']
                );
            }
            return () => detachAllListener();
        }
        setupApp();
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isLoggedIn || restauId === 'None') {
            return;
        }
        //setDbListener(result['restauTableKey']);
        if (isDineInMode(operationMode)) {
            setDineInListener(tableIds, restauId);
            setSummonListener(restauId);
        } else {
            detachDineInListener();
            detachSummonListener();
        }

        return () => detachAllDineInListener();
    }, [isLoggedIn, tableIds, restauId, operationMode]);

    useEffect(() => {
        if (!isLoggedIn || restauId === 'None' || restauName == 'None') {
            return;
        }
        if (isTakeoutMode(operationMode)) {
            setTakeoutListener(restauId);
            return () => detachTakeoutListener();
        }
    }, [isLoggedIn, restauId, restauName, operationMode]);

    const detachPaymentListeners = () => {
        const database = window.customerApp.database();
        const salesTaxRef = database.ref(
            'menus/' + restauId + '/payments/payments'
        );
        salesTaxRef.off();
        const serviceChargeRef = database.ref(
            'menus/' + restauId + '/payments/serviceCharge'
        );
        serviceChargeRef.off();
    }

    useEffect(() => {
        if (!isLoggedIn || restauId === 'None') {
            return;
        }
        const database = window.customerApp.database();
        database.ref('menus/' + restauId + '/payments/salesTax')
            .on('value', snapshot => {
                if (snapshot.val() != null){
                    setSalesTax(snapshot.val());
                }
            });
        database.ref('menus/' + restauId + '/payments/serviceCharge')
            .on('value', snapshot => {
                debugger;
                if (snapshot.val() != null){
                    debugger;
                    setserviceCharge(snapshot.val());
                }
            });
        return () => detachPaymentListeners();
    }, [isLoggedIn, restauId]);

    useEffect(() => {
        console.log('playBeep useEffect enableAutoPlay', enableAutoPlay);
        if (enableAutoPlay) {
            console.log('playBeep useEffect playBeep', playBeep);
            if (playBeep) {
                console.log('playBeep useEffect playBeep PLAYING');
                setPlayBeepInterval(setInterval(beep, 1000));
            } else {
                if (playBeepInterval) {
                    console.log(
                        'playBeep useEffect playBeep CLEARING INTERVAL'
                    );
                    clearInterval(playBeepInterval);
                }
                if (!window.ReactNativeWebView) {
                    const snd = document.getElementById('beep');
                    if (snd) {
                        console.log(
                            'playBeep useEffect playBeep snd.muted orig ',
                            snd.muted
                        );
                        snd.muted = true;
                        console.log(
                            'playBeep useEffect playBeep snd.muted orig ',
                            snd.muted
                        );
                    }
                }
            }
        }
        return;
    }, [playBeep, enableAutoPlay]);
    // useEffect(() => {
    //   debugger;
    //   //setDbListener(result['restauTableKey']);
    //   setDbListener2(tableIds, restauId);
    // }, [orders,tableIds,restauId]);

    return {
        restauId,
        tableId,
        tableIds,
        setTableId,
        setTableIds,
        orders,
        dispatchOrder,
        //setOrders,
        restauName,
        summons,
        tableOrders,
        setTableOrders,
        tableIdsAll,
        template,
        setPlaySound,
        playSound,
        checkForTableId,
        setCheckForTableId,
        salesTax,
        serviceCharge,
        takeoutOrders,
        completedTakeoutOrders,
        operationMode,
        setOperationMode,
        showPrintPrice,
        setShowPrintPrice,
        setPlayBeep,
        enableAutoPlay,
        setEnableAutoPlay,
        enqueueSnackbar,
        minCreditCardAmt,
        minCreditCardFees,
        userSettings,
        setUserSettings,
        defaultNumOfPrints,
        showReadFeature,
        setShowReadFeature,
        autoAcceptConfig,
        setAutoAcceptConfig,
        autoAcceptDineInConfig,
        setAutoAcceptDineInConfig,
        prepTimeConfig,
        setPrepTimeConfig,
        scheduledOrderConfig,
        setScheduledOrderConfig,
        deliveryConfig,
        setDeliveryConfig,
        stationPrintingEnabled,
        setStationPrintingEnabled,
        payLaterToken,
        restauAddress,
        restauPhoneNumber,
        mergePrints,
        autoAcceptQueue,
        setAutoAcceptQueue,
        diningScreenConfig,
        setDiningScreenConfig,
        setDeliveryThreshold,
        setDeliveryFees,
        deliveryThreshold,
        deliveryFees,
    };
}
