import styled from 'styled-components';
import { Title } from '../../Styles/title';

export const ModifierGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (max-width: 720px) {
        grid-template-columns: 1fr;
    }
`;

export const CheckboxLabel = styled.label`
    cursor: pointer;
`;

export const ModifierDiv = styled.div`
    margin-top: 20px;
`;

export const ModifierCheckbox = styled.input`
    margin-right: 10px;
    cursor: pointer;
`;

export const LabelContainer = styled(Title)`
    display: flex;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #222c31;
    margin-bottom: 5px;
`;

export const CursorPointer = `cursor: pointer;`;

export const RadioInput = styled.input`
    ${CursorPointer}
    margin-right: 10px;
`;
