import * as firebase from 'firebase';
import '@firebase/auth';
import '@firebase/firestore';
import Config from './config/Config';
firebase.initializeApp(Config.firebaseConfig);
var secondaryServer = firebase.initializeApp(
    Config.secondaryAppConfig,
    'secondary'
);
export function initApp() {
    window.customerApp = secondaryServer;
    return secondaryServer;
}
