import Config from '../../config/Config';
import { post } from '../default';

export async function markAllOrdersReady(restauId) {
    const apiUrl = Config.mark_all_orders_ready;
    const token = await window.customerApp.auth().currentUser.getIdToken();
    const result = await post(apiUrl, { restauId, headers: token });
    if (result.status == 200 && result.data.status) {
        return true;
    } else {
        return false;
    }
}
