import React from 'react';
import { getLocalDateTime } from '../../../../utils/Util';
import {
    pStyleH1,
    pStyleH2,
    pStyleH3,
    pStyleH4,
    pStyleText,
} from './TemplateStyles';

export default ({
    isCancelled,
    isUpdated,
    number,
    orderUnderName,
    paymentStatus,
    isTogo,
    tableId,
    timeStamp,
    orderInstruction,
    scheduledOrder = false,
    scheduledDate = '',
    scheduledTime = '',
    deliveryScheduledForLater =false,
    deliveryScheduledDate='',
    deliveryScheduledTime='',
    orderType=''
}) => {
    let type = '';
    if(orderType === 'Delivery'){
        type = orderType
    }else{
        type = isTogo ? 'To-go' : 'Dine-in'
    }
    //const isNameMissing = !orderUnderName;
    return (
        <div style={{ marginBottom: '30px' }}>
            {isCancelled && <div style={pStyleH2}>{'(Cancelled)'}&nbsp;</div>}
            {isUpdated && <div style={pStyleH2}>{'(Updated)'}&nbsp;</div>}
            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    ...pStyleH4,
                }}
            >
                <div style={pStyleH1}>{type}</div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'baseline',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div>
                        {typeof paymentStatus != 'undefined' && (
                            <span style={paymentStatus ? pStyleH4 : pStyleH3}>
                                {paymentStatus ? 'Paid' : 'Unpaid'}&nbsp;
                            </span>
                        )}
                        {number && (
                            <span style={pStyleH3}>#{number}&nbsp;</span>
                        )}
                    </div>
                </div>
            </div>

            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    ...pStyleH4,
                }}
            >
                {orderType === 'Delivery'&& deliveryScheduledForLater &&
                 <>
                     <div style={pStyleH1}>[Scheduled Delivery Order]</div>
                 </>
                
                }
                {scheduledOrder && (
                    <>
                        <div style={pStyleH1}>[Scheduled Order]</div>
                    </>
                )}
                <div>{tableId && <div>{'Table: ' + tableId}&nbsp;</div>}</div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexGrow: '4',
                    }}
                >
                    {orderUnderName && (
                        <div style={pStyleH2}>{orderUnderName}&nbsp;</div>
                    )}
                </div>
            </div>
            <div
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                    ...pStyleH4,
                }}
            >
                {scheduledOrder && (
                    <>
                        <div style={pStyleH2}>
                            {scheduledDate + ' ' + scheduledTime}
                        </div>
                    </>
                )}

                {orderType === 'Delivery'&& deliveryScheduledForLater &&
                 <>
                     <div style={pStyleH2}>{deliveryScheduledDate + ' ' + deliveryScheduledTime}</div>
                 </>
                
                }
            </div>

            {/* <div style={ { textAlign: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "baseline", ...pStyleH4 } }>
            <div style={{display: "flex", flexWrap: "wrap", alignItems: "baseline", flex: "0 0 50%"}}>
                <div style={pStyleH1}>{isTogo ? "To-go"  : "Dine-in" }&nbsp;</div>
                {tableId && <div>{"Table: " +tableId}&nbsp;</div>}
            </div>
            <div style={{display: "flex", flexWrap: "wrap", alignItems: "baseline", justifyContent: "flex-end", flex: "2 0 50%"}}>
                <div>{typeof paymentStatus != "undefined" && <span style={pStyleH3}>{paymentStatus ? "Paid" : "Unpaid"}&nbsp;</span>}
                {number && <span style={pStyleH3}>#{number}&nbsp;</span>}
                {(number && orderUnderName) ? <span style={pStyleH3} >/&nbsp;</span>: ""}</div>
                {orderUnderName && <div style={pStyleH4}>{orderUnderName}&nbsp;</div>}
            </div>
        </div> */}
            {!scheduledOrder && timeStamp && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        ...pStyleText,
                    }}
                >
                    <span>{getLocalDateTime(timeStamp)}&nbsp;</span>
                    {/* <span>{getLocalDate(timeStamp)}&nbsp;</span>
                <span>{getLocalTime(timeStamp)}&nbsp;</span> */}
                </div>
            )}
            {orderInstruction && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        ...pStyleText,
                    }}
                >
                    {orderInstruction}
                </div>
            )}
        </div>
    );
};
