import { assignOrderHistoryId } from './assignOrderHistoryId';
import { getDateTime } from '../../utils/Util';

export async function bulkUpdateTableOrders(
    updateOrders,
    newStatus,
    restauId,
    holdStatus,
    enqueueSnackbar,
    printOrderWithRetrySnackBar,
    tid = null,
    dispatchSendToChef = null,
    setPlayBeep = null
) {
    let tableId = '';
    try {
        if (newStatus === 'COOK') {
            if (!printOrderWithRetrySnackBar) {
                const proceed = window.confirm(
                    'Are you sure you want to proceed without print'
                );
                if (!proceed) {
                    return;
                }
            }
        }

        var updates = {};
        const database = window.customerApp.database();
        var dt = getDateTime();
        for (var key of Object.keys(updateOrders)) {
            var rtkey = restauId + ':' + updateOrders[key].tableId;
            tableId = updateOrders[key].tableId;
            updates['orders/' + rtkey + '/' + key + '/status'] = newStatus;
            updates[
                'orders/' + rtkey + '/' + key + '/' + newStatus + '_timestamp'
            ] = dt;
            if (newStatus === 'COOK') {
                updates['orders/' + rtkey + '/' + key + '/notify'] = true;
                if (holdStatus && holdStatus.getHoldStatus(key)) {
                    updateOrders[key]['hold'] = true;
                    updates['orders/' + rtkey + '/' + key + '/hold'] = true;
                }
                if (updateOrders[key].station) {
                    for (var station of updateOrders[key].station) {
                        updates[
                            'orders/' +
                                rtkey +
                                '/' +
                                key +
                                '/' +
                                station +
                                'status'
                        ] = 'NOT_SEEN';
                    }
                }
                // updates["orders/"+rtkey+"/" + key + "/cookstatus"] = "NOT_SEEN";
            } else {
                updates['orders/' + rtkey + '/' + key + '/notify'] = false;
            }
        }

        //we can genereate id, if any action taken by waiter on order
        const orderHistoryId = await assignOrderHistoryId(restauId, tableId);

        if (newStatus === 'COOK' && printOrderWithRetrySnackBar) {
            // Print to kitchen.
            try {
                if (dispatchSendToChef) {
                    dispatchSendToChef({ tableId: tid, status: true });
                }
                const printResult = await printOrderWithRetrySnackBar({
                    order: {
                        items: updateOrders,
                        created_timestamp: getDateTime(),
                        tableId,
                        id: orderHistoryId,
                        isDineIn: true,
                    },
                    isDineIn: true,
                    onSuccess: () => {
                        database
                            .ref()
                            .update(updates)
                            .then(() => {
                                enqueueSnackbar(
                                    'Print Successful for Table-' + tableId,
                                    { variant: 'success' }
                                );
                                return;
                            })
                            .catch(e => {
                                setPlayBeep && setPlayBeep(true);
                                enqueueSnackbar(
                                    'Print Successful but failed to update database. Please update table without printing. Table: ' +
                                        tableId,
                                    { variant: 'error', persist: true }
                                );
                                return;
                            });
                    },
                });
            } catch (e) {
                setPlayBeep && setPlayBeep(true);
                enqueueSnackbar(
                    'Failed to print for table ' +
                        tableId +
                        ' Error: ' +
                        (e ? JSON.stringify(e) : ''),
                    { variant: 'error', persist: true }
                );
                return;
            } finally {
                if (dispatchSendToChef) {
                    dispatchSendToChef({ tableId: tid, status: false });
                }
            }
        } else {
            await database.ref().update(updates);
        }
    } catch (e) {
        enqueueSnackbar(
            'Failed to update ' +
                tableId +
                ' Please retry. Error: ' +
                (e ? JSON.stringify(e) : ''),
            { variant: 'error', persist: true }
        );
        return;
    }
}
