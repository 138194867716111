import { useState } from 'react';

export function useReturnQty(defaultStatus) {
    const [returnQty, setReturnQty] = useState(defaultStatus);

    function setItemReturnQty(key, val) {
        const newreturnQty = { ...returnQty };
        newreturnQty[key] = val;
        console.log('setItemReturnQty status', newreturnQty);
        setReturnQty(newreturnQty);
    }

    function getReturnQty(key) {
        if (!(key in returnQty)) {
            return '';
        } else {
            return returnQty[key];
        }
    }

    return {
        setItemReturnQty,
        getReturnQty,
        setReturnQty,
    };
}
