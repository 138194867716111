import { getDateTime } from '../../utils/Util';

export async function updatePaymentsDineIn(
    restauId,
    {
        tableId,
        id,
        payment_subtotal,
        payment_total,
        tip,
        items,
        server,
        payment_salestax,
        serviceFees,
    }
) {
    const database = window.customerApp.database();
    var dateTime = getDateTime();
    var updates = {};
    updates['orderhistory/' + restauId + '/' + id + '/tableId'] = tableId;
    updates[
        'orderhistory/' + restauId + '/' + id + '/subTotal'
    ] = payment_subtotal;
    updates['orderhistory/' + restauId + '/' + id + '/total'] = payment_total;
    updates['orderhistory/' + restauId + '/' + id + '/tip'] = tip;
    updates['orderhistory/' + restauId + '/' + id + '/time'] = dateTime;
    updates['orderhistory/' + restauId + '/' + id + '/items'] = items;
    updates['orderhistory/' + restauId + '/' + id + '/server'] = server;
    updates[
        'orderhistory/' + restauId + '/' + id + '/salesTax'
    ] = payment_salestax;
    updates[
        'orderhistory/' + restauId + '/' + id + '/serviceFees'
    ] = serviceFees;

    await database.ref().update(updates);
}
