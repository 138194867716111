import React from 'react';
import styled from 'styled-components';
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';
import Config from '../config/Config';
//  position: fixed;
import { pomegranateRed } from '../Styles/colors';
import { isTakeoutMode } from '../Hooks/useOrders';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Support } from './Support';
 
const { default: axios } = require('axios');


export const PauseButton = styled(Title)`
    margin-right: 40px;
    color: white;
    height: 18px;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    background-color: grey;
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export const PauseButtonRed = styled(Title)`
    margin-right: 40px;
    color: white;
    height: 18px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    background-color: ${pomegranateRed};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;
const PauseButtonYellow = styled(PauseButtonRed)`
    background-color: orange;
    color: #333;
`;
export const NavbarStyled = styled.div`
    background-color: ${corianderGreen};
    padding: 10px;
    z-index: 999;
    display: flex;
    justify-content: space-between;
`;

export const Logo = styled(Title)`
    font-size: 20px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RightCorner = styled(Title)`
    font-size: 20px;
    color: white;
    font-weight: bold;
    display: flex;
    margin: 5px 5px;
    justify-content: center;
    align-items: center;
`;

const UserStatus = styled.div`
    color: white;
    font-size: 20px;
    margin-right: 30px;
`;

const CartStatus = styled.div`
    color: white;
    font-size: 20px;
    margin-right: 30px;
    letter-spacing: -6px;
`;
const CartStatusWithoutSpacing = styled.div`
    color: white;
    font-size: 20px;
    margin-right: 30px;
`;

const LoginButton = styled.span`
    cursor: pointer;
`;

// https://www.copypastecharacter.com/numerals
const numberDict = {
    0: '⚪',
    1: '➊',
    2: '➋',
    3: '➌',
    4: '➍',
    5: '➎',
    6: '➏',
    7: '➐',
    8: '➑',
    9: '➒',
    10: '➓',
    11: '⓫',
    12: '⓬',
    13: '⓭',
    14: '⓮',
    15: '⓯',
    16: '⓰',
    17: '⓱',
    18: '⓲',
    19: '⓳',
    20: '⓴',
};

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export function Navbar({
    setPausedState,
    loggedInUser,
    loggedIn,
    orders,
    toggleShowSettings,
    restauName,
    restauId,
    isPaused,
    operationMode,
    prepTimeConfig,
}) {
    const database = window.customerApp.database();
    const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
   
    function pauseOrders() {
        console.log('ispaused1' + isPaused);
        if (!isPaused) {
            var pause = window.confirm('Do you really want to pause orders?');
            if (pause) {
                // alert("Pausing orders");
                var updates = {};
                updates['/pause_orders'] = true;
                database
                    .ref('menus/' + restauId + '/details')
                    .update(updates)
                    .then(async function() {
                        alert('Orders paused successfully');
                        setPausedState(true);
                        let audit = {
                            type: 'ON',
                            email: loggedIn ? loggedIn.email : '',
                        };
                        var url = Config.move_audit_log;
                        const token = await window.customerApp
                            .auth()
                            .currentUser.getIdToken();
                        axios.post(url, {
                            restauId,
                            action: 'PAUSE',
                            audit,
                            headers: token,
                        });
                    })
                    .catch(function(error) {
                        var msg =
                            'There was an error pausing orders. ' +
                            (error.message ? error.message : '');
                        alert(msg);
                    });
                console.log('ispaused' + isPaused);
            } else {
                // alert("Canceled pausing orders")
            }
        } else {
            var resume = window.confirm('Do you really want to resume orders?');
            if (resume) {
                // alert("Resuming orders");
                var updates = {};
                updates['/pause_orders'] = false;
                database
                    .ref('menus/' + restauId + '/details')
                    .update(updates)
                    .then(async function() {
                        alert('Orders resumed successfully');
                        setPausedState(false);
                        let audit = {
                            type: 'OFF',
                            email: loggedIn ? loggedIn.email : '',
                        };
                        var url = Config.move_audit_log;
                        const token = await window.customerApp
                            .auth()
                            .currentUser.getIdToken();
                        axios.post(url, {
                            restauId,
                            action: 'PAUSE',
                            audit,
                            headers: token,
                        });
                    })
                    .catch(function(error) {
                        var msg =
                            'There was an error resuming orders. ' +
                            (error.message ? error.message : '');
                        alert(msg);
                    });
            } else {
                alert('Canceled resuming orders');
            }
        }
    }

    return (
        <NavbarStyled>
             <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Support</h2>
      <p id="simple-modal-description">
        <Support restauId={restauId} restauName={restauName} handleClose={handleClose}/>
      </p>
     </div>
  </Modal>
            <Logo>
                {Config.environment}
                {restauName}{' '}
                {/* <span role="img" aria-label="pizza slice">
          🍕
        </span> */}
            </Logo>

            <RightCorner>
            <PauseButtonYellow  onClick={(e)=>handleOpen()}>
                        {/* {'Support'} */}
                        <img src='./img/help-icon.png' width="20" height="20"></img>
            </PauseButtonYellow>
                {prepTimeConfig &&
                prepTimeConfig.enabled &&
                prepTimeConfig.estimatedTimeInMins &&
                prepTimeConfig.estimatedTimeInMins > 0 ? (
                    <PauseButtonYellow onClick={toggleShowSettings}>
                        {'Current Prep Time: ' +
                            prepTimeConfig.estimatedTimeInMins +
                            'm'}
                    </PauseButtonYellow>
                ) : (
                    <></>
                )}
                {isTakeoutMode(operationMode) ? (
                    isPaused ? (
                        <PauseButtonRed
                            id="pause"
                            onClick={() => pauseOrders()}
                        >
                            <span id="pause_btn_text">
                                <b>
                                    <i className="fas fa-play-circle" /> Resume
                                    Orders
                                </b>
                            </span>
                        </PauseButtonRed>
                    ) : (
                        <PauseButton id="pause" onClick={() => pauseOrders()}>
                            <span id="pause_btn_text">
                                <b>
                                    <i className="fas fa-pause-circle" /> Pause
                                    Orders
                                </b>
                            </span>
                        </PauseButton>
                    )
                ) : (
                    <></>
                )}

                <CartStatusWithoutSpacing onClick={toggleShowSettings}>
                    <i className="fas fa-cog" />
                </CartStatusWithoutSpacing>
            </RightCorner>
            {/* {
          <CartStatus>
              🍽️  👨‍🍳 {loggedIn !== "loading" ? (
          <>
            👤 {loggedIn ? "Logged in." : ""}
            {loggedIn ? (
              <LoginButton onClick={logout}> Log out </LoginButton>
            ) : (
              <LoginButton onClick={login}> Log in / Sign up </LoginButton>
            )}
          </>
        ) : (
          "loading..."
        )} 
        </CartStatus>}
       */}
        </NavbarStyled>
    );
}
