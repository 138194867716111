import React from 'react';
import styled from 'styled-components';
import { Title } from '../Styles/title';

//  height: 24px;

export const NotesContainer = styled(Title)`
    display: flex;
    margin: 20px 0px;
`;

export function PriceInput(props) {
    return (
        <NotesContainer>
            <div>{props.label && props.label}</div>
            <input
                name="price-custom"
                type="number"
                pattern="[0-9]*"
                placeholder="1.23"
                value={props.value}
                style={{
                    width: '40px',
                    display: 'block',
                    margin: '0px 10px',
                    height: '25px',
                }}
                min="0"
                max="999"
                onChange={props.onChange}
            />
        </NotesContainer>
    );
}
