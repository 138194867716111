import React from 'react';
import styled from 'styled-components';
import { Title } from '../Styles/title';
import { corianderGreen } from '../Styles/colors';
//import Dropdown from 'react-dropdown'; //https://www.npmjs.com/package/react-dropdown
//import 'react-dropdown/style.css';
import Select from 'react-dropdown-select'; //

// Styling a regular HTML input
//  width: 24px;
// const StyledInput = styled.input`
//   display: block;
//   margin: 20px 0px;
//   border: 1px solid lightblue;
// `;

// display: block;
// border: 1px solid lightred;
const StyledInput = styled.div`
    margin: 0px 10px;
`;

//  height: 24px;

const TableContainer = styled(Title)`
    display: flex;
    margin: 20px 0px;
`;

export function TableInput({ tableIds, tableId, setTableId, disabled }) {
    function changeTable(value) {
        console.log('changeTable', value);
        setTableId(value[0].name);
    }
    const tableIdsOptions = tableIds.map(tableId => ({
        name: tableId,
    }));
    console.log('tableIds', tableIds);
    console.log('tableIdsOptions', tableIdsOptions);
    //style={{ overflow: 'auto', height: '100px' }}
    return (
        <TableContainer>
            <div>Table: </div>
            <StyledInput>
                <Select
                    options={tableIdsOptions}
                    values={[{ name: tableId }]}
                    onChange={value => changeTable(value)}
                    labelField="name"
                    valueField="name"
                    dropdownHeight="200px"
                    disabled={disabled}
                />
                {/* <Dropdown options={tableIds} onChange={(value) => changeTable(value)} value={tableId} placeholder="Select table number" />; */}
            </StyledInput>
        </TableContainer>
    );
}
