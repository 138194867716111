import { useEffect, useState } from 'react';

export function useShowSettings() {
    const [showSettings, setShowSettings] = useState(false);
    function toggleShowSettings() {
        setShowSettings(showSettings => !showSettings);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showSettings]);

    return {
        showSettings,
        toggleShowSettings,
    };
}
