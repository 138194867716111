import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButton from './ToggleButton';

function ToggleGroup(props) {
    const { options, selected, onClick } = props;
    return (
        <ButtonGroup>
            {options.map((option, index) => {
                return (
                    <ToggleButton
                        key={option.value}
                        {...option}
                        selected={selected}
                        onClick={onClick}
                        isFirst={index === 0}
                        isLast={index + 1 === options.length}
                    />
                );
            })}
        </ButtonGroup>
    );
}

ToggleGroup.propTypes = {};

export default ToggleGroup;
