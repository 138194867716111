import Config from '../config/Config';
import { post } from './default';

export async function sendSms({
    token,
    restauId,
    restauName,
    orderId,
    orderNumber,
    phoneNumber,
    orderUnderName,
    customerType,
    estimatedTimeInMins,
    isScheduledOrder,
    scheduledDate,
    deliveryScheduledForLater,
    deliveryScheduledDate,
    deliveryScheduledTime,
    orderType
}) {
    if (phoneNumber && phoneNumber.length > 0) {
        var url = Config.accept_sms_api;
        const resp = await post(url, {
            restauId: restauId,
            restauName: restauName,
            orderId: orderId,
            orderNum: orderNumber,
            orderUnderName: orderUnderName,
            phoneNumber: phoneNumber,
            customerType: customerType,
            waitTime: { hour: '0', minute: '' + estimatedTimeInMins },
            headers: token,
            isScheduledOrder: isScheduledOrder,
            scheduledDate: scheduledDate,
            deliveryScheduledForLater: deliveryScheduledForLater,
            deliveryScheduledDate: deliveryScheduledDate,
            deliveryScheduledTime: deliveryScheduledTime,
            orderType: orderType
        });
        if (resp.status == 200 && resp.data.send_success_status) return true;
    }
    return false;
}
