import React from 'react';
import styled from 'styled-components';
import { validateNumberKeyPress } from '../../utils/ValidationUtils';

export default function TipInput({ tip, setTip }) {
    const tipOther = tip !== 10 && tip !== 15 && tip != 20;

    return (
        <TipOptions>
            <div>
                <label>
                    <TipRadioInput
                        type="radio"
                        name="tip"
                        value={10}
                        checked={tip === 10}
                        onChange={() => setTip(10)}
                    />
                    10%
                </label>
            </div>

            <TipLabel>
                <label>
                    <TipRadioInput
                        type="radio"
                        name="tip"
                        value={15}
                        checked={tip === 15}
                        onChange={() => setTip(15)}
                    />
                    15%
                </label>
            </TipLabel>

            <TipLabel>
                <label>
                    <TipRadioInput
                        type="radio"
                        name="tip"
                        value={20}
                        checked={tip === 20}
                        onChange={() => setTip(20)}
                    />
                    20%
                </label>
            </TipLabel>
            <TipLabel>
                <label>
                    <TipRadioInput
                        type="radio"
                        name="tip"
                        value="Other"
                        checked={tipOther}
                        onChange={() => setTip(0)}
                    />
                    <input
                        pattern="[0-9]*"
                        name="tip-other"
                        placeholder="Any"
                        value={tipOther ? tip : '0'}
                        style={{ width: '25px' }}
                        min="0"
                        max="999"
                        //onKeyDown={validateNumber}
                        onKeyDown={e =>
                            !(validateNumberKeyPress(e) || e.key === 'Enter') &&
                            e.preventDefault()
                        }
                        onChange={e => setTip(+e.target.value)}
                        //onFocus={setTipOtherRadio}
                    />
                    %
                </label>
            </TipLabel>
        </TipOptions>
    );
}

const TipOptions = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TipLabel = styled.div`
    margin-left: 10px;
`;

const TipRadioInput = styled.input`
    cursor: pointer;
    margin-right: 3px;
`;
