import React, { useContext, useState } from "react";
import ItemMetaData from "../components/modifiers/ItemMetaData";

import styled from "styled-components";
import {
  DialogContent
} from "../FoodDialog/FoodDialog";
import { formatPrice } from "../Data/FoodData";
import { getPrice } from "../FoodDialog/FoodDialog";
import Collapsible from "../Menu/Collapsible"
import { RibbonButton } from "../Navbar/Ribbon";
import { CheckboxLabel, DetailItem, OrderContainerNonClickable, Title20px, 
    ClearButton, DetailItemGray, passPrintBrowser, nativePrint} from "./Feed";
import {TakeoutItem} from "./QsrAndTakeoutOrders";
import { useHoldStatus } from "../Hooks/useHoldStatus";
import {getDateTime, getLocalDateTime, getLocalTime, getQsrTableIdLabel, isUndefinedOrNullOrEmpty} from "../utils/Util";
import {isTakeoutMode, isDineInMode} from "../Hooks/useOrders";
import { renderToString } from "react-dom/server";
import { useTableTips } from "../Hooks/useTableTips";
import {SearchTakeoutOrder} from "./SearchTakeoutOrder";
import {getScheduledDelivery} from "./QsrAndTakeoutOrders"
import CircularProgress from '@material-ui/core/CircularProgress';
import OrderPrintButton from "../components/common/OrderPrintButton";
import { PrintContext } from "../contexts/PrintContext";
import { CreateOrderContext, ORDER_TYPE } from "../contexts/CreateOrderContext";
import AsyncProgressRibbonButton from "../components/CreateOrder/AsyncProgressRibbonButton";
import ReprintDialog from "../components/print/ReprintDialog"
import PayButton from "../components/PayButton";
import { PaymentContext } from "../contexts/PaymentContext";
import { bulkUpdateTableOrders, updateTipDineIn } from "../apis/DineIn";
import { corianderGreen } from "../Styles/colors";
import {DineInTodaysOrder} from "./DineInTodaysOrder";
import { ElaspedTimeSpan } from "../components/common/ElapsedTimeSpan";


//width: 100%; 
const MenuStyled = styled.div`
   margin: 0px 50px 50px 50px;
// Add this part
  @media(max-width: 720px){ 
    width: 96%;     
    margin: auto; 
    margin-bottom: 100px;
  }
`;


const OrderContent = styled(DialogContent)`
  padding: 20px;
  
  height: 100%;
`;

export const OrderContainer = styled.div`
  padding: 5px 0px;
  border-bottom: 1px solid rgb(39,174,96,0.5);
  ${({ editable }) =>
    editable
      ? `
    &:hover {
      cursor: pointer;
      background-color: #e7e7e7;
    }
  `
      : `
    pointer-events: none; 
  `}
`;

const OrderContainerEditableNoColor = styled.div`
  padding: 5px 0px;
  border-bottom: 1px solid rgb(39,174,96,0.5);
`;



const RightAlignPrice = styled.div`
  text-align: right;
`;

//  grid-template-columns: 20px 150px 20px 60px;
const OrderItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 125px 30px 35px 35px 35px 75px;
  justify-content: space-between;
`;
 
const TableTipItem = styled.div`
  padding: 5px 0px;
  display: grid;
  grid-template-columns: 125px 135px 125px;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;;
`;

export const ButtonContainerCenter = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const orderStatusDict = {
"DRAFT" : "🚧",
"WAITER" : "🤵",
"COOK" : "👨‍🍳",
"DELIVERED" : "🍽" 
};


export  function getSubTotal(tableOrders){
  const subtotal = Object.keys(tableOrders).reduce((total, key) => {
   if (tableOrders[key].status == "DRAFT") {
    return total;
   } 
   return total + getPrice(tableOrders[key]);
  }, 0);  
  return subtotal;
}

export  function getTakeOutSubTotal(takeoutOrders){
  const subtotal = Object.keys(takeoutOrders).reduce((total, key) => {
   return total + getPrice(takeoutOrders[key]);
  }, 0);  
  return subtotal;
}

export function deleteItem(key, name, tableId, restauId) {
  var res = window.confirm("\nDELETED ITEMS ARE TRACKED.\n\n Are you sure you want to delete " + name + " for table-"+tableId);
  if (!res){
    return;
  }
  const database = window.customerApp.database();
  var rtkey = restauId + ":" + tableId;
  database.ref("orders/"+rtkey+"/"+key).remove();
};


export function Order({ orders, setOpenFood, login, loggedIn, setOpenOrderDialog, setTableId, 
  restauId, setTableOrders, feedCount, setFeedCount,setTab, checkForTableId, salesTax, serviceCharge, summons, operationMode, 
  completedTakeoutOrders, tableId, setRefundOrder, enqueueSnackbar, minCreditCardAmt, minCreditCardFees, restauName, setAcceptOrderDialog, showPrintPrice,
  restauAddress, restauPhoneNumber }) {

  const holdStatus = useHoldStatus({});
  const tableTips = useTableTips({});
  const [printPending, setPrintPending] = useState('false');
  const [reprintOrder,setReprintOrder] = useState(null);
  const [reprintOpen,setReprintOpen] = useState(false);
  const rePrintStatus = useHoldStatus({});

  const { printOrderWithRetrySnackBar } = useContext(PrintContext);
  const { setInProgressOrderType } = useContext(CreateOrderContext);
  const { terminalReaderConnected } = useContext(PaymentContext);

  const database = window.customerApp.database();

  async function updateTableTip(tid){
    if (!tableTips.getTableTip(tid)){
      return;
    }
    var tip = Math.round(parseFloat(tableTips.getTableTip(tid))*100);
    await updateTipDineIn(restauId, tid, tip);
    enqueueSnackbar("Tip for table " + tid + " updated.", {variant:'success'});
    tableTips.deleteTableTip(tid);
  }

  function closeOrder(tableOrders, tid){
    setTableOrders(tableOrders);
    setTableId(tid);
    setOpenOrderDialog(true);
    // var histRef = database.ref("orderhistory/" + restauId).push();
    // var today = new Date();
    // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    // var dateTime = date+' '+time;

    // var result = {subtotal : getSubTotal(tableOrders), time: dateTime, tableId: tid, items : tableOrders }
    // histRef.set(result);

    // var rtkey = restauId + ":" + tid;
    // database.ref("orders/"+rtkey).remove();
  }
  // const subtotal = orders.reduce((total, order) => {
  //   return total + getPrice(order);
  // }, 0);
  // const tax = subtotal * 0.07;
  // const total = subtotal + tax;



  const placeOrder = (key,tableId) => {
    const database = window.customerApp.database();
    var updates = {}; 
    var rtkey = restauId + ":" + tableId;
    updates["orders/"+rtkey+"/" + key + "/status"] = "WAITER";
    database.ref().update(updates);
    setFeedCount(feedCount + 1);
  };

  var tableStartedTime = {};
  var orderByTables = {};
  var draftOrderByTables = {};

  for (const key in orders) {
    if (!(orders[key].tableId in orderByTables)) {
      orderByTables[orders[key].tableId] = {};
      draftOrderByTables[orders[key].tableId] = {};
      tableStartedTime[orders[key].tableId] = orders[key].added_timestamp ? orders[key].added_timestamp : null;
    }
    if (orders[key].status === "DRAFT") {
      draftOrderByTables[orders[key].tableId][key] = orders[key];
    }else {
      orderByTables[orders[key].tableId][key] = orders[key];
    }
    
  }

  function getTakeOutTotal(){
    var total = 0;
    for (var tkey in completedTakeoutOrders){
      total += completedTakeoutOrders[tkey]['payment_total'];
    }
    return total;
  }


  const markTakeoutOrderArchive = (tkey) => {
    const database = window.customerApp.database();
    var updates = {}; 
    updates["takeout_orders/"+restauId+"/" + tkey + "/status"] = "ARCHIVE";
    database.ref().update(updates);
    enqueueSnackbar("Order "+completedTakeoutOrders[tkey].number + " archived.", {variant:'success'});

  };
  const markTakeoutOrderArchiveAll = () => {
    const database = window.customerApp.database();
    var updates = {};
    for (var tkey in completedTakeoutOrders){
      updates["takeout_orders/"+restauId+"/" + tkey + "/status"] = "ARCHIVE";
    } 
    database.ref().update(updates);
    enqueueSnackbar("All orders archived.", {variant:'success'});
  
  };

  const openReprint=(updateOrders)=>{
    setReprintOrder({items: updateOrders,created_timestamp:getDateTime()});
    setReprintOpen(true);

  }
  const closeRePrint = () =>{
    setReprintOpen(false);
  }
  
  function geTipPercentage(subTotal,tipObj){
    if(!tipObj || !tipObj.tip || !subTotal){
      return 0;
    }
    const tip = tipObj.tip;
    return Math.round((100 * tip) / subTotal);
  }

  function validateNumber(event) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else if (key === 190 || key === 110) {
      if (event.target.value.includes(".")){
        return false;
      }else{
        return true;
      }
    } else {
    	return true;
    }
  };

  function getScheduledOrderTitle(o){
    if(o['scheduledOrder']){
        return "/ Scheduled Order[ "+o['scheduledDate'] +" " +o['scheduledTime']+" ]";
    }else{
        return "";
    }
  }

  const TipSaveButton = styled(ClearButton)`
  padding: 3px 8px;
  height: 16px;
  `;
 

  async function clearCheckRequest(restauId, tableId){
    if (isUndefinedOrNullOrEmpty(restauId) || isUndefinedOrNullOrEmpty(tableId)){
      return;
    }

    const database = window.customerApp.database();
    var updates = {}; 
    var rtkey = "summons/" + restauId + "/" + tableId + "/";
    updates[rtkey + "check"] = false;
    updates[rtkey + "checkClearedBy"] = 'Host';
    updates[rtkey + "check_timestamp"] = null;

    updates["activeusers/"+restauId+"/" + tableId + "/checkPlease"] = {status: false, actionBy: "Host"}; 

    //updates[rtkey + "check"] = false;
    await database.ref().update(updates);
  }

  //TODO: Extract searchResult panel and Completed takeout Panel into a reusable component.
  return (
    <>
    {reprintOpen && <ReprintDialog onClose ={closeRePrint} order={reprintOrder} isItemSelect={true}/>}
    <MenuStyled>
        
        <OrderContent>

        {isDineInMode(operationMode) && 
          <>
            <OrderContainer><h3>Dine-In Orders: </h3></OrderContainer>
            {Object.keys(orderByTables).map((tkey, index) =>(
              <Collapsible key={"Dinein Table" + tkey} 
                trigger={
                  <span>
                  Table - {tkey} {tableStartedTime[tkey] && <ElaspedTimeSpan timestamp={tableStartedTime[tkey]} thresholdMins={45} />}
                  </span>
                }
                open={tkey === checkForTableId}>
                  {Object.keys(draftOrderByTables[tkey]).length > 0 && 
                    <>
                      <OrderContainer><i>Items Not Ordered Yet</i></OrderContainer>                
                      {Object.keys(draftOrderByTables[tkey]).map((key, index) => (
                        <OrderContainer key={key} editable>
                          <OrderItem
                            onClick={() => {
                                setInProgressOrderType(ORDER_TYPE.DINEIN);
                                setOpenFood({ ...orders[key], index, key });
                            }}
                          >
                            <Title20px>{orders[key].name}</Title20px>
                            <Title20px>{orders[key].quantity}</Title20px>
                            <div>{orderStatusDict[orders[key].status]}</div>
                            <div><span role="img" aria-label="edit" style={{color: "#27ae60"}}><i className="fas fa-edit"></i></span></div>
                            <div
                            style={{ cursor: "pointer" }}
                            onClick={e => {
                              e.stopPropagation();
                              deleteItem(key, orders[key].name, orders[key].tableId, restauId);
                            }}
                            >
                            <span role="img" aria-label="delete" style={{color: "#27ae60"}}><i className="fas fa-trash-alt"></i></span>
                            </div>
                            <div>
                            <CheckboxLabel>
                            <input
                              type="checkbox"
                              checked={holdStatus.getHoldStatus[key]}
                              onClick={e=> {
                                e.stopPropagation();
                                holdStatus.changeHoldStatus(key);
                              }}
                            />
                              Hold
                          </CheckboxLabel>
                            </div>
                            {/* <div
                        style={{ cursor: "pointer" }}
                        onClick={e => {
                          e.stopPropagation();
                          placeOrder(key, orders[key].tableId);
                        }}
                        >
                        {orderStatusDict['WAITER']}
                        </div> */}
                          </OrderItem>
                          <ItemMetaData item={orders[key]} />
                          {orders[key].addedBy && <DetailItemGray>Added by - {orders[key].addedBy} {orders[key].added_timestamp && <span> at {getLocalTime(orders[key].added_timestamp)}</span>}</DetailItemGray>}
                          {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy !== orders[key].updatedBy && 
                            <DetailItemGray>
                              Updated by - {orders[key].updatedBy} {orders[key].updated_timestamp && <span> at {getLocalTime(orders[key].updated_timestamp)}</span>}
                            </DetailItemGray>}

                        </OrderContainer>
                      ))}
                      <ButtonContainerCenter>
                        {/* ASK ANKIT HERE
                          <AsyncProgressRibbonButton onClick={async () => {
                              await printOrder({order: {items: draftOrderByTables[tkey]}, isDineIn: true});
                              await bulkSendToCook(draftOrderByTables[tkey], holdStatus, restauId, loggedIn, showPrintPrice, enqueueSnackbar);
                            }} 
                            errorHandler={() => enqueueSnackbar("Failed to send to Bar/Cook", {variant:'error', persist:true})}>Send All : Bar/Cook</AsyncProgressRibbonButton>
                        */}
                        <AsyncProgressRibbonButton onClick={() => bulkUpdateTableOrders(draftOrderByTables[tkey], "COOK", restauId, holdStatus, enqueueSnackbar, printOrderWithRetrySnackBar)}>Send To Chef</AsyncProgressRibbonButton>
                        

                      </ButtonContainerCenter>
                    </>
                  }
                  {Object.keys(orderByTables[tkey]).length > 0 && 
                            <OrderContainer><i>Processed Order Items:</i></OrderContainer>
                  }
                  {Object.keys(orderByTables[tkey]).length > 0 && 
                      Object.keys(orderByTables[tkey]).map((key, index) => (
                  <OrderContainer key={key} editable>
                    <OrderItem
                      onClick={() => {
                          setInProgressOrderType(ORDER_TYPE.DINEIN);
                          setOpenFood({ ...orders[key], index, key });
                      }}
                    >
                      <Title20px>{orders[key].name}</Title20px>
                      <Title20px>{orders[key].quantity}</Title20px>
                      <div>{orderStatusDict[orders[key].status]}</div>
                      <div><span role="img" aria-label="edit" style={{color: "#27ae60"}}><i className="fas fa-edit"></i></span></div>
                      <div
                      style={{ cursor: "pointer" }}
                      onClick={e => {
                        e.stopPropagation();
                        deleteItem(key, orders[key].name, orders[key].tableId, restauId);
                      }}
                      >
                        
                        <span role="img" aria-label="delete" style={{color: "#27ae60"}}><i className="fas fa-trash-alt"></i></span>
                      </div>
                        
                      <RightAlignPrice>
                      {formatPrice(getPrice(orders[key])/100)}
                      </RightAlignPrice>
                    </OrderItem>
                          <ItemMetaData item={orders[key]} />
                          {orders[key].addedBy && <DetailItemGray>Added by - {orders[key].addedBy}</DetailItemGray>}
                          {orders[key].addedBy && orders[key].updatedBy && orders[key].addedBy !== orders[key].updatedBy && <DetailItemGray>Updated by - {orders[key].updatedBy}</DetailItemGray>}
                          {orders[key].submitted_timestamp && <DetailItemGray>Submitted to tab at {getLocalTime(orders[key].submitted_timestamp)}</DetailItemGray>}
                          {orders[key].COOK_timestamp && <DetailItemGray>Sent to kitchen at {getLocalTime(orders[key].COOK_timestamp)}</DetailItemGray>}
                          {orders[key].READY_timestamp && <DetailItemGray>Ready in kitchem at {getLocalTime(orders[key].READY_timestamp)}</DetailItemGray>}
                          {orders[key].DELIVERED_timestamp && <DetailItemGray>Served to customer at {getLocalTime(orders[key].DELIVERED_timestamp)}</DetailItemGray>}

                  </OrderContainer>
                ))}
                {Object.keys(orderByTables[tkey]).length > 0 && (<OrderContainerEditableNoColor>
                  <OrderItem>
                    <div>Sub-Total</div>
                    <div /> <div/> <div /> <div/>
                    <RightAlignPrice>{formatPrice(getSubTotal(orderByTables[tkey])/100)}</RightAlignPrice>
                  </OrderItem>
                  <OrderItem>
                    <div>Tax</div>
                    <div /> <div/> <div /> <div/>
                    <RightAlignPrice>{formatPrice(getSubTotal(orderByTables[tkey])*salesTax/100.0/100)}</RightAlignPrice>
                  </OrderItem>
                  {serviceCharge ?<OrderItem>
                    <div>Service Charge {serviceCharge}%</div>
                    <div /> <div/> <div /> <div/>
                    <RightAlignPrice>{formatPrice(getSubTotal(orderByTables[tkey])*serviceCharge/100.0/100)}</RightAlignPrice>
                  </OrderItem> : null}
                  <TableTipItem>
                    <div>Tip</div>
                    <div style={{display:"flex", alignItems: "center"}}> $<input
                            type="number" pattern="[0-9]*" name="tip-other" placeholder="0.00"
                            value={tableTips.getTableTip(tkey)}
                            style={{ width: "40px" }}
                            min="0" max="999"
                            onChange={(e) => {tableTips.setTableTip(tkey, e.target.value)}}
                          />&nbsp;&nbsp; <AsyncProgressRibbonButton style={{height: "16px", padding: "5px"}} onClick={() => updateTableTip(tkey)}><b>Save</b></AsyncProgressRibbonButton> </div>
                    <RightAlignPrice>{"("+geTipPercentage(getSubTotal(orderByTables[tkey]),summons[tkey])+"%) "}{formatPrice(summons[tkey] && summons[tkey].tip ? summons[tkey].tip/100: 0)}</RightAlignPrice>
                  </TableTipItem>
                  <OrderItem>
                    <div>Total</div>
                    <div /> <div/> <div /> <div/>
                    <RightAlignPrice>{formatPrice(
                      (getSubTotal(orderByTables[tkey])*(1+salesTax/100.0+serviceCharge/100.0) + (summons[tkey] && summons[tkey].tip ? summons[tkey].tip: 0))
                      /100  
                    )}</RightAlignPrice>
                  </OrderItem>
                </OrderContainerEditableNoColor>)
                }
                <ButtonContainer>
                  <RibbonButton onClick={() => {setInProgressOrderType(ORDER_TYPE.DINEIN); setTableId(tkey); setTab("MENU"); window.scrollTo(0, 0);  }}>Add Items</RibbonButton>
                  { 
                    (!orderByTables[tkey] 
                      || Object.values(orderByTables[tkey]).length == 0 
                      || Object.keys(draftOrderByTables[tkey]).length > 0
                      || !(summons[tkey] && summons[tkey]["orderHistoryId"])
                      ) ? 
                      <RibbonButton onClick={() => {
                        if(Object.keys(draftOrderByTables[tkey]).length > 0) { alert("Sent all to chef before printing receipt.") }
                        else if(!orderByTables[tkey] || Object.values(orderByTables[tkey]).length == 0) { alert("No items to print receipt.") }
                      }} ><b>Print Receipt</b></RibbonButton>
                      :
                      <OrderPrintButton order={ createNormalizedDineInOrder(tkey, orderByTables, summons, salesTax, serviceCharge, loggedIn) } receipt={ true }><b>Print Receipt</b></OrderPrintButton>
                  }
                  <RibbonButton onClick={async () => {
                    openReprint(orderByTables[tkey])
                  }}
                  
                  >Reprint</RibbonButton>
                  { (terminalReaderConnected && 
                      (summons[tkey] && summons[tkey]["orderHistoryId"] ? 
                        (//!summons[tkey]["payment_success"] 
                          //?
                          (!orderByTables[tkey] || Object.values(orderByTables[tkey]).length == 0 || Object.keys(draftOrderByTables[tkey]).length > 0) ? 
                            <RibbonButton onClick={() => {
                              if(Object.keys(draftOrderByTables[tkey]).length > 0) { alert("Sent all to chef before pay.") }
                              else if(!orderByTables[tkey] || Object.values(orderByTables[tkey]).length == 0) { alert("No items to pay.") }
                            }}><b>Pay</b></RibbonButton>
                            :        
                            <PayButton order={createNormalizedDineInOrder(tkey, orderByTables, summons, salesTax, serviceCharge, loggedIn)} 
                              color={corianderGreen}
                              disabled={!orderByTables[tkey] || Object.values(orderByTables[tkey]).length == 0 || Object.keys(draftOrderByTables[tkey]).length > 0}
                            />
                          //: 
                            //<RibbonButton disabled={true}>Paid</RibbonButton>
                          )
                        : <RibbonButton disabled={true}>Pay</RibbonButton>)
                    )
                  }

                  { summons[tkey] && summons[tkey].check &&
                      <AsyncProgressRibbonButton onClick={async () => {
                            await clearCheckRequest(restauId, tkey)
                        }}>Clear Check Call
                    </AsyncProgressRibbonButton>
                  }

                  <RibbonButton onClick={() => {closeOrder(orderByTables[tkey], tkey);}}>Close Order</RibbonButton>
                </ButtonContainer>
              </Collapsible>


            ))}
        
            <OrderContainer>{" "}</OrderContainer>
            <OrderContainer>
                <OrderContent>
                  Order Item Status Symbols:<br/>
                      Not placed / Draft  - 🚧<br/>
                      Host / Server - 🤵<br/>
                      Cook / Kitchen - 👨‍🍳<br/>
                      On Table - 🍽 <br/><br/>
                  <li> Ensure all items are delivered to customer and has status 🍽 before closing the table order.</li> 
                  <li> Ask customer if they will want to order items with status 🚧. </li>
                </OrderContent>
            </OrderContainer>
        </>}

        {isDineInMode(operationMode) && 
          <DineInTodaysOrder restauId={restauId} enqueueSnackbar ={enqueueSnackbar} salesTax={salesTax} serviceCharge={serviceCharge} setPrintPending={setPrintPending} restauName={restauName} loggedIn={loggedIn} restauPhoneNumber={restauPhoneNumber} restauAddress={restauAddress} loggedIn={loggedIn} />
        }
        {isTakeoutMode(operationMode) &&
          <>
          <SearchTakeoutOrder restauId={restauId} enqueueSnackbar={enqueueSnackbar}  setRefundOrder={setRefundOrder} minCreditCardFees={minCreditCardFees} 
              minCreditCardAmt={minCreditCardAmt} salesTax={salesTax} restauName={restauName} setAcceptOrderDialog={setAcceptOrderDialog} showPrintPrice={showPrintPrice}
              restauAddress={restauAddress} restauPhoneNumber={restauPhoneNumber}
          ></SearchTakeoutOrder>
          <OrderContainer><h3>Recently Completed Takeout Orders: </h3></OrderContainer>
          {/* <OrderContainerNonClickable>
                <TakeoutItem>
                  <div>Overall - Total</div>
                  <div/>
                  <div>{formatPrice(getTakeOutTotal()/100)}</div>
                </TakeoutItem>
                <ButtonContainerCenter>
                  <RibbonButton onClick={() => markTakeoutOrderArchiveAll()}><b>Archive All</b></RibbonButton>
                </ButtonContainerCenter>

          </OrderContainerNonClickable> */}

          { Object.keys(completedTakeoutOrders).length > 0 &&
            Object.keys(completedTakeoutOrders).reverse().map((tkey) =>(
            <Collapsible key={tkey} 
              trigger={"Order # " + completedTakeoutOrders[tkey]['number'] + " / " 
                + getQsrTableIdLabel(completedTakeoutOrders[tkey], "Table-" , " / ")
                + (completedTakeoutOrders[tkey]['orderUnderName'] ? completedTakeoutOrders[tkey]['orderUnderName'] + " / " : "") 
                + (completedTakeoutOrders[tkey]['payment_success'] ?  "Paid " + (completedTakeoutOrders[tkey]['paid_in_store'] ? "(Store) " : "")  + "/ " : "Unpaid / ")  
                + completedTakeoutOrders[tkey]['orderType'] + getScheduledDelivery(completedTakeoutOrders[tkey]) +getScheduledOrderTitle(completedTakeoutOrders[tkey])+ " / "   
                + getLocalDateTime(completedTakeoutOrders[tkey]['created_timestamp'])
                
              }
              triggerStyle={{backgroundColor: completedTakeoutOrders[tkey]['payment_success'] ? "#c3f6c3":"#f6c3c3"}}  
              >
                {completedTakeoutOrders[tkey]['orderInstruction']&& <div>{"Order Instruction :"+ completedTakeoutOrders[tkey]['orderInstruction']}</div>}
                <div> 
                  Phone Number : {completedTakeoutOrders[tkey]['phoneNumber']? completedTakeoutOrders[tkey]['phoneNumber']:"Not Given"}
                  &nbsp;/ {completedTakeoutOrders[tkey]['customerType']}
                </div>
                {completedTakeoutOrders[tkey]['deliveryAddress'] && (
                       <div>
                        {'Delivery Address :' +
                        completedTakeoutOrders[tkey][
                        'deliveryAddress'
                        ]}
                      </div>
                 )}
                {completedTakeoutOrders[tkey]['deliveryScheduledForLater'] && (
                     <div>
                    {'Delivery Schedule Time :' +completedTakeoutOrders[tkey]['deliveryScheduledDate']+' '+completedTakeoutOrders[tkey]['deliveryScheduledTime']
                    }
                    </div>
                )}
              {Object.keys(completedTakeoutOrders[tkey]['items']).map((key) => (
                <OrderContainerNonClickable key={key}>
                  <TakeoutItem>
                    <Title20px>{completedTakeoutOrders[tkey]['items'][key].name}</Title20px>
                    <Title20px>Qty-{completedTakeoutOrders[tkey]['items'][key].quantity}</Title20px>
                    <div>
                    { formatPrice(getPrice(completedTakeoutOrders[tkey]['items'][key])/100) }
                    </div>
                  </TakeoutItem>
                  <ItemMetaData item={completedTakeoutOrders[tkey]['items'][key]} />
    
                </OrderContainerNonClickable>
              ))}
              <OrderContainer>
                <TakeoutItem>
                  <div>Sub-Total</div>
                  <div/>
                  <div>{formatPrice(getTakeOutSubTotal(completedTakeoutOrders[tkey]['items'])/100)}</div>
                </TakeoutItem>
                <TakeoutItem>
                  <div>Tax</div>
                  <div/>
                  <div>{formatPrice(Math.round(getTakeOutSubTotal(completedTakeoutOrders[tkey]['items'])*salesTax/100)/100)}</div>
                </TakeoutItem>
                {completedTakeoutOrders[tkey]['minCreditCardFees'] ?
                    (<TakeoutItem>
                      <div>Credit Card fees  <br/> (order &lt; {formatPrice(minCreditCardAmt/100)})</div>
                      <div />
                      <div>{formatPrice(completedTakeoutOrders[tkey]['minCreditCardFees']/100)}</div>
                    </TakeoutItem>):
                    ""
                }

                
                <TakeoutItem>
                  <div>Tip</div>
                  <div/>
                  <div>{completedTakeoutOrders[tkey].tip ?
                    formatPrice(completedTakeoutOrders[tkey].tip/100)
                    : "" }</div>
                </TakeoutItem>
                <TakeoutItem>
                  <div>Total</div>
                  <div/>
                  <div>{formatPrice(completedTakeoutOrders[tkey]['payment_total']/100)}</div>
                  {/* <div>{completedTakeoutOrders[tkey].tip ? formatPrice(getTakeOutSubTotal(completedTakeoutOrders[tkey]['items'])*(1+salesTax/100.0) +
                  parseFloat(completedTakeoutOrders[tkey].tip )):
                  formatPrice(getTakeOutSubTotal(completedTakeoutOrders[tkey]['items'])*(1+salesTax/100.0)
                  )}</div> */}
                </TakeoutItem>
                {!completedTakeoutOrders[tkey]['payment_success'] && completedTakeoutOrders[tkey].total_amount_paid && (<>
                      <TakeoutItem>
                        <div>Amount Paid</div>
                        <div/>
                        <div>{formatPrice(completedTakeoutOrders[tkey].total_amount_paid/100)}</div>
                      </TakeoutItem>
                      <TakeoutItem>
                        <div>Unpaid Amount</div>
                        <div/>
                        <div>{formatPrice((completedTakeoutOrders[tkey].payment_total-completedTakeoutOrders[tkey].total_amount_paid)/100)}</div>
                      </TakeoutItem>
                    </>
                  )}
              </OrderContainer>
              <ButtonContainerCenter>
                <OrderPrintButton order={ completedTakeoutOrders[tkey] } receipt={ true }><b>Print Receipt</b></OrderPrintButton>
                {/* <RibbonButton onClick={() => printOrder(completedTakeoutOrders[tkey], 1, false, showPrintPrice, restauName, true, restauAddress, restauPhoneNumber, enqueueSnackbar, setPrintPending)}>
                  {printPending === tkey? <CircularProgress size={20} style={{'color': 'black'}}/> : <b>Print Receipt</b>}
                </RibbonButton>  */}
                <RibbonButton onClick={() => setRefundOrder(completedTakeoutOrders[tkey])}><b>Refund</b></RibbonButton>
                {/* <RibbonButton onClick={() => markTakeoutOrderArchive(tkey)}><b>Archive</b></RibbonButton> */}
              </ButtonContainerCenter>
          </Collapsible>
          ))}

          
        </>}

        </OrderContent>
    </MenuStyled>
    </>
  );
}

function createNormalizedDineInOrder(tkey, orderByTables, summons, salesTax, serviceCharge, loggedIn) {
  return {
    tableId: tkey,
    isDineIn: true,
    items: orderByTables[tkey],
    id: summons[tkey] && summons[tkey]["orderHistoryId"],
    payment_total: Math.round(getSubTotal(orderByTables[tkey]) * (1 + salesTax/100.0 + serviceCharge/100.0) + (summons[tkey] && summons[tkey].tip ? summons[tkey].tip : 0)),
    tip: (summons[tkey] && summons[tkey].tip ? summons[tkey].tip : 0),
    payment_subtotal: getSubTotal(orderByTables[tkey]),
    payment_salestax: Math.round(getSubTotal(orderByTables[tkey]) * salesTax / 100.0),
    serviceFees: Math.round(getSubTotal(orderByTables[tkey]) * serviceCharge / 100.0),
    server: loggedIn.email,
    payment_success: summons[tkey] && summons[tkey]["payment_success"]
  };
}
