import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

function PriceField(props) {
    const { id, label, value, fullWidth, onChange } = props;

    return (
        <TextField
            id={id}
            type="number"
            label={label}
            value={parseFloat(value / 100)}
            onChange={onChange}
            type="number"
            variant="outlined"
            size="small"
            margin="dense"
            fullWidth={fullWidth}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                // readOnly: true,
                startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                ),
            }}
        />
    );
}

PriceField.propTypes = {};

export default PriceField;
