import React from 'react';
import { Fragment } from 'react';
import { formatPrice } from '../Data/FoodData';
import { getPrice } from '../FoodDialog/FoodDialog';

const fontWeight = '600';
const fontSize = '35px';

const tableStyle = {
    fontSize: fontSize,
    width: '100%',
    borderCollapse: 'collapse',
    fontWeight: fontWeight,
};
const pStyleLight = {
    fontSize: fontSize,
    fontWeight: '300',
};

const pStyleBold = {
    fontSize: '40px',
    fontWeight: '900',
};

const itemTrStyleBottomBorder = {
    borderBottom: '1px solid black',
};
const itemTrStyleTopBorder = {
    borderTop: '1px solid black',
};

const trHeader = {
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
};

const toppingStyle = {
    marginLeft: fontSize,
    fontWeight: 300,
};

const itemStyle = {
    fontSize: fontSize,
    fontWeight: fontWeight,
    paddingTop: '20px',
    paddingBottom: '20px',
};

const bottomStyle = {
    height: '150px',
    width: '100%',
    clear: 'both',
};

const rightAlignStyle = {
    textAlign: 'right',
};

export function MergeAndPrintOrder(props) {
    let takeoutOrder = props.takeoutOrder;
    let reprint = false;
    let receipt = false;
    let from = props.from;
    let to = props.to;
    let showPrices = props.showPrices;
    let restauName = props.restauName;

    return (
        <html>
            <body>
                <div>
                    <center>
                        <br />
                        <br />
                        <div style={pStyleBold}>{restauName}</div>

                        <div>
                            <span style={pStyleLight}>powered by</span>
                            <span style={pStyleBold}>&nbsp;StrideQ</span>
                        </div>

                        <div>
                            <br />

                            <br />
                            {from && (
                                <>
                                    <br />
                                    <span style={pStyleBold}>Order # </span>
                                    <span style={pStyleLight}>
                                        {from}-{to}
                                    </span>
                                </>
                            )}
                            <br />
                            <br />
                            <br />
                        </div>
                    </center>

                    <table style={tableStyle}>
                        <tr style={trHeader}>
                            <td>
                                <span style={pStyleLight}>
                                    Qty &nbsp;&nbsp;X&nbsp;&nbsp; Item
                                </span>
                            </td>
                        </tr>
                        {Object.keys(takeoutOrder.items).map(
                            (itemId, itemIndex) => {
                                return (
                                    <Fragment key={itemId}>
                                        <tr>
                                            <td>
                                                {Object.keys(
                                                    takeoutOrder.items[itemId]
                                                ).length > 1
                                                    ? 'Total - ' +
                                                      Object.entries(
                                                          takeoutOrder.items[
                                                              itemId
                                                          ]
                                                      ).reduce(
                                                          (total, [, curr]) =>
                                                              total +
                                                              curr.quantity,
                                                          0
                                                      )
                                                    : ' '}
                                            </td>
                                        </tr>
                                        {Object.keys(
                                            takeoutOrder.items[itemId]
                                        ).map((key, keyIndex) => {
                                            return takeoutOrder.items[itemId][
                                                key
                                            ].quantity ? (
                                                <tr key={key}>
                                                    <td style={itemStyle}>
                                                        {
                                                            takeoutOrder.items[
                                                                itemId
                                                            ][key].quantity
                                                        }{' '}
                                                        &nbsp;&nbsp;
                                                        <span
                                                            style={pStyleLight}
                                                        >
                                                            X
                                                        </span>
                                                        &nbsp;&nbsp;{' '}
                                                        {
                                                            takeoutOrder.items[
                                                                itemId
                                                            ][key].name
                                                        }
                                                        {takeoutOrder.items[
                                                            itemId
                                                        ][key].choice ? (
                                                            <div
                                                                style={
                                                                    toppingStyle
                                                                }
                                                            >
                                                                -{' '}
                                                                {
                                                                    takeoutOrder
                                                                        .items[
                                                                        itemId
                                                                    ][key]
                                                                        .choice
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div />
                                                        )}
                                                        {takeoutOrder.items[
                                                            itemId
                                                        ][key].toppings &&
                                                            takeoutOrder.items[
                                                                itemId
                                                            ][key].toppings.map(
                                                                t =>
                                                                    t.checked ? (
                                                                        <div
                                                                            key={
                                                                                key +
                                                                                t.id
                                                                            }
                                                                            style={
                                                                                toppingStyle
                                                                            }
                                                                        >
                                                                            -{' '}
                                                                            {
                                                                                t.name
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            key={
                                                                                key +
                                                                                t.id
                                                                            }
                                                                        />
                                                                    )
                                                            )}
                                                        {takeoutOrder.items[
                                                            itemId
                                                        ][key].notes ? (
                                                            <div
                                                                style={
                                                                    toppingStyle
                                                                }
                                                            >
                                                                [Note]:{' '}
                                                                {
                                                                    takeoutOrder
                                                                        .items[
                                                                        itemId
                                                                    ][key].notes
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div />
                                                        )}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <></>
                                            );
                                        })}
                                        <tr>
                                            <td style={itemTrStyleTopBorder}>
                                                {' '}
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </Fragment>
                                );
                            }
                        )}
                    </table>
                </div>
                <div style={bottomStyle}>
                    <span>&nbsp;</span>
                </div>
                <div style={pStyleBold}>
                    <center>
                        Thank You !
                        <br />
                        *** REPRINT ***
                    </center>
                </div>
            </body>
        </html>
    );
}
