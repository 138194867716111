import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { ORDER_TYPE } from './createOrderConstants';
import {
    createOrderReducer,
    ACTIONS,
    initTakeoutOrder,
} from './createOrderReducer';

//initial state of context. Also describes what all fields this context holds.
const initialState = {
    inProgressOrderType: ORDER_TYPE.TAKEOUT,
    inProgressTakeoutOrderIndex: 0,
    takeoutOrders: [initTakeoutOrder()],
    takeoutOrder: {},
};

//context providing access to this store.
const CreateOrderContext = createContext(initialState);

//provider to wrap the parent component
const CreateOrderProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(createOrderReducer, initialState);

    const setInProgressOrderType = inProgressOrderType =>
        dispatch({
            type: ACTIONS.SET_INPROGRESS_ORDER_TYPE,
            payload: inProgressOrderType,
        });
    const setInProgressTakeoutOrderIndex = inProgressOrderIndex =>
        dispatch({
            type: ACTIONS.SET_INPROGRESS_ORDER_INDEX,
            payload: inProgressOrderIndex,
        });
    const startNewOrder = () => dispatch({ type: ACTIONS.START_NEW_ORDER });
    const startEditOrder = takeoutOrder =>
        dispatch({ type: ACTIONS.START_EDIT_ORDER, payload: takeoutOrder });
    const clearOrder = () => dispatch({ type: ACTIONS.CLEAR_ORDER });

    //TODO just use id, and fetch rest details from menu context
    const addItem = (
        id,
        name,
        quantity,
        price,
        desc,
        toppingsStr,
        toppings,
        choice,
        choiceId,
        choices,
        notes,
        status,
        station,
        modifierGroup,
        isCustomItem
    ) =>
        dispatch({
            type: ACTIONS.ADD_ITEM,
            payload: {
                id,
                name,
                quantity,
                price,
                desc,
                toppingsStr,
                toppings,
                choice,
                choiceId,
                choices,
                notes,
                status,
                station,
                modifierGroup,
                isCustomItem,
            },
        });
    const udpateItem = (
        index,
        id,
        name,
        quantity,
        price,
        desc,
        toppingsStr,
        toppings,
        choice,
        choiceId,
        choices,
        notes,
        modifierGroup
    ) =>
        dispatch({
            type: ACTIONS.UPDATE_ITEM,
            payload: {
                index,
                id,
                name,
                quantity,
                price,
                desc,
                toppingsStr,
                toppings,
                choice,
                choiceId,
                choices,
                notes,
                modifierGroup,
            },
        });
    const removeItem = index =>
        dispatch({ type: ACTIONS.REMOVE_ITEM, payload: index });
    const setTip = tip => dispatch({ type: ACTIONS.SET_TIP, payload: tip });
    const setTogoOrDineIn = _togoOrDineIn =>
        dispatch({ type: ACTIONS.SET_TOGO_OR_DINEIN, payload: _togoOrDineIn });
    const setPhoneNumber = phoneNumber =>
        dispatch({ type: ACTIONS.SET_PHONE_NUMBER, payload: phoneNumber });
    const setOrderUnderName = orderUnderName =>
        dispatch({
            type: ACTIONS.SET_ORDER_UNDER_NAME,
            payload: orderUnderName,
        });
    const setEstimatedTimeInMins = estimatedTimeInMins =>
        dispatch({
            type: ACTIONS.ESTIMATED_TIME_IN_MINS,
            payload: estimatedTimeInMins,
        });
    const setNoOfPrints = noOfPrints =>
        dispatch({ type: ACTIONS.SET_NO_OF_PRINTS, payload: noOfPrints });
    const setUserDetails = _userDetails =>
        dispatch({ type: ACTIONS.SET_USER_DETAILS, payload: _userDetails });

    const currentInProgressOrder =
        state.inProgressOrderType == ORDER_TYPE.TAKEOUT
            ? state.takeoutOrders[state.inProgressTakeoutOrderIndex]
            : {};
    const value = {
        ...state,
        takeoutOrder: currentInProgressOrder,
        setInProgressOrderType,
        setInProgressTakeoutOrderIndex,
        startNewOrder,
        clearOrder,
        startEditOrder,
        addItem,
        udpateItem,
        removeItem,
        setTip,
        setTogoOrDineIn,
        setPhoneNumber,
        setOrderUnderName,
        setEstimatedTimeInMins,
        setNoOfPrints,
        setUserDetails,
    };
    return (
        <CreateOrderContext.Provider value={value}>
            {children}
        </CreateOrderContext.Provider>
    );
};

export { CreateOrderContext, CreateOrderProvider };
