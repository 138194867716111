import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { PaymentContext } from '../../contexts/PaymentContext';
import {
    Dialog,
    DialogBannerName,
    DialogContent,
    DialogFooter,
    DialogShadow,
} from '../../FoodDialog/FoodDialog';
import AsyncProgressRibbonButton from '../CreateOrder/AsyncProgressRibbonButton';
import { Title } from '../../Styles/title';
import { addTipTakeoutOrder } from '../../apis/placeOrder';
import { RestaurantContext } from '../../contexts/RestaurantContext';
import { formatPrice } from '../../Data/FoodData';
import { OrderContainer } from '../../Order/Order';
import { TakeoutItemWithPrice } from '../../Order/QsrAndTakeoutOrders';
import {
    closeDineInOrder,
    updatePaymentsDineIn,
    updateTipDineIn,
} from '../../apis/DineIn';
import { Title20px } from '../../Order/Feed';
import { reportError } from '../../apis/reportError';
import OrderPrintButton from '../common/OrderPrintButton';

const ButtonBar = styled.div`
    margin-top: 20px;
    min-height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;
const ClearButton = styled(Title)`
    margin: 10px;
    color: black;
    height: 18px;
    border: solid 1px grey;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
`;
const MessageDiv = styled.div`
    padding-top: 20px;
    padding-left: 20px;
    font-size: 18px;
    color: black;
`;
const WarningMessageDiv = styled.div`
    padding-top: 20px;
    padding-left: 20px;
    font-size: 18px;
    color: red;
`;

export default function CashOrCardDialog({ onClose, order }) {
    const { processCardPayment, processCashPayment } = useContext(
        PaymentContext
    );
    const [paymentStatus, setPaymentStatus] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { restauId } = useContext(RestaurantContext);

    const onCashSelected = async () => {
        try {
            if (order.isDineIn) {
                await updatePaymentsDineIn(restauId, order);
            }
            const resultMsg = await processCashPayment(order);
            if (resultMsg && resultMsg.length > 0) {
                if (order.isDineIn) {
                    enqueueSnackbar(resultMsg, { variant: 'success' });
                } else {
                    enqueueSnackbar(resultMsg, { variant: 'success' });
                }
                setPaymentStatus({ success: true, message: resultMsg });
            }
        } catch (error) {
            console.error('cashpayment failed', error);
            setPaymentStatus({
                success: false,
                message: 'Accepting cash Payment failed.' + error,
            });
        }
    };

    const onCardSelected = async () => {
        try {
            if (order.isDineIn) {
                await updatePaymentsDineIn(restauId, order);
            }
            const amountToPay =
                order.payment_total - (order.total_amount_paid || 0);
            await processCardPayment(
                order,
                order.id,
                amountToPay,
                order.isDineIn,
                order.tableId
            );
            //const result = await processPayment(order.payment_total-(order.total_amount_paid||0), "usd");
            //const status = await captureTerminalPayment(result, order.id);
            if (order.isDineIn) {
                enqueueSnackbar('Payment accepted.', { variant: 'success' });
                setPaymentStatus({
                    success: true,
                    message: 'Payment successful for table ' + order.tableId,
                });
            } else {
                enqueueSnackbar('Payment accepted.', { variant: 'success' });
                setPaymentStatus({ success: true });
            }
        } catch (error) {
            console.log(error, order);
            reportError(window.restauId, window.email, {
                msg: { message: 'Card payment failure', order, error },
            });
            setPaymentStatus({
                success: false,
                message:
                    'Payment failed. ' +
                    (error
                        ? error.code
                            ? error.code + ': ' + error.error
                            : JSON.stringify(error)
                        : ''),
            });
        }

        //     .then((res1)=> console.log("RCaputrepayment resul", res1)))
        //     .catch(error => { alert("Error: "+ (error && error.code ? (error.code+error.error) : JSON.stringify(error)));})
    };
    
    //const { addTipTakeoutOrder } = useContext(PaymentContext);
    const updateTip = async (order, tipInCents) => {
        try {
            if (order.isDineIn) {
                await updateTipDineIn(restauId, order.tableId, tipInCents);
            } else {
                const token = await window.customerApp
                    .auth()
                    .currentUser.getIdToken();
                await addTipTakeoutOrder(token, {
                    restauId,
                    id: order.id,
                    tipInCents,
                });
            }
        } catch (e) {
            console.log('Failed to update tip', e);
            return;
        }
        setTipSelected({ ...tipSelected, [order.id]: tipInCents });
    };

    const [tipSelected, setTipSelected] = useState({});
    const customTipInputRef = useRef(null);
    return (
        <>
            <DialogShadow onClick={onClose} />
            {order.tip == 0 && typeof tipSelected[order.id] == 'undefined' ? (
                <Dialog>
                    <DialogBannerName>
                        <Title20px>Tip</Title20px>
                    </DialogBannerName>
                    <DialogContent>
                        <MessageDiv>
                            Subtotal:{' '}
                            {formatPrice(order.payment_subtotal / 100)}
                        </MessageDiv>
                        <ButtonBar>
                            <AsyncProgressRibbonButton
                                style={{
                                    width: '200px',
                                    padding: '10px 20px 10px 20px',
                                }}
                                //color={pomegranateRed}
                                onClick={() =>
                                    updateTip(
                                        order,
                                        Math.round(order.payment_subtotal * 0.1)
                                    )
                                }
                            >
                                <b>
                                    10% (
                                    {formatPrice(
                                        Math.round(
                                            order.payment_subtotal * 0.1
                                        ) / 100
                                    )}
                                    )
                                </b>
                            </AsyncProgressRibbonButton>
                            <AsyncProgressRibbonButton
                                style={{
                                    width: '200px',
                                    padding: '10px 20px 10px 20px',
                                }}
                                //color={pomegranateRed}
                                onClick={() =>
                                    updateTip(
                                        order,
                                        Math.round(
                                            order.payment_subtotal * 0.15
                                        )
                                    )
                                }
                            >
                                <b>
                                    15% (
                                    {formatPrice(
                                        Math.round(
                                            order.payment_subtotal * 0.15
                                        ) / 100
                                    )}
                                    )
                                </b>
                            </AsyncProgressRibbonButton>
                        </ButtonBar>
                        <ButtonBar>
                            <AsyncProgressRibbonButton
                                style={{
                                    width: '200px',
                                    padding: '10px 20px 10px 20px',
                                }}
                                //color={pomegranateRed}
                                onClick={() =>
                                    updateTip(
                                        order,
                                        Math.round(order.payment_subtotal * 0.2)
                                    )
                                }
                            >
                                <b>
                                    20% (
                                    {formatPrice(
                                        Math.round(
                                            order.payment_subtotal * 0.2
                                        ) / 100
                                    )}
                                    )
                                </b>
                            </AsyncProgressRibbonButton>
                            <AsyncProgressRibbonButton
                                style={{
                                    width: '200px',
                                    padding: '10px 20px 10px 20px',
                                }}
                                //color={pomegranateRed}
                                onClick={() => updateTip(order, 0)}
                            >
                                <b>No tip</b>
                            </AsyncProgressRibbonButton>
                        </ButtonBar>
                        <ButtonBar>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    <b>Amount $</b>
                                </div>
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    name="tip-other"
                                    placeholder="0.00"
                                    ref={customTipInputRef}
                                    style={{ marginLeft: '5px', width: '80px' }}
                                    min="0"
                                    max="999"
                                />
                                <AsyncProgressRibbonButton
                                    style={{
                                        width: '100px',
                                        marginLeft: '10px',
                                        padding: '10px 10px 10px 10px',
                                    }}
                                    //color={pomegranateRed}
                                    onClick={() => {
                                        if (customTipInputRef.current.value) {
                                            try {
                                                const tipOther = getInteger(
                                                    customTipInputRef.current.value.trim()
                                                );
                                                if (
                                                    tipOther > 10 &&
                                                    tipOther >
                                                        0.5 *
                                                            (order.payment_subtotal /
                                                                100)
                                                ) {
                                                    if (
                                                        !window.confirm(
                                                            'Continue with tip: ' +
                                                                tipOther
                                                        )
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                }
                                                if (tipOther > 0) {
                                                    return updateTip(
                                                        order,
                                                        Math.round(
                                                            tipOther * 100
                                                        )
                                                    );
                                                } else {
                                                    alert(
                                                        'Invalid tip: ' +
                                                            customTipInputRef
                                                                .current.value
                                                    );
                                                }
                                            } catch (e) {
                                                console.log(
                                                    'Failed to process tip'
                                                );
                                            }
                                        } else {
                                            alert('Enter tip amount');
                                        }
                                        return Promise.resolve();
                                    }}
                                >
                                    <b>Save</b>
                                </AsyncProgressRibbonButton>
                            </div>
                        </ButtonBar>
                    </DialogContent>
                    <DialogFooter>
                        <ClearButton onClick={onClose}>
                            <b>Cancel</b>
                        </ClearButton>
                    </DialogFooter>
                </Dialog>
            ) : paymentStatus && paymentStatus.success && order.isDineIn ? (
                <Dialog>
                    <DialogBannerName>Table: {order.tableId}</DialogBannerName>
                    <DialogContent>
                        {typeof paymentStatus.message != 'undefined' &&
                            paymentStatus.message &&
                            paymentStatus.message.length > 0 && (
                                <Title20px>{paymentStatus.message}</Title20px>
                            )}
                    </DialogContent>
                    <DialogFooter>
                        <OrderPrintButton order={order} receipt={true}>
                            <b>Print Receipt</b>
                        </OrderPrintButton>
                        <AsyncProgressRibbonButton
                            onClick={async () => {
                                await closeDineInOrder(
                                    restauId,
                                    order.tableId,
                                    order.id
                                );
                                onClose();
                            }}
                        >
                            <b>Close Order</b>
                        </AsyncProgressRibbonButton>
                        <ClearButton onClick={onClose}>
                            <b>Cancel</b>
                        </ClearButton>
                    </DialogFooter>
                </Dialog>
            ) : (
                <Dialog>
                    <DialogBannerName>Pay by Cash or Card? </DialogBannerName>
                    <DialogContent>
                        <OrderContainer>
                            <TakeoutItemWithPrice>
                                <div>Sub-Total</div>
                                <div />
                                <div>
                                    {formatPrice(order.payment_subtotal / 100)}
                                </div>
                            </TakeoutItemWithPrice>
                            <TakeoutItemWithPrice>
                                <div>Tax</div>
                                <div />
                                <div>
                                    {formatPrice(order.payment_salestax / 100)}
                                </div>
                            </TakeoutItemWithPrice>
                            {order['serviceFees'] ? (
                                <TakeoutItemWithPrice>
                                    <div>Service Charge</div>
                                    <div />
                                    <div>
                                        {formatPrice(
                                            order['serviceFees'] / 100
                                        )}
                                    </div>
                                </TakeoutItemWithPrice>
                            ) : (
                                ''
                            )}
                            {order['minCreditCardFees'] ? (
                                <TakeoutItemWithPrice>
                                    <div>Credit Card fees</div>
                                    <div />
                                    <div>
                                        {formatPrice(
                                            order['minCreditCardFees'] / 100
                                        )}
                                    </div>
                                </TakeoutItemWithPrice>
                            ) : (
                                ''
                            )}
                            <TakeoutItemWithPrice>
                                <div>Tip</div>
                                <div />
                                <div>
                                    {order.tip
                                        ? formatPrice(order.tip / 100)
                                        : ''}
                                </div>
                            </TakeoutItemWithPrice>
                            <TakeoutItemWithPrice>
                                <div>
                                    <b>Total</b>
                                </div>
                                <div />
                                <div>
                                    <b>
                                        {formatPrice(
                                            order['payment_total'] / 100
                                        )}
                                    </b>
                                </div>
                            </TakeoutItemWithPrice>
                            {order.total_amount_paid && (
                                <>
                                    <TakeoutItemWithPrice>
                                        <div>Amount Paid</div>
                                        <div />
                                        <div>
                                            {formatPrice(
                                                order.total_amount_paid / 100
                                            )}
                                        </div>
                                    </TakeoutItemWithPrice>
                                    <TakeoutItemWithPrice>
                                        <div>Unpaid Amount</div>
                                        <div />
                                        <div>
                                            {formatPrice(
                                                (order.payment_total -
                                                    order.total_amount_paid) /
                                                    100
                                            )}
                                        </div>
                                    </TakeoutItemWithPrice>
                                </>
                            )}
                        </OrderContainer>
                        {paymentStatus && (
                            <WarningMessageDiv>
                                {paymentStatus.message}
                            </WarningMessageDiv>
                        )}
                        <ButtonBar>
                            <AsyncProgressRibbonButton
                                style={{ width: '200px' }}
                                //color={pomegranateRed}
                                onClick={onCardSelected}
                            >
                                <b>Card</b>
                            </AsyncProgressRibbonButton>
                            <AsyncProgressRibbonButton
                                style={{ width: '200px' }}
                                //color={pomegranateRed}
                                onClick={onCashSelected}
                            >
                                <b>Cash</b>
                            </AsyncProgressRibbonButton>
                        </ButtonBar>
                    </DialogContent>
                    <DialogFooter>
                        <ClearButton onClick={onClose}>
                            <b>Cancel</b>
                        </ClearButton>
                    </DialogFooter>
                </Dialog>
            )}
        </>
    );
}

function getInteger(str) {
    var n = parseFloat(str);
    return n !== Infinity && String(n) === str && n >= 0 ? n : 0;
}
