import React, { useState, useEffect } from 'react';
import { pomegranateRed } from '../Styles/colors';

export function OrderAheadSpan({ localDate, isScheduledOrder }) {
    const [className, setClassName] = useState('');
    const [color, setColor] = useState('black');

    useEffect(() => {
        const int = setInterval(() => {
            setNotification(localDate, isScheduledOrder);
        }, 60000);
        return () => clearInterval(int);
    }, []);

    const isToday = someDate => {
        const today = new Date();
        return (
            someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
        );
    };

    const setNotification = (localDate, isScheduledOrder) => {
        if (isScheduledOrder) {
            let parsedDate = new Date();
            try {
                parsedDate = new Date(localDate.split(' ')[0]);
            } catch (e) {
                console.log(e);
            }
            if (isToday(parsedDate)) {
                setColor(pomegranateRed);
                setClassName('blinking');
            }
        }
    };

    return (
        <span
            style={{
                color: color,
            }}
            className={className}
        >
            {localDate}
        </span>
    );
}
