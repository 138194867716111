import React from 'react';

export function ModifierGroup({ item, styleObj }) {
    let detailedItems = [];
    if (item.modifierGroup && item.modifierGroup.modifiers) {
        for (let modifier of Object.values(item.modifierGroup.modifiers)) {
            let modifierStr = '';
            if (modifier.options) {
                for (let option of Object.values(modifier.options)) {
                    if (option.checked) {
                        if (modifierStr !== '') {
                            modifierStr += ', ';
                        }
                        modifierStr += option.label;
                    }
                }
            }
            if (modifierStr !== '') {
                detailedItems.push(
                    <div key={'mod' + modifier.id} style={styleObj}>
                        + {modifierStr}
                    </div>
                );
            }
        }
    }
    return detailedItems;
}
