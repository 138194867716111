import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { filterObjectByEntry, guid } from '../../utils/Util';
import { RestaurantContext } from '../RestaurantContext';
import { print } from './print';
import { PrintOrder } from './templates/PrintOrder';
import { PrintOrderDineIn } from './templates/PrintOrderDineIn';
import { PrintReceipt } from './templates/PrintReceipt';
import { RetryPrintButton } from '../../components/common/OrderPrintButton';
import { reportError } from '../../apis/reportError';
import { MenuContext } from '../MenuContext';

//context providing access to this store.
const PrintContext = createContext({});

const PrintProvider = ({ children }) => {
    // const printReceipt = ({takeoutOrder}) => {

    // }
    const {
        restauName,
        restauAddress,
        restauPhoneNumber,
        setPlayBeep,
        settings: { showPrintPrice, stationPrintingEnabled = false } = {},
    } = useContext(RestaurantContext);
    const { defaultStations } = useContext(MenuContext);

    const printOrder = ({
        order,
        numCopies = 1,
        reprint = false,
        receipt = false,
        isDineIn = false,
        filterStations = null,
    }) => {
        let prints = [];
        if (!receipt) {
            if (stationPrintingEnabled) {
                let itemByStation = Object.entries(order.items).reduce(
                    (prev, [itemId, item]) => {
                        const itemStations = item.station
                            ? item.station
                            : defaultStations;
                        Object.values(itemStations)
                            .filter(s => s != null)
                            .map(s => s.toLowerCase())
                            .forEach(eachStation => {
                                prev[eachStation] = [
                                    ...(prev[eachStation]
                                        ? prev[eachStation]
                                        : []),
                                    item,
                                ];
                            });
                        return prev;
                    },
                    {}
                );

                //only print stations part of filterStations. If null print all stations
                filterStations =
                    filterStations && filterStations.length > 0
                        ? filterStations.map(f => f.toLocaleLowerCase())
                        : null;
                // if(filterStations && filterStations.length>0){
                //     filterStationsKeys = filterStations.map(f => f.toLocaleLowerCase());
                //     //itemByStation = filterObjectByEntry(itemByStation, ([key, ]) => (filterStationsKeys.indexOf(key) >=0));
                // }

                prints = Object.entries(itemByStation)
                    .map(([station, items], index, allEntries) => {
                        if (
                            filterStations &&
                            !filterStations.includes(station)
                        ) {
                            return null;
                        }
                        const html = isDineIn
                            ? renderToString(
                                  <PrintOrderDineIn
                                      takeoutOrder={{ ...order, items }}
                                      stationDetails={{
                                          station,
                                          stationNumber: index + 1,
                                          totalStations: allEntries.length,
                                      }}
                                      reprint={reprint}
                                      showPrices={false}
                                  />
                              )
                            : renderToString(
                                  <PrintOrder
                                      takeoutOrder={{ ...order, items }}
                                      stationDetails={{
                                          station,
                                          stationNumber: index + 1,
                                          totalStations: allEntries.length,
                                      }}
                                      reprint={reprint}
                                      showPrices={false}
                                  />
                              );
                        return {
                            //html: order.number +" S: " + station  + " I:"  + items.map(i => i.name).join(","),
                            html,
                            station,
                            numCopies,
                        };
                    })
                    .filter(x => x != null); //null is returned for filtered stations
            } else {
                const html = isDineIn
                    ? renderToString(
                          <PrintOrderDineIn
                              takeoutOrder={order}
                              reprint={reprint}
                              showPrices={showPrintPrice}
                              restauName={restauName}
                              address={restauAddress}
                              phonenumber={restauPhoneNumber}
                          />
                      )
                    : renderToString(
                          <PrintOrder
                              takeoutOrder={order}
                              reprint={reprint}
                              showPrices={showPrintPrice}
                              restauName={restauName}
                              address={restauAddress}
                              phonenumber={restauPhoneNumber}
                          />
                      );
                prints = [
                    {
                        //html: order.items.map(i => i.id).join(","),
                        html,
                        numCopies,
                    },
                ];
                //console.log(html);
            }
        } else {
            prints = [
                {
                    //html: order.items.map(i => i.id).join(","),
                    html: renderToString(
                        <PrintReceipt
                            takeoutOrder={order}
                            showPrices={true}
                            restauName={restauName}
                            address={restauAddress}
                            phonenumber={restauPhoneNumber}
                        />
                    ),
                    numCopies,
                },
            ];
        }

        const resPro = [];
        prints.forEach(p => {
            resPro.push(
                print(p)
                    .then(value => {
                        return { status: 'fulfilled', value };
                    })
                    .catch(reason => {
                        return {
                            status: 'rejected',
                            reason,
                            station: p.station,
                        };
                    })
            );
        });
        return Promise.all(resPro).then(res =>
            res.reduce(
                (prev, curr) => {
                    if (curr.status == 'rejected') {
                        prev.success = false;
                        prev.errors = [
                            ...prev.errors,
                            { error: curr.reason, station: curr.station },
                        ];
                    }
                    return prev;
                },
                { success: true, errors: [] }
            )
        );
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackBarKeys, setSnackBarKeys] = useState({}); //tracks all failed print snackbar keys to close.
    //console.log("snackBarKeys: ", snackBarKeys);
    const printOrderWithRetrySnackBar = async ({
        order,
        numCopies = 1,
        reprint = false,
        receipt = false,
        filterStations = null,
        onSuccess,
    }) => {
        let printResult = null;
        let errorMessage = null;
        let failedStations = null;
        let isDineIn = order.isDineIn;
        try {
            printResult = await printOrder({
                order,
                numCopies,
                reprint,
                receipt,
                isDineIn,
                filterStations,
            });
            if (!printResult.success) {
                failedStations = printResult.errors
                    .map(e => e.station)
                    .filter(e => e && e.length > 0);
                errorMessage =
                    'Failed to print' +
                    (isDineIn
                        ? order.tableId
                            ? ' Table: ' + order.tableId
                            : ''
                        : order.number
                        ? ' Order ' + order.number
                        : '');
                setPlayBeep(true);
            } else {
                onSuccess && onSuccess();
                return true;
            }
        } catch (e) {
            reportError(window.restauId, window.email, {
                msg: {
                    message: 'Print exception',
                    numCopies,
                    receipt,
                    isDineIn,
                    filterStations,
                    error: e,
                },
            });
            setPlayBeep(true);
            //enqueueSnackbar("Failed to print for table "+tableId + " Error: "+(e ?JSON.stringify(e): ""),{variant:'error', persist:true});
            //errorMessage = "Failed to print for table ";//+tableId + " Error: "+(e ?JSON.stringify(e): "");
            errorMessage =
                'Failed to print' +
                (isDineIn
                    ? order.tableId
                        ? ' Table: ' + order.tableId
                        : ''
                    : order.number
                    ? ' Order ' + order.number
                    : '');
        }
        //not returned means error
        let uniqId = 'snackKey:' + guid();
        const setSnackBarKey = argStrOrFn => {
            if (typeof argStrOrFn !== 'function') {
                setSnackBarKeys(prev => {
                    return { ...prev, [uniqId]: argStrOrFn };
                });
            } else {
                //this func is only called to close snackbar
                setSnackBarKeys(prev => {
                    if (typeof prev[uniqId] != 'undefined') {
                        argStrOrFn(prev[uniqId]);
                        const newKeys = { ...prev };
                        delete newKeys[uniqId];
                        return newKeys;
                    }
                    return prev;
                });
            }
        };
        let errorSnackBar = (
            <RetryPrintButton
                {...{
                    errorMessage,
                    order,
                    printOrder,
                    numCopies,
                    reprint,
                    receipt,
                    setSnackBarKey,
                    closeSnackbar,
                    enqueueSnackbar,
                    //errorMessage, order, numCopies, reprint, receipt, failedStations,
                    failedStations,
                    printResult,
                    onSuccess,
                    onCompletion: () => {
                        setPlayBeep(false);
                        try {
                            setSnackBarKey(prev => {
                                if (prev != null) {
                                    closeSnackbar(prev);
                                    return true;
                                }
                                return null;
                            });
                        } catch (e) {}
                    },
                }}
            />
        );
        setSnackBarKey(
            enqueueSnackbar(errorSnackBar, { variant: 'error', persist: true })
        );
        return false;
    };
    const value = {
        //printReceipt,
        printOrder,
        printOrderWithRetrySnackBar,
    };

    return (
        <PrintContext.Provider value={value}>{children}</PrintContext.Provider>
    );
};

export { PrintContext, PrintProvider };
